import React from 'react';

class Privacy extends React.Component {
    render() {
        return (
            <div className="listing-flow">
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <div className="navbar">
                        {/* <button className="back" onClick={this.prevPage} >
                            <img className="backArrow" src={require("../img/icon-arrow-back.svg")}></img>
                            <div className="backText">
                                Back
                            </div>
                        </button> */}
                        <a href="/"><img className="logo" src={require("../img/logo.svg")} alt='free'></img></a>
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div className="pageHeadingPolicy">
                            Privacy Policy
                        </div>
                        <div className="pageSub">
                            Introduction
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            Free Free Free Ltd and More Simple Money Ltd (Free) want to give you the best Free experience possible. This Privacy Policy gives you a clear view of how we use personal information that you provide, our dedication to protecting it and your rights and the options you have to control your personal information and protect your privacy. It also outlines what personal information we collect about you when you visit our website, use our online platform, and speak to our Call Team, and how we use your personal information and the third parties we will share it with.
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            What information do we collect about you?
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            When you use our products and services we will ask for personal information (at the moment of collection we will explain what information is required in order for us to provide the particular product or service, and what information you may choose to provide), such as:
                            <ul>
                                <li>your postal address</li>
                                <li>your name</li>
                                <li>your IP address</li>
                                <li>your session data</li>
                                <li>your telephone numbers</li>
                                <li>your date of birth</li>
                                <li>your financial information, such as income and liabilities</li>
                            </ul>
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            How we protect your personal information
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            We are committed to protecting your personal information and implementing appropriate technical and organisational security measures to protect it against any unauthorised or unlawful processing and against any accidental loss, destruction or damage. We take every care to ensure that your personal data is kept secure. The security measures we take include:
                            <ul>
                                <li>only storing your personal data on our secure servers</li>
                                <li>ensuring that our staff receive regular data security awareness training</li>
                                <li>keeping paper records to a minimum</li>
                                <li>maintaining up to date firewalls and anti-virus software to minimise the risk of unauthorised access to our systems</li>
                            </ul>
                        Although we will do our best to protect your personal data, we cannot guarantee the security of personal data sent to our website; you send us personal data at your own risk.
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            How will we use the information about you?
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            We are committed to protecting your personal information and implementing appropriate technical and organisational security measures to protect it against any unauthorised or unlawful processing and against any accidental loss, destruction or damage. We take every care to ensure that your personal data is kept secure. The security measures we take include:
                            <ol>
                                <li><span style={{ textDecoration: "underline" }}>Provide and Improve the Free platform:</span> we use relevant personal information described above to allow you to access and use the Free platform and to interact with us and other parties. This may include passing your information to third parties, for example, Splento and MessageBird (see below)</li>
                                <li><span style={{ textDecoration: "underline" }}>Preventing money laundering:</span> we will also use your personal information at the appropriate time to conduct an identification check as we have a legal obligation to do so in accordance with the anti-money laundering regulations. We will share your personal information with our phone authentication provider, Message Bird. (see our Terms of Use for more detail). </li>
                                <li><span style={{ textDecoration: "underline" }}>Ability to proceed with a viewing and/or offer on a property:</span> if you are a potential buyer we are obliged to clarify your financial position i.e. do you have a mortgage agreed in principle or are you a cash buyer and whether you have a property to sell to enable you to purchase another property. We will process your personal information for the purposes of our legitimate interests in determining your ability to proceed with a purchase so that the seller has an informed choice should there be an offer. This also has the potential to speed up any subsequent transaction.</li>
                                <li><span style={{ textDecoration: "underline" }}>To engage with you in relation to our products and services:</span> we will use your personal information to provide you with the products and services you have requested from us. We will process your personal information in this way if it is necessary for the performance of the contract with us.</li>
                                <li><span style={{ textDecoration: "underline" }}>Voice Recordings:</span> we shall record calls to and from our Call Team in order to ensure that we comply with our customers’ instructions and our legal obligations to our customers (including those under privacy legislation such as GDPR).</li>
                            </ol>
                            <span style={{ textDecoration: "underline" }}>Information we receive from other sources.</span>
                            <br /><br />
                            We may combine this information with information you give to us and information we collect about you. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).
                            <br /><br />
                            Whilst we always want you to be aware of how we are using your personal data, this does not necessarily mean that we are required to ask for your consent before we can use it. In the day to day running of our business, we may use your personal data without asking for your consent because:
                            <ul>
                                <li>we are entering into and carrying out our obligations under a contract with you</li>
                                <li>we need to use your personal data for our own legitimate purposes (such as the administration and management of our business and the improvement of our services) and our doing so will not interfere with your privacy rights</li>
                            </ul>
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            How will we contact you?
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            We would like to send you information about products and services of ours, which may be of interest to you. If you have consented to receive marketing communications, you may opt-out at a later date.
                        <br /><br />
                        You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please contact us at
                        &nbsp;<a href="mailto:born@free.co.uk" style={{ textDecoration: "underline" }}>born@free.co.uk</a>.
                        <br /><br />
                        Free will not share your information for marketing purposes with other companies unless you have consented to us doing so.
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            Access to your information and correction
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            You have the right to request a copy of the information that we hold about you. If you would like a copy of some or all of your personal information, please email us at
                            &nbsp;<a href="mailto:born@free.co.uk" style={{ textDecoration: "underline" }}>born@free.co.uk</a>.
                            <br /><br />
                            We want to make sure that your personal information is accurate and up to date. You may visit your listings/buyer dashboard to correct or remove information you think is inaccurate.
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            How long will we keep your personal information?
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            We retain your personal data only for as long as we need to comply with our obligations under The Property Ombudsman Code of Practice and rules set by HMRC.  The retention periods are:
                        <ul>
                                <li>Six years for data related to a property transaction (such as a Valuation, Offer or Viewing)</li>
                                <li>Five years for data related to our obligations under Anti-Money Laundering regulations</li>
                                <li>18 months for other personal data not covered by one of the above.</li>
                            </ul>
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            Cookies
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            This policy explains what cookies are and how they are used on the Free website and applications. We encourage you to read the policy in full so that you understand what information we collect using cookies.
                        <br /><br />
                            <span style={{ fontWeight: "700", fontSize: "16px", marginTop: "10px", marginBottom: "10px" }}>
                                What are cookies?
                        </span>
                            <br /><br />
                        A “cookie” is a simple ‘text file’ which typically contains two pieces of information: a site name and a unique user identifier using your Internet Protocol (IP) address. If you agree, we will store these cookies on your browser or hard drive when you visit our website. We use the following four types of cookie:
                        <br /><br />
                            <span style={{ textDecoration: "underline" }}>Strictly Necessary Cookies</span>
                            <br /><br />
                        These cookies are essential to make our website work. Without these cookies, our website cannot operate effectively - Examples are; ‘Book a Valuation’, ‘Book a Viewing’ and ‘Make an Offer’.
                        <br /><br />
                            <span style={{ textDecoration: "underline" }}>Analytical and Performance Cookies</span>
                            <br /><br />
                        These cookies allow us to measure and analyse how our customers use the site, to improve both its functionality and your website experience. These cookies do not collect information that identifies you, instead they aggregate masses of data to provide information about how our website is performing.
                        <br /><br />
                            <span style={{ textDecoration: "underline" }}>Functionality Cookies</span>
                            <br /><br />
                        When you are browsing these cookies remember your preferences and help improve your website experience. These include things like remembering your browsing and language preferences or your chosen image size, meaning we can serve you the best possible experience each time you visit our site.
                        <br /><br />
                            <span style={{ textDecoration: "underline" }}>Targeted Advertising Cookies</span>
                            <br /><br />
                        These cookies record your visit to our website, the pages you have visited and the links you have followed. We use this information to deliver advertisements which are more relevant to you. For example; location information, buying or selling status. We may also share this information with third parties for this purpose and you may see our advertisements while using other third-party websites.
                        <br /><br />
                        By using our website, you agree to us placing these sorts of cookies on your device and accessing them when you visit our website in the future. You can delete any cookies that are already on your device through your browser and opt-out of targeted advertising cookies <a href="http://www.youronlinechoices.com/uk/your-ad-choices" target="_blank" rel='noopener noreferrer' style={{ textDecoration: "underline" }}>here</a>.
                        <br /><br />
                            <span style={{ textDecoration: "underline" }}>Our Cookies</span>
                            <br /><br />
                        To make full use of your online experience, your computer or device will need to accept cookies. If cookies are not enabled on your computer or device you will be unable to log in, request a valuation, book viewings, or receive an experience personalised to you.
                        <br /><br />
                            <span style={{ textDecoration: "underline" }}>Controlling the Use of Cookies</span>
                            <br /><br />
                        You can enable, disable and delete cookies through your browser. If you choose to disable cookies, our website may not operate correctly, and you may not be able to access secure areas of our website, make bookings or accept viewings. If you choose to delete your cookies, you may have to update your preferences with us again and the experience you receive upon returning to our site for the first time may be slightly degraded until our cookies update with your preferences again.
                        <br /><br />
                            {/* Click the links below to learn how to manage your cookie preferences through your browser:
                            <ul>
                                <li>Microsoft Internet Explorer</li>
                                <li>Google Chrome</li>
                                <li>Mozilla Firefox</li>
                                <li>Safari</li>
                            </ul> */}
                            <span style={{ textDecoration: "underline" }}>Advertising on Other Third Party Websites</span>
                            <br /><br />
                        We use Targeted Advertising Cookies to remember the services you are interested in when browsing our website. If you later view certain selected third-party websites or social media platforms, we are able to provide advertisements relating to those products and services that you have shown interest. The advertisement could appear in different formats, such as this example below.
                        <br /><br />
                        You can opt-out of targeted advertising shown on third party websites at any time on the <a href="http://www.youronlinechoices.com/uk/your-ad-choices" target="_blank" rel='noopener noreferrer' style={{ textDecoration: "underline" }}>Your Online Choices Website</a> or clicking the double lines in the bottom corner of the advertisement. If you decide to remove all your browser information including cookies the adverts will appear again following your visit to our website as the information will have been lost.
                        <br /><br />
                            <hr className="policyDivider" />
                        </div>

                        <div className="pageSub">
                            Cookies
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            Our website contains links to other websites. This Privacy Policy only applies to this website so when you link to other websites you should read their own privacy policies.
                        <br /><br />
                            <hr className="policyDivider" />
                        </div>

                        <div className="pageSub">
                            Changes to our Privacy Policy
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            We keep our Privacy Policy under regular review and we will place any updates on this web page. This Privacy Policy was last updated on 02/10/2020.
                        <br /><br />
                            <hr className="policyDivider" />
                        </div>

                        <div className="pageSub">
                            How to Contact Us
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            Please contact us if you have any questions about our privacy policy or information we hold about you:
                        <br /><br />
                        By email: <a href="mailto:born@free.co.uk" style={{ textDecoration: "underline" }}>born@free.co.uk</a>.
                        <br /><br />
                        We are committed to working with you to obtain a fair resolution of any complaint or concern about privacy. We comply with The Property Ombudsman Codes of Practice. If, however, you believe that we have not been able to assist with your complaint or concern, you have the right to make a complaint to your local data protection authority the Information Commissioners Office using their website.

                        {/* <br /><br /> */}
                            {/* <hr className="policyDivider" /> */}
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageContent">
                            Free free free Ltd
                            <br />
                            38B Enmore Road
                            <br />
                            London
                            <br />
                            SE25 5NG
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Privacy;