import React from 'react';
import AuthModal from './auth_modal';

function ThankYou(props) {
    return (
        <div className="adverse_flow">
            <AuthModal thankyou store={props.store} show ask_name prev={props.prev} setPrev={props.setPrev} setPage={props.setPage} />
            <div className="heading">
                Thank you for signing up
            </div>
            <div className="content">
                Please come back any time to continue your form or speak to an advisor.
            </div>
        </div>
    );
}

export default ThankYou;