import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function Employed(props) {
    const [display, setDisplay] = useState(null)

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Employment status",
                        content: "Lenders need to know this so they can work out how much they will lend you.",
                        buttons: {
                            "Permanent contract": {
                                value: "PERMANENT",
                                next: props.switch + 1
                            },
                            "Fixed term contract": {
                                value: "FIXED_TERM",
                                next: props.switch + 1
                            },
                            "Subcontractor (fixed term)": {
                                value: "SUBCONTRACTOR_FIXED_TERM",
                                next: props.switch + 1
                            },
                            "Subcontractor (open ended)": {
                                value: "SUBCONTRACTOR_OPEN_ENDED",
                                next: props.switch + 1
                            },
                            "Temporary contract": {
                                value: "TEMPORARY",
                                next: props.switch + 1
                            },
                        },
                    }} id={"employment_contract"} setValue={(e) => props.setValue({ ...props.store, employment_contract: e })} />
                )

            // case props.switch + 1:
            //     return <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
            //         heading: "Do you work full time or part time?",
            //         buttons: {
            //             "Full time": {
            //                 value: "FULL_TIME",
            //                 next: props.switch + 2
            //             },
            //             "Part time": {
            //                 value: "PART_TIME",
            //                 next: props.switch + 2
            //             },
            //         }
            //     }} id={"employment_basis"} setValue={(e) => props.setValue({ ...props.store, employment_basis: e })} />

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What's your annual income?",
                        content: "This is before tax, and excluding any  bonuses, commission, or overtime.",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "annual_income",
                            min: 10000,
                            next: props.switch + 2
                        }
                    }} id={"annual_income"} setValue={(e) => props.setValue({ ...props.store, annual_income: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Are there any monthly deductions included on your payslip?",
                        content: "This includes monthly childcare vouchers, student loan repayments, season ticket loan repayments, or other deductions after tax.",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "deductions",
                            next: props.getSwitch("retirementAge")
                        }
                    }} id={"deductions"} setValue={(e) => props.setValue({ ...props.store, deductions: e })} />
                )

            default:
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Employed;