import React, { useEffect, useState } from 'react';
import AddressHistory2 from '../../../apply/questions/address_history_2';
import Communications2 from '../../../apply/questions/communications_2';
import Country2 from '../../../apply/questions/country_2';
import Dependents2 from '../../../apply/questions/dependents_2';
import NationalInsurance2 from '../../../apply/questions/national_insurance_2';
import Smoker2 from '../../../apply/questions/smoker_2';
import Selection from '../../selection';

function SecondApplicant(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <span>
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            heading: "What's the other applicant's name?",
                            content: "We need to understand a few details about them.",
                            select: {
                                id: "title_2",
                                placeholder: "Title",
                                options: {
                                    "Mr": "MR",
                                    "Mrs": "MRS",
                                    "Miss": "MISS",
                                    "Ms": "MS",
                                    "Mx": "MX",
                                    "Dr": "DR",
                                    "Sir": "SIR",
                                    "Prof": "PROF",
                                    "Lord": "LORD",
                                    "Lady": "LADY",
                                    "Dame": "DAME",
                                    "Prefer not to say": "PREFER_NOT_TO_SAY"
                                }
                            }
                        }} id={"title_2"} setValue={(e) => props.setValue({ ...props.store, title_2: e })} />
                        <div style={{ marginTop: "-15px" }} />
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            input: {
                                type: "text",
                                id: "first_name_2",
                                placeholder: "First name",
                                next: props.switch + 1,
                                min: 1,
                                button: false
                            }
                        }} id={"first_name_2"} setValue={(e) => props.setValue({ ...props.store, first_name_2: e })} />
                        <div style={{ marginTop: "-15px" }} />
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            input: {
                                type: "text",
                                id: "last_name_2",
                                placeholder: "Last name",
                                next: props.switch + 1,
                                min: 1,
                                focus: false
                            }
                        }} id={"last_name_2"} setValue={(e) => props.setValue({ ...props.store, last_name_2: e })} />
                    </span>
                )

            case props.switch + 1:
                return (
                    <span>
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            reference: props.store.first_name_2 + "'s details",
                            heading: `How does ${props.store.first_name_2} identify?`,
                            content: `Please let us know the gender ${props.store.first_name_2} identifies with, and their marital status.`,
                            select: {
                                id: "gender_2",
                                placeholder: "Gender",
                                options: {
                                    "Male": "MALE",
                                    "Female": "FEMALE",
                                    "None": "NONE",
                                    "Other": "OTHER",
                                    "Prefer not to say": "PREFER_NOT_TO_SAY"
                                }
                            }
                        }} id={"gender_2"} setValue={(e) => props.setValue({ ...props.store, gender_2: e })} />
                        <div style={{ marginTop: "-15px" }} />
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            select: {
                                id: "marital_status_2",
                                placeholder: "Marital status",
                                options: {
                                    "Married": "MARRIED",
                                    "Single": "SINGLE",
                                    "Divorced": "DIVORCED",
                                    "Separated": "SEPARATED",
                                    "Widowed": "WIDOWED",
                                    "Cohabit": "COHABIT",
                                    "Civil partnership": "CIVIL_PARTNERSHIP"
                                },
                                next: props.switch + 2,
                                button: true
                            }
                        }} id={"marital_status_2"} setValue={(e) => props.setValue({ ...props.store, marital_status_2: e })} />
                    </span>
                )

            // case props.switch + 3:
            //     return <NationalInsurance2 name={props.store.first_name_2} next={() => {
            //         props.setPage(props.switch + 4)
            //         props.setPrev([...props.prev, props.switch + 4])
            //     }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />

            // case props.switch + 4:
            //     return <Smoker2 name={props.store.first_name_2} next={() => {
            //         props.setPage(props.switch + 5)
            //         props.setPrev([...props.prev, props.switch + 5])
            //     }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When is " + props.store.first_name_2 + "'s date of birth?",
                        content: "This helps us gauge the length of mortgages lenders can offer you.",
                        content2: "(Okay, we might send them a card too).",
                        input: {
                            type: "date",
                            id: "dob",
                            next: props.switch + 3,
                            min: 10
                        }
                    }} id={"date_of_birth_2"} setValue={(e) => props.setValue({ ...props.store, date_of_birth_2: e })} />
                )

            case props.switch + 3:
                return <span>
                    <Country2 name={props.store.first_name_2} next={() => {
                        props.setPage(props.switch + 4)
                        props.setPrev([...props.prev, props.switch + 4])
                    }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />
                </span>

            case props.switch + 4:
                return <AddressHistory2 name={props.store.first_name_2} next={() => {
                    props.setPage(props.switch + 5)
                    props.setPrev([...props.prev, props.switch + 5])
                }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />

            // case props.switch + 6:
            //     return (
            //         <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
            //             reference: props.store.first_name_2 + "'s details",
            //             heading: `How many dependents does ${props.store.first_name_2} have?`,
            //             content: "This includes any children or financially dependent adults over the age of 17 (please don't include any other applicants!)",
            //             buttons: {
            //                 "0": {
            //                     value: "0",
            //                     next: props.switch + 8
            //                 },
            //                 "1": {
            //                     value: 1,
            //                     next: props.switch + 7
            //                 },
            //                 "2": {
            //                     value: 2,
            //                     next: props.switch + 7
            //                 },
            //                 "3": {
            //                     value: 3,
            //                     next: props.switch + 7
            //                 },
            //                 accordion: {
            //                     id: "dependants_accordion_2",
            //                     phrase: "More options",
            //                     alt: "Less options",
            //                     buttons: {
            //                         "4": {
            //                             value: 4,
            //                             next: props.switch + 7
            //                         },
            //                         "5": {
            //                             value: 5,
            //                             next: props.switch + 7
            //                         },
            //                         "6": {
            //                             value: 6,
            //                             next: props.switch + 7
            //                         },
            //                         "7": {
            //                             value: 7,
            //                             next: props.switch + 7
            //                         },
            //                         "8": {
            //                             value: 8,
            //                             next: props.switch + 7
            //                         },
            //                         "9": {
            //                             value: 9,
            //                             next: props.switch + 7
            //                         }
            //                     }
            //                 }
            //             }
            //         }} id={"number_of_dependants_2"} setValue={(e) => props.setValue({ ...props.store, number_of_dependants_2: e })} />
            //     )

            // case props.switch + 7:
            //     return <span>
            //         <Dependents2 name={props.store.first_name_2} next={() => {
            //             props.setPage(props.switch + 8)
            //             props.setPrev([...props.prev, props.switch + 8])
            //         }} add={(a, b) => props.setValue({ ...props.store, [a]: b })} store={props.store} />
            //     </span>

            default:
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default SecondApplicant;