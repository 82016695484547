import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function Default2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        if (props.page === props.switch + 5 && props.store.default_date_2 !== undefined && props.store.default_amount_2 !== undefined && props.store.default_settled_2 !== undefined && props.store.default_for_mobile_2 !== undefined) {
            let store = { ...props.store }
            let default_date_2 = store.default_date_2
            let default_amount_2 = store.default_amount_2
            let default_settled_2 = store.default_settled_2
            let default_for_mobile_2 = store.default_for_mobile_2
            delete store.default_date_2
            delete store.default_amount_2
            delete store.default_settled_2
            delete store.default_for_mobile_2
            props.setValue({ ...store, defaults_2: [...props.store.defaults_2, { default_date_2: default_date_2, default_amount_2: default_amount_2, default_settled_2: default_settled_2, default_for_mobile_2: default_for_mobile_2 }] })
        }
        setDisplay(displayPage())
    }, [props.page, props.store])

    function remove_default(i) {
        props.store.defaults_2.splice(i, 1)
        props.setValue({ ...props.store, defaults_2: props.store.defaults_2 })
    }

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Has " + props.store.first_name_2 + " ever defaulted?",
                        info: {
                            id: "default_modal_3",
                            text: "What is a default?",
                            content: "A default means that you failed to repay a debt, so it negatively affects your credit score – causing it to go down.",
                            content2: "Lenders don’t like defaults because it’s a signal to them that you’ve struggled to repay debts in the past. And this means you’re considered a ‘high-risk’ borrower."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 6
                            }
                        }
                    }} id={"had_a_default_2"} setValue={(e) => props.setValue({ ...props.store, had_a_default_2: e, defaults_2: [] })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When was " + props.store.first_name_2 + "'s most recent default?",
                        input: {
                            type: "month",
                            placeholder: "MM - YYYY",
                            id: "default_date_2",
                            min: 7,
                            next: props.switch + 2
                        }
                    }} id={"default_date_2"} setValue={(e) => props.setValue({ ...props.store, default_date_2: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "How much was the default for?",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "default_amount_2",
                            next: props.switch + 3
                        }
                    }} id={"default_amount_2"} setValue={(e) => props.setValue({ ...props.store, default_amount_2: e })} />
                )

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When was the default settled?",
                        input: {
                            type: "month",
                            placeholder: "MM - YYYY",
                            id: "default_settled_2",
                            min: 7,
                            next: props.switch + 4
                        },
                        sub_button: {
                            "Or mark this Default as not yet satisfied": {
                                value: "ONGOING",
                                next: props.switch + 4
                            }
                        }
                    }} id={"default_settled_2"} setValue={(e) => props.setValue({ ...props.store, default_settled_2: e })} />
                )

            case props.switch + 4:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Was the default for a mobile phone?",
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 5
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 5
                            }
                        }
                    }} id={"default_for_mobile_2"} setValue={(e) => props.setValue({ ...props.store, default_for_mobile_2: e })} />
                )

            case props.switch + 5:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Please list all of " + props.store.first_name_2 + "'s defaults since May 2019",
                        info: {
                            id: "default_modal_4",
                            text: "What is a default?",
                            content: "A default means that you failed to repay a debt, so it negatively affects your credit score – causing it to go down.",
                            content2: "Lenders don’t like defaults because it’s a signal to them that you’ve struggled to repay debts in the past. And this means you’re considered a ‘high-risk’ borrower."
                        },
                        collection: {
                            type: "default",
                            append: "_2",
                            add: props.switch + 1
                        },
                        next: props.switch + 6
                    }} id={"default_collection_2"} setValue={(e) => { }} remove={(e) => remove_default(e)} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Default2;