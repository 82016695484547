import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function SelfEmployed(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "How are you self employed?",
                        buttons: {
                            "As a sole trader": {
                                value: "SOLE_TRADER",
                                next: props.switch + 1
                            },
                            "In a partnership": {
                                value: "PARTNERSHIP",
                                next: props.switch + 1
                            }
                        }
                    }} id={"self_employment"} setValue={(e) => props.setValue({ ...props.store, self_employment: e })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "How many years have you been in business?",
                        input: {
                            type: "years",
                            placeholder: "0",
                            id: "self_employed_years_in_business",
                            min: 1,
                            next: props.switch + 2
                        }
                    }} id={"self_employed_years_in_business"} setValue={(e) => props.setValue({ ...props.store, self_employed_years_in_business: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What was your annual net profit for 2020–2021?",
                        content: "This is after any business expenses, before tax, for the 2020-2021 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "self_employed_net_profit_20_21",
                            min: 10000,
                            next: props.switch + 3
                        }
                    }} id={"self_employed_net_profit_20_21"} setValue={(e) => props.setValue({ ...props.store, self_employed_net_profit_20_21: e })} />
                )

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What was your annual net profit for 2019–2020?",
                        content: "This is after any business expenses, before tax, for the 2019-2020 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "self_empolyed_net_profit_19_20",
                            min: 10000,
                            next: props.getSwitch("retirementAge")
                        }
                    }} id={"self_employed_net_profit_19_20"} setValue={(e) => props.setValue({ ...props.store, self_employed_net_profit_19_20: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default SelfEmployed;