import React, { Component } from 'react';
import FetchLenders from '../FetchLenders';
import Selections from '../prototype/selections';
import logos from "../logos";
import Accordion from '../../Accordion/Accordion';
import AccordionButton from '../../Accordion/AccordionButton';
import const_lenders from "../lenders.json"
import links from "../lenderLinks";
import reasons from "../prototype/lenderReasons";
import Toggle from '../prototype/toggle';
import ReactTooltip from 'react-tooltip';
import * as Icon from "react-bootstrap-icons";
import "./_step12.sass"
import superagent from "superagent"
import Loader from '../../loader';
import convert_name from '../skyscanner/convert_name';
import commaNumber from 'comma-number';
import ReactResizeDetector from 'react-resize-detector';
import Calculator from '../../tools/calculator';
import Optimiser2 from '../../tools/optimiser2';
import Optimiser from '../../tools/optimiser';
import Valuator from '../../tools/valuation';
import json from '../../tools/outcodes.json';

class Step12 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lenders: [],
            archive: [],
            arr: [],
            adverse_credit: null,
            style: this.props?.match?.params?.style || this.props.style,
            info: { ...const_lenders },
            income: 45000,
            data: {},
            outcodes: json,
            loading: true,
            propertyPrice: 230000,
            postcode: "SW11",
            interimPostcode: "SW11",
            toolSearch: false,
            postcodeNotFound: false,
            numberBeds: 2,
            overseas_buyer: null
        };
    }

    componentDidMount() {
        // this.broadcastHeight();

        window.addEventListener("resize", () => {
            this.broadcastHeight()
        })

        let [lenders, info] = FetchLenders([]);
        if (this.props.setLenders) this.props.setLenders(lenders)
        superagent
            .get(process.env.REACT_APP_API_URL + "/mbt")
            .then(res => {
                if (res.body.success) {
                    this.setState({
                        data: res.body.data,
                        loading: false
                    })
                }
            })
            .catch(err => console.log(err))
        this.setState({
            lenders: lenders,
            info: info
        }, () => this.broadcastHeight());

        let begin = 0;
        let end = this.state.outcodes.length;
        let prediction = [];
        if (this.state.postcode !== undefined) {
            for (var k = begin; k <= end; k++) {
                if (this.state.outcodes[k] !== undefined) {
                    let expr = (
                        <option value={this.state.outcodes[k].outcode}></option>
                    );
                    prediction.push(expr);
                }
            }
        }

        this.setState({
            predictionArray: prediction
        });
    }

    broadcastHeight() {
        let height = document.getElementById("free_iframe").scrollHeight + 115
        let message = "freeWindowHeight:" + height
        window.parent.postMessage(message, "*")
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { })
    }

    addOption(input) {
        let arr = this.state.arr;
        let [res, rep] = this.checkArray(arr, input);
        if (res === -1) {
            arr.push(input);
        } else if (res === -2) {
            arr.splice(rep, 1);
            arr.push(input);
        } else {
            arr.splice(rep, 1);
        }
        let [lenders, info] = FetchLenders(arr);
        let archive = this.archive(lenders);
        this.setState({
            arr: arr,
            lenders: lenders,
            archive: archive,
            info: info
        }, () => {
            if (this.props.setLenders) this.props.setLenders(this.state.lenders)
        });
    }

    removeOption(input) {
        let arr = this.state.arr
        let [res, rep] = this.checkArray(arr, input)
        if (res === -2 || res === 1) {
            arr.splice(rep, 1)
        }
        let [lenders, info] = FetchLenders(arr);
        let archive = this.archive(lenders);
        this.setState({
            arr: arr,
            lenders: lenders,
            archive: archive,
            info: info
        }, () => {
            if (this.props.setLenders) this.props.setLenders(this.state.lenders)
        })
    }

    wipeOptions() {
        let arr = []
        let ltv = this.findValue("LTV")
        if (ltv) arr.push({ "LTV": ltv })
        let [lenders, info] = FetchLenders(arr);
        let archive = this.archive(lenders);
        this.setState({
            arr: arr,
            lenders: lenders,
            archive: archive,
            info: info
        }, () => {
            if (this.props.setLenders) this.props.setLenders(this.state.lenders)
        })
    }

    archive(lenders) {
        let all = Object.keys(const_lenders);
        let arr = [];
        for (let i = 0; i < all.length; i++) {
            if (!lenders.includes(all[i])) {
                arr.push(all[i]);
            }
        }
        return arr;
    }

    checkArray(arr, comp) {
        for (let i = 0; i < arr.length; i++) {
            let l = Object.entries(arr[i]);
            let r = Object.entries(comp);
            if (l[0][0] === r[0][0] && l[0][1] === r[0][1]) {
                return [1, i];
            } else if (l[0][0] === r[0][0] && l[0][1] !== r[0][1]) {
                return [-2, i];
            }
        }

        return [-1, 0];
    }

    findValue(input) {
        let arr = this.state.arr;
        for (let i = 0; i < arr.length; i++) {
            let l = Object.entries(arr[i])[0];
            if (l[0] === input) {
                return l[1];
            }
        }
        return false
    }

    renderInfo(lender) {
        let arr = []
        let more_arr = []
        Object.entries(reasons[lender]).map(e => {
            if (e[0] === "LTV" && !this.state.info[lender].LTV["70%"]) e[1] = "Minimum 35% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["70%"]) e[1] = "Minimum 30% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["75%"]) e[1] = "Minimum 25% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["80%"]) e[1] = "Minimum 20% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["85%"]) e[1] = "Minimum 15% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["90%"]) e[1] = "Minimum 10% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["95%"]) e[1] = "Minimum 5% deposit."
            if (e[1] !== "" && !arr.includes(e[1]) && this.state.arr.filter(er => Object.keys(er)[0] === e[0]).length > 0) {
                if (!more_arr.includes(e[1])) arr.push(e[1])
                else {
                    more_arr.splice(more_arr.indexOf(e[1]), 1)
                    arr.push(e[1])
                }
            }
            else if (e[1] !== "" && !more_arr.includes(e[1]) && !arr.includes(e[1]) && !this.state.arr.filter(er => Object.keys(er)[0] === e[0]).length > 0) more_arr.push(e[1])
        })

        if (this.state.arr.length > 0) {
            return <div className="lenderInfo">
                <ul>{arr.map(el => <li key={el}>{el}</li>)}</ul>
                <Accordion id={lender + "_2"}>
                    <ul>{more_arr.map(el => <li key={el} style={{ paddingBottom: "12px" }}>{el}</li>)}</ul>
                </Accordion>
                <div style={{ paddingLeft: "39px" }}>
                    <AccordionButton id={lender + "_2"} phrase="See more" alt="See less" />
                </div>
            </div>
        } else {
            return <ul>{more_arr.map(el => <li key={el} style={{ paddingBottom: "12px" }}>{el}</li>)}</ul>
        }
    }

    toNearest(number, nearest, min, max) {
        if (number < min) return min
        else if (number > max) return max
        else return Math.round(number / nearest) * nearest
    }

    updatePostcode = (event) => {
        this.setState({
            interimPostcode: event.target.value.trim().toUpperCase()
        }, () => {
            let postcode = this.state.interimPostcode;
            let result = this.state.outcodes.filter(function (e) { return e.outcode === postcode });
            if (result[0] !== undefined) {
                this.setState({
                    postcodeNotFound: false,
                    location: result[0].locationIdent,
                    postcode: postcode
                });
            } else {
                this.setState({
                    postcodeNotFound: true
                });
            }
        });
    }

    render() {
        let arr = this.state.arr;
        let checkArray = (e) => this.checkArray(e);
        let addOption = (e) => this.addOption(e);
        let removeOption = (e) => this.removeOption(e)

        let parent = (window.location !== window.parent.location) ? document.referrer : document.location.href;

        return (
            <div className={this.state.style ? this.state.style + " step12" : "free step12"} style={{ paddingBottom: this.props.lower ? "115px" : "" }}>
                <Loader show={this.state.loading} />

                <div className="adverse_credit_container" id="free_iframe">
                    <ReactResizeDetector handleHeight handleWidth onResize={(w, h) => {
                        this.broadcastHeight()
                    }}>
                        <div className="adverse-credit">
                            {/* <div className="white" style={{ borderBottom: "1px solid black" }}>
                    <div className="white_content">
                        <img className="logo" src={require("../../img/logo.svg")} alt="free"></img>

                        <br />

                        
                    </div>
                </div> */}

                            <span style={{ display: this.props.remove_head ? "none" : "" }}>
                                <div className="heading">
                                    Get closer to owning your own home
                                </div>

                                <div className="sub_heading">
                                    Answer a couple of questions and find out which lender could give you a mortgage.
                                </div>
                            </span>

                            {(() => {
                                let no_adverse = ["closefield"]
                                if (no_adverse.includes(this.state.style)) return null
                                else {
                                    return <>
                                        <div className="high_level" style={{ display: this.state.adverse_credit !== null ? "none" : "" }}>
                                            <div style={{ marginBottom: "10px" }}>Do you have any adverse credit? &nbsp;<span style={{ verticalAlign: "middle" }} data-for="credit_info" data-tip="This includes things such as late payments, arrears, defaults, payday loans and CCJ's."><Icon.InfoCircle /></span></div>
                                            <ReactTooltip place="bottom" multiline effect="solid" id="credit_info" type={this.state.style === "creditladder" ? "light" : "dark"} backgroundColor={this.state.style === "creditladder" ? "white" : "black"} />
                                            <button className="secondary" onClick={() => this.setState({ adverse_credit: true })}>Yes</button>
                                            <button className="secondary" onClick={() => this.setState({ adverse_credit: false })}>No</button>
                                        </div>

                                        <span style={{ display: this.state.adverse_credit === null ? "none" : "" }}>
                                            <div className="high_level" style={{ marginBottom: "10px" }}>
                                                {/* <div style={{ marginBottom: "10px" }}>Do you have any adverse credit?
                                        &nbsp;<span style={{ verticalAlign: "middle" }} data-tip="This includes things such as late payments, arrears, defaults, payday loans and CCJ's."><Icon.InfoCircle /></span>
                                    </div> */}
                                                <ReactTooltip effect="solid" type="light" backgroundColor="white" />
                                                {this.state.adverse_credit !== false ? "Please select all that apply to you:" : "Please select your expected deposit:"}
                                            </div>
                                            <span style={{ display: this.state.adverse_credit === false ? "none" : "" }}>
                                                <Selections active={this.findValue("Payday Loans")} arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Payday Loans": "last 6 months" }}>Payday Loans</Selections>
                                                <Selections active={this.findValue("Defaults")} arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Defaults": "last 6 months" }}>Defaults</Selections>
                                                <Selections active={this.findValue("Bankruptcy")} arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Bankruptcy": "last 3 years" }}>Bankruptcy</Selections>
                                                <Selections active={this.findValue("Missed Secured Payment")} arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Missed Secured Payment": "last 6 months" }}>Missed secured payment</Selections>
                                                <Selections active={this.findValue("CCJ")} arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "CCJ": "last 6 months" }}>County court judgment</Selections>
                                                <Selections active={this.findValue("IVA")} arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "IVA": "last 3 years" }}>Individual voluntary arrangement</Selections>
                                                <Selections active={this.findValue("Missed Unsecured Payment")} arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Missed Unsecured Payment": "last 6 months" }}>Missed unsecured payment</Selections>
                                                <Selections active={this.findValue("DMP")} arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "DMP": "last 3 years" }}>Debt management plan</Selections>
                                                <Selections active={this.findValue("Repossession")} arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Repossession": "last 3 years" }}>Repossession</Selections>
                                            </span>
                                            <Toggle style={this.state.style} options={{
                                                "Expected deposit": {
                                                    "LTV": [95, 90, 85, 80, 75, 70]
                                                }
                                            }} addOption={addOption} value={this.findValue("LTV")} />
                                            <div className='reverse_decision' style={{ textDecoration: "underline", textUnderlineOffset: "4px", margin: "10px 0px 0px 0px", cursor: "pointer" }} onClick={() => this.setState({ adverse_credit: !this.state.adverse_credit }, () => this.wipeOptions())}>
                                                {this.state.adverse_credit ? "I don't have adverse credit" : "I have adverse credit"}
                                            </div>
                                        </span>

                                        <div className="additional_question" style={{ display: !this.findValue("Bankruptcy") ? "none" : "" }}>
                                            <div style={{ marginBottom: "5px" }}>
                                                When were you discharged from your bankruptcy?
                                            </div>
                                            <select onChange={e => this.addOption({ "Bankruptcy": e.currentTarget.value })} value={this.findValue("Bankruptcy")}>
                                                <option value={"last 3 years"}>Last 3 years</option>
                                                <option value={"last 6 years"}>Last 6 years</option>
                                                <option value={"> 6 years"}>Over 6 years</option>
                                                <option value={"ongoing"}>Ongoing</option>
                                            </select>
                                        </div>

                                        <div className="additional_question" style={{ display: !this.findValue("IVA") ? "none" : "" }}>
                                            <div style={{ marginBottom: "5px" }}>
                                                When were you discharged from your IVA?
                                            </div>
                                            <select onChange={e => this.addOption({ "IVA": e.currentTarget.value })} value={this.findValue("IVA")}>
                                                <option value={"last 3 years"}>Last 3 years</option>
                                                <option value={"last 6 years"}>Last 6 years</option>
                                                <option value={"> 6 years"}>Over 6 years</option>
                                                <option value={"ongoing"}>Ongoing</option>
                                            </select>
                                        </div>

                                        <div className="additional_question" style={{ display: !this.findValue("DMP") ? "none" : "" }}>
                                            <div style={{ marginBottom: "5px" }}>
                                                When were you discharged from your DMP?
                                            </div>
                                            <select onChange={e => this.addOption({ "DMP": e.currentTarget.value })} value={this.findValue("DMP")}>
                                                <option value={"last 3 years"}>Last 3 years</option>
                                                <option value={"last 6 years"}>Last 6 years</option>
                                                <option value={"> 6 years"}>Over 6 years</option>
                                                <option value={"ongoing"}>Ongoing</option>
                                            </select>
                                        </div>
                                    </>
                                }
                            })()}

                            {/* {(() => {
                                let no_overseas = ["creditladder", "nrla", "nude", "vesta"]
                                if (no_overseas.includes(this.state.style)) {
                                    return null
                                } else {
                                    return <>
                                        <div className="high_level" style={{ marginTop: "25px" }}>
                                        <div style={{ marginBottom: "10px" }}>Are you an overseas buyer?</div>
                                            &nbsp;<span style={{ verticalAlign: "middle" }} data-for="income_info" data-tip="Provide us with your gross, annual household income. Take 50% of any additional income like bonuses or commission.<br/><br/>If you're self-employed, give us an average of your last two years income.<br/><br/>Your results will be an approximation but you can speak to one of our advisers to get a more accurate result"><Icon.InfoCircle /></span>
                                            <ReactTooltip place="bottom" multiline effect="solid" id="income_info" type={this.state.style === "creditladder" ? "light" : "dark"} backgroundColor={this.state.style === "creditladder" ? "white" : "black"} />
                                            <button className="secondary" style={{opacity: this.state.overseas_buyer === false ? "0.5" : ""}} onClick={() => this.setState({ overseas_buyer: true })}>Yes</button>
                                            <button className="secondary" style={{opacity: this.state.overseas_buyer === true ? "0.5" : ""}} onClick={() => this.setState({ overseas_buyer: false })}>No</button>
                                        </div>
                                    </>
                                }
                            })()} */}

                            <div className="high_level" style={{ marginTop: "25px" }}>
                                What is your annual household income?&nbsp;<span style={{ verticalAlign: "middle" }} data-for="income_info" data-tip="Provide us with your gross, annual household income. Take 50% of any additional income like bonuses or commission.<br/><br/>If you're self-employed, give us an average of your last two years income.<br/><br/>Your results will be an approximation but you can speak to one of our advisers to get a more accurate result"><Icon.InfoCircle /></span>
                                <ReactTooltip place="bottom" multiline effect="solid" id="income_info" type={this.state.style === "creditladder" ? "light" : "dark"} backgroundColor={this.state.style === "creditladder" ? "white" : "black"} />
                            </div>
                            <div className="sticky" style={{ zIndex: 2, position: "sticky", top: "-1px", backgroundColor: "white", padding: "0px 15px 0px", margin: "0px -15px" }}>
                                <div className="input_wrap" style={{ marginTop: "15px" }}>
                                    <div style={{ display: "inline-block" }} className="pound_sign">£</div>
                                    <input className="input" type="number" placeholder="0" value={this.state.income} onChange={e => this.setState({ income: e.currentTarget.value })}></input>
                                </div>

                                <input type="range" min="10000" max="500000" value={this.state.income} className="slider" id="myRange" onChange={e => {
                                    let value = e.currentTarget.value
                                    if (value >= 10000 && value <= 200000) this.setState({
                                        income: this.toNearest(value, 1000, 10000, 500000)
                                    })
                                    else this.setState({
                                        income: this.toNearest(value, 25000, 10000, 500000)
                                    })
                                }} />
                                <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px" }}>
                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                        £10,000
                                    </div>
                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                        £500,000
                                    </div>
                                </div>
                            </div>


                            {/* <Accordion id="options_accordion">
                    
                    <Selections arr={arr} checkArray={checkArray} addOption={addOption} value={{ "DMP": "last 3 years" }}>Self Employed</Selections>
                    <Selections arr={arr} checkArray={checkArray} addOption={addOption} value={{ "DMP": "last 3 years" }}>Foreign National</Selections>
                </Accordion> */}
                            <br />
                            {/* <AccordionButton id="options_accordion" phrase="Show more options" alt="Show less options" /> */}

                            <div className="content">
                                {/* Find out which of the UK's top lenders will offer you a mortgage tailored to your situation: */}
                            </div>

                            {/* <span style={{ display: this.props.remove_buttons ? "none" : "" }}>
                            <div style={{ display: !this.props.combine ? "" : "none", position: "sticky", top: "15px", zIndex: "2" }}>
                                <div onClick={() => this.state.style ? window.location.href = "/adverse_credit_full/" + this.state.style : window.location.href = "/adverse_credit_full"}>
                                    <button className="primary">Fill out a form to see your mortgage rates</button>
                                </div>
                            </div>

                            <div style={{ display: !this.props.combine ? "" : "none" }}>
                                OR
                                <br /><br />
                            </div>

                            <div onClick={() => window.open("https://calendly.com/free40/book", "blank")}>
                                <button className="primary">Arrange a free, no obligation advisor call</button>
                            </div>
                        </span> */}

                            <div className="mins" style={{ textAlign: "left", lineHeight: "60px", fontSize: "18px" }}>
                                You're just 5 minutes from getting your personalised results.
                            </div>
                            <div className='cta_btn' onClick={() => window.open("/adverse_credit_full" + (this.state.style ? "/" + this.state.style + `?campaign=${parent === "https://www.creditladder.co.uk/" ? "iframe" : "none"}` : ""), "_blank")} style={{ cursor: "pointer", backgroundColor: this.state.style === "creditladder" ? "#5e00fa" : "black", color: "white", padding: "15px", fontSize: "18px", marginBottom: "0px", border: this.state.style === "creditladder" ? "1px solid white" : "", borderRadius: this.state.style === "creditladder" ? "40px" : "" }}>What mortgage rate can I get?</div>
                            {/* <div style={{ textAlign: "right", lineHeight: "60px" }}>
                                Fill out the form to find out.
                            </div> */}

                            <div className="speak" style={{ textAlign: "left", lineHeight: "60px", fontSize: "18px" }}>
                                Prefer to speak to us? <span onClick={() => {
                                    if (parent === "https://www.creditladder.co.uk/") {
                                        window.open("https://calendly.com/free40/book?utmSource=creditladder&utmMedium=website&utmCampaign=iframe", "_blank")
                                    } else {
                                        window.open("https://calendly.com/free40/book?utmSource=moresimplemoney&utmMedium&utmCampaign=none", "_blank")
                                    }
                                }} style={{ textDecoration: "underline", textUnderlineOffset: "4px", cursor: "pointer" }}>Book a call</span>
                            </div>

                            <div className="lender_group">
                                <div className="lender_number" style={{ margin: "20px 0px" }}>{this.state.lenders.length - this.state.data[this.toNearest(this.state.income, this.state.income <= 200000 ? 1000 : 25000)]?.quote_lenders.filter(e => e.status_message !== "Affordable" && this.state.lenders.includes(convert_name(e.name))).length} of 60 lenders can consider you for a mortgage.</div>
                                {this.state.data[this.toNearest(this.state.income, this.state.income <= 200000 ? 1000 : 25000, 10000, 500000)]?.quote_lenders.filter(e => e.status_message === "Affordable").map(e => {
                                    if (this.state.lenders.includes(convert_name(e.name)) && !this.state.archive.includes(convert_name(e.name))) {
                                        return <div key={convert_name(e.name)} className="lender_box">
                                            <div className="lender_logo">{logos[convert_name(e.name)]}</div>
                                            <div className="lender_info">
                                                <div className="lender_name">
                                                    {convert_name(e.name)}
                                                </div>
                                                <div className="lender_amount">
                                                    £{commaNumber(e.lend_amount_max || e.lend_amount)}
                                                </div>
                                                <div>
                                                    {
                                                        this.state.info[convert_name(e.name)].LTV["95%"] ? "Max 95% LTV" :
                                                            this.state.info[convert_name(e.name)].LTV["90%"] ? "Max 90% LTV" :
                                                                this.state.info[convert_name(e.name)].LTV["85%"] ? "Max 85% LTV" :
                                                                    this.state.info[convert_name(e.name)].LTV["80%"] ? "Max 80% LTV" :
                                                                        this.state.info[convert_name(e.name)].LTV["75%"] ? "Max 75% LTV" :
                                                                            this.state.info[convert_name(e.name)].LTV["70%"] ? "Max 70% LTV" :
                                                                                "Max 65% LTV"
                                                    }
                                                </div>
                                                <div className="sub_lender_accordion" style={{ display: !this.state.adverse_credit ? "" : "" }}>
                                                    <AccordionButton id={convert_name(e.name)} phrase="Learn more" alt="Learn less" extra={convert_name(e.name) + "_2"} />
                                                </div>
                                            </div>
                                            <Accordion id={convert_name(e.name)}>
                                                <div>
                                                    <div style={{ display: "inline-block", textDecoration: "underline", textUnderlineOffset: "4px", margin: "10px 0px", cursor: "pointer" }} onClick={() => window.open("/adverse_credit_full" + (this.state.style ? "/" + this.state.style : ""), "_blank")}>Get your personal quote</div>
                                                </div>
                                                <div className="lender_reasons" style={{ marginTop: "10px" }}>
                                                    Accepted Criteria:
                                                    {this.renderInfo(convert_name(e.name))}
                                                </div>
                                            </Accordion>
                                        </div>
                                    } else {
                                        return null
                                    }
                                })}
                                {this.state.lenders.sort().map(e => {
                                    if (this.state.data[this.toNearest(this.state.income, this.state.income <= 200000 ? 1000 : 25000, 10000, 500000)]?.quote_lenders.filter(f => convert_name(f.name) === e).length === 0) {
                                        // <div key={e} onClick={() => window.open(links[e], "_blank")} className="lender_section">
                                        return <div key={e} className="lender_box">
                                            <div className="lender_logo">
                                                {logos[e]}
                                            </div>
                                            <div className="lender_info">
                                                <div className="lender_name">
                                                    {e}
                                                </div>
                                                <div className="lender_amount" style={{}}>
                                                    <div style={{ display: "inline-block", textDecoration: "underline", textUnderlineOffset: "4px", cursor: "pointer" }} onClick={() => window.open("https://wa.me/447590501969", "_blank")}>Contact us</div> for a quote
                                                </div>
                                                <div style={{ marginTop: "4px" }}>
                                                    {
                                                        this.state.info[e].LTV["95%"] ? "Max 95% LTV" :
                                                            this.state.info[e].LTV["90%"] ? "Max 90% LTV" :
                                                                this.state.info[e].LTV["85%"] ? "Max 85% LTV" :
                                                                    this.state.info[e].LTV["80%"] ? "Max 80% LTV" :
                                                                        this.state.info[e].LTV["75%"] ? "Max 75% LTV" :
                                                                            this.state.info[e].LTV["70%"] ? "Max 70% LTV" :
                                                                                "Max 65% LTV"
                                                    }
                                                </div>
                                                <div className="sub_lender_accordion" style={{ display: !this.state.adverse_credit ? "" : "" }}>
                                                    <AccordionButton id={e} phrase="Learn more" alt="Learn less" extra={e + "_2"} />
                                                </div>
                                            </div>
                                            <Accordion id={e}>
                                                <div className="lender_reasons" style={{ marginTop: "10px" }}>
                                                    Accepted Criteria:
                                                    {this.renderInfo(e)}
                                                </div>
                                            </Accordion>
                                        </div>
                                    } else {
                                        return null
                                    }
                                })}
                            </div>

                            <Accordion id="rejected_lenders" noPadding extendMargin>
                                <ReactResizeDetector handleWidth handleHeight onResize={(w, h) => {
                                    if (document.getElementById("rejected_lenders").style.maxHeight !== "0px") {
                                        document.getElementById("rejected_lenders").style.maxHeight = (h + 100) + "px"
                                    }
                                }}>
                                    <div className="lender_group" style={{ backgroundColor: "#dddddd50" }}>
                                        <div className="lender_number_reject" style={{ margin: "20px 0px" }}>{(this.state.lenders.length + this.state.archive.length) - (this.state.lenders.length - this.state.data[this.toNearest(this.state.income, this.state.income <= 200000 ? 1000 : 25000, 10000, 500000)]?.quote_lenders.filter(e => e.status_message !== "Affordable" && this.state.lenders.includes(convert_name(e.name))).length)} of 60 lenders can't consider you for a mortgage.</div>
                                        {this.state.data[this.toNearest(this.state.income, this.state.income <= 200000 ? 1000 : 25000, 10000, 500000)]?.quote_lenders.map(e => {
                                            if (this.state.archive.includes(convert_name(e.name)) || (this.state.lenders.includes(convert_name(e.name)) && e.status_message !== "Affordable")) {
                                                return <div key={convert_name(e.name)} className="lender_box lender_box_reject" >
                                                    <div className="lender_logo">{logos[convert_name(e.name)]}</div>
                                                    <div className="lender_info">
                                                        <div className="lender_name">
                                                            {convert_name(e.name)}
                                                        </div>
                                                        {/* <div className="lender_amount">
                                                        {e.status_message === "Rejected eligibility criteria" ? "Rejected" : e.status_message}
                                                    </div> */}
                                                        <div className="sub_lender_accordion" style={{ display: !this.state.adverse_credit ? "" : "" }}>
                                                            <AccordionButton id={convert_name(e.name)} phrase="Learn more" alt="Learn less" extra={convert_name(e.name) + "_2"} />
                                                        </div>
                                                    </div>
                                                    <Accordion id={convert_name(e.name)}>
                                                        <div className="lender_reasons" style={{ marginTop: "10px" }}>
                                                            Accepted Criteria:
                                                            {this.renderInfo(convert_name(e.name))}
                                                        </div>
                                                    </Accordion>
                                                </div>
                                            } else {
                                                return null
                                            }
                                        })}
                                        {this.state.archive.sort().map(e => {
                                            if (this.state.data[this.toNearest(this.state.income, this.state.income <= 200000 ? 1000 : 25000, 10000, 500000)]?.quote_lenders.filter(f => convert_name(f.name) === e).length === 0) {
                                                // <div key={e} onClick={() => window.open(links[e], "_blank")} className="lender_section">
                                                return <div key={e} className="lender_box">
                                                    <div className="lender_logo">
                                                        {logos[e]}
                                                    </div>
                                                    <div className="lender_info">
                                                        <div className="lender_name">
                                                            {e}
                                                        </div>
                                                        <div className="sub_lender_accordion" style={{ display: !this.state.adverse_credit ? "" : "" }}>
                                                            <AccordionButton id={e} phrase="Learn more" alt="Learn less" extra={e + "_2"} />
                                                        </div>
                                                    </div>
                                                    <Accordion id={e}>
                                                        <div className="lender_reasons" style={{ marginTop: "10px" }}>
                                                            Accepted Criteria:
                                                            {this.renderInfo(e)}
                                                        </div>
                                                    </Accordion>
                                                </div>
                                            } else {
                                                return null
                                            }
                                        })}
                                    </div>
                                </ReactResizeDetector>
                            </Accordion>

                            {
                                (this.state.lenders.length + this.state.archive.length) - (this.state.lenders.length - this.state.data[this.toNearest(this.state.income, this.state.income <= 200000 ? 1000 : 25000, 10000, 500000)]?.quote_lenders.filter(e => e.status_message !== "Affordable" && this.state.lenders.includes(convert_name(e.name))).length) > 0 ?
                                    <AccordionButton id="rejected_lenders" phrase="View unavailable lenders" alt="Hide unavailable lenders" underline />
                                    : null
                            }

                            {
                                (() => {
                                    let no_stamp = []
                                    let no_below = ["closefield"]
                                    if (no_stamp.includes(this.state.style)) {
                                        return null
                                    } else {
                                        return <div className="accordion_wrap" style={{ marginBottom: no_below.includes(this.state.style) ? "" : "-1px", marginTop: "30px" }}>
                                            <AccordionButton id="stamp_calc" phrase="Calculate your stamp duty" chevron />
                                            <Accordion active id="stamp_calc">
                                                <Calculator style={this.state.style} />
                                            </Accordion>
                                        </div>
                                    }
                                })()
                            }


                            {
                                (() => {
                                    let no_valuation = ["closefield"]
                                    if (no_valuation.includes(this.state.style)) {
                                        return null
                                    } else {
                                        return <div className="accordion_wrap" style={{ marginBottom: "-1px" }}>
                                            <AccordionButton height={3000} id="val" phrase="Is the property a good price?" chevron />
                                            <Accordion id="val">
                                                <div className="valuator">
                                                    <div className="context">
                                                        <div className="contextHeading">
                                                            Are you getting a good price on your new home?
                                                        </div>
                                                        <div className="contextContent">
                                                            <div className="contextText">
                                                                We'll give you an estimated valuation of your new home based on your opinion of similar properties in the area.
                                                            </div>

                                                            <i>
                                                                Compare the location, quality of the interior, size of rooms, and any features to decide if the property is worth more or less than yours.
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!this.state.toolSearch ?
                                                    <div>
                                                        <div className="toolSearchHeading">
                                                            Just enter the details of your new home...
                                                        </div>
                                                        <div style={{ textAlign: "center" }}>
                                                            <div className="high_level" style={{ marginTop: "15px" }}>
                                                                What is the price of your desired house?
                                                            </div>
                                                            <div style={{ zIndex: 2, backgroundColor: "transparent", padding: "0px 15px 0px", margin: "0px -15px" }}>
                                                                <div className="input_wrap" style={{ marginTop: "15px" }}>
                                                                    <div style={{ display: "inline-block" }} className="pound_sign">£</div>
                                                                    <input className="input" type="number" placeholder="0" value={this.state.propertyPrice} onChange={e => this.setState({ propertyPrice: e.currentTarget.value })}></input>
                                                                </div>

                                                                <input type="range" min="50000" max="5000000" value={this.state.propertyPrice} className="slider" id="myRange" onChange={e => {
                                                                    let value = e.currentTarget.value
                                                                    if (value >= 50000 && value <= 200000) this.setState({
                                                                        propertyPrice: this.toNearest(value, 1000, 50000, 5000000)
                                                                    })
                                                                    else this.setState({
                                                                        propertyPrice: this.toNearest(value, 25000, 50000, 5000000)
                                                                    })
                                                                }} />
                                                                <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px" }}>
                                                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                                                        £50,000
                                                                    </div>
                                                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                                                        £5,000,000
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="high_level" style={{ marginTop: "25px" }}>
                                                                How many bedrooms does it have?
                                                            </div>
                                                            <div style={{ zIndex: 2, backgroundColor: "transparent", padding: "0px 15px 0px", margin: "0px -15px" }}>
                                                                <div className="input_wrap" style={{ marginTop: "15px" }}>
                                                                    {/* <div style={{ display: "inline-block" }} className="pound_sign">£</div> */}
                                                                    <input className="input" type="number" placeholder="0" value={this.state.numberBeds} onChange={e => this.setState({ numberBeds: e.currentTarget.value })}></input>
                                                                </div>

                                                                <input type="range" min="0" max="10" value={this.state.numberBeds} className="slider" id="myRange" onChange={e => {
                                                                    this.setState({
                                                                        numberBeds: e.currentTarget.value
                                                                    })
                                                                }} />
                                                                <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px" }}>
                                                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                                                        0
                                                                    </div>
                                                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                                                        10
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="high_level" style={{ marginTop: "25px" }}>
                                                                What is the area code?&nbsp;<span style={{ verticalAlign: "middle" }} data-for="income_info" data-tip="This is the first part of the postcode, e.g. SW11"><Icon.InfoCircle /></span>
                                                                <ReactTooltip place="bottom" multiline effect="solid" id="income_info" type={this.state.style === "creditladder" ? "light" : "dark"} backgroundColor={this.state.style === "creditladder" ? "white" : "black"} />
                                                            </div>
                                                            <div className="input_wrap" style={{ marginTop: "15px" }}>
                                                                {/* <div style={{ display: "inline-block" }} className="pound_sign">£</div> */}
                                                                <input list="postcodes" className="input" type="text" placeholder="SW11" value={this.state.interimPostcode} onChange={this.updatePostcode}></input>
                                                            </div>
                                                            <datalist id="postcodes">
                                                                {this.state.predictionArray}
                                                            </datalist>

                                                            <div>
                                                                <button onClick={() => this.setState({ toolSearch: true })} disabled={this.state.postcodeNotFound || !(this.state.propertyPrice > 0)} className="searchButton">Search</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <Valuator price={this.state.propertyPrice} postcode={this.state.postcode} numRooms={this.state.numberBeds} />
                                                }
                                            </Accordion>
                                        </div>
                                    }
                                })()
                            }


                            {
                                (() => {
                                    let no_optimiser = ["closefield"]
                                    if (no_optimiser.includes(this.state.style)) {
                                        return null
                                    } else {
                                        return <div className="accordion_wrap">
                                            <AccordionButton height={3000} id="opt" phrase="10,000 foot view of the area" chevron />
                                            <Accordion id="opt">
                                                <div className="valuator">
                                                    <div className="context">
                                                        <div className="contextHeading">
                                                            View all the properties in your search area
                                                        </div>
                                                        <div className="contextContent">
                                                            <div className="contextText">
                                                                Have a look at some of the other properties around yours with the same number of bedrooms.
                                                            </div>

                                                            <i>
                                                                The advert will get most attention if it is on the first page - more attention may mean more interest!
                                                            </i>
                                                        </div>
                                                    </div>
                                                </div>
                                                {!this.state.toolSearch
                                                    ?
                                                    <div>
                                                        <div className="toolSearchHeading">
                                                            Just enter the details of your new home...
                                                        </div>
                                                        <div style={{ textAlign: "center" }}>
                                                            <div className="high_level" style={{ marginTop: "15px" }}>
                                                                What is the price of your desired house?
                                                            </div>
                                                            <div style={{ zIndex: 2, backgroundColor: "transparent", padding: "0px 15px 0px", margin: "0px -15px" }}>
                                                                <div className="input_wrap" style={{ marginTop: "15px" }}>
                                                                    <div style={{ display: "inline-block" }} className="pound_sign">£</div>
                                                                    <input className="input" type="number" placeholder="0" value={this.state.propertyPrice} onChange={e => this.setState({ propertyPrice: e.currentTarget.value })}></input>
                                                                </div>

                                                                <input type="range" min="50000" max="5000000" value={this.state.propertyPrice} className="slider" id="myRange" onChange={e => {
                                                                    let value = e.currentTarget.value
                                                                    if (value >= 50000 && value <= 200000) this.setState({
                                                                        propertyPrice: this.toNearest(value, 1000, 50000, 5000000)
                                                                    })
                                                                    else this.setState({
                                                                        propertyPrice: this.toNearest(value, 25000, 50000, 5000000)
                                                                    })
                                                                }} />
                                                                <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px" }}>
                                                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                                                        £50,000
                                                                    </div>
                                                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                                                        £5,000,000
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="high_level" style={{ marginTop: "25px" }}>
                                                                How many bedrooms does it have?
                                                            </div>
                                                            <div style={{ zIndex: 2, backgroundColor: "transparent", padding: "0px 15px 0px", margin: "0px -15px" }}>
                                                                <div className="input_wrap" style={{ marginTop: "15px" }}>
                                                                    {/* <div style={{ display: "inline-block" }} className="pound_sign">£</div> */}
                                                                    <input className="input" type="number" placeholder="0" value={this.state.numberBeds} onChange={e => this.setState({ numberBeds: e.currentTarget.value })}></input>
                                                                </div>

                                                                <input type="range" min="0" max="10" value={this.state.numberBeds} className="slider" id="myRange" onChange={e => {
                                                                    this.setState({
                                                                        numberBeds: e.currentTarget.value
                                                                    })
                                                                }} />
                                                                <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px" }}>
                                                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                                                        0
                                                                    </div>
                                                                    <div className="slider_label" style={{ color: "#999", fontSize: "12px" }}>
                                                                        10
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="high_level" style={{ marginTop: "25px" }}>
                                                                What is the area code?&nbsp;<span style={{ verticalAlign: "middle" }} data-for="income_info" data-tip="This is the first part of the postcode, e.g. SW11"><Icon.InfoCircle /></span>
                                                                <ReactTooltip place="bottom" multiline effect="solid" id="income_info" type={this.state.style === "creditladder" ? "light" : "dark"} backgroundColor={this.state.style === "creditladder" ? "white" : "black"} />
                                                            </div>
                                                            <div className="input_wrap" style={{ marginTop: "15px" }}>
                                                                {/* <div style={{ display: "inline-block" }} className="pound_sign">£</div> */}
                                                                <input list="postcodes" className="input" type="text" placeholder="SW11" value={this.state.interimPostcode} onChange={this.updatePostcode}></input>
                                                            </div>
                                                            <datalist id="postcodes">
                                                                {this.state.predictionArray}
                                                            </datalist>

                                                            <div>
                                                                <button onClick={() => this.setState({ toolSearch: true })} disabled={this.state.postcodeNotFound || !(this.state.propertyPrice > 0)} className="searchButton">Search</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <Optimiser price={this.state.propertyPrice} postcode={this.state.postcode} numRooms={this.state.numberBeds} />
                                                }
                                            </Accordion>
                                        </div>
                                    }
                                })()
                            }

                            {/* <div className="white" style={{ display: this.state.lenders.length === 0 ? "none" : "" }}>
                            <div className="white_content">
                                <div className="content">
                                    {this.state.lenders.length} lender{this.state.lenders.length > 1 ? "s" : ""} can consider you for a mortgage
                                </div>

                                <div>
                                    {this.state.lenders.sort().map(e =>
                                        // <div key={e} onClick={() => window.open(links[e], "_blank")} className="lender_section">
                                        <div key={e} className="lender_section">
                                            <div className="sub_lender_logo">
                                                {logos[e]}
                                            </div>
                                            <div className="sub_lender">
                                                {e}<br />
                                                <div className="sub_lender_accordion">
                                                    <AccordionButton id={e} phrase="Learn more" alt="Learn less" extra={e + "_2"} />
                                                </div>
                                            </div>
                                            <Accordion id={e}>
                                                <div className="lender_reasons">
                                                    Accepted Criteria:
                                                    {this.renderInfo(e)}
                                                </div>
                                            </Accordion>
                                        </div>
                                    )}
                                     {this.state.lenders.map(e => <span>{logos[e]}</span>)} 
                                </div>
                            </div>
                        </div>

                        <div className="archive" style={{ display: this.state.archive.length === 0 ? "none" : "" }}>
                            <div className="content">
                                {this.state.archive.length} lender{this.state.archive.length > 1 ? "s" : ""} {this.state.archive.length > 1 ? "are" : "is"} unable to offer you a mortgage
                            </div>

                            <div style={{ filter: "grayscale(100%)" }}>
                                {this.state.archive.sort().map(e => <span>{logos[e]}</span>)}
                            </div>
                        </div> */}

                            <div className='bottom_text'>
                                <div style={{ textAlign: "left", lineHeight: "30px", marginTop: "20px" }}>
                                    Happy with your options? Take 5 minutes to <div onClick={() => window.open("/adverse_credit_full" + (this.state.style ? "/" + this.state.style : ""), "_blank")} style={{ display: "inline-block", textDecoration: "underline", textUnderlineOffset: "4px", cursor: "pointer" }}>fill out the full form</div> to see the rates lenders can offer you. Want to find out more, or have some questions? <div onClick={() => window.open("https://calendly.com/free40/book", "_blank")} style={{ display: "inline-block", textDecoration: "underline", textUnderlineOffset: "4px", cursor: "pointer" }}>Book a call</div> with us{/*} or chat to us on <div onClick={() => window.open("https://wa.me/447590501969", "_blank")} style={{ display: "inline-block", textDecoration: "underline", textUnderlineOffset: "4px", cursor: "pointer" }}>WhatsApp</div>*/}.
                                </div>

                                <div style={{ textAlign: "left", lineHeight: "30px", marginTop: "20px", display: !this.state.style ? "" : "none" }}>
                                    Ray? Is that you? <span onClick={() => window.open("/example_2", "_blank")} style={{ textDecoration: "underline", textUnderlineOffset: "4px", cursor: "pointer" }}>Click here to see some top secret stuff...</span>
                                </div>

                                <div className="content" style={{ display: this.props.remove_credit ? "none" : "", textAlign: "left" }}>
                                    {this.state.style ? this.state.style[0].toUpperCase() + this.state.style.slice(1) : null} © 2022 in partnership with 2022 More Simple Money Limited
                                </div>
                            </div>
                        </div>
                    </ReactResizeDetector >
                </div>
            </div>

        );
    }
}

export default Step12;