import React, { useEffect, useState } from 'react';
import "./_widget.sass"
import { isMobile, isMobileOnly } from "react-device-detect";
import ReactResizeDetector from 'react-resize-detector';
import * as Icon from "react-bootstrap-icons"

function Widget(props) {
    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const [count, setCount] = useState(1)

    useEffect(() => {
        if (window.MessageBirdChatWidget) {
            window.MessageBirdChatWidget.on('ready', () => {
                setShow(true)
            })
        }
    }, [])

    useEffect(() => {
        if (window.MessageBirdChatWidget && window.MessageBirdChatWidget.show) {
            window.MessageBirdChatWidget.toggleChat()
        }
    }, [open])

    useEffect(() => {
        var f = function () {
            let cta = document.getElementById("chat_cta_wrap")
            if (cta) cta.style.maxWidth = "232px"
        }

        if (show) setTimeout(f, 3000)
    }, [show])

    return (
        <div id="chat" style={{ display: props.show ? "" : "none" }}>

            <ReactResizeDetector handleWidth handleHeight onResize={(w, h) => {
                if (h === 0) {
                    setShow(false)
                    if (open) {
                        document.getElementById("chat_x").style.display = "block"
                    }
                }
                else setShow(true)
            }}>
                <div id="live-chat-widget" />
                <div id="chat_x" onClick={() => {
                    setOpen(!open)
                    setShow(true)
                    document.getElementById("chat_x").style.display = "none"
                }}><Icon.X /></div>
            </ReactResizeDetector>
            <div className="chat_widget">
                <div id="popup" style={{ display: show ? "" : "none" }}>
                    <div id="hem_face" onClick={() => setOpen(!open)}></div>
                    <div id="chat_cta_wrap">
                        <div id="chat_cta">
                            {/* <button id="book" onClick={() => window.open("https://calendly.com/free40/book", "_blank")}>
                                Book a call
                            </button> */}
                            <button id="whatsapp" onClick={() => window.open("https://wa.me/447590501969", "_blank")}>
                                <img src={require("../img/WhatsApp.png")} className="whatsapp_logo" /> 
                                <div id="text">
                                    Chat on <b>Whatsapp</b>
                                </div>
                            </button>
                            <div id="arrow" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Widget;