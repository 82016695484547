import React from 'react';
import "./_calculator.sass"

class Calculator extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			price: null,
			purchaseOption: 0,
			countryOption: 0,
			newStamp: 0,
			previousStamp: 0,
			savings: 0,
			style: null
		};
	}

	componentDidMount() {
		if (this.props?.match?.params?.style) this.setState({ style: this.props.match.params.style })
		else if (this.props?.style) this.setState({ style: this.props.style })
	}

	updatePrice = (e) => {
		let price = e.target.value.toString();
		if (price.length === 1) {
			price = null;
		} else {
			price = price.slice(2, e.target.value.toString().length);
			price = price.replace(/,/g, "");
		}

		if (price >= 0 || price === null) {
			this.setState({
				price: price
			}, () => {
				this.calculateSavings();
			});
		}
	}

	updatePurchaseOption = (e) => {
		let option = parseInt(e.target.value);
		this.setState({
			purchaseOption: option
		}, () => {
			this.calculateSavings();
		});

		document.getElementById("0").classList.remove("activeRadio");
		document.getElementById("1").classList.remove("activeRadio");
		document.getElementById("2").classList.remove("activeRadio");
		document.getElementById("3").classList.remove("activeRadio");
		document.getElementById(e.target.value).classList.add("activeRadio");
	}

	updateCountryOption = (e) => {
		let option = parseInt(e.target.value);
		this.setState({
			countryOption: option
		}, () => {
			this.calculateSavings();
		});

		document.getElementById("country0").classList.remove("activeRadio");
		document.getElementById("country1").classList.remove("activeRadio");
		document.getElementById("country2").classList.remove("activeRadio");
		document.getElementById("country3").classList.remove("activeRadio");
		document.getElementById("country" + e.target.value).classList.add("activeRadio");
	}

	calculateSavings = () => {
		let price = this.state.price;
		if (price > 40000) {
			if (this.state.countryOption === 0 || this.state.countryOption === 3) {
				if (this.state.purchaseOption === 0) {
					let newStamp = 0;
					if (price <= 500000) {
						newStamp = 0;
					}
					if (price > 500000 && price <= 925000) {
						newStamp += (price - 500000) / 100 * 5;
					}
					if (price > 925000 && price <= 1500000) {
						newStamp += (price - 500000) / 100 * 5;
						newStamp -= (price - 925000) / 100 * 5;
						newStamp += (price - 925000) / 100 * 10;
					}
					if (price > 1500000) {
						newStamp += (price - 500000) / 100 * 5;
						newStamp -= (price - 925000) / 100 * 5;
						newStamp += (price - 925000) / 100 * 10;
						newStamp -= (price - 1500000) / 100 * 10;
						newStamp += (price - 1500000) / 100 * 12;
					}

					let previousStamp = 0;
					if (price <= 300000) {
						previousStamp = 0;
					} else if (price <= 500000) {
						previousStamp += (price - 300000) / 100 * 5;
					} else if (price > 500000 && price <= 925000) {
						previousStamp += (price - 125000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
					} else if (price > 925000 && price <= 1500000) {
						previousStamp += (price - 125000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 925000) / 100 * 5;
						previousStamp += (price - 925000) / 100 * 10;
					} else if (price > 1500000) {
						previousStamp += (price - 125000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 925000) / 100 * 5;
						previousStamp += (price - 925000) / 100 * 10;
						previousStamp -= (price - 1500000) / 100 * 10;
						previousStamp += (price - 1500000) / 100 * 12;
					}

					this.setState({
						newStamp: newStamp,
						previousStamp: previousStamp,
						savings: previousStamp - newStamp
					});
				} else if (this.state.purchaseOption === 1) {
					let newStamp = 0;
					if (price <= 500000) {
						newStamp = 0;
					}
					if (price > 500000 && price <= 925000) {
						newStamp += (price - 500000) / 100 * 5;
					}
					if (price > 925000 && price <= 1500000) {
						newStamp += (price - 500000) / 100 * 5;
						newStamp -= (price - 925000) / 100 * 5;
						newStamp += (price - 925000) / 100 * 10;
					}
					if (price > 1500000) {
						newStamp += (price - 500000) / 100 * 5;
						newStamp -= (price - 925000) / 100 * 5;
						newStamp += (price - 925000) / 100 * 10;
						newStamp -= (price - 1500000) / 100 * 10;
						newStamp += (price - 1500000) / 100 * 12;
					}

					let previousStamp = 0;
					if (price <= 125000) {
						previousStamp += 0;
					} else if (price <= 250000) {
						previousStamp += (price - 125000) / 100 * 2;
					} else if (price > 250000 && price <= 925000) {
						previousStamp += (price - 125000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
					} else if (price > 925000 && price <= 1500000) {
						previousStamp += (price - 125000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 925000) / 100 * 5;
						previousStamp += (price - 925000) / 100 * 10;
					} else if (price > 1500000) {
						previousStamp += (price - 125000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 925000) / 100 * 5;
						previousStamp += (price - 925000) / 100 * 10;
						previousStamp -= (price - 1500000) / 100 * 10;
						previousStamp += (price - 1500000) / 100 * 12;
					}

					this.setState({
						newStamp: newStamp,
						previousStamp: previousStamp,
						savings: previousStamp - newStamp
					});
				} else if (this.state.purchaseOption === 2 || this.state.purchaseOption === 3) {
					let newStamp = 0;
					if (price >= 40000) {
						newStamp = price / 100 * 3;
						if (price <= 500000) {
							newStamp += 0;
						}
						if (price > 500000 && price <= 925000) {
							newStamp += (price - 500000) / 100 * 5;
						}
						if (price > 925000 && price <= 1500000) {
							newStamp += (price - 500000) / 100 * 5;
							newStamp -= (price - 925000) / 100 * 5;
							newStamp += (price - 925000) / 100 * 10;
						}
						if (price > 1500000) {
							newStamp += (price - 500000) / 100 * 5;
							newStamp -= (price - 925000) / 100 * 5;
							newStamp += (price - 925000) / 100 * 10;
							newStamp -= (price - 1500000) / 100 * 10;
							newStamp += (price - 1500000) / 100 * 12;
						}
					}

					let previousStamp = 0;
					if (price >= 40000) {
						previousStamp = price / 100 * 3;
					}
					if (price <= 125000) {
						previousStamp += 0;
					} else if (price <= 250000) {
						previousStamp += (price - 125000) / 100 * 2;
					} else if (price > 250000 && price <= 925000) {
						previousStamp += (price - 125000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
					} else if (price > 925000 && price <= 1500000) {
						previousStamp += (price - 125000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 925000) / 100 * 5;
						previousStamp += (price - 925000) / 100 * 10;
					} else if (price > 1500000) {
						previousStamp += (price - 125000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 925000) / 100 * 5;
						previousStamp += (price - 925000) / 100 * 10;
						previousStamp -= (price - 1500000) / 100 * 10;
						previousStamp += (price - 1500000) / 100 * 12;
					}

					this.setState({
						newStamp: newStamp,
						previousStamp: previousStamp,
						savings: previousStamp - newStamp
					});
				}
			} else if (this.state.countryOption === 1) {
				if (this.state.purchaseOption === 0 || this.state.purchaseOption === 1) {
					let threshold = 175000;
					if (this.state.purchaseOption === 1) {
						threshold = 145000;
					}

					let newStamp = 0;
					if (price <= 250000) {
						newStamp = 0;
					} else if (price <= 325000) {
						newStamp += (price - 250000) / 100 * 5;
					} else if (price <= 750000) {
						newStamp += (price - 250000) / 100 * 5;
						newStamp -= (price - 325000) / 100 * 5;
						newStamp += (price - 325000) / 100 * 10;
					} else if (price > 750000) {
						newStamp += (price - 250000) / 100 * 5;
						newStamp -= (price - 325000) / 100 * 5;
						newStamp += (price - 325000) / 100 * 10;
						newStamp -= (price - 750000) / 100 * 10;
						newStamp += (price - 750000) / 100 * 12;
					}

					let previousStamp = 0;
					if (price <= threshold) {
						previousStamp = 0;
					} else if (price <= 250000) {
						previousStamp = (price - threshold) / 100 * 2;
					} else if (price <= 325000) {
						previousStamp += (price - threshold) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
					} else if (price <= 750000) {
						previousStamp += (price - threshold) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 325000) / 100 * 5;
						previousStamp += (price - 325000) / 100 * 10;
					} else if (price > 750000) {
						previousStamp += (price - threshold) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 325000) / 100 * 5;
						previousStamp += (price - 325000) / 100 * 10;
						previousStamp -= (price - 750000) / 100 * 10;
						previousStamp += (price - 750000) / 100 * 12;
					}

					this.setState({
						newStamp: newStamp,
						previousStamp: previousStamp,
						savings: previousStamp - newStamp
					});
				} else if (this.state.purchaseOption === 2 || this.state.purchaseOption === 3) {
					let newStamp = 0;
					newStamp = price / 100 * 4;
					if (price <= 250000) {
						newStamp += 0;
					} else if (price <= 325000) {
						newStamp += (price - 250000) / 100 * 5;
					} else if (price <= 750000) {
						newStamp += (price - 250000) / 100 * 5;
						newStamp -= (price - 325000) / 100 * 5;
						newStamp += (price - 325000) / 100 * 10;
					} else if (price > 750000) {
						newStamp += (price - 250000) / 100 * 5;
						newStamp -= (price - 325000) / 100 * 5;
						newStamp += (price - 325000) / 100 * 10;
						newStamp -= (price - 750000) / 100 * 10;
						newStamp += (price - 750000) / 100 * 12;
					}

					let previousStamp = 0;
					previousStamp = price / 100 * 4;
					if (price <= 145000) {
						previousStamp += 0;
					} else if (price <= 250000) {
						previousStamp += (price - 145000) / 100 * 2;
					} else if (price <= 325000) {
						previousStamp += (price - 145000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
					} else if (price <= 750000) {
						previousStamp += (price - 145000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 325000) / 100 * 5;
						previousStamp += (price - 325000) / 100 * 10;
					} else if (price > 750000) {
						previousStamp += (price - 145000) / 100 * 2;
						previousStamp -= (price - 250000) / 100 * 2;
						previousStamp += (price - 250000) / 100 * 5;
						previousStamp -= (price - 325000) / 100 * 5;
						previousStamp += (price - 325000) / 100 * 10;
						previousStamp -= (price - 750000) / 100 * 10;
						previousStamp += (price - 750000) / 100 * 12;
					}

					this.setState({
						newStamp: newStamp,
						previousStamp: previousStamp,
						savings: previousStamp - newStamp
					});
				}
			} else if (this.state.countryOption === 2) {
				let newStamp = 0;
				if (price <= 250000) {
					newStamp = 0;
				} else if (price <= 400000) {
					newStamp = (price - 250000) / 100 * 5;
				} else if (price <= 750000) {
					newStamp = (price - 250000) / 100 * 5;
					newStamp -= (price - 400000) / 100 * 5;
					newStamp += (price - 400000) / 100 * 7.5;
				} else if (price <= 1500000) {
					newStamp = (price - 250000) / 100 * 5;
					newStamp -= (price - 400000) / 100 * 5;
					newStamp += (price - 400000) / 100 * 7.5;
					newStamp -= (price - 750000) / 100 * 7.5;
					newStamp += (price - 750000) / 100 * 10;
				} else if (price > 1500000) {
					newStamp = (price - 250000) / 100 * 5;
					newStamp -= (price - 400000) / 100 * 5;
					newStamp += (price - 400000) / 100 * 7.5;
					newStamp -= (price - 750000) / 100 * 7.5;
					newStamp += (price - 750000) / 100 * 10;
					newStamp -= (price - 1500000) / 100 * 10;
					newStamp += (price - 1500000) / 100 * 12;
				}

				let previousStamp = 0;
				if (price <= 180000) {
					previousStamp = 0;
				} else if (price <= 250000) {
					previousStamp = (price - 180000) / 100 * 3.5;
				} else if (price <= 400000) {
					previousStamp = (price - 180000) / 100 * 3.5;
					previousStamp -= (price - 250000) / 100 * 3.5;
					previousStamp += (price - 250000) / 100 * 5;
				} else if (price <= 750000) {
					previousStamp = (price - 180000) / 100 * 3.5;
					previousStamp -= (price - 250000) / 100 * 3.5;
					previousStamp += (price - 250000) / 100 * 5;
					previousStamp -= (price - 400000) / 100 * 5;
					previousStamp += (price - 400000) / 100 * 7.5;
				} else if (price <= 1500000) {
					previousStamp = (price - 180000) / 100 * 3.5;
					previousStamp -= (price - 250000) / 100 * 3.5;
					previousStamp += (price - 250000) / 100 * 5;
					previousStamp -= (price - 400000) / 100 * 5;
					previousStamp += (price - 400000) / 100 * 7.5;
					previousStamp -= (price - 750000) / 100 * 7.5;
					previousStamp += (price - 750000) / 100 * 10;
				} else if (price > 1500000) {
					previousStamp = (price - 180000) / 100 * 3.5;
					previousStamp -= (price - 250000) / 100 * 3.5;
					previousStamp += (price - 250000) / 100 * 5;
					previousStamp -= (price - 400000) / 100 * 5;
					previousStamp += (price - 400000) / 100 * 7.5;
					previousStamp -= (price - 750000) / 100 * 7.5;
					previousStamp += (price - 750000) / 100 * 10;
					previousStamp -= (price - 1500000) / 100 * 10;
					previousStamp += (price - 1500000) / 100 * 12;
				}

				if (this.state.purchaseOption === 2 || this.state.purchaseOption === 3) {
					newStamp += price / 100 * 3;
					previousStamp += price / 100 * 3;
				}

				this.setState({
					newStamp: newStamp,
					previousStamp: previousStamp,
					savings: previousStamp - newStamp
				});
			}
		} else {
			this.setState({
				newStamp: 0,
				previousStamp: 0,
				savings: 0
			});
		}
	}

	render() {
		let inputValue = "£ ";
		if (this.state.price !== null && this.state.price !== undefined) {
			inputValue = "£ " + this.state.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		}

		return (
			<div style={{ margin: window.location.pathname.split("/")[1] === "stamp_duty" ? "0 15" : "", width: window.location.pathname.split("/")[1] === "stamp_duty" ? "calc(100% - 30px)" : "100%" }} className={this.state.style ? "calculator_container " + this.state.style : "calculator_container"}>
				<div id="calculator" style={{ fontFamily: "Universal" }}>

					<h1>
						Stamp Duty Calculator
					</h1>

					<div className="input">
						<div className="inputLabel first">
							Property Price
						</div>
						<br />
						<input className="priceInput" type="text" value={inputValue} onChange={this.updatePrice}></input>
						<br /><br />
						<div className="inputLabel">
							I am purchasing...
						</div>
						<div className="purchaseSelect">
							<button id="0" className="radio activeRadio" onClick={this.updatePurchaseOption} value="0">My first house</button>

							<button id="1" className="radio" onClick={this.updatePurchaseOption} value="1">My next house</button>

							<button id="2" className="radio" onClick={this.updatePurchaseOption} value="2">An additional property</button>

							<button id="3" className="radio" onClick={this.updatePurchaseOption} value="3">A buy-to-let property</button>

						</div>
						<br />
						<div className="inputLabel">
							Country
						</div>
						<div className="purchaseSelect">
							<button id="country0" className="radio activeRadio" value="0" onClick={this.updateCountryOption}>England</button>

							<button id="country1" className="radio" value="1" onClick={this.updateCountryOption}>Scotland</button>

							<button id="country2" className="radio" value="2" onClick={this.updateCountryOption}>Wales</button>

							<button id="country3" className="radio" value="3" onClick={this.updateCountryOption}>N. Ireland</button>
						</div>
					</div>

					<div className="output" style={{ verticalAlign: "top" }}>
						{/* <div className="outputBlock">
						<div className="outputHeading">
							New stamp duty
						</div>
						<div className="outputExplain">
							This is the price you will pay if you complete your purchase before 31st March 2021
						</div>
						<div className="outputPrice">
							£{this.state.newStamp.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
						</div>
					</div> */}

						<div className="outputBlock" style={{ border: "none" }}>
							<div className="outputHeading first">
								Stamp duty
							</div>
							<div className="outputExplain">
								This is the price you'll need to pay on Stamp Duty
							</div>
							<div className="outputPrice totalSavings">
								£{this.state.previousStamp.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
							</div>
						</div>

						{/* <div>
						<div className="outputHeading">
							Total Savings
						</div>
						<div className="outputPrice totalSavings">
							£{this.state.savings.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
						</div>
					</div> */}
					</div>
				</div>
			</div>
		)
	}
}

export default Calculator;