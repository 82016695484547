import commaNumber from 'comma-number';
import React, { useEffect } from 'react';
import "./applied.sass"

function Applied(props) {
    useEffect(() => {
        console.log(props.product)
    }, [props.product])

    let name = props.product.name.split("-")

    let rate = props.product.name.slice(0, props.product.name.indexOf("%"))
    rate = rate.slice(rate.lastIndexOf(" "))

    let term = !isNaN(parseInt(props.product.name)) ? parseInt(props.product.name) : parseInt(props.product.name.slice(props.product.name.indexOf(" ")))
    if (name[0].includes("Month")) term = Math.round(term / 12)

    return (
        <div className='applied_wrap'>
            <div className='applied'>
                <div className='product'>
                    {getLogo(props.product.lender)}
                    {/* <br />
                    <b>Rate:</b> {rate}%
                    <br/>
                    <b>Term:</b> {term} years
                    <br />
                    <b>Fees:</b> £{commaNumber(props.product.fees.totalFees)}
                    <br />
                    <b>True cost:</b> £{commaNumber(props.product.truecostforperiod)} */}
                </div>
                {/* <div className='congrats'>
                    Congratulations on selecting your product!
                </div> */}
                <div className='next_heading'>
                    What's next?
                </div>
                <div className='content'>
                    You should receive an email soon that will
                    move you on to the next steps. Click the link in the email to upload your documents and perform 
                    a full credit check to progress your application.
                    <br /><br />
                    In the meantime, please book in a call with us to discuss your options. This will help to speed up your
                    application and get you familiarised with how we'll progress from here.
                </div>
                <button onClick={() => window.open("https://calendly.com/free40/book", "_blank")} className="primary">Book a call</button>
            </div>
        </div>
    );
}

function getLogo(lender) {
    try {
        // return logos[lender]
        return <img className='lender_logo_result' alt={lender} src={`https://api1.acreplatforms.co.uk/v1/acre/local-sourcing/lenderImage?lenderName=${lender.replace(/ /g, "%20")}`} />
        // return lender
    } catch (err) {
        return false
    }
}

export default Applied;