import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function PaydayLoan2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        if (props.page === props.switch + 3 && props.store.payday_loan_date_2 !== undefined && props.store.payday_loan_amount_2 !== undefined) {
            let store = { ...props.store }
            let payday_loan_date_2 = store.payday_loan_date_2
            let payday_loan_amount_2 = store.payday_loan_amount_2
            delete store.payday_loan_date_2
            delete store.payday_loan_amount_2
            props.setValue({ ...store, payday_loans_2: [...props.store.payday_loans_2, {payday_loan_date_2: payday_loan_date_2, payday_loan_amount_2: payday_loan_amount_2 }]})
        }
        setDisplay(displayPage())
    }, [props.page, props.store])

    function remove_payday_loan(i) {
        props.store.payday_loans_2.splice(i, 1)
        props.setValue({...props.store, payday_loans_2: props.store.payday_loans_2})
    }

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Has " + props.store.first_name_2 + " ever had a payday loan?",
                        info: {
                            id: "payday_loan_modal_3",
                            text: "What is a payday loan?",
                            content: "A payday loan is a short-term loan that can help you cover immediate cash needs until you get your next paycheck.",
                            content2: "Generally, lenders don’t like payday loans because they take it as a sign you might find yourself in financial difficulties in the future."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 4
                            }
                        }
                    }} id={"had_a_payday_loan_2"} setValue={(e) => props.setValue({ ...props.store, had_a_payday_loan_2: e, payday_loans_2: [] })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When did " + props.store.first_name_2 + " last take a payday loan?",
                        input: {
                            type: "month",
                            id: "payday_loan_date_2",
                            min: 7,
                            next: props.switch + 2
                        }
                    }} id={"payday_loan_date_2"} setValue={(e) => props.setValue({ ...props.store, payday_loan_date_2: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When was the payday loan settled?",
                        input: {
                            type: "month",
                            id: "payday_loan_settled_2",
                            min: 7,
                            next: props.switch + 3
                        }
                    }} id={"payday_loan_settled_2"} setValue={(e) => props.setValue({ ...props.store, payday_loan_settled_2: e })} />
                )

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Please list all of " + props.store.first_name_2 + "'s payday loans taken since May 2019",
                        info: {
                            id: "payday_loan_modal_4",
                            text: "What is a payday loan?",
                            content: "",
                            content2: ""
                        },
                        collection: {
                            type: "payday_loan",
                            append: "_2",
                            add: props.switch + 1
                        },
                        next: props.switch + 4
                    }} id={"payday_loan_collection_2"} setValue={(e) => {}} remove={(e) => remove_payday_loan(e)} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default PaydayLoan2;