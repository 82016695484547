import React, { useEffect, useState } from 'react';
import Accordion from '../../Accordion/Accordion';
import AccordionButton from '../../Accordion/AccordionButton';
import LandingResults from '../full/results/landing';
import AdverseCredit from '../prototype/AdverseCredit';
import ReactResizeDetector from 'react-resize-detector';
import "./_steps.sass"
import Skyscanner from '../skyscanner';

function Steps(props) {
    const [lenders, setLenders] = useState([])
    const [income, setIncome] = useState(10000)

    return (
        <div className={props.match.params.style ? props.match.params.style : "encompass"}>
            <div className="adverse_flow_steps" style={{ width: "700px", maxWidth: "calc(100% - 30px)", paddingBottom: props.lower ? "115px" : "20px" }}>
                <h1 className="content_heading">
                    Here to apply for a mortgage?
                </h1>
                <div className="content_content">
                    Want to buy a home with a mortgage? Have questions? We make mortgages and money more simple.
                </div>

                <div className="step">
                    <AccordionButton chevron id="step_1" phrase="Step 1: Who will lend to me?" />
                    <Accordion id="step_1">
                        <div style={{ height: "30px" }} />
                        <div style={{ textAlign: "left", marginBottom: "30px" }}>Tell us about your credit history, and we can tell you which lenders will lend to you.</div>
                        <AdverseCredit smaller_icons remove_head remove_buttons remove_credit setLenders={e => setLenders(e)} />
                    </Accordion>
                </div>
                <div className="step" style={{ marginTop: "-1px" }}>
                    <AccordionButton chevron id="step_2" phrase="Step 2: How much can I borrow?" />
                    <Accordion id="step_2">
                        <div style={{ height: "30px" }} />
                        <div style={{ textAlign: "left", marginBottom: "30px" }}>Check out how much you can borrow based on your income.</div>
                        <Skyscanner remove_head income={income} setIncome={e => setIncome(e)} />
                    </Accordion>
                </div>
                <div className="step" style={{ marginTop: "-1px" }}>
                    <AccordionButton chevron id="step_3" phrase="Step 3: How much will it cost?" />
                    <Accordion id="step_3">
                        <div style={{ height: "30px" }} />
                        <div style={{ textAlign: "left", marginBottom: "30px" }}>Check out the rates that lenders could offer you.</div>
                        <ReactResizeDetector handleWidth handleHeight onResize={(w, h) => {
                            document.getElementById("step_3").style.maxHeight = (h + 100) + "px"
                        }}>
                            <LandingResults remove_head lenders={lenders} income={income} />
                        </ReactResizeDetector>
                    </Accordion>
                </div>

                <div className="subheading" style={{ marginTop: "30px", paddingLeft: "0px", paddingRight: "0px" }}>
                    Ready to make that offer? Prove that you can borrow
                </div>
                <div className="content" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    Get a free mortgage-in-principle to give confidence to your offers, detailing how much you can borrow, and who could provide the mortgage.
                    <ul>
                        <li>Takes under 10 minutes</li>
                        <li>Does not affect your credit score</li>
                        <li>Absolutely free</li>
                    </ul>
                </div>

                <button onClick={() => window.location.href = "/adverse_credit_full"} className="result_button" style={{ marginLeft: "0px", marginRight: "0px" }}>Get a mortgage in principle</button>

                <div className="subheading" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    Need some questions answered before making your next move?
                </div>
                <div className="content" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    Arrange a quick call with one of our experienced advisors or chat to us on WhatsApp.
                </div>

                <button style={{ marginLeft: "0px", marginRight: "0px", backgroundColor: "white", border: "1px solid black", color: "black", marginBottom: "0px" }} className="alt_result_button" onClick={() => {
                    window.open("https://calendly.com/free40/book", "_blank")
                }}>Book a call</button>

                <button style={{ marginLeft: "0px", marginRight: "0px" }} className="alt_result_button" onClick={() => {
                    window.open("https://wa.me/447590501969", "_blank")
                }}>
                    <img style={{ width: "26px", verticalAlign: "middle", marginRight: "10px" }} src={require("../../img/WhatsApp.png")} className="whatsapp_logo" />
                    <div style={{ display: "inline-block", verticalAlign: "middle", paddingTop: "2px" }}>
                        Chat on <b>WhatsApp</b>
                    </div>
                </button>
            </div>
        </div>
    );
}

export default Steps;