import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Accordion from '../../../Accordion/Accordion';
import AccordionButton from '../../../Accordion/AccordionButton';
import Loader from '../../../loader';
import FetchLenders from '../../FetchLenders';
import reasons from '../../prototype/lenderReasons';
import AuthModal from '../auth/auth_modal';
import format_data from '../format_data';
import logos from './logos';
import pullAdverse from './pull_adverse';

function LandingResults(props) {
    const commaNumber = require('comma-number')
    const [deposit, setDeposit] = useState("5%")
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [lenders, setLenders] = useState([])
    const [dis, setDis] = useState([])
    const [money, setMoney] = useState(20000)
    const [income, setIncome] = useState(props.income)

    useEffect(() => {
        // console.log(props.store, format_data(props.store))
        let lenders = pullAdverse({})
        setLenders(lenders[0])
    }, [])

    useEffect(() => {
        (async () => {
            let res = await axios.get(process.env.REACT_APP_API_URL + "/mortgage_brain", {})
            if (res.data.success) {
                setData(res.data.data)
                setLoading(false)
            }
        })()
    }, [])

    useEffect(() => {
        if (document.getElementById("lender_table_body").children.length === 0 && loading === true) {
            document.getElementById("lender_table").style.display = "none"
            document.getElementById("no_results").style.display = "none"
            document.getElementById("loading_results").style.display = "block"
        } else if (document.getElementById("lender_table_body").children.length === 0 && loading === false) {
            // setDis([...dis, deposit])
            // setDeposit(parseInt(deposit) + 5 + "%")
            document.getElementById("lender_table").style.display = "none"
            document.getElementById("no_results").style.display = "block"
            document.getElementById("loading_results").style.display = "none"
        } else {
            document.getElementById("lender_table").style.display = "table"
            document.getElementById("no_results").style.display = "none"
            document.getElementById("loading_results").style.display = "none"
        }
    })

    function checkMath(input, comp) {
        return Math.round(input) === comp || Math.floor(input) === comp
        // || Math.ceil(input) === comp
    }

    function getEntries() {
        let entries = Object.entries(data).map(e => {
            let yr2 = 0
            let yr2Cost = 1000000000
            let yr5 = 0
            let yr5Cost = 1000000000
            let yr10 = 0
            let yr10Cost = 1000000000

            for (let i = 0; i < e[1].length; i++) {
                let cost = (e[1][i].initialMonthlyPayment * e[1][i].tieInPeriod) + e[1][i].totalFees

                if (e[1][i].maxLTV > (100 - parseInt(deposit))) {
                    if (e[1][i].maxLoan >= money * 100 / parseInt(deposit) && e[1][i].minLoan <= money * 100 / parseInt(deposit)) {
                        if (checkMath(e[1][i].tieInPeriod / 12, 2) && cost < yr2Cost) {
                            yr2Cost = cost
                            yr2 = e[1][i].initialRate
                        }
                        if (checkMath(e[1][i].tieInPeriod / 12, 5) && cost < yr5Cost) {
                            yr5Cost = cost
                            yr5 = e[1][i].initialRate
                        }
                        if (checkMath(e[1][i].tieInPeriod / 12, 10) && cost < yr10Cost) {
                            yr10Cost = cost
                            yr10 = e[1][i].initialRate
                        }
                    }
                }
            }

            if (!lenders.includes(e[0])) return null

            if (yr2 !== 0 || yr5 !== 0 || yr10 !== 0) {
                return <tr key={e[0]} style={{ filter: props.lenders.includes(e[0]) ? "" : "blur(10px)" }}>
                    <td>{logos[e[0]] ? logos[e[0]] : e[0]}</td>
                    <td value={yr2Cost}>{yr2 !== 0 ? yr2 + "%" : "-"}</td>
                    <td value={yr5Cost}>{yr5 !== 0 ? yr5 + "%" : "-"}</td>
                    <td value={yr10Cost}>{yr10 !== 0 ? yr10 + "%" : "-"}</td>
                </tr>
            }
        })
            .filter(e => e !== null)
            .sort((a, b) => {
                if (parseFloat(a.props.children[1].props.value) && parseFloat(b.props.children[1].props.value)) {
                    return parseFloat(a.props.children[1].props.value) - parseFloat(b.props.children[1].props.value)
                } else if (parseFloat(a.props.children[2].props.value) && parseFloat(b.props.children[2].props.value)) {
                    return parseFloat(a.props.children[2].props.value) - parseFloat(b.props.children[2].props.value)
                } else if (parseFloat(a.props.children[3].props.value) && parseFloat(b.props.children[3].props.value)) {
                    return parseFloat(a.props.children[3].props.value) - parseFloat(b.props.children[3].props.value)
                }
            })

        let first10 = entries.filter((e, ind) => ind < 10).filter(e => e !== undefined)
        let last = entries.filter((e, ind) => ind >= 10).filter(e => e !== undefined)

        // if (first10.length > 0) console.log(deposit)

        return [first10, last]
    }

    function toNearest(number, nearest) {
        return Math.round(number / nearest) * nearest
    }

    return (
        <div className="encompass">
            <div className="adverse_flow" style={{ width: "100%" }}>
                <Loader show={loading} text="This may take a moment" />
                <div className="heading" style={{ display: props.remove_head ? "none" : "" }}>
                    Adverse credit doesn’t need to get in the way of you finding a new home
                </div>
                <div className="content" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    {/* These fixed-rates are what lenders could offer you with a deposit of */}
                    Alter your deposit below to see the fixed-rates that lenders could offer you.
                    <div style={{ backgroundColor: "white", padding: "0px 15px 25px", margin: "0px -15px" }}>
                        <div className="input_wrap">
                            <div style={{ display: "inline-block" }} className="pound_sign">£</div>
                            <input className="input" type="number" placeholder="Income" value={money} onChange={e => setMoney(e.currentTarget.value)}></input>
                        </div>

                        <input type="range" min="5000" max="100000" value={money} className="slider" id="myRange" onChange={e => {
                            setMoney(e.currentTarget.value)
                        }} />
                        <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px" }}>
                            <div style={{ color: "#999", fontSize: "12px" }}>
                                £5,000
                            </div>
                            <div style={{ color: "#999", fontSize: "12px" }}>
                                £100,000
                            </div>
                        </div>
                    </div>
                    {/* <span className="underline">£{props.income}</span> and a deposit of <span className="underline">£50,000</span>. */}
                    {/* These fixed-rates are what lenders could offer you for a <span className="underline">£{commaNumber(Math.round(50000 * 100 / parseInt(deposit)))} property</span>, with a deposit of <span className="underline">£{commaNumber(50000)}</span> ({100 - parseInt(deposit)}% LTV). */}
                </div>
                <div className="content" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <span className="underline">Find out what you can borrow</span>
                </div>

                <div className="grey_box">
                    <div className="content">
                        Change the deposit amount below to see how it impacts what you could borrow:
                    </div>
                </div>

                <div className="deposit_changer" style={{ marginTop: "50px" }}>
                    <div className="deposit_value" style={{ left: `calc(100% / 6 * ${parseInt(deposit) / 5 - 1} + ${parseInt(deposit) > 15 ? "100% / 6" : "0px"})`, transform: parseInt(deposit) < 20 ? "" : "translate(-100%, 0%)" }}>
                        £{commaNumber(Math.round(money * 100 / parseInt(deposit)))} mortgage
                    </div>
                    <div className="deposit_arrow" style={{ left: `calc(100% / 6 * ${parseInt(deposit) / 5 - 1} + 100% / 6 / 2 - 10px)` }} />
                    <div disabled={dis.includes("5%")} onClick={dis.includes("5%") ? () => { } : () => setDeposit("5%")} className={dis.includes("5%") ? "deposit disabled" : deposit === "5%" ? "deposit active" : "deposit"}  style={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }} >5%</div>
                    <div disabled={dis.includes("10%")} onClick={dis.includes("10%") ? () => { } : () => setDeposit("10%")} className={dis.includes("10%") ? "deposit disabled" : deposit === "10%" ? "deposit active" : "deposit"}>10%</div>
                    <div disabled={dis.includes("15%")} onClick={dis.includes("15%") ? () => { } : () => setDeposit("15%")} className={dis.includes("15%") ? "deposit disabled" : deposit === "15%" ? "deposit active" : "deposit"}>15%</div>
                    <div disabled={dis.includes("20%")} onClick={dis.includes("20%") ? () => { } : () => setDeposit("20%")} className={dis.includes("20%") ? "deposit disabled" : deposit === "20%" ? "deposit active" : "deposit"}>20%</div>
                    <div disabled={dis.includes("25%")} onClick={dis.includes("25%") ? () => { } : () => setDeposit("25%")} className={dis.includes("25%") ? "deposit disabled" : deposit === "25%" ? "deposit active" : "deposit"}>25%</div>
                    <div disabled={dis.includes("30%")} onClick={dis.includes("30%") ? () => { } : () => setDeposit("30%")} className={dis.includes("30%") ? "deposit disabled" : deposit === "30%" ? "deposit active" : "deposit"}>30%</div>
                </div>

                <table className="lender_table" id="lender_table">
                    <thead>
                        <tr>
                            <td></td>
                            <td>2yr Fix</td>
                            <td>5yr Fix</td>
                            <td>10yr Fix</td>
                        </tr>
                    </thead>
                    <tbody id="lender_table_body">
                        {getEntries()[0]}
                    </tbody>
                </table>

                {getEntries()[1].length > 0 ?
                    <div>
                        <div id="table_acc" style={{ maxHeight: "0px", overflow: "hidden", transition: "0.2s all ease-in-out" }}>
                            <table style={{ marginTop: "-1px" }} className="lender_table" id="lender_table">
                                <tbody id="lender_table_body">
                                    {getEntries()[1]}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ margin: "15px 15px 0px 15px" }} className="table_acc_button">
                            <AccordionButton id="table_acc" phrase="Load more lenders" alt="Show less lenders" />
                        </div>
                    </div>
                    : null}

                <div id="loading_results" className="table_replace">
                    Loading results...
                </div>

                <div id="no_results" className="table_replace">
                    Sorry, no results at {100 - parseInt(deposit)}% LTV
                </div>
            </div>
        </div>
    );
}

export default LandingResults;