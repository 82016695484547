import React from 'react';
import * as Icon from "react-bootstrap-icons"

class Thanks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: 0
        };
    }

    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
        document.body.style.overflow = "hidden";

        // document.getElementsByClassName("bm-burger-button")[0].style.display = "none";
    }

    displayCalender = () => {
        let display = 1;
        if (this.state.display === 1) {
            display = 0;
        }
        this.setState({
            display: display
        });
    }

    render() {
        let display = "none";
        let frontDisplay = "block";
        if (this.state.display === 1) {
            display = "block";
            frontDisplay = "none";
        }

        let vh = window.innerHeight - 10;

        return (
            <div className="listing-flow" style={{  }}>

                <div style={{ display: frontDisplay, padding: "30px 0px", width: "100%", transform: "translate(-50%, -50%)", position: "absolute", top: "50%", left: "50%", textAlign: "center" }}>

                    <div className="pageHeading" style={{ textAlign: "center", paddingLeft: "30px", paddingRight: "30px", fontSize: "30px", margin: "0px 0px 25px 0px" }}>Thanks for keeping in touch with our progress.</div>

                    <div className="pageContent" style={{ textAlign: "center", marginBottom: "0px", paddingLeft: "30px", paddingRight: "30px", fontSize: "42px", margin: "0px 0px 25px 0px", lineHeight: "46px", textTransform: "uppercase" }}>Want to catch up on the phone?</div>

                    <button style={{ boxShadow: "0px 5px 10px 0px #999999", padding: "10px 25px", fontSize: "20px", border: "1px solid black", borderRadius: "10px" }} onClick={this.displayCalender}>Schedule a call with Ray</button>
                </div>

                {/* <!--<a href="" className="uk-button uk-button-large uk-width-1-1 cta-button">Setup a phone call</a>--> */}

                {/* <!-- Calendly link widget begin --> */}
                <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
                <div style={{ display: display }}>
                    <div onClick={this.displayCalender} style={{ display: display, fontSize: "40px", position: "absolute", right: "15px", zIndex: "5" }}>
                        <Icon.X />
                    </div>
                    <div
                        className="calendly-inline-widget"
                        data-url="https://calendly.com/free40/ray"
                        style={{ height: "1000px", maxHeight: "100vh", overflow: "hidden" }}
                    />
                </div>
                {/* <a href="" className="uk-button uk-button-large uk-width-1-1 cta-button" onClick={Calendly.initPopupWidget({url: 'https://calendly.com/free40?background_color=f8f8f8&text_color=64696f&primary_color=a400ff'})}>Schedule a call with Ray</a> */}
                {/* <!-- Calendly link widget end --> */}
            </div>

        );
    }
}

export default Thanks;