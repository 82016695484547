import React, { useEffect, useState } from 'react';
import Selection from '../selection';
import Results from '../results';
import ThankYou from '../auth/thankyou';
import AcreResults from '../acre_results/acre_results';
import AcreResults2 from '../acre_results/acre_results_2';

function AccountCreation(props) {
    const [createAccount, setCreateAccount] = useState(false)
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection originator={props.originator} prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Almost there...",
                        content: props.store.phone_number ? "Save your progress so far. This will allow us to retrieve sourcing and affordability for your case." : "Create an account to save your progress so far. This will allow us to retrieve sourcing and affordability for your case.",
                        buttons: props.store.phone_number ? {
                            "Save progress": {
                                value: true,
                                next: props.switch + 1,
                                dot: false,
                                small: true
                            }
                        } : {
                            "Create account": {
                                value: true,
                                next: props.switch + 1,
                                dot: false,
                                small: true
                            }
                        },
                        // content3: props.store.phone_number ? "Or see what you can borrow without saving your progress (you'll have to start from your last save point when you come back)." : "Or see what you can borrow without creating an account (you'll have to start again if you want to come back).",
                        // sub_button: {
                        //     "See your results without saving": {
                        //         value: false,
                        //         next: props.switch + 1
                        //     }
                        // }
                    }} id={"deposit"} setValue={(e) => setCreateAccount(e)} />
                )

            case props.switch + 1:
                return (
                    // <Results style={props.style} setValue={props.setValue} store={props.store} setPage={props.setPage} auth={createAccount} prev={props.prev} setPrev={props.setPrev} />
                    <AcreResults2 style={props.style} setValue={props.setValue} store={props.store} setPage={props.setPage} auth={createAccount} prev={props.prev} setPrev={props.setPrev} />
                )

            case props.switch + 2:
                return (
                    <ThankYou setValue={props.setValue} store={props.store} prev={props.prev} setPrev={props.setPrev} setPage={props.setPage} />
                )

            default: return
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default AccountCreation;