import React, { Component } from 'react';
import FetchLenders from '../FetchLenders';
import Selections from './selections';
import logos from "../logos";
import Accordion from '../../Accordion/Accordion';
import AccordionButton from '../../Accordion/AccordionButton';
import const_lenders from "../lenders.json"
import links from "../lenderLinks";
import reasons from "./lenderReasons";
import Toggle from './toggle';
import ReactTooltip from 'react-tooltip';
import * as Icon from "react-bootstrap-icons";
import "./_creditladder.sass"
import "./_black_and_white.sass"
import "./_nude.sass"
import "./_nrla.sass"
import "./_vesta.sass"
import "./_closefield.sass"

class AdverseCredit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lenders: [],
            archive: [],
            arr: [],
            adverse_credit: null,
            style: this.props.style,
            info: { ...const_lenders }
        };
    }

    componentDidMount() {
        // this.broadcastHeight();

        window.addEventListener("resize", this.broadcastHeight)

        let [lenders, info] = FetchLenders([]);
        if (this.props.setLenders) this.props.setLenders(lenders)
        this.setState({
            lenders: lenders,
            info: info
        }, () => this.broadcastHeight());
    }

    broadcastHeight() {
        let height = document.getElementById("free_iframe").scrollHeight
        let message = "freeWindowHeight:" + height
        window.parent.postMessage(message, "*")
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => { })
    }

    addOption(input) {
        let arr = this.state.arr;
        let [res, rep] = this.checkArray(arr, input);
        if (res === -1) {
            arr.push(input);
        } else if (res === -2) {
            arr.splice(rep, 1);
            arr.push(input);
        } else {
            arr.splice(rep, 1);
        }
        let [lenders, info] = FetchLenders(arr);
        let archive = this.archive(lenders);
        this.setState({
            arr: arr,
            lenders: lenders,
            archive: archive,
            info: info
        }, () => {
            if (this.props.setLenders) this.props.setLenders(this.state.lenders)
        });
    }

    toNearest(number, nearest) {
        return Math.round(number / nearest) * nearest
    }

    removeOption(input) {
        let arr = this.state.arr
        let [res, rep] = this.checkArray(arr, input)
        if (res === -2 || res === 1) {
            arr.splice(rep, 1)
        }
        let [lenders, info] = FetchLenders(arr);
        let archive = this.archive(lenders);
        this.setState({
            arr: arr,
            lenders: lenders,
            archive: archive,
            info: info
        }, () => {
            if (this.props.setLenders) this.props.setLenders(this.state.lenders)
        })
    }

    archive(lenders) {
        let all = Object.keys(const_lenders);
        let arr = [];
        for (let i = 0; i < all.length; i++) {
            if (!lenders.includes(all[i])) {
                arr.push(all[i]);
            }
        }
        return arr;
    }

    checkArray(arr, comp) {
        for (let i = 0; i < arr.length; i++) {
            let l = Object.entries(arr[i]);
            let r = Object.entries(comp);
            if (l[0][0] === r[0][0] && l[0][1] === r[0][1]) {
                return [1, i];
            } else if (l[0][0] === r[0][0] && l[0][1] !== r[0][1]) {
                return [-2, i];
            }
        }

        return [-1, 0];
    }

    findValue(input) {
        let arr = this.state.arr;
        for (let i = 0; i < arr.length; i++) {
            let l = Object.entries(arr[i])[0];
            if (l[0] === input) {
                return l[1];
            }
        }
        return false
    }

    renderInfo(lender) {
        let arr = []
        let more_arr = []
        Object.entries(reasons[lender]).map(e => {
            if (e[0] === "LTV" && !this.state.info[lender].LTV["70%"]) e[1] = "Minimum 35% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["70%"]) e[1] = "Minimum 30% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["75%"]) e[1] = "Minimum 25% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["80%"]) e[1] = "Minimum 20% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["85%"]) e[1] = "Minimum 15% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["90%"]) e[1] = "Minimum 10% deposit."
            if (e[0] === "LTV" && this.state.info[lender].LTV["95%"]) e[1] = "Minimum 5% deposit."
            if (e[1] !== "" && !arr.includes(e[1]) && this.state.arr.filter(er => Object.keys(er)[0] === e[0]).length > 0) {
                if (!more_arr.includes(e[1])) arr.push(e[1])
                else {
                    more_arr.splice(more_arr.indexOf(e[1]), 1)
                    arr.push(e[1])
                }
            }
            else if (e[1] !== "" && !more_arr.includes(e[1]) && !arr.includes(e[1]) && !this.state.arr.filter(er => Object.keys(er)[0] === e[0]).length > 0) more_arr.push(e[1])
        })

        if (this.state.arr.length > 0) {
            return <div className="lenderInfo">
                <ul>{arr.map(el => <li key={el}>{el}</li>)}</ul>
                <Accordion id={lender + "_2"}>
                    <ul>{more_arr.map(el => <li key={el} style={{ paddingBottom: "12px" }}>{el}</li>)}</ul>
                </Accordion>
                <AccordionButton id={lender + "_2"} phrase="See more" alt="See less" />
            </div>
        } else {
            return <ul>{more_arr.map(el => <li key={el} style={{ paddingBottom: "12px" }}>{el}</li>)}</ul>
        }
    }

    render() {
        let arr = this.state.arr;
        let checkArray = (e) => this.checkArray(e);
        let addOption = (e) => this.addOption(e);
        let removeOption = (e) => this.removeOption(e)

        return (
            <div className={this.state.style ? this.state.style : "free"} style={{ paddingBottom: this.props.lower ? "115px" : "" }}>
                <div className="adverse_credit_container" id="free_iframe">
                    <div className="adverse-credit">
                        {/* <div className="white" style={{ borderBottom: "1px solid black" }}>
                    <div className="white_content">
                        <img className="logo" src={require("../../img/logo.svg")} alt="free"></img>

                        <br />

                        
                    </div>
                </div> */}

                        <span style={{ display: this.props.remove_head ? "none" : "" }}>
                            <div className="heading">
                                Find out how much you could borrow.
                            </div>

                            <div className="sub_heading">
                                Answer a couple of questions and find out which lender could give you a mortgage.
                            </div>
                        </span>

                        <div className="high_level" style={{ display: this.state.adverse_credit !== null ? "none" : "" }}>
                            <span style={{ lineHeight: "30px" }}>Do you have any adverse credit? &nbsp;<span style={{ verticalAlign: "middle" }} data-tip="This includes things such as late payments, arrears, defaults, payday loans and CCJ's."><Icon.InfoCircle /></span></span>
                            <ReactTooltip effect="solid" type="light" backgroundColor="white" />
                            <br /><br />
                            <button className="secondary" onClick={() => this.setState({ adverse_credit: true })}>Yes</button>
                            <button className="secondary" onClick={() => this.setState({ adverse_credit: false })}>No</button>
                        </div>

                        <span style={{ display: this.state.adverse_credit === null ? "none" : "" }}>
                            <div className="high_level">
                                {this.state.adverse_credit !== false ? "Please select all that apply to you:" : "Please select your expected deposit:"}<br /><br />
                            </div>
                            <span style={{ display: this.state.adverse_credit === false ? "none" : "" }}>
                                <Selections arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Payday Loans": "last 6 months" }}>Payday Loans</Selections>
                                <Selections arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Defaults": "last 6 months" }}>Defaults</Selections>
                                <Selections arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Bankruptcy": "last 3 years" }}>Bankruptcy</Selections>
                                <Selections arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Missed Secured Payment": "last 6 months" }}>Missed secured payment</Selections>
                                <Selections arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "CCJ": "last 6 months" }}>County court judgment</Selections>
                                <Selections arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "IVA": "last 3 years" }}>Individual voluntary arrangement</Selections>
                                <Selections arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Missed Unsecured Payment": "last 6 months" }}>Missed unsecured payment</Selections>
                                <Selections arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "DMP": "last 3 years" }}>Debt management plan</Selections>
                                <Selections arr={arr} checkArray={checkArray} removeOption={removeOption} addOption={addOption} value={{ "Repossession": "last 3 years" }}>Repossession</Selections>
                            </span>
                            <Toggle options={{
                                "Expected deposit": {
                                    "LTV": [95, 90, 85, 80, 75, 70]
                                }
                            }} addOption={addOption} value={this.findValue("LTV")} />
                        </span>

                        <div className="additional_question" style={{ display: !this.findValue("Bankruptcy") ? "none" : "" }}>
                            When were you discharged from your bankruptcy?
                            <br />
                            <select onChange={e => this.addOption({ "Bankruptcy": e.currentTarget.value })} value={this.findValue("Bankruptcy")}>
                                <option value={"last 3 years"}>Last 3 years</option>
                                <option value={"last 6 years"}>Last 6 years</option>
                                <option value={"> 6 years"}>Over 6 years</option>
                                <option value={"ongoing"}>Ongoing</option>
                            </select>
                        </div>

                        <div className="additional_question" style={{ display: !this.findValue("IVA") ? "none" : "" }}>
                            When were you discharged from your IVA?
                            <br />
                            <select onChange={e => this.addOption({ "IVA": e.currentTarget.value })} value={this.findValue("IVA")}>
                                <option value={"last 3 years"}>Last 3 years</option>
                                <option value={"last 6 years"}>Last 6 years</option>
                                <option value={"> 6 years"}>Over 6 years</option>
                                <option value={"ongoing"}>Ongoing</option>
                            </select>
                        </div>

                        <div className="additional_question" style={{ display: !this.findValue("DMP") ? "none" : "" }}>
                            When were you discharged from your DMP?
                            <br />
                            <select onChange={e => this.addOption({ "DMP": e.currentTarget.value })} value={this.findValue("DMP")}>
                                <option value={"last 3 years"}>Last 3 years</option>
                                <option value={"last 6 years"}>Last 6 years</option>
                                <option value={"> 6 years"}>Over 6 years</option>
                                <option value={"ongoing"}>Ongoing</option>
                            </select>
                        </div>
                        {/* <Accordion id="options_accordion">
                    
                    <Selections arr={arr} checkArray={checkArray} addOption={addOption} value={{ "DMP": "last 3 years" }}>Self Employed</Selections>
                    <Selections arr={arr} checkArray={checkArray} addOption={addOption} value={{ "DMP": "last 3 years" }}>Foreign National</Selections>
                </Accordion> */}
                        <br />
                        {/* <AccordionButton id="options_accordion" phrase="Show more options" alt="Show less options" /> */}

                        <div className="content">
                            {/* Find out which of the UK's top lenders will offer you a mortgage tailored to your situation: */}
                        </div>

                        <span style={{ display: this.props.remove_buttons ? "none" : "" }}>
                            <div style={{ display: !this.props.combine ? "" : "none", position: "sticky", top: "15px", zIndex: "2" }}>
                                <div onClick={() => this.state.style ? window.location.href = "/adverse_credit_full/" + this.state.style : window.location.href = "/adverse_credit_full"}>
                                    <button className="primary">Fill out a form to see your mortgage rates</button>
                                </div>
                            </div>

                            <div style={{ display: !this.props.combine ? "" : "none" }}>
                                OR
                                <br /><br />
                            </div>

                            <div onClick={() => window.open("https://calendly.com/free40/book", "blank")}>
                                <button className="primary">Arrange a free, no obligation advisor call</button>
                            </div>
                        </span>

                        <div className="white" style={{ display: this.state.lenders.length === 0 ? "none" : "" }}>
                            <div className="white_content">
                                <div className="content">
                                    {this.state.lenders.length} lender{this.state.lenders.length > 1 ? "s" : ""} can consider you for a mortgage
                                </div>

                                <div>
                                    {this.state.lenders.sort().map(e =>
                                        // <div key={e} onClick={() => window.open(links[e], "_blank")} className="lender_section">
                                        <div key={e} className="lender_section">
                                            <div className="sub_lender_logo">
                                                {logos[e]}
                                            </div>
                                            <div className="sub_lender">
                                                {e}<br />
                                                <div className="sub_lender_accordion">
                                                    <AccordionButton id={e} phrase="Learn more" alt="Learn less" extra={e + "_2"} />
                                                </div>
                                            </div>
                                            <Accordion id={e}>
                                                <div className="lender_reasons">
                                                    Accepted Criteria:
                                                    {this.renderInfo(e)}
                                                </div>
                                            </Accordion>
                                        </div>
                                    )}
                                    {/* {this.state.lenders.map(e => <span>{logos[e]}</span>)} */}
                                </div>
                            </div>
                        </div>

                        <div className="archive" style={{ display: this.state.archive.length === 0 ? "none" : "" }}>
                            <div className="content">
                                {this.state.archive.length} lender{this.state.archive.length > 1 ? "s" : ""} {this.state.archive.length > 1 ? "are" : "is"} unable to offer you a mortgage
                            </div>

                            <div style={{ filter: "grayscale(100%)" }}>
                                {this.state.archive.sort().map(e => <span>{logos[e]}</span>)}
                            </div>
                        </div>

                        <div className="content" style={{ display: this.props.remove_credit ? "none" : "", textAlign: "left" }}>
                            CreditLadder © 2021 in partnership with 2020 More Simple Money Limited
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdverseCredit;