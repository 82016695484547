import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function CompanyDirector2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What's " + props.store.first_name_2 + "'s shareholding of the company?",
                        content: "This helps lenders understand hwo and what they can offer to lend you.",
                        buttons: {
                            "Less than 20%": {
                                value: "LESS_THAN_20",
                                next: props.switch + 1
                            },
                            "More than 20%": {
                                value: "MORE_THAN_20",
                                next: props.switch + 1
                            }
                        }
                    }} id={"shareholding_of_company_2"} setValue={(e) => props.setValue({ ...props.store, shareholding_of_company_2: e })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "How many years has " + props.store.first_name_2 + " been in business?",
                        input: {
                            type: "years",
                            placeholder: "0",
                            id: "company_director_years_in_business_2",
                            min: 1,
                            next: props.switch + 2
                        }
                    }} id={"company_director_years_in_business_2"} setValue={(e) => props.setValue({ ...props.store, company_director_years_in_business_2: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What was " + props.store.first_name_2 + "'s salary for 2019–2020?",
                        content: "This is before tax, and excluding any  bonuses, dividends, commission, or overtime, for the 2019-2020 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "company_director_net_profit_19_20_2",
                            min: 10000,
                            next: props.switch + 3
                        }
                    }} id={"company_director_net_profit_19_20_2"} setValue={(e) => props.setValue({ ...props.store, company_director_net_profit_19_20_2: e })} />
                )

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What was " + props.store.first_name_2 + "'s salary for 2018–2019?",
                        content: "This is before tax, and excluding any  bonuses, dividends, commission, or overtime, for the 2018-2019 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "company_director_net_profit_18_19_2",
                            min: 10000,
                            next: props.switch + 4
                        }
                    }} id={"company_director_net_profit_18_19_2"} setValue={(e) => props.setValue({ ...props.store, company_director_net_profit_18_19_2: e })} />
                )

            case props.switch + 4:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What was " + props.store.first_name_2 + "'s operating profit for 2019–2020?",
                        content: "This is any after all business expenses before tax, for the 2019-2020 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "company_director_operating_profit_19_20_2",
                            min: 10000,
                            next: props.switch + 5
                        }
                    }} id={"company_director_operating_profit_19_20_2"} setValue={(e) => props.setValue({ ...props.store, company_director_operating_profit_19_20_2: e })} />
                )

            case props.switch + 5:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What was " + props.store.first_name_2 + "'s operating profit for 2018–2019?",
                        content: "This is any after all business expenses before tax, for the 2018-2019 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "company_director_operating_profit_18_19_2",
                            min: 10000,
                            next: props.getSwitch("retirementAge2")
                        }
                    }} id={"company_director_operating_profit_18_19_2"} setValue={(e) => props.setValue({ ...props.store, company_director_operating_profit_18_19_2: e })} />
                )

        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default CompanyDirector2;