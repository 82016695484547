import React from 'react';
import Loader from '../loader';
import json from './outcodes.json';
// import "scroll-behavior-polyfill";

class Optimiser3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      location: 2496,
      numRooms: 2,
      propArray: [],
      greaterPropArray: [],
      lesserPropArray: [],
      withUserPrice: [],
      featured: [],
      pages: ["Loading..."],
      userPrice: 530000,
      index: 24,
      outcodes: json,
      postcode: "Loading...",
      postcodeNotFound: false,
      locationError: null,
      predictionArray: null,
      pageNumber: null,
      pagePosition: null,
      links: [],
      scroll: false,
      pagePercent: 0,
      loadingUpdate: null,
      started: false,
      loading: <Loader />
    };
  }

  async componentWillMount() {
    let begin = 0;
    let end = this.state.outcodes.length;
    let prediction = [];
    if (this.state.postcode !== undefined) {
      for (var k = begin; k <= end; k++) {
        if (this.state.outcodes[k] !== undefined) {
          let expr = (
            <option value={this.state.outcodes[k].outcode}></option>
          );
          prediction.push(expr);
        }
      }
    }

    this.setState({
      predictionArray: prediction
    });
  }

  async componentDidMount() {
    const $style = document.createElement("style");
    document.head.appendChild($style);
    $style.innerHTML = `
          
          .optimiser3, .optimiser3 #slide1, .optimiser3 #slide2, .optimiser3 #slide3 {
            height: 100vh;
            width: 100vw;
            position: relative;
          }
          
          .optimiser3 .main {
            height: calc(100% - 42px);
          }
          
          .optimiser3 ul {
            list-style-type: none;
            padding: 0%;
            margin-top: 0px;
            margin-bottom: 0;
          }
          
          .optimiser3 li {
            font-size: 13px;
          }
          
          .optimiser3 .main {
            font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;;
            text-align: center;
            height: 100%;
          }
          
          .optimiser3 .optimiser3-td {
            /* padding: 15px 30px 15px 30px; */
            background-color: white;
            /* border-radius: 10px; */
            margin: 4px;
            /* color: white; */
            display: inline-block;
            vertical-align: top;
            border: 1px solid black;
            min-height: 272px;
            min-width: 62px;
          }
          
          .optimiser3 .optimiser3-page {
            background-color: transparent;
            color: black;
            font-size: 16px;
            font-weight: 600;
          }
          
          .optimiser3 .inline {
            display: inline-block;
            vertical-align: top;
          }
          
          .optimiser3 .otherProperties {
            height: 10px;
            font-size: 8px;
            color: transparent;
          }
          
          @media (max-height: 600px){
            .optimiser3 .optimiser3-td {
                padding: 5px 30px 5px 30px;
            }
          
            .optimiser3 .optimiser3-page {
                font-size: 20px;
            }
          }
          
          .optimiser3 .optimiser3-user-price {
            background-color: black;
            color: white;
            text-shadow: 0px 0px 1px black;
            margin-bottom: -11px;
          }
          
          .optimiser3 table {
            width: 100%;
            height: 100%;
            table-layout: fixed;
            overflow-y: hidden;
            border: none;
            border-collapse: collapse;
            
          }
          
          .optimiser3 td {
            width: 50%;
            overflow: hidden;
            overflow-y: hidden;
            position: relative;
            -webkit-scroll-snap-align: start;
            scroll-snap-align: start;
          }
          
          .optimiser3 .scrollTable {
            position: relative;
            width: 100%;
            overflow-x: auto;
            scroll-behavior: smooth;
          }
          
          .optimiser3 .optimiser3-table {
            overflow: auto;
            white-space: nowrap;
            scroll-behavior: smooth;
          }
          
          .optimiser3 .fadeLeft {
            background-image : linear-gradient(to left, 
                            rgba(255,255,255, 0), 
                            rgba(255,255,255, 1) 90%);
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100px;
            height: 100%;
            z-index: -1;
          }
          
          .optimiser3 .fadeRight {
            background-image : linear-gradient(to right, 
                            rgba(255,255,255, 0), 
                            rgba(255,255,255, 1) 90%);
            position: absolute;
            right: 0;
            top: 0;
            width: 100px;
            height: 100%;
            z-index: -1;
          }
          
          .optimiser3 .alterPrice {
            font-size: 50px;
            vertical-align: middle;
            z-index: 1;
          }
          
          .optimiser3 .alterPriceText {
            padding-top: 5px;
            font-size: 18px;
            font-weight: 600;
            vertical-align: middle;
            z-index: 1;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          
          .optimiser3 .logo {
            width: 200px;
            position: absolute;
            top: 12%;
            left: 11%;
          }
          
          .optimiser3 .loading {
            width: auto;
          }
          
          .optimiser3 .top{
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0px solid;
            border-radius: 10px;
          }
          
          .optimiser3 .middle-container {
            display: block;
            /* min-height: 65vh; */
          }
          
          .optimiser3 .section-content {
            z-index: 1;
            padding-top: 1px;
            padding-bottom: 5px;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          
          .optimiser3 .uk-navbar {
            overflow: hidden;
            display: flex;
            position: relative;
            background: #f6f6f6!important;
            padding: 0 17px;
          }
          
          .optimiser3 .App {
            width: 100%;
          }
          
          .optimiser3 .uk-logo {
            font-size: 1.1875rem;
            font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
            color: #666;
            text-decoration: none;
            font-family: Oswald;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 18px;
            letter-spacing: 0px;
            color: #ccc;
          }
          
          .optimiser3 .uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            min-height: 80px;
            padding: 0 15px;
            font-size: 16px;
            font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
            text-decoration: none;
          }
          
          .optimiser3 .uk-navbar-item {
            color: #666;
            min-height: 42px;
            padding: 0;
          }
          
          .optimiser3 label {
            font-size: 22px;
            font-weight: 600;
          }
          
          .optimiser3 input {
            text-align: left;
            padding: 1px 2px;
            padding-left: 13px;
            padding-right: 13px;
            border: 1px solid black;
            font-size: 20px;
            margin-top: 5px;
            margin-bottom: 10px;
            margin-right: -1px;
            margin-left: -1px;
            height: 36px;
            width: calc(100% - 41px);
            border-radius: 0;
          }
          
          .optimiser3 #priceInput {
            padding-left: 15px;
            padding-right: 0px;
            width: calc(100% - 122px)
          }
          
          .optimiser3 #locationInput {
            margin-right: 0px;
            padding-right: 0px;
          }

          .optimiser3 #roomInput {
            width: calc(100% - 133px)
          }
          
          .optimiser3 .score {
            color: rgb(143, 143, 143);
            font-weight: 700;
          }
          
          .optimiser3 a {
            text-decoration: none;
            color: transparent;
          }
          
          .optimiser3 a:visited {
            /* color: #00694a; */
          }
          
          .optimiser3 a:hover {
            text-shadow: 0px 0px 1px;
          }
          
          .optimiser3 .updateBtn {
            width: 40%;
            padding: 5px;
            margin-top: 10px;
            background-color: #AAFF00;
            border: none;
            font-size: 24px;
            color: black;
            vertical-align: middle;
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
          }
          
          .optimiser3 .positionBtn {
            width: 40%;
            padding: 5px;
            margin-top: 10px;
            background-color: #AAFF00;
            border: none;
            font-size: 14px;
            color: black;
            vertical-align: middle;
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
          }
          
          .optimiser3 .incrBtn {
            margin-left: 5px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          
          .optimiser3 .decrBtn {
            padding-top: 10px;
            padding-bottom: 10px;
            margin-right: 5px;
            background-color: #bc0202;
          }
          
          .optimiser3 .incrBtn:hover, .optimiser3 .decrBtn:hover {
            transform: scale(1.02);
          }
          
          .optimiser3 .disableBtn {
            background-color: rgb(143, 143, 143);
            color: rgb(63, 63, 63);
          }
          
          .optimiser3 .disableBtn:hover {
            transform: none;
          }
          
          .optimiser3 .startBtn {
            padding: 15px;
            width: 50%;
          }
          
          .optimiser3 .scrollBtn {
            width: 100%;
            padding: 5px;
            background-color: transparent;
            border: none;
            border-radius: 5px;
            font-size: 20px;
            color: black;
            vertical-align: middle;
            cursor: pointer;
          }
          
          .optimiser3 .backBtn {
            color: black;
          }
          
          .optimiser3 .updateBtn:hover {
            transition: all 0.1s ease-in-out;
            transform: scale(1.05);
          }
          
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button {  
          
           opacity: 0;
           display: none;
          
          }
          
          input::-webkit-calendar-picker-indicator {
            opacity: 0;
            display: none;
          }
          
          .optimiser3 .note {
            margin-top: -10px;
            font-size: 20px;
            vertical-align: middle;
          }
          
          .optimiser3 .notes {
            padding-left: 15px;
            padding-right: 15px;
          }
          
          .optimiser3 #clickNote {
            position: absolute;
            top: 50%;
            left: -100px;
            font-weight: 700;
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
          }
          
          .optimiser3 .note2 {
            margin-top: -10px;
            font-size: 18px;
          }
          
          .optimiser3 .scrollToPages {
            font-size: 30px;
            vertical-align: middle;
            z-index: 2;
          }
          
          @media (max-width:847px){
            .optimiser3 table {
                width: 100%;
            }
          
            .optimiser3 .scrollBtn {
                display: block;
            }
          }
          
          @media (max-height: 620px){
            .optimiser3 .disappearOnSmallerScreen {
                display: none;
            }
          
            .optimiser3 label {
                font-size: 20px;
            }
          
            .optimiser3 .note {
                font-size: 18px;
            }
          
            .optimiser3 input {
                font-size: 20px;
            }
          }
          
          .optimiser3 .formContent {
            padding-left: 15px;
            padding-right: 15px;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            width: 100%;
            max-height: 100%;
          }
          
          .optimiser3 #formHouse {
            padding-top: 42px;
            padding-bottom: 26px;
          }
          
          .optimiser3 #slide1, .optimiser3 #slide2, .optimiser3 #slide3 {
            -webkit-scroll-snap-align: start;
            -webkit-scroll-snap-stop: always;
            scroll-snap-stop: always;
            scroll-snap-align: start;
          }
          
          .optimiser3 small {
            z-index: 2;
          }
          
          .optimiser3 .firstBreak {
            display: none;
          }
          
          @media (min-height: 650px){
            .optimiser3 .firstBreak {
                display: block;
            }
          
            .optimiser3 .alterPriceText {
                font-size: 22px;
            }
          }
          
          .optimiser3 .secondBreak {
            display: none;
          }
          
          @media (min-height: 700px) {
            .optimiser3 .secondBreak {
                display: block;
            }
          
            .optimiser3 li {
                font-size: 16px;
            }
          }
          
          @media (min-height: 800px) {
            .optimiser3 li {
                font-size: 18px;
            }
          
            .optimiser3 .optimiser3-page {
                font-size: 26px;
            }
          }
          
          .optimiser3 #comment {
            font-size: 28px;
            font-weight: 600;
            padding-left: 10px;
            padding-right: 10px;
          }
          
          .optimiser3 #fillComment {
            font-size: 20px;
          }
          
          .optimiser3 .mainLogo {
            width: 200px;
          }
          
          .optimiser3 .mainMenu {
            width: calc(100% - 30px);
          }
          
          .optimiser3 .poundSign {
            position: absolute;
            margin-left: 1px;
            margin-top: 13px;
            color: black;
            font-size: 24px;
          }
          
          @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) { 
            @media {
              .optimiser3 .poundSign {
                    margin-top: 7px;
                }
            }
          }
          
          @media not all and (min-resolution:.001dpcm) { 
            @media {
              .optimiser3 .poundSign {
                    margin-top: 7px;
                }
            }
          }
          
          .optimiser3 .tooltip {
            position: relative;
            display: inline-block;
            font-size: 22px;
          }
          
          .optimiser3 .tooltiptext {
            font-size: 14px;
            font-weight: 400;
          }
          
          .optimiser3 .tooltip .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: rgb(34, 34, 34);
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 10px 10px;
            position: absolute;
            z-index: 1;
            top: calc(-50% - 5px);
            right: 125%;
          }
          
          .optimiser3 .tooltip .tooltiptext::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent rgb(34, 34, 34);
          }
          
          .optimiser3 .tooltip:hover .tooltiptext {
            visibility: visible;
          }
          
          .optimiser3 .negative, .optimiser3 .plus {
            background-color: rgb(214, 214, 214);
            border: 1px solid black;
            font-size: 20px;
            cursor: pointer;
            width: 40px;
            height: 40px;
            text-align: center;
            padding: 0;
            margin: 0;
          }
          
          /* .negative:hover {
            background-color: #02bc83;
          } */
          
          /* .plus:hover {
            background-color: #02bc83;
          } */
          
          .optimiser3 .negative {
          }
          
          :focus {
            outline: none;
          }
          
          .optimiser3 .propertyForm {
            text-align: left;
            margin-left: 5%;
            margin-right: 5%;
          }
          
          .optimiser3 .listItem {
            padding-left: 10px;
            padding-right: 10px;
          }
          
          .optimiser3 .context {
            text-align: left;
            padding: 15px;
          }
          
          .optimiser3 .contextHeading {
            padding-bottom: 5px;
          }
          
          .optimiser3 .contextText {
            padding-bottom: 5px;
          }
          
          .optimiser3 .saveBtn {
            height: 40px;
            font-size: 24px;
            border-radius: 30px;
            padding-left: 30px;
            padding-right: 30px;
            border: 1px solid black;
          }
          
          .optimiser3 .percentage {
            padding: 15px;
            padding-top: 5px;
          }
          
          .optimiser3 .billAndTed {
            position: absolute; 
            left: calc(50% - 100px);
            top: calc(50% - 75px);
          }
          
          .optimiser3 .gifClose {
            position: absolute;
            top: 0;
            color: white;
            right: 6px;
            font-size: 20px;
            cursor: pointer;
          }
          
          .optimiser3 .gifClose:hover {
            transform: scale(1.2);
          }
        `;

    // Without a proxy, used a chrome extension to circumvent the issue, which I think arose due to the fact that it was being run locally:
    const url = "https://optimiser-proxy.herokuapp.com/optimiser-proxy?location=" + this.state.location + "&numRooms=" + this.state.numRooms + "&index=" + this.state.index;

    const response = await fetch(url);
    const data = await response.json();
    this.setState({
      data: data
    });
    this.sortData();
  }

  sortData() {
    let numPropResponses = this.state.data.numReturnedResults;
    let totalResults = this.state.data.totalAvailableResults;
    let featuredPropertyNum = this.state.data.featuredProperties.length;
    for (let i = 0; i < featuredPropertyNum; i++) {
      let link = this.state.data.featuredProperties[0].identifier;
      this.setState({
        featured: this.state.featured.concat(<a style={{ color: "black" }} href={"https://www.rightmove.co.uk/property-for-sale/property-" + link + ".html"} target="_blank" rel="noopener noreferrer">Featured</a>)
      });
    }

    for (let i = 0; i < numPropResponses; i++) {
      let price = this.state.data.properties[i].price;
      let link = this.state.data.properties[i].identifier;
      this.setState({
        propArray: this.state.propArray.concat(price),
        links: this.state.links.concat(link)
      });
    }

    if (this.state.index >= totalResults) {
      this.sortPropArray();
    }

    if (this.state.index < totalResults) {
      this.state.index += 24;
      this.componentDidMount();
    }
  }

  sortPropArray() {
    let begin = 0;
    let end = this.state.outcodes.length;
    let prediction = [];
    if (this.state.postcode !== undefined) {
      for (var k = begin; k <= end; k++) {
        if (this.state.outcodes[k] !== undefined) {
          let expr = (
            <option value={this.state.outcodes[k].outcode}></option>
          );
          prediction.push(expr);
        }
      }
    }

    this.setState({
      propArray: this.state.propArray.sort(function (a, b) { return b - a })
    }, () => {
      this.filterPropArray();
    });
  }

  filterPropArray() {
    let greaterArray = [];
    let lesserArray = [];

    for (let i = 0; i < this.state.propArray.length; i++) {
      if (this.state.propArray[i] > this.state.userPrice) {
        greaterArray.push(this.state.propArray[i]);
      } else {
        lesserArray.push(this.state.propArray[i]);
      }
    }

    this.setState({
      greaterPropArray: greaterArray,
      lesserPropArray: lesserArray
    }, () => {
      this.findPostcode();
    });
  }

  findPostcode() {
    this.setState({
      postcode: this.state.outcodes[this.state.location - 1].outcode,
      postcodeNotFound: false
    }, () => {
      this.formatProps();
    });
  }

  formatProps() {
    let currentArray = [];

    let count = 1;
    let totalProperties = 0;

    for (let i = 0; i < this.state.greaterPropArray.length; i++) {
      let link = this.state.links[i];
      let place = count % 24;
      if (place === 0) {
        place = 24;
      }
      currentArray.push(<div className="otherProperties"><a href={"https://www.rightmove.co.uk/property-for-sale/property-" + link + ".html"} target="_blank" rel="noopener noreferrer">{place}</a></div>);
      count++;
      totalProperties++;
    }

    let pageNumber = Math.floor(this.state.greaterPropArray.length / 24 + 1);
    let pagePosition = this.state.greaterPropArray.length % 24 + 1;
    let linkLocation = 1;
    if (this.state.featured.length >= pageNumber) {
      pagePosition = this.state.greaterPropArray.length % 24 + 2;
      linkLocation = 2;
    }
    let place2 = count % 24;
    if (place2 === 0) {
      place2 = 24;
    }
    if (this.state.featured[pageNumber - 1]) {
      place2 += 1;
    }

    currentArray.push(<div id="userPrice" className="optimiser3-user-price">{place2}</div>);

    let userPosition = totalProperties;

    count++;

    for (let i = 0; i < this.state.lesserPropArray.length; i++) {
      let link = this.state.links[i + ((pageNumber - 1) * 24 + pagePosition - linkLocation)];
      let place = count % 24;
      if (place === 0) {
        place = 24;
      }
      currentArray.push(<div className="otherProperties"><a href={"https://www.rightmove.co.uk/property-for-sale/property-" + link + ".html"} target="_blank" rel="noopener noreferrer">{place}</a></div>);
      count++;
      totalProperties++;
    }

    let percent = Math.round((userPosition / totalProperties) * 100);

    this.setState({
      withUserPrice: currentArray,
      pageNumber: pageNumber,
      pagePosition: pagePosition,
      pagePercent: percent
    }, () => {
      this.splitProps();
    });
  }

  splitProps() {
    const chunkSize = 24;
    const arr = this.state.withUserPrice;
    const groups = arr.map((e, i) => {
      return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
    }).filter(e => { return e; });

    let defaultPages = [];
    let count = 1;

    for (let i = 0; i < groups.length; i++) {
      let tableStructure = (
        <div className="inline">
          <div className="optimiser3-td" id={count}>
            {/* <ul> */}
            {this.state.featured[i]}
            {groups[i]}
            {/* </ul> */}
          </div>
          <div className="optimiser3-page">Page <br /> {count}</div>
        </div>
      );

      defaultPages.push(tableStructure);
      count++;
    }

    this.setState({
      pages: defaultPages,
      loading: null,
      loadingUpdate: null
    }, () => {
      if (this.state.scroll === true) {
        this.scrollToObject();
        this.setState({
          scroll: false
        });
      }
    });
  }

  updatePrice = (event) => {
    this.setState({
      userPrice: event.target.value
    }, () => {
      this.sortPropArray();
    });
  }

  updateRooms = (event) => {
    this.setState({
      numRooms: event.target.value
    });
  }

  updatePostcode = (event) => {
    this.setState({
      postcode: event.target.value.trim().toUpperCase()
    }, () => {
      let postcode = this.state.postcode;
      let result = this.state.outcodes.filter(function (e) { return e.outcode === postcode });
      if (result[0] !== undefined) {
        this.setState({
          postcodeNotFound: false,
          location: result[0].locationIdent,
        });
      } else {
        this.setState({
          postcodeNotFound: true
        });
      }
    });
  }

  runUpdate = () => {
    this.setState({
      propArray: [],
      featured: [],
      index: 24,
      locationError: null,
      links: [],
      scroll: true,
      loadingUpdate: <Loader />
    }, () => {
      this.componentDidMount();
    });
  }

  scrollToObject = () => {
    document.getElementById(this.state.pageNumber).scrollIntoView({ inline: "center" });
  }

  scrollToHome = () => {
    document.getElementById("slide1").scrollIntoView({ inline: "center" });
  }

  increasePrice = () => {
    this.setState({
      userPrice: this.state.greaterPropArray[this.state.greaterPropArray.length - 1],
      scroll: true
    }, () => {
      this.sortPropArray();
    });
  }

  decreasePrice = () => {
    this.setState({
      userPrice: this.state.lesserPropArray[0] - 50,
      scroll: true
    }, () => {
      this.sortPropArray();
    });
  }

  startSession = () => {
    this.setState({
      started: true
    });
  }

  plusPrice = (e) => {
    e.preventDefault();
    let newPrice = parseInt(this.state.userPrice) + 5000;
    this.setState({
      userPrice: newPrice,
      scroll: false
    }, () => {
      this.sortPropArray();
    });
  }

  minusPrice = (e) => {
    e.preventDefault();
    let newPrice = this.state.userPrice - 5000;
    if (newPrice < 0) {
      newPrice = this.state.userPrice;
    }
    this.setState({
      userPrice: newPrice,
      scroll: false
    }, () => {
      this.sortPropArray();
    });
  }

  plusRooms = (e) => {
    e.preventDefault();
    this.setState({
      numRooms: parseInt(this.state.numRooms) + 1,
      scroll: false
    });
  }

  minusRooms = (e) => {
    e.preventDefault();
    if (this.state.numRooms !== 0) {
      this.setState({
        numRooms: this.state.numRooms - 1,
        scroll: false
      });
    }
  }

  closeGif = () => {
    if (document.getElementById("gif")) {
      document.getElementById("gif").style.display = "none";
    }
  }

  render() {
    let display = "none";
    if (this.state.loading === null) {
      display = "block";
    }

    let opacity = "0.2";
    let scroll = "none";
    if (this.state.loadingUpdate === null) {
      opacity = "1";
      scroll = "block"
    }

    let colour = "white";
    if (this.state.postcodeNotFound === true) {
      colour = "#bc020240";
    }

    let priceColour = "#bc020240";
    if (this.state.userPrice > 0) {
      priceColour = "white";
    }

    let roomColour = "#bc020240";
    if (this.state.numRooms >= 0) {
      roomColour = "white";
    }

    let rating = "Excellent";
    let stars = <div className="stars"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i></div>;
    if (this.state.pagePercent > 20 && this.state.pagePercent < 80) {
      rating = "Average";
      stars = <div className="stars"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i><i className="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i></div>;
    } else if (this.state.pagePercent >= 80) {
      rating = "Bad";
      stars = <div className="stars"><i className="fa fa-star" aria-hidden="true"></i><i className="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i><i className="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i><i className="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i><i className="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i></div>;
    }

    let wordEnd = "th";
    if (this.state.pagePosition === 1 || this.state.pagePosition === 21) {
      wordEnd = "st";
    } else if (this.state.pagePosition === 2 || this.state.pagePosition === 22) {
      wordEnd = "nd";
    } else if (this.state.pagePosition === 3 || this.state.pagePosition === 23) {
      wordEnd = "rd";
    }

    let searchPercent = 100;
    if (this.state.pageNumber > 1) {
      searchPercent -= Math.round(this.state.pageNumber * (100 / (this.state.pages.length * 1.5)));
    }

    let gif = null;
    if (this.state.pageNumber === 1 && this.state.pagePosition === 1 && this.state.featured.length === 0) {
      gif = <div id="gif" className="billAndTed">
        <img src={require("../img/billAndTed.gif")} alt='free'></img>
        <div className="gifClose" onClick={this.closeGif}><i className="fa fa-times" aria-hidden="true"></i></div>
      </div>;
    } else if (this.state.pageNumber === 1 && this.state.pagePosition === 2 && this.state.featured.length > 0) {
      gif = <div id="gif" className="billAndTed">
        <img src={require("../img/billAndTed.gif")} alt='free'></img>
        <div className="gifClose" onClick={this.closeGif}><i className="fa fa-times" aria-hidden="true"></i></div>
      </div>;
    }

    return (
      <div className="optimiser3">


        {this.state.loading}

        <div className="main" style={{ display: display, opacity: opacity }}>
          <div valign="center" id="slide1">
            <div className="formContent" id="formHouse">
              {/* <img className="logo" src={require("../img/splashHome.svg")}></img> */}

              {/* <br/> */}

              <span id="comment">How visible is your property?</span>

              <br />

              <span id="fillComment">Fill out your details to find out!</span>

              <br /><br />

              <div className="propertyForm">

                <label>Price of Property<br /><span className="poundSign">£</span>
                  <span className="inputBar">
                    <input
                      style={{ backgroundColor: priceColour }}
                      id="priceInput"
                      type="number"
                      min="0"
                      value={this.state.userPrice}
                      placeholder={this.state.userPrice}
                      onChange={this.updatePrice}
                      step="50">
                    </input>
                    <button onClick={this.minusPrice} className="negative"><i className="fa fa-minus" aria-hidden="true"></i></button>
                    <button onClick={this.plusPrice} className="plus"><i className="fa fa-plus" aria-hidden="true"></i></button>
                  </span>
                </label>
                            &nbsp;
                            <span className="tooltip">
                  <i className="fas fa-info-circle"></i>
                  <span className="tooltiptext">The price that you would like to sell your house at</span>
                </span>

                <br />

                <label>Location of Property<br />
                  <input
                    style={{ backgroundColor: colour }}
                    id="locationInput"
                    type="text"
                    list="postcodes"
                    name="myBrowser"
                    value={this.state.postcode}
                    onChange={this.updatePostcode}
                  />
                </label>
                <datalist id="postcodes">
                  {this.state.predictionArray}
                </datalist>
                            &nbsp;
                            <span className="tooltip">
                  <i className="fas fa-info-circle"></i>
                  <span className="tooltiptext">The postcode district of your location, e.g. SW2</span>
                </span>

                <br />

                <label>Number of Bedrooms <br />

                  <input
                    style={{ backgroundColor: roomColour }}
                    id="roomInput"
                    type="number"
                    min="0"
                    value={this.state.numRooms}
                    placeholder={this.state.numRooms}
                    onChange={this.updateRooms}>
                  </input>
                  <button onClick={this.minusRooms} className="negative"><i className="fa fa-minus" aria-hidden="true"></i></button>
                  <button onClick={this.plusRooms} className="plus"><i className="fa fa-plus" aria-hidden="true"></i></button>
                </label>
                            &nbsp;
                            <span className="tooltip">
                  <i className="fas fa-info-circle"></i>
                  <span className="tooltiptext">The number of bedrooms in your house</span>
                </span>

              </div>

              <br className="firstBreak" />

              <div className="notes">
                <span className="note">You are number <span className="score">{this.state.pagePosition}</span> on page <span className="score">{this.state.pageNumber}</span></span><br />
                {/* {moveUp} */}
              </div>

              <br className="secondBreak" />

              <button className="updateBtn" onClick={this.runUpdate}>UPDATE</button>

              <button className="scrollBtn" onClick={this.scrollToObject}>Scroll to see listings <span className="fa fa-angle-down scrollToPages"></span></button>

            </div>
          </div>

          <div valign="center" id="slide2" style={{ display: scroll }}>

            <div className="context">
              <div className="contextHeading">
                <strong>How visible is you property on Rightmove?</strong>
              </div>
              <div className="contextContent">
                <div className="contextText">
                  Changing price will affect where your advert appears in people's searches on Rightmove.
							</div>

                <i>
                  Your advert will get the most attention if it's on the 1st page because more people will see
                  it - the higher up the page the better!
							</i>
              </div>
            </div>

            <div className="">


              <div className="scrollTable">
                <div className="optimiser3-table">
                  <img src={require("../img/mobile.png")} style={{ width: "73px", position: "relative", marginRight: "-73px" }} alt='mobile'></img>
                  {this.state.pages}
                </div>
                {gif}
              </div>

              {/* <br className="secondBreak"/> */}


              {stars}
              <div className="alterPriceText">{rating} - <span className="score">{this.state.pagePosition}{wordEnd}</span> advert on page <span className="score">{this.state.pageNumber}</span></div>
              <div className="percentage">
                <i>
                  You'll appear in {searchPercent}% of searches that match your property advert.
							</i>
              </div>
              {/* {lesserDisplay}
                        {greaterDisplay} */}
              <label><span className="poundSign">£</span>
                <span className="inputBar">
                  <button onClick={this.minusPrice} className="negative"><i className="fa fa-minus" aria-hidden="true"></i></button>

                  <input
                    style={{ backgroundColor: priceColour }}
                    id="priceInput"
                    type="number"
                    min="0"
                    value={this.state.userPrice}
                    placeholder={this.state.userPrice}
                    onChange={this.updatePrice}
                    step="50">
                  </input>

                  <button onClick={this.plusPrice} className="plus"><i className="fa fa-plus" aria-hidden="true"></i></button>
                </span>
              </label>

              <br />

              {/* <button className="saveBtn">
                SAVE YOUR PRICE
						</button> */}

            </div>
          </div>
        </div>
        {this.state.loadingUpdate}
      </div>
    );
  }
}

export default Optimiser3;