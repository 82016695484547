import React, { useEffect, useState } from 'react';
import * as Icon from "react-bootstrap-icons"
import codes from '../country_codes.json';
import "./address.sass"

function AddressHistory2(props) {
    const [num, setNum] = useState(1)
    const baseAddress = {
        address: {
            address1: "",
            address2: "",
            address3: "",
            posttown: "",
            postcode: "",
            country: ""
        },
        is_current_correspondence: false,
        move_in_date: "",
        move_out_date: ""
    }
    const [addresses, setAddresses] = useState(props.store?.addresses_2 || [{ ...baseAddress }])

    useEffect(() => {
        if (addresses !== props.store?.addresses) props.add("addresses_2", addresses)
    }, [addresses])

    function addToAddress(key, value, item) {
        let arr = [...addresses]
        arr[item] = {
            ...arr[item], address: {
                ...arr[item].address,
                [key]: value
            }
        }
        setAddresses(arr)
    }

    return (
        <div>
            <div className="applicantReference">
                {props.name}'s details
            </div>
            <div className='heading'>
                Address history
            </div>
            <div className='content'>
                Please let us know of their current and previous addresses. We need at least the past 3 years worth of Addresses.
            </div>

            {Array(num).fill(num).map((e, n) => {
                return <div style={{ marginBottom: "30px" }} key={n}>
                    <div style={{ margin: "0px", fontSize: "20px", fontWeight: "600", flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "space-between" }} className='content further-sub'>
                        <div>Address {n + 1}</div>
                        {n > 0 ? <div style={{ fontSize: "36px", cursor: "pointer" }} onClick={() => removeAddress(n)}><Icon.X /></div> : null}
                    </div>
                    <div className='further-checkbox' style={{ marginTop: "15px" }} onClick={() => addToAddresses("is_current_correspondence", addresses[n]?.is_current_correspondence ? false : true, n)}>
                        <div style={{ border: "1px solid black", width: "20px", height: "20px", margin: "0px 15px", display: "inline-block", verticalAlign: "middle", backgroundColor: addresses[n]?.is_current_correspondence ? props.style === "creditladder" ? "#00dfbc" : "black" : "white" }}>
                            <div style={{ fontSize: "19px", marginTop: "0px", marginLeft: "0px", color: "white" }}><Icon.Check2 /></div>
                        </div>
                        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                            Current address
                        </div>
                    </div>
                    {getAddressInput("text", "Address Line 1", "address1", n, true)}
                    {getAddressInput("text", "Address Line 2", "address2", n, false)}
                    {getAddressInput("text", "Address Line 3", "address3", n, false)}
                    {getAddressInput("text", "Town", "posttown", n, true)}
                    {getAddressInput("text", "Postcode", "postcode", n, true)}
                    {/* {getAddressInput("text", "Country", "country", n, false)} */}
                    <div className='input_contain'>
                        <div className='input_wrap'>
                            <input style={{ fontSize: "14px", lineHeight: "16px", height: "20px" }} value={addresses[n].address?.country || undefined} list='country' placeholder='Country' onChange={e => {
                                if (codes.filter(f => f.Name === e.currentTarget.value).length === 1) addToAddress("country", e.currentTarget.value, n)
                                else addToAddress("country", "", n)
                            }} />
                            <datalist id="country" name="country">
                                <option value="United Kingdom">United Kingdom</option>
                                {codes.map(e => {
                                    if (e.Code !== "GB") return <option value={e.Name}>{e.Name}</option>
                                })}
                            </datalist>
                        </div>
                    </div>

                    <div className='further-sub-heading' style={{ margin: "15px 15px -10px", fontWeight: "600" }}>Move in date</div>
                    {getInput("date", "", "move_in_date", n, true)}

                    {addresses[n]?.is_current_correspondence ? null :
                        <>
                            <div className='further-sub-heading' style={{ margin: "15px 15px -10px", fontWeight: "600" }}>Move out date</div>
                            {getInput("date", "", "move_out_date", n)}
                        </>
                    }

                    <div className='input_contain'>
                        <div className='input_wrap'>
                            <select value={addresses[n]?.residential_status || undefined} onChange={e => addToAddresses("residential_status", e.currentTarget.value, n)} style={{ width: "calc(100% + 2px)", fontSize: "14px", color: addresses[n]?.residential_status ? "black" : "grey" }}>
                                <option value="" disabled selected>Residential status</option>
                                <option value="TENANT" style={{ color: "black" }}>Tenant</option>
                                <option value="OWNER_OCCUPIER" style={{ color: "black" }}>Owner/Occupier</option>
                                <option value="LIVING_WITH_FAMILY" style={{ color: "black" }}>Living with family</option>
                                <option value="CORRESPONDENCE_ONLY" style={{ color: "black" }}>Correspondence only</option>
                            </select>
                        </div>
                    </div>
                </div>
            })}

            <div className='further-add' style={{ margin: "15px", cursor: "pointer" }} onClick={() => {
                let arr = [...addresses]
                arr.push({ ...baseAddress })
                setAddresses(arr)
                setNum(num + 1)
            }}>
                <Icon.Plus /> Add address
            </div>

            <button disabled={!checkReq()} className='capBtn' style={{ margin: "15px" }} onClick={() => props.next()}>
                Next
            </button>
        </div>
    );

    function addToAddresses(key, value, item) {
        let arr = [...addresses]
        arr[item] = { ...arr[item], [key]: value }
        setAddresses(arr)
    }

    function removeAddress(n) {
        let arr = [...addresses]
        arr.splice(n, 1)
        setAddresses(arr)
        setNum(num - 1)
    }

    function getAddressInput(type, placeholder, key, n, req) {
        let input_style = {
            fontSize: "14px",
            lineHeight: "16px",
            height: "20px"
        }

        return <div className='input_contain'>
            <div className='input_wrap'>
                <input value={addresses?.[n]?.address?.[key]} style={input_style} type={type} placeholder={placeholder} onChange={e => addToAddress(key, e.currentTarget.value, n)} />
                {req ? <div className='required'>*</div> : null}
            </div>
        </div>
    }

    function checkReq() {
        let check = true
        for (let i = 0; i < addresses.length; i++) {
            if (
                addresses[i].address.address1 &&
                addresses[i].address.posttown &&
                addresses[i].address.postcode &&
                addresses[i].move_in_date
            ) { }
            else check = false
        }
        return check
    }

    function getInput(type, placeholder, key, n, req) {
        let input_style = {
            fontSize: "14px",
            lineHeight: "16px",
            height: "20px"
        }

        return <div className='input_contain'>
            <div className='input_wrap'>
                <input value={addresses?.[n]?.[key]} style={input_style} type={type} placeholder={placeholder} onChange={e => addToAddresses(key, e.currentTarget.value, n)} />
                {req ? <div className='required'>*</div> : null}
            </div>
        </div>
    }
}

export default AddressHistory2;