import React, { useEffect, useState } from 'react';
import Communications from '../../apply/questions/communications';
import Communications2 from '../../apply/questions/communications_2';

function Email(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return <span>
                    <Communications next={() => {
                        props.setPage(props.store.number_of_applicants === 1 ? props.switch + 2 : props.switch + 1)
                        props.setPrev([...props.prev, props.store.number_of_applicants === 1 ? props.switch + 2 : props.switch + 1])
                    }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />
                    {/* <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What's your email address?",
                        content: "We'll add it to your application, it may be used to contact you.",
                        input: {
                            type: "email",
                            id: "email",
                            placeholder: "Email address",
                            next: props.switch + 4,
                            min: 1,
                            focus: false
                        }
                    }} id={"email"} setValue={(e) => props.setValue({ ...props.store, email: e })} /> */}
                </span>

            case props.switch + 1:
                return <span>
                    <Communications2 name={props.store.first_name_2} next={() => {
                        props.setPage(props.switch + 2)
                        props.setPrev([...props.prev, props.switch + 2])
                    }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />
                </span>

            default:
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Email;