import React from 'react';
import json from './outcodes.json';
import Loader from '../loader';
import "./_optimiser.sass"
// import "scroll-behavior-polyfill";

class Optimiser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            location: 2496,
            numRooms: null,
            propArray: [],
            greaterPropArray: [],
            lesserPropArray: [],
            withUserPrice: [],
            featured: [],
            pages: ["Loading..."],
            userPrice: 530000,
            index: 24,
            outcodes: json,
            postcode: "Loading...",
            postcodeNotFound: false,
            locationError: null,
            predictionArray: null,
            pageNumber: null,
            pagePosition: null,
            links: [],
            scroll: false,
            pagePercent: 0,
            loadingUpdate: null,
            started: false,
            loading: <Loader transparent absolute show text="This may take a few minutes" />
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.price !== state.userPrice
            || props.numRooms !== state.numRooms
            || props.postcode !== state.postcode) {
            return {
                userPrice: props.price,
                numRooms: props.numRooms,
                postcode: props.postcode
            };
        }
        else return null;
    }

    componentDidUpdate(props, state) {
        if (props.numRooms !== this.props.numRooms
            || props.postcode !== this.props.postcode) {
            this.setState({
                userPrice: this.props.price,
                numRooms: this.props.numRooms,
                postcode: this.props.postcode
            });
            this.runUpdate();
        }

        if (props.price !== this.props.price) {
            this.setState({
                userPrice: this.props.price
            }, () => {
                this.sortPropArray();
            });
        }
    }

    async componentWillMount() {
        let begin = 0;
        let end = this.state.outcodes.length;
        let prediction = [];
        if (this.state.postcode !== undefined) {
            for (var k = begin; k <= end; k++) {
                if (this.state.outcodes[k] !== undefined) {
                    let expr = (
                        <option value={this.state.outcodes[k].outcode}></option>
                    );
                    prediction.push(expr);
                }
            }
        }

        this.setState({
            predictionArray: prediction
        });
    }

    async componentDidMount() {
        let postcode = this.props.postcode.trim();
        let numRooms = this.props.numRooms;
        let outcodeSearch = this.state.outcodes.find(record => record.outcode === postcode);
        let outcode = outcodeSearch.locationIdent;
        let price = this.props.price;

        this.setState({
            location: outcode,
            numRooms: numRooms,
            postcode: postcode,
            userPrice: parseInt(price)
        });

        // Without a proxy, used a chrome extension to circumvent the issue, which I think arose due to the fact that it was being run locally:
        const url = "https://optimiser-proxy.herokuapp.com/optimiser-proxy?location=" + outcode + "&numRooms=" + numRooms + "&index=" + this.state.index;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            data: data
        }, () => {
            this.sortData();
        });
    }

    sortData() {
        let numPropResponses = this.state.data.numReturnedResults;
        let totalResults = this.state.data.totalAvailableResults;
        let featuredPropertyNum = this.state.data.featuredProperties.length;
        for (let i = 0; i < featuredPropertyNum; i++) {
            let link = this.state.data.featuredProperties[0].identifier;
            this.setState({
                featured: this.state.featured.concat(<a style={{ color: "black" }} href={"https://www.rightmove.co.uk/property-for-sale/property-" + link + ".html"} target="_blank" rel="noopener noreferrer">Featured</a>)
            });
        }

        let propArray = this.state.propArray;
        let linkArray = this.state.links;

        for (let i = 0; i < numPropResponses; i++) {
            let price = this.state.data.properties[i].price;
            let link = this.state.data.properties[i].identifier;
            propArray.push(price);
            linkArray.push(link);
        }

        this.setState({
            propArray: propArray,
            links: linkArray
        }, () => {
            if (this.state.index >= totalResults) {
                this.sortPropArray();
            }

            if (this.state.index < totalResults) {
                this.state.index += 24;
                this.componentDidMount();
            }
        });
    }

    sortPropArray() {
        let begin = 0;
        let end = this.state.outcodes.length;
        let prediction = [];
        if (this.state.postcode !== undefined) {
            for (var k = begin; k <= end; k++) {
                if (this.state.outcodes[k] !== undefined) {
                    let expr = (
                        <option value={this.state.outcodes[k].outcode}></option>
                    );
                    prediction.push(expr);
                }
            }
        }

        this.setState({
            propArray: this.state.propArray.sort(function (a, b) { return b - a })
        }, () => {
            this.filterPropArray();
        });
    }

    filterPropArray() {
        let greaterArray = [];
        let lesserArray = [];

        for (let i = 0; i < this.state.propArray.length; i++) {
            if (this.state.propArray[i] > this.state.userPrice) {
                greaterArray.push(this.state.propArray[i]);
            } else {
                lesserArray.push(this.state.propArray[i]);
            }
        }

        this.setState({
            greaterPropArray: greaterArray,
            lesserPropArray: lesserArray
        }, () => {
            this.findPostcode();
        });
    }

    findPostcode() {
        this.setState({
            postcode: this.state.outcodes[this.state.location - 1].outcode,
            postcodeNotFound: false
        }, () => {
            this.formatProps();
        });
    }

    formatProps() {
        let currentArray = [];

        let count = 1;
        let totalProperties = 0;

        for (let i = 0; i < this.state.greaterPropArray.length; i++) {
            let link = this.state.links[i];
            let place = count % 24;
            if (place === 0) {
                place = 24;
            }
            currentArray.push(<div className="otherProperties"><a href={"https://www.rightmove.co.uk/property-for-sale/property-" + link + ".html"} target="_blank" rel="noopener noreferrer">£{this.state.greaterPropArray[i].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</a></div>);
            count++;
            totalProperties++;
        }

        let pageNumber = Math.floor(this.state.greaterPropArray.length / 24 + 1);
        let pagePosition = this.state.greaterPropArray.length % 24 + 1;
        let linkLocation = 1;
        if (this.state.featured.length >= pageNumber) {
            pagePosition = this.state.greaterPropArray.length % 24 + 2;
            linkLocation = 2;
        }
        let place2 = count % 24;
        if (place2 === 0) {
            place2 = 24;
        }
        if (this.state.featured[pageNumber - 1]) {
            place2 += 1;
        }

        currentArray.push(<div id="userPrice" className="optimiser-user-price">£{this.state.userPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>);

        let userPosition = totalProperties;

        count++;

        for (let i = 0; i < this.state.lesserPropArray.length; i++) {
            let link = this.state.links[i + ((pageNumber - 1) * 24 + pagePosition - linkLocation)];
            let place = count % 24;
            if (place === 0) {
                place = 24;
            }
            currentArray.push(<div className="otherProperties"><a href={"https://www.rightmove.co.uk/property-for-sale/property-" + link + ".html"} target="_blank" rel="noopener noreferrer">£{this.state.lesserPropArray[i].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</a></div>);
            count++;
            totalProperties++;
        }

        let percent = Math.round((userPosition / totalProperties) * 100);

        this.setState({
            withUserPrice: currentArray,
            pageNumber: pageNumber,
            pagePosition: pagePosition,
            pagePercent: percent
        }, () => {
            this.splitProps();
        });
    }

    splitProps() {
        const chunkSize = 24;
        const arr = this.state.withUserPrice;
        const groups = arr.map((e, i) => {
            return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
        }).filter(e => { return e; });

        let defaultPages = [];
        let count = 1;

        for (let i = 0; i < groups.length; i++) {
            let tableStructure = (
                <div key={"optimiser_" + i} className="inline">
                    <div className="optimiser-td" id={count}>
                        {/* <ul> */}
                        {this.state.featured[i]}
                        {groups[i]}
                        {/* </ul> */}
                    </div>
                    <div className="optimiser-page">Page <br /> {count}</div>
                </div>
            );

            defaultPages.push(tableStructure);
            count++;
        }

        this.setState({
            pages: defaultPages,
            loading: null,
            loadingUpdate: null
        }, () => {
            if (this.state.scroll === true) {
                this.scrollToObject();
                this.setState({
                    scroll: false
                });
            }
        });
    }

    updatePrice = (event) => {
        this.setState({
            userPrice: event.target.value
        }, () => {
            this.sortPropArray();
        });
    }

    updateRooms = (event) => {
        this.setState({
            numRooms: event.target.value
        });
    }

    updatePostcode = (event) => {
        this.setState({
            postcode: event.target.value.trim().toUpperCase()
        }, () => {
            let postcode = this.state.postcode;
            let result = this.state.outcodes.filter(function (e) { return e.outcode === postcode });
            if (result[0] !== undefined) {
                this.setState({
                    postcodeNotFound: false,
                    location: result[0].locationIdent,
                });
            } else {
                this.setState({
                    postcodeNotFound: true
                });
            }
        });
    }

    runUpdate = () => {
        this.setState({
            propArray: [],
            featured: [],
            index: 24,
            locationError: null,
            links: [],
            scroll: true,
            loadingUpdate: <Loader transparent absolute show text="This may take a few minutes" />
        }, () => {
            this.componentDidMount();
        });
    }

    scrollToObject = () => {
        document.getElementById(this.state.pageNumber).scrollIntoView({ inline: "center" });
    }

    scrollToHome = () => {
        document.getElementById("slide1").scrollIntoView({ inline: "center" });
    }

    increasePrice = () => {
        this.setState({
            userPrice: this.state.greaterPropArray[this.state.greaterPropArray.length - 1],
            scroll: true
        }, () => {
            this.sortPropArray();
        });
    }

    decreasePrice = () => {
        this.setState({
            userPrice: this.state.lesserPropArray[0] - 50,
            scroll: true
        }, () => {
            this.sortPropArray();
        });
    }

    startSession = () => {
        this.setState({
            started: true
        });
    }

    plusPrice = (e) => {
        e.preventDefault();
        let newPrice = parseInt(this.state.userPrice) + 5000;
        this.setState({
            userPrice: newPrice,
            scroll: false
        }, () => {
            this.sortPropArray();
        });
    }

    minusPrice = (e) => {
        e.preventDefault();
        let newPrice = this.state.userPrice - 5000;
        if (newPrice < 0) {
            newPrice = this.state.userPrice;
        }
        this.setState({
            userPrice: newPrice,
            scroll: false
        }, () => {
            this.sortPropArray();
        });
    }

    plusRooms = (e) => {
        e.preventDefault();
        this.setState({
            numRooms: parseInt(this.state.numRooms) + 1,
            scroll: false
        });
    }

    minusRooms = (e) => {
        e.preventDefault();
        if (this.state.numRooms !== 0) {
            this.setState({
                numRooms: this.state.numRooms - 1,
                scroll: false
            });
        }
    }

    closeGif = () => {
        if (document.getElementById("gif")) {
            document.getElementById("gif").style.display = "none";
        }
    }

    render() {
        let display = "none";
        if (this.state.loading === null) {
            display = "block";
        }

        let opacity = "0.2";
        let scroll = "none";
        if (this.state.loadingUpdate === null) {
            opacity = "1";
            scroll = "block"
        }

        let priceColour = "#bc020240";
        if (this.state.userPrice > 0) {
            priceColour = "white";
        }

        let rating = "Excellent";
        let stars = <div className="stars"><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i></div>;
        if (this.state.pagePercent > 20 && this.state.pagePercent < 80) {
            rating = "Average";
            stars = <div className="stars"><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i><i class="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i></div>;
        } else if (this.state.pagePercent >= 80) {
            rating = "Bad";
            stars = <div className="stars"><i class="fa fa-star" aria-hidden="true"></i><i class="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i><i class="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i><i class="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i><i class="fa fa-star" aria-hidden="true" style={{ color: "rgb(200,200,200)" }}></i></div>;
        }

        let wordEnd = "th";
        if (this.state.pagePosition === 1 || this.state.pagePosition === 21) {
            wordEnd = "st";
        } else if (this.state.pagePosition === 2 || this.state.pagePosition === 22) {
            wordEnd = "nd";
        } else if (this.state.pagePosition === 3 || this.state.pagePosition === 23) {
            wordEnd = "rd";
        }

        let searchPercent = 100;
        if (this.state.pageNumber > 1) {
            searchPercent -= Math.round(this.state.pageNumber * (100 / (this.state.pages.length * 1.5)));
        }

        let gif = null;
        if (this.state.pageNumber === 1 && this.state.pagePosition === 1 && this.state.featured.length === 0) {
            gif = <div id="gif" className="billAndTed">
                <img src={require("../img/billAndTed.gif")} alt='free'></img>
                <div className="gifClose" onClick={this.closeGif}><i class="fa fa-times" aria-hidden="true"></i></div>
            </div>;
        } else if (this.state.pageNumber === 1 && this.state.pagePosition === 2 && this.state.featured.length > 0) {
            gif = <div id="gif" className="billAndTed">
                <img src={require("../img/billAndTed.gif")} alt='free'></img>
                <div className="gifClose" onClick={this.closeGif}><i class="fa fa-times" aria-hidden="true"></i></div>
            </div>;
        }

        return (
            <div className="App" id="optimiser">


                <div style={{ position: "relative", height: "200px", display: this.state.loading === null ? "none" : "" }}>
                    {this.state.loading}
                </div>

                <div className="main" style={{ display: display, opacity: opacity }}>

                    <div valign="center" id="slide2" style={{ display: scroll }}>

                        {/* <div className="context">
                            <div className="contextHeading">
                                <strong>View all the properties in your search area</strong>
                            </div>
                            <div className="contextContent">
                                <div className="contextText">
                                    Have a look at some of the other properties around yours with the same number of bedrooms.
                                </div>

                                <i>
                                    The advert will get most attention if it is on the first page - more attention may mean more interest!
                                </i>
                            </div>
                        </div> */}

                        <div style={{ paddingBottom: "15px" }}>


                            <div className="scrollTable">
                                <div style={{ fontSize: "18px", marginBottom: "15px" }}><span className="score">{this.state.pagePosition}{wordEnd}</span> advert on page <span className="score">{this.state.pageNumber}</span></div>
                                <div className="optimiser-table">
                                    {/* <img src={require("../img/mobile.png")} style={{width: "82px", position: "relative", marginRight: "-81px"}} alt='mobile'></img> */}
                                    {this.state.pages}
                                </div>
                                {/* {gif} */}
                            </div>

                            {/* <br className="secondBreak"/> */}


                            {/* {stars}
						<div className="alterPriceText">{rating} - <span className="score">{this.state.pagePosition}{wordEnd}</span> advert on page <span className="score">{this.state.pageNumber}</span></div>
						<div className="percentage">
							<i>
								You'll appear in {searchPercent}% of searches that match your property advert.
							</i>
						</div>
                        {lesserDisplay}
                        {greaterDisplay}
                        <label><span className="poundSign">£</span>
                            <span className="inputBar">
								<button onClick={this.minusPrice} className="negative"><i className="fa fa-minus" aria-hidden="true"></i></button>

                                <input 
                                    style={{backgroundColor: priceColour}}
                                    id="priceInput"
                                    type="number" 
                                    min="0" 
                                    value={this.state.userPrice} 
                                    placeholder={this.state.userPrice} 
                                    onChange={this.updatePrice}
                                    step="50">
                                </input>
                                
                                <button onClick={this.plusPrice} className="plus"><i className="fa fa-plus" aria-hidden="true"></i></button>
                            </span>
                        </label> */}

                            {/* <br/>
						<button className="saveBtn">
							SAVE YOUR PRICE
						</button> */}

                        </div>
                    </div>
                </div>
                {this.state.loadingUpdate}
            </div>
        );
    }
}

export default Optimiser;