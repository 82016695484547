import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function CreditProfile2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What's " + props.store.first_name_2 + "'s current credit card balance?",
                        content: "Don't worry, this doesn't massively effect how much you can borrow.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "credit_card_balance_2",
                            next: props.switch + 1
                        }
                    }} id={"credit_card_balance_2"} setValue={(e) => props.setValue({ ...props.store, credit_card_balance_2: e })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Does " + props.store.first_name_2 + " have any adverse credit?",
                        content: "This refers to any negative payment information that would appear on your credit rating (such as defaulted loans, bankruptcy, missed payments and CCJs, or mortgage repossession).",
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 2
                            },
                            "No": {
                                value: "NO",
                                next: props.getSwitch("deposit")
                            }
                        }
                    }} id={"adverse_credit_2"} setValue={(e) => props.setValue({ ...props.store, adverse_credit_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default CreditProfile2;