import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function Contractor2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "What's " + props.store.first_name_2 + "'s daily rate?",
                        content: "This helps lenders understand how and what they can offer to lend you.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "contractor_rate_2",
                            next: props.getSwitch("retirementAge2")
                        }
                    }} id={"contractor_rate_2"} setValue={(e) => props.setValue({ ...props.store, contractor_rate_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Contractor2;