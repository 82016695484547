import React from 'react';
import "./Loader.sass"

function Loader(props) {
    return (
        <div className="loader" style={{display: props.show ? "" : "none", position: props.absolute ? "absolute" : "", backgroundColor: props.transparent ? "transparent" : ""}}>
            <div className="loader1"></div>
            <div className="loader2"></div>
            <div className="loader3"></div>
            <div className="text">{props.text}</div>
        </div>
    );
}

export default Loader;