import React, { useState } from 'react';
import AuthModal from './auth/auth_modal';

function SectionDivider(props) {

    const [show, setShow] = useState(false)

    if (props.switch === props.page) {
        return (
            <div className="adverse_flow">
                <div className="applicantReference">
                    {props.reference}
                </div>

                <div className="section_divider">
                    <div className="heading">
                        {props.heading}
                    </div>
                    <div className="subheading">
                        {props.subheading}
                    </div>
                    <div className="content">
                        {props.content}
                    </div>

                    {props.personal ?
                        <div className={props.personal === "complete" ? "section_overview complete" : "section_overview"}>
                            <div className="icon">
                                {props.personal === "complete" ? <img src={require("../../img/icon-tick.svg")} /> : <img src={require("../../img/personal.svg")} />}
                            </div>
                            <div className="text">
                                <div className="top">Your personal details</div>
                                <div className="bottom">Your name, age, nationality, and if you have any dependents. <span style={{ display: props.personal === "complete" ? "none" : "" }} className="timeframe">Est. 1 min</span></div>
                            </div>
                        </div>
                        : null}

                    {props.employment ?
                        <div className={props.employment === "complete" ? "section_overview complete" : "section_overview"}>
                            <div className="icon">
                                {props.employment === "complete" ? <img src={require("../../img/icon-tick.svg")} /> : <img src={require("../../img/employment.svg")} />}
                            </div>
                            <div className="text">
                                <div className="top">Employment details</div>
                                <div className="bottom">Details about your income &amp; emploment for the past 3 financial years. <span style={{ display: props.employment === "complete" ? "none" : "" }} className="timeframe">Est. 2 mins</span></div>
                            </div>
                        </div>
                        : null}

                    {props.financial ?
                        <div className={props.financial === "complete" ? "section_overview complete" : "section_overview"}>
                            <div className="icon">
                                {props.financial === "complete" ? <img src={require("../../img/icon-tick.svg")} /> : <img src={require("../../img/financial.svg")} />}
                            </div>
                            <div className="text">
                                <div className="top">Financial details</div>
                                <div className="bottom">Details about your {props.sprung ? "financial" : "adverse credit"} history. <span style={{ display: props.financial === "complete" ? "none" : "" }} className="timeframe">Est. 5 mins</span></div>
                            </div>
                        </div>
                        : null}

                    {props.personal_2 ?
                        <div className={props.personal_2 === "complete" ? "section_overview complete" : "section_overview"}>
                            <div className="icon">
                                {props.personal_2 === "complete" ? <img src={require("../../img/icon-tick.svg")} /> : <img src={require("../../img/personal.svg")} />}
                            </div>
                            <div className="text">
                                <div className="top">{props.store.first_name_2 ? props.store.first_name_2 + "'s" : "The second applicant's"} personal details</div>
                                <div className="bottom">{props.store.first_name_2 ? props.store.first_name_2 : "Their"} name, age, nationality, and if they have any dependents. <span style={{ display: props.personal_2 === "complete" ? "none" : "" }} className="timeframe">Est. 2 mins</span></div>
                            </div>
                        </div>
                        : null}

                    {props.employment_2 ?
                        <div className={props.employment_2 === "complete" ? "section_overview complete" : "section_overview"}>
                            <div className="icon">
                                {props.employment_2 === "complete" ? <img src={require("../../img/icon-tick.svg")} /> : <img src={require("../../img/employment.svg")} />}
                            </div>
                            <div className="text">
                                <div className="top">{props.store.first_name_2 ? props.store.first_name_2 + "'s" : "The second applicant's"} employment details</div>
                                <div className="bottom">Details about {props.store.first_name_2 ? props.store.first_name_2 + "'s" : "their"} income &amp; emploment for the past 3 financial years. <span style={{ display: props.employment_2 === "complete" ? "none" : "" }} className="timeframe">Est. 5 mins</span></div>
                            </div>
                        </div>
                        : null}

                    {props.financial_2 ?
                        <div className={props.financial_2 === "complete" ? "section_overview complete" : "section_overview"}>
                            <div className="icon">
                                {props.financial_2 === "complete" ? <img src={require("../../img/icon-tick.svg")} /> : <img src={require("../../img/financial.svg")} />}
                            </div>
                            <div className="text">
                                <div className="top">{props.store.first_name_2 ? props.store.first_name_2 + "'s" : "The second applicant's"} financial details</div>
                                <div className="bottom">Details about {props.store.first_name_2 ? props.store.first_name_2 + "'s" : "their"} {props.sprung ? "financial" : "adverse credit"} history. <span style={{ display: props.financial_2 === "complete" ? "none" : "" }} className="timeframe">Est. 8 mins</span></div>
                            </div>
                        </div>
                        : null}

                    <button className="capBtn" onClick={() => {
                        props.setPage(props.switch + 1)
                        props.setPrev([...props.prev, props.switch + 1])
                    }}>
                        Next
                    </button>

                    <div className="content" style={{ marginBottom: "5px" }}>
                        Want to save your progress?
                    </div>
                    <div style={{ margin: "0px 15px", textAlign: "left" }}>
                        <div className="sub_button" onClick={() => setShow("save")}>
                            Save your details
                        </div>
                    </div>

                    <div className="content" style={{ marginBottom: "5px", marginTop: "15px", display: !props.auth ? "none" : "" }}>
                        Already been here?
                    </div>
                    <div style={{ margin: "0px 15px", textAlign: "left", display: !props.auth ? "none" : "" }}>
                        <div className="sub_button" onClick={() => setShow("login")}>
                            Login
                        </div>
                    </div>
                </div>

                <AuthModal originator={props.originator} show={show === "save"} setShow={e => setShow(e)} signin setValue={props.setValue} same_page={true} save_mortgage store={props.store} prev={props.prev} setPrev={props.setPrev} setPage={props.setPage} />
                <AuthModal originator={props.originator} id="auth_modal_2" show={show === "login"} setShow={e => setShow(e)} signin setValue={props.setValue} same_page={true} return_data store={props.store} prev={props.prev} setPrev={props.setPrev} setPage={props.setPage} />

            </div>
        )
    } else return null
}

export default SectionDivider