import React, { useEffect, useState } from 'react';
import Selection from '../selection';

function CreditProfile(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: props.sprung ? "How much are your total monthly repayments?" : "What's your current credit card balance?",
                        content: props.sprung ? "This could include loan repayments, credit card repayments... Any repayments you're currently making." : "Don't worry, this doesn't massively effect how much you can borrow.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "credit_card_balance",
                            next: props.switch + 1
                        }
                    }} id={"credit_card_balance"} setValue={(e) => props.setValue({ ...props.store, credit_card_balance: e })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Do you have any adverse credit?",
                        content: "This refers to any negative payment information that would appear on your credit rating (such as defaulted loans, bankruptcy, missed payments and CCJs, or mortgage repossession).",
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 2
                            },
                            "No": {
                                value: "NO",
                                next: props.store.number_of_applicants === 2 ? props.switch + 33 : props.getSwitch("deposit")
                            }
                        }
                    }} id={"adverse_credit"} setValue={(e) => props.setValue({ ...props.store, adverse_credit: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default CreditProfile;