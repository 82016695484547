import React, { useEffect, useState } from 'react';
import codes from '../country_codes.json';
import * as Icon from "react-bootstrap-icons"
import "./nationality.sass"

function Country(props) {
    const [cit, setCit] = useState(props.store?.nationalities || [])
    const [citInput, setCitInput] = useState("")

    useEffect(() => {
        props.add("nationalities", cit)
    }, [cit])

    return (
        <div>
            <div className='heading'>
                Where are you from?
            </div>
            <div className='content'>
                We'd like to know what country you were born in, and what citizenships do you hold.
            </div>

            <div className='input_contain'>
                <div className='input_wrap'>
                    <input value={props.store?.country_of_birth || undefined} list='country_of_birth' placeholder='Country of Birth' onChange={e => {
                        if (codes.filter(f => f.Name === e.currentTarget.value).length === 1) props.add("country_of_birth", e.currentTarget.value)
                        else props.add("country_of_birth", undefined)
                    }} />
                    <datalist id="country_of_birth" name="country_of_birth">
                        <option value="United Kingdom">United Kingdom</option>
                        {codes.map(e => {
                            if (e.Code !== "GB") return <option value={e.Name}>{e.Name}</option>
                        })}
                    </datalist>
                    {/* <div className='required'>*</div> */}
                </div>
            </div>

            <div className='content' style={{margin: "30px 0px 0px", fontWeight: "600"}}>
                Countries of Citizenship
            </div>

            <div className='input_contain'>
                <div className='input_wrap'>
                    <input value={citInput} list='citizenships' placeholder='Add Country of Citizenship' onChange={e => {
                        setCitInput(e.currentTarget.value)
                        if (codes.filter(f => f.Name === e.currentTarget.value).length === 1) {
                            setCit([...cit, e.currentTarget.value + ", " + codes.filter(f => f.Name === e.currentTarget.value)[0].Code])
                            setCitInput("")
                        }
                    }} />
                    <datalist id="citizenships" name="citizenships">
                        {!cit.includes("United Kingdom, GB") ? <option value="United Kingdom">United Kingdom</option> : undefined}
                        {codes.map(e => {
                            if (e.Code !== "GB" && !cit.includes(e.Name + ", " + codes.filter(f => f.Name === e.Name)[0].Code)) return <option value={e.Name}>{e.Name}</option>
                        })}
                    </datalist>
                    {/* <div className='required'>*</div> */}
                </div>
            </div>

            <div style={{margin: "15px"}}>
                {props.store?.nationalities?.length > 0 ? props.store.nationalities.map(e => {
                    return <div className='nationality'>
                        {e.split(", ")[0]}
                        <div onClick={() => {
                            setCit(cit.filter(f => f !== e))
                        }} style={{display: "inline-block", paddingLeft: "10px", fontSize: "26px", verticalAlign: "middle", cursor: "pointer", margin: "-5px"}}><Icon.X /></div>
                    </div>
                }) : null}
            </div>

            <button disabled={!props.store.country_of_birth || props.store.nationalities.length === 0} className='capBtn' style={{ margin: "15px" }} onClick={() => {
                props.next()
            }}>
                Next
            </button>
        </div>
    );
}

export default Country;