import React, { useEffect, useState } from 'react'
import AccountCreation from './sections/account_creation'
import Bankruptcy from './sections/adverse_credit/bankruptcy'
import CCJ from './sections/adverse_credit/ccj'
import Default from './sections/adverse_credit/default'
import DMP from './sections/adverse_credit/dmp'
import IVA from './sections/adverse_credit/iva'
import PaydayLoan from './sections/adverse_credit/payday_loan'
import SecuredPayment from './sections/adverse_credit/secure_payment'
import UnsecuredPayment from './sections/adverse_credit/unsecured_payment'
import ApplicationDetails from './sections/application_details'
import CreditProfile from './sections/credit_profile'
import Deposit from './sections/deposit'
import CompanyDirector from './sections/employment/company_director'
import Contractor from './sections/employment/contractor'
import Employed from './sections/employment/employed'
import Employment from './sections/employment/employment'
import SelfEmployed from './sections/employment/self_employed'
import Bankruptcy2 from './sections/second_applicant/adverse_credit/bankruptcy'
import CCJ2 from './sections/second_applicant/adverse_credit/ccj'
import Default2 from './sections/second_applicant/adverse_credit/default'
import DMP2 from './sections/second_applicant/adverse_credit/dmp'
import IVA2 from './sections/second_applicant/adverse_credit/iva'
import PaydayLoan2 from './sections/second_applicant/adverse_credit/payday_loan'
import SecuredPayment2 from './sections/second_applicant/adverse_credit/secure_payment'
import UnsecuredPayment2 from './sections/second_applicant/adverse_credit/unsecured_payment'
import CreditProfile2 from './sections/second_applicant/credit_profile'
import CompanyDirector2 from './sections/second_applicant/employment/company_director'
import Contractor2 from './sections/second_applicant/employment/contractor'
import Employed2 from './sections/second_applicant/employment/employed'
import Employment2 from './sections/second_applicant/employment/employment'
import SelfEmployed2 from './sections/second_applicant/employment/self_employed'
import SecondApplicant from './sections/second_applicant/second_applicant'
import "./full.sass"
import "./creditladder.sass"
import "./portify.sass"
import "./nrla.sass"
import "./sprung.sass"
import "./vesta.sass"
import "./nude.sass"
import "./closefield.sass"
import Repossession from './sections/adverse_credit/repossession'
import Repossession2 from './sections/second_applicant/adverse_credit/repossession'
import SectionDivider from './section_divider'
import Intro from './sections/intro'
import AdditionalIncome from './sections/employment/additional_income'
import AdditionalIncome2 from './sections/second_applicant/employment/additional_income'
import format_data from './format_data'
import ReactResizeDetector from 'react-resize-detector';
import RetirementAge from './sections/employment/retirement_age'
import Expenditure from './sections/employment/expenditure'
import MortgageReason from './sections/mortgage_reason'
import RetirementAge2 from './sections/second_applicant/employment/retirement_age_2'
import Expenditure2 from './sections/second_applicant/employment/expenditure'
import Email from './sections/email'

function Full(props) {
    const [store, setStore] = useState({
        referrer: props.match.params.style ? props.match.params.style : "moresimplemoney"
    })
    const [page, setPage] = useState(1)
    const [prev, setPrev] = useState([1])
    const [num, setNum] = useState(1)
    // const [progress, setProgress] = useState(1)
    const [full, setFull] = useState(7)
    const [pop, setPop] = useState(true)

    useEffect(() => {
        console.log(store)
    }, [store])

    useEffect(() => {
        broadcastHeight()
    }, [page])

    useEffect(() => () => {
        window.removeEventListener("resize", () => { })
    })

    function broadcastHeight() {
        let height = document.getElementById("free_iframe").scrollHeight + 115
        let message = "freeWindowHeight:" + height
        window.parent.postMessage(message, "*")
    }

    useEffect(() => {
        if (page !== 1) {
            try {
                sessionStorage.setItem("store", JSON.stringify(page === 114 || page === 116 ? { ...store, page: 116, prev: store.prev.includes(115) || store.prev.includes(116) ? store.prev : [...store.prev, 115, 116] } : store))
            } catch (err) {
                // console.log("Can't access localStorage")
            }
        }
        if (store.page !== page) {
            if (page !== 1) {
                setStore({ ...store, page: page, prev: prev })
            } else {
                try {
                    let stored = JSON.parse(sessionStorage.getItem("store"))
                    if (stored) {
                        setStore(stored)
                        setPage(stored.page === 114 ? 116 : stored.page)
                        setPrev(stored.prev === 114 || stored.prev === 116 ? [...stored.prev, 114, 115, 116] : stored.prev)
                    }
                } catch (err) {
                    // console.log("Can't access localStorage")
                }
            }
        }
        if (store.number_of_applicants === 1) {
            setFull(3)
        } else {
            setFull(6)
        }
    }, [store])

    useEffect(() => {
        if (prev.length < num && prev.length >= 1) {
            setPage(prev[prev.length - 1])
        }
        // let remove = [1, 8, 25, 59, 63, 80]
        // let done = false
        // let p = prev
        // for (let i = 0; i < remove.length; i++) {
        //     if (prev.includes(remove[i])) {
        //         done = true
        //         p.splice(prev.indexOf(remove[i]), 1)
        //     }
        // }
        // if (done) setPrev(p)
        setNum(prev.length)
    }, [prev])

    useEffect(() => {
        if (pop) {
            window.history.pushState({ page: page }, "", window.location.href)
            setPop(true)
        }
    }, [page])

    useEffect(() => {
        window.addEventListener('popstate', function (event) {
            setPop(false)
            setPage(event.state.page);
        });

        return () => window.removeEventListener("popstate", () => { });
    }, [])

    function goBack() {
        // let remove = [1, 8, 25, 59, 63, 80]
        let remove = []
        if (!remove.includes(prev[prev.length - 2])) setPrev(prev.slice(0, -1))
        else setPrev(prev.slice(0, -2))
    }

    const pageSizes = {
        intro: 1,
        sectionDivider1: 1,

        applicationDetails: 6,

        sectionDivider2: 1,

        employment: 1,
        employed: 3,
        selfEmployed: 4,
        companyDirector: 6,
        contractor: 1,
        retirementAge: 0,
        additionalIncome: 1,
        expenditure: 0,

        sectionDivider3: 1,

        creditProfile: 2,
        ccj: 5,
        default: 6,
        securedPayment: 4,
        unsecuredPayment: 4,
        paydayLoan: 4,
        bankruptcy: 2,
        iva: 2,
        dmp: 2,
        repossession: 2,

        sectionDivider4: 1,

        secondApplicant: 5,

        sectionDivider5: 1,

        employment2: 1,
        employed2: 3,
        selfEmployed2: 4,
        companyDirector2: 6,
        contractor2: 1,
        retirementAge2: 0,
        additionalIncome2: 1,
        expenditure2: 0,

        sectionDivider6: 1,

        creditProfile2: 2,
        ccj2: 5,
        default2: 6,
        securedPayment2: 6,
        unsecuredPayment2: 4,
        paydayLoan2: 4,
        bankruptcy2: 2,
        iva2: 2,
        dmp2: 2,
        repossession2: 2,

        deposit: 1,
        mortgageReason: 2,
        emails: 2,
        accountCreation: 1
    }

    function getSwitch(text) {
        let sizes = Object.entries(pageSizes)
        let count = 0
        for (let i = 0; i < sizes.length; i++) {
            if (sizes[i][0] === text) break;
            else count += sizes[i][1]
        }
        // console.log(text, count)
        return count
    }

    return (
        <ReactResizeDetector handleHeight onResize={(w, h) => {
            broadcastHeight()
        }}>
            <div className={props.match.params.style ? props.match.params.style : "encompass"}>
                <div className="adverse_flow" style={{ paddingBottom: props.lower ? "115px" : "20px" }} id="free_iframe">
                    <div className="navbar">
                        <button style={{ display: prev.length <= 1 ? "none" : "" }} className="back" onClick={() => goBack()}>
                            <img className="backArrow" src={require("../../img/icon-arrow-back.svg")} alt="back"></img>
                            <div className="backText">Back</div>
                        </button>
                        {/* <img className="logo" src={require("../../img/logo.svg")} alt="free" ></img> */}
                        <br /><br /><br />
                        <div className="progress_bar" style={{ display: page > 2 && page < getSwitch("accountCreation") ? "" : "none" }}>
                            <div className={page >= getSwitch("sectionDivider2") ? "progress active" : "progress"} style={{ width: full === 3 ? "calc((100% / 3) - 4px)" : "calc((100% / 6) - 4px)" }}></div>
                            <div className={page >= getSwitch("sectionDivider3") ? "progress active" : "progress"} style={{ width: full === 3 ? "calc((100% / 3) - 4px)" : "calc((100% / 6) - 4px)" }}></div>
                            <div className={page >= getSwitch("sectionDivider4") ? "progress active" : "progress"} style={{ display: full === 6 ? "" : "none", width: "calc((100% / 6) - 4px)" }}></div>
                            <div className={page >= getSwitch("sectionDivider5") ? "progress active" : "progress"} style={{ display: full === 6 ? "" : "none", width: "calc((100% / 6) - 4px)" }}></div>
                            <div className={page >= getSwitch("sectionDivider6") ? "progress active" : "progress"} style={{ display: full === 6 ? "" : "none", width: "calc((100% / 6) - 4px)" }}></div>
                            <div className={page >= getSwitch("deposit") ? "progress active" : "progress"} style={{ width: full === 3 ? "calc((100% / 3) - 4px)" : "calc((100% / 6) - 4px)" }}></div>
                        </div>
                    </div>

                    {/* Page 0 */}
                    <Intro switch={getSwitch("intro")} switch3={0} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    <SectionDivider switch={getSwitch("sectionDivider1")} switch3={1}
                        originator={props.match.params.style === "sprung" ? "Sprung" : "MS.Money"}
                        heading="Let's see what you can borrow"
                        content="For accurate results, please have the following information to hand:"
                        personal
                        employment
                        financial
                        sprung={props.match.params.style === "sprung"}
                        auth
                        prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 1 - 6 */}
                    <ApplicationDetails switch={getSwitch("applicationDetails")} switch3={2} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    <SectionDivider switch={getSwitch("sectionDivider2")} switch3={9}
                        originator={props.match.params.style === "sprung" ? "Sprung" : "MS.Money"}
                        heading="Great!"
                        subheading="Your personal details are complete."
                        content="What's next?"
                        employment
                        prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Page 7 */}
                    <Employment getSwitch={getSwitch} switch={getSwitch("employment")} switch3={10} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 8 - 10 */}
                    <Employed getSwitch={getSwitch} switch={getSwitch("employed")} switch3={11} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 11 - 14 */}
                    <SelfEmployed getSwitch={getSwitch} switch={getSwitch("selfEmployed")} switch3={14} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 15 - 20 */}
                    <CompanyDirector getSwitch={getSwitch} switch={getSwitch("companyDirector")} switch3={18} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Page 21 */}
                    <Contractor switch={getSwitch("contractor")} switch3={24} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* <RetirementAge switch={getSwitch("retirementAge")} switch3={24} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} /> */}

                    <AdditionalIncome switch={getSwitch("additionalIncome")} switch3={25} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* <Expenditure switch={getSwitch("expenditure")} switch3={25} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} /> */}

                    <SectionDivider switch={getSwitch("sectionDivider3")} switch3={26}
                        originator={props.match.params.style === "sprung" ? "Sprung" : "MS.Money"}
                        sprung={props.match.params.style === "sprung"}
                        heading="Great!"
                        subheading="Your employment details are complete."
                        content="What's next?"
                        financial
                        prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 22 - 24 */}
                    {/* VARIABLE */}
                    <CreditProfile getSwitch={getSwitch} switch={getSwitch("creditProfile")} sprung={props.match.params.style === "sprung"} switch3={27} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 25 - 29 */}
                    <CCJ switch={getSwitch("ccj")} switch3={29} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 30 - 35 */}
                    <Default switch={getSwitch("default")} switch3={34} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 36 - 39 */}
                    <SecuredPayment switch={getSwitch("securedPayment")} switch3={40} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 40 - 43 */}
                    <UnsecuredPayment switch={getSwitch("unsecuredPayment")} switch3={44} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 44 - 47 */}
                    <PaydayLoan switch={getSwitch("paydayLoan")} switch3={48} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 48 - 49 */}
                    <Bankruptcy switch={getSwitch("bankruptcy")} switch3={52} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 50 - 51 */}
                    <IVA switch={getSwitch("iva")} switch3={54} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 52 - 53 */}
                    <DMP switch={getSwitch("dmp")} switch3={56} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 54 - 55 */}
                    {/* VARIABLE */}
                    <Repossession getSwitch={getSwitch} switch={getSwitch("repossession")} switch3={58} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    <SectionDivider switch={getSwitch("sectionDivider4")} switch3={60}
                        originator={props.match.params.style === "sprung" ? "Sprung" : "MS.Money"}
                        heading="Great!"
                        subheading="Your financial details are complete."
                        content="What's next?"
                        personal_2={store.number_of_applicants === 1 ? false : true}
                        employment_2={store.number_of_applicants === 1 ? false : true}
                        financial_2={store.number_of_applicants === 1 ? false : true}
                        prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 56 - 58 */}
                    <SecondApplicant switch={getSwitch("secondApplicant")} switch3={61} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    <SectionDivider switch={getSwitch("sectionDivider5")} switch3={64}
                        reference={store.first_name_2 + "'s details"}
                        originator={props.match.params.style === "sprung" ? "Sprung" : "MS.Money"}
                        heading="Great!"
                        subheading={store.first_name_2 + "'s financial details are complete."}
                        content="What's next?"
                        employment_2
                        prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Page 59 */}
                    <Employment2 getSwitch={getSwitch} switch={getSwitch("employment2")} switch3={65} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 60 - 62 */}
                    <Employed2 getSwitch={getSwitch} switch={getSwitch("employed2")} switch3={65} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 63 - 66 */}
                    <SelfEmployed2 getSwitch={getSwitch} switch={getSwitch("selfEmployed2")} switch3={69} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 67 - 72 */}
                    <CompanyDirector2 getSwitch={getSwitch} switch={getSwitch("companyDirector2")} switch3={73} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Page 73 */}
                    <Contractor2 getSwitch={getSwitch} switch={getSwitch("contractor2")} switch3={79} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* <RetirementAge2 switch={getSwitch("retirementAge2")} switch3={24} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} /> */}

                    <AdditionalIncome2 getSwitch={getSwitch} switch={getSwitch("additionalIncome2")} switch3={80} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* <Expenditure2 switch={getSwitch("expenditure2")} switch3={25} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} /> */}

                    <SectionDivider switch={getSwitch("sectionDivider6")} switch3={81}
                        reference={store.first_name_2 + "'s details"}
                        originator={props.match.params.style === "sprung" ? "Sprung" : "MS.Money"}
                        heading="Great!"
                        subheading={store.first_name_2 + "'s financial details are complete."}
                        content="What's next?"
                        financial_2
                        prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 74 - 76 */}
                    {/* VARIABLE */}
                    <CreditProfile2 getSwitch={getSwitch} switch={getSwitch("creditProfile2")} switch3={82} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 77 - 81 */}
                    <CCJ2 switch={getSwitch("ccj2")} switch3={84} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 82 - 87 */}
                    <Default2 switch={getSwitch("default2")} switch3={89} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 88 - 91 */}
                    <SecuredPayment2 getSwitch={getSwitch} switch={getSwitch("securedPayment2")} switch3={95} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 92 - 95 */}
                    <UnsecuredPayment2 switch={getSwitch("unsecuredPayment2")} switch3={99} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 96 - 99 */}
                    <PaydayLoan2 switch={getSwitch("paydayLoan2")} switch3={103} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 100 - 101 */}
                    <Bankruptcy2 switch={getSwitch("bankruptcy2")} switch3={107} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 102 - 103 */}
                    <IVA2 switch={getSwitch("iva2")} switch3={109} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 104 - 105 */}
                    <DMP2 switch={getSwitch("dmp2")} switch3={111} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 106 - 107 */}
                    <Repossession2 switch={getSwitch("repossession2")} switch3={113} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Page 108 */}
                    <Deposit switch={getSwitch("deposit")} switch3={115} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    <MortgageReason switch={getSwitch("mortgageReason")} switch3={115} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    <Email  switch={getSwitch("emails")} switch3={115} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />

                    {/* Pages 109 - 110 */}
                    <AccountCreation switch={getSwitch("accountCreation")} originator={props.match.params.style === "sprung" ? "Sprung" : "MS.Money"} style={props.match.params.style} switch3={116} prev={prev} setPrev={setPrev} page={page} setPage={setPage} store={store} setValue={(e) => setStore({ ...e, page: page, prev: prev })} />
                </div>
            </div>
        </ReactResizeDetector>
    );
}

export default Full;