import React, { useEffect } from 'react';
import "./iframe.sass";

function IFrame(props) {
    useEffect(() => {
        window.addEventListener("message", (e) => {
            if (typeof e.data === "string") {
                console.log(e.data.split(":")[1])
            }
        })

        return () => window.removeEventListener("message", () => { })
    })

    return (
        <div>
            <div className="iframe_container">
                <iframe src="https://free.co.uk/steps_combine/closefield" title="Adverse credit lender finder" />
            </div>
        </div>
    );
}

export default IFrame;