import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function Repossession(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Have you ever had a repossession?",
                        info: {
                            id: "repossession_modal",
                            text: "What is a repossession?",
                            content: "Repossession is the term used to describe the taking back of property after a borrower has defaulted on payments. The lender either repossesses the collateral or pays a third-party service to do so.",
                            content2: "You probably won’t be able to get a mortgage in the first 12 months after a repossession. The further away in time the repossession is, the more lenders will be willing to consider you and the more likely you will be accepted for a mortgage."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.store.number_of_applicants === 1 ? props.getSwitch("deposit") : props.switch + 2
                            }
                        }
                    }} id={"had_a_repossession"} setValue={(e) => props.setValue({ ...props.store, had_a_repossession: e })} />
                )
            
            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When did the repossession occur?",
                        input: {
                            type: "month",
                            id: "repossession_settled",
                            min: 7,
                            next: props.store.number_of_applicants === 1 ? props.getSwitch("deposit") : props.switch + 2
                        }
                    }} id={"repossession_settled"} setValue={(e) => props.setValue({ ...props.store, repossession_settled: e })} />
                )

            default:
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Repossession;