import React from "react";
import './idd.sass';

class IDD extends React.Component {
    render() {
        return (
            <div className="listing-flow">
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <div className="navbar">
                        {/* <button className="back" onClick={this.prevPage} >
                            <img className="backArrow" src={require("../img/icon-arrow-back.svg")}></img>
                            <div className="backText">
                                Back
                            </div>
                        </button> */}
                        <a className="iddLink" href="/"><img className="logo" src={require("../img/logo.svg")} alt='free'></img></a>
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div className="pageHeadingPolicy">
                            Initial Disclosure Document
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            <strong>
                                More Simple Money Limited<br />
                                38B Enmore Road<br />
                                London<br />
                                SE25 5NG
                            </strong>
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            1. The Financial Conduct Authority (FCA)
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            The FCA is the independent watchdog that regulates financial services. This document is designed by the FCA
                            to be given to consumers considering buying certain financial products. You need to read this important
                            document. It explains the service you are being offered and how you will pay for it.
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            2. Whose products do we offer?
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            <strong>Mortgages</strong>
                            <table className="iddTable">
                                <tbody>
                                    <tr>
                                        <td><div className="iddBox"><div className="checkMark">&#10003;</div></div></td>
                                        <td>We offer mortgages from the whole market.</td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"></div></td>
                                        <td>We only offer some, but not all, of the mortgages from certain lenders. We can provide you with a list of these.</td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"></div></td>
                                        <td>We only offer mortgages from a single lender.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <strong>Insurance</strong>
                            <table className="iddTable">
                                <tbody>
                                    <tr>
                                        <td><div className="iddBox"><div className="checkMark">&#10003;</div></div></td>
                                        <td>We offer products from a range of providers for life cover, income protection and critical illness cover.</td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"></div></td>
                                        <td>We only offer products from a limited number of providers for life cover, income protection and critical illness cover. Ask us for a list of the providers we offer insurance from.</td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"></div></td>
                                        <td>We offer products from a single provider for life cover, income protection and critical illness cover. </td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"><div className="checkMark">&#10003;</div></div></td>
                                        <td>We only refer to a limited number of providers for home insurance and accident, sickness and unemployment insurance. Ask us for a list of the providers we may refer you to.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            3. Which service will we provide you with?
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            <strong>Mortgages</strong>
                            <table className="iddTable">
                                <tbody>
                                    <tr>
                                        <td><div className="iddBox"><div className="checkMark">&#10003;</div></div></td>
                                        <td>We will advise and make a recommendation for you after we have assessed your needs.</td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"></div></td>
                                        <td>You will not receive advice or a recommendation from us.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <strong>Insurance</strong>
                            <table className="iddTable">
                                <tbody>
                                    <tr>
                                        <td><div className="iddBox"><div className="checkMark">&#10003;</div></div></td>
                                        <td>We will advise and make a recommendation for you after we have assessed your needs in respect of life cover, income protection and critical illness cover.</td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"></div></td>
                                        <td>You will not receive advice or a recommendation from us in respect of term assurance, income protection and critical illness cover. We may ask some questions to narrow down the selection of products that we will provide details on. You will then need to make your own choice about how to proceed.</td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"><div className="checkMark">&#10003;</div></div></td>
                                        <td>You will not receive advice or a recommendation from us in respect of home insurance or accident, sickness and unemployment cover. We have a limited number of providers who we refer you to in this instance. You will then need to make your own choice about how to proceed. </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            4. What will you have to pay us for our services?
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            <strong>Mortgages</strong>
                            <table className="iddTable">
                                <tbody>
                                    <tr>
                                        <td><div className="iddBox"></div></td>
                                        <td>No fee. We will be paid a procuration fee from the lender.</td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"><div className="checkMark">&#10003;</div></div></td>
                                        <td>A non-refundable fee. The minimum fee is £495 and the maximum fee is 2% of the loan amount. The level of fee we charge depends upon the individual complexity of the advice and support required in securing a mortgage for you. A typical fee for securing a standard mortgage arrangement is £499.
                                            <br/><br/>
                                            The level of fee to be charged will be confirmed and agreed at outset and before proceeding. The fee will only be payable upon successful completion of your mortgage.
                                            <br/><br/>
                                            We will also receive a procuration fee from the lender.
                                            <br/><br/>
                                            You will receive a Key Facts Illustration when considering a particular mortgage which will confirm the fees relating to our recommendation.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br/>
                            <strong>Insurance</strong>
                            <table className="iddTable">
                                <tbody>
                                    <tr>
                                        <td><div className="iddBox"></div></td>
                                        <td>A fee.
                                            <br /><br />
                                            Although we normally receive commission from the respective provider for products such as life cover, income protection, critical illness cover and private medical insurance, we will still give you the alternative of paying for our services by means of a fee. This means that the premiums payable to the product provider will be lower as you will have paid for our services separately. You will not be subject to VAT for this service. </td>
                                    </tr>
                                    <tr>
                                        <td><div className="iddBox"><div className="checkMark">&#10003;</div></div></td>
                                        <td>No fee. The product provider will pay us commission.
                                            <br /><br />
                                            You will receive a personalised illustration which will confirm the amount of commission relating to our recommendation. If for any reason we are unable to provide you with a personalised illustration, the amount of commission will be confirmed by us in writing.</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            For home insurance and accident, sickness and unemployment cover, we will receive a referral fee directly from the provider if you decide to proceed with their offering. No fees will be payable by you to us.
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            5. Who regulates us?
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            More Simple Money Limited is authorised and regulated by the Financial Conduct Authority. Our Financial Services Register number is 787605. Our permitted business is advising on and arranging pensions, investments, mortgages, life assurance and general insurance. You can check this on the FCA Register by visiting the FCA website <a className="iddLink" href="https://register.fca.org.uk/s/">register.fca.org.uk/s/</a> or by contacting the FCA on 0845 606 1234.
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            6. What to do if you have a complaint
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            If you wish to register a complaint, please write to The Compliance Officer, <strong>38B Enmore Road, London SE25 5NG</strong> or email <a className="iddLink" href="mailto:ray@free.co.uk">ray@free.co.uk</a>. A summary of our internal complaints handling procedures for the reasonable and prompt handling of complaints is available on request and if you cannot settle your complaint with us, you may be entitled to refer it to the Financial Ombudsman Service at <a className="iddLink" href="https://www.financial-ombudsman.org.uk">www.financial-ombudsman.org.uk</a> or by contacting them on 0800 023 4 567.
                        </div>
                        <hr className="policyDivider" />

                        <div className="pageSub">
                            7. Are we covered by the Financial Services Compensation Scheme (FSCS)?
                        </div>
                        <div className="pageContent" style={{ textAlign: "left" }}>
                            We are covered by the FSCS. You may be entitled to compensation from the scheme if we cannot meet our obligations. This depends on the type of business and the circumstances of the claim. General insurance and life insurance business is covered for 90% of the claim, without any upper limit. Advising on or arranging house purchase finance is covered up to a maximum of £85,000. Further information about this compensation scheme arrangement is available from the FSCS.
                        </div>
                        <hr className="policyDivider" />
                        {/* <div className="pageContent" style={{ textAlign: "left" }}>
                            150730 Mortgages & Protection CIDD
                        </div>
                        <hr className="policyDivider" /> */}

                        <div className="pageContent">
                            Free free free Ltd<br />
                            38B Enmore Road<br />
                            London<br />
                            SE25 5NG
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IDD;