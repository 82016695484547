import React, { useEffect, useState } from 'react';
import CountryCodes from './country_codes';

function PhoneInput(props) {
    const [code, setCode] = useState(props.countryCode)
    const [number, setNumber] = useState(props.phoneNumber)

    useEffect(() => {
        props.cc(code)
        props.number(number)
    }, [code, number])

    return (
        <div className="input_contain">
            <div className="input_wrap">
                <CountryCodes value={code} function={(e) => setCode(e.currentTarget.value)} /><input value={number} onChange={e => setNumber(isNaN(parseInt(e.currentTarget.value).toString()) ? "" : parseInt(e.currentTarget.value).toString())} style={{width: "calc(100% - 121px)", marginLeft: "-1px", verticalAlign: "middle"}} type="number" placeholder="Phone number" />
            </div>
        </div>
    );
}

export default PhoneInput;