import React from 'react';

function NationalInsurance(props) {
    return (
        <div>
            <div className='heading'>
                What is your national insurance number?
            </div>
            <div className='content'>
                We understand you might be a bit wary of giving over your national insurance number, so we can collect it at a different time if you'd prefer.
            </div>

            <div className='input_contain'>
                <div className='input_wrap'>
                    <input value={props.store?.national_insurance_number || undefined} type="text" placeholder='National Insurance Number' onChange={e => props.add("national_insurance_number", e.currentTarget.value)} />
                </div>
            </div>

            <button className='capBtn' style={{ margin: "15px" }} onClick={() => props.next()}>
                Next
            </button>
        </div>
    );
}

export default NationalInsurance;