import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function UnsecuredPayment2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        if (props.page === props.switch + 3 && props.store.missed_unsecured_payment_date_2 !== undefined && props.store.missed_unsecured_payment_amount_2 !== undefined) {
            let store = { ...props.store }
            let missed_unsecured_payment_date_2 = store.missed_unsecured_payment_date_2
            let missed_unsecured_payment_amount_2 = store.missed_unsecured_payment_amount_2
            delete store.missed_unsecured_payment_date_2
            delete store.missed_unsecured_payment_amount_2
            props.setValue({ ...store, missed_unsecured_payments_2: [...props.store.missed_unsecured_payments_2, {missed_unsecured_payment_date_2: missed_unsecured_payment_date_2, missed_unsecured_payment_amount_2: missed_unsecured_payment_amount_2 }]})
        }
        setDisplay(displayPage())
    }, [props.page, props.store])

    function remove_missed_unsecured_payment(i) {
        props.store.missed_unsecured_payments_2.splice(i, 1)
        props.setValue({...props.store, missed_unsecured_payments_2: props.store.missed_unsecured_payments_2})
    }

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Has " + props.store.first_name_2 + " ever missed an unsecured payment?",
                        info: {
                            id: "unsecured_payment_loan_modal_3",
                            text: "What is an unsecured payment?",
                            content: "An unsecured late payment covers credit agreements including mobile phone contracts, credit cards, overdrafts, personal loans, and other things where the debt isn’t secured against anything.",
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 4
                            }
                        }
                    }} id={"had_a_missed_unsecured_payment_2"} setValue={(e) => props.setValue({ ...props.store, had_a_missed_unsecured_payment_2: e, missed_unsecured_payments_2: [] })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When did " + props.store.first_name_2 + " last miss an unsecured payment?",
                        input: {
                            type: "month",
                            placeholder: "MM - YYYY",
                            id: "missed_unsecured_payment_date_2",
                            min: 7,
                            next: props.switch + 2
                        }
                    }} id={"missed_unsecured_payment_date_2"} setValue={(e) => props.setValue({ ...props.store, missed_unsecured_payment_date_2: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "How much was the unsecured payment for?",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "missed_unsecured_payment_amount_2",
                            next: props.switch + 3
                        }
                    }} id={"missed_unsecured_payment_amount_2"} setValue={(e) => props.setValue({ ...props.store, missed_unsecured_payment_amount_2: e })} />
                )

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Please list all of " + props.store.first_name_2 + "'s missed unsecured payments since May 2019",
                        info: {
                            id: "unsecured_payment_loan_modal_4",
                            text: "What is an unsecured payment?",
                            content: "An unsecured late payment covers credit agreements including mobile phone contracts, credit cards, overdrafts, personal loans, and other things where the debt isn’t secured against anything.",
                        },
                        collection: {
                            type: "missed_unsecured_payment",
                            append: "_2",
                            add: props.switch + 1
                        },
                        next: props.switch + 4
                    }} id={"missed_unsecured_payment_collection_2"} setValue={(e) => {}} remove={(e) => remove_missed_unsecured_payment(e)} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default UnsecuredPayment2;