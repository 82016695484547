import React from 'react';

function Smoker(props) {
    function changeStatus(bool) {
        props.add("smoker_status", bool)
        props.next()
    }

    return (
        <div>
            <div className='heading'>
                Do you smoke?
            </div>
            <div className='content'>
                Have you smoked or used any tobacco or nicotine replacement products in the last 12 months?
                This includes cigarettes, e-cigarettes, vaping, cigars or pipes.
                <br /><br />
                Insurers ask this so we can accurately price your life insurance quote.
            </div>

            <button className='radio' onClick={() => changeStatus(true)}>
                <div className="radio_circle" style={{ backgroundColor: props.store.smoker_status ? props.style === "creditladder" ? "#00dfbc" : "black" : "white" }}>
                    <img className="tick" src={require("../../../img/tick-white.png")} alt="tick"></img>
                </div>
                Yes
            </button>
            <button className='radio' onClick={() => changeStatus(false)}>
                <div className="radio_circle" style={{ backgroundColor: props.store.smoker_status === false ? props.style === "creditladder" ? "#00dfbc" : "black" : "white" }}>
                    <img className="tick" src={require("../../../img/tick-white.png")} alt="tick"></img>
                </div>
                No
            </button>
        </div>
    );
}

export default Smoker;