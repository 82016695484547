import React from 'react';

function OwnedProperty(props) {
    function changeStatus(bool) {
        props.add("has_previously_owned_property", bool)
        props.next()
    }

    return (
        <div>
            <div className='heading'>
                Have you previously owned property?
            </div>

            <button className='radio' onClick={() => changeStatus(true)}>
                <div className="radio_circle" style={{ backgroundColor: props.store.has_previously_owned_property ? props.style === "creditladder" ? "#00dfbc" : "black" : "white" }}>
                    <img className="tick" src={require("../../../img/tick-white.png")} alt="tick"></img>
                </div>
                Yes
            </button>
            <button className='radio' onClick={() => changeStatus(false)}>
                <div className="radio_circle" style={{ backgroundColor: props.store.has_previously_owned_property === false ? props.style === "creditladder" ? "#00dfbc" : "black" : "white" }}>
                    <img className="tick" src={require("../../../img/tick-white.png")} alt="tick"></img>
                </div>
                No
            </button>
        </div>
    );
}

export default OwnedProperty;