import React, { useEffect, useState } from 'react';
import MortgageType from '../../apply/questions/mortgage_type';
import OwnedProperty from '../../apply/questions/owned_property';
import Selection from '../selection';

function MortgageReason(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <MortgageType next={() => {
                        props.setPage(props.switch + 1)
                        props.setPrev([...props.prev, props.switch + 1])
                    }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />
                )

            case props.switch + 1:
                return (
                    <OwnedProperty next={() => {
                        props.setPage(props.switch + 2)
                        props.setPrev([...props.prev, props.switch + 2])
                    }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />
                )

            default:
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default MortgageReason;