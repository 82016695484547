import React from 'react';
import "./loading_bar.sass"

function LoadingBar(props) {
    const style = props.style

    let colors = {
        creditladder: {
            start: "#ffed00",
            end: "#5e00fa",
            text: "black"
        }
    }

    let start = colors?.[style]?.start || "black"
    let end = colors?.[style]?.end || "#d4d4d4"
    let text = colors?.[style]?.text || "black"

    return (
        props.current === props.max || props.max === 0 ? null :
            <div className='loading_bar_wrap'>
                <div className='loading_bar'>
                    <div className='text' style={{color: text}}>
                        {props.current} / {props.max}
                    </div>
                    <div className='subtext' style={{color: text}}>lenders loaded</div>
                    <div className='bar' style={{ background: `linear-gradient(to right, ${start} 0%, ${start} ${props.current / props.max * 100}%, ${end} ${props.current / props.max * 100}%, ${end} 100%)` }}>

                    </div>
                </div>
            </div>
    );
}

export default LoadingBar;