import React from 'react';

class Thanks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: 0
        };
    }

    componentDidMount() {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head.appendChild(script);
        document.body.style['overflow-x'] = "hidden";

        // document.getElementsByClassName("bm-burger-button")[0].style.display = "none";
    }

    displayCalender = () => {
        let display = 1;
        if (this.state.display === 1) {
            display = 0;
        }
        this.setState({
            display: display
        });
    }

    render() {
        let display = "none";
        let frontDisplay = "block";
        if (this.state.display === 1) {
            display = "block";
            frontDisplay = "none";
        }

        let vh = window.innerHeight - 10;

        return (
            <div className="listing-flow" style={{ width: "1500px", maxWidth: "100%", padding: "30px", minHeight: '100vh', display: 'flex', flexDirection: "column", justifyContent: "center" }}>

                <div style={{ display: frontDisplay, width: "100%" }}>
                    <div style={{ width: '100%', maxWidth: '600px', textAlign: 'left', margin: 'auto' }}>
                        <p>Free.co.uk has partnered with Juno to offer your free guidance on life insurance.<br /><br />
                            Our expert advisors will explain the options available to you and help you work out the most suitable insurance for your needs.
                            <br /><br />
                            Most people buying a home arrange an insurance policy but it can often be delayed as they focus on getting a mortgage. Arranging an insurance policy before you exchange contracts is the smart move - most insurers will give you some temporary cover whilst you’re applying to ensure your purchase is not at risk.
                            <br /><br />
                            There’s no obligation. At the end of our 15 minute call, you’ll know roughly how much insurance costs, you’ll understand the next steps and you’ll have peace of mind when you exchange contracts.
                        </p>
                    </div>
                    <button onClick={this.displayCalender}>Book in a call with an advisor now</button>

                    <details>
                        <summary><span className="pageHeading" style={{ paddingLeft: "30px", paddingRight: "30px" }}>Want to learn more about the different types of cover before your call?</span></summary>
                        <div style={{ width: '100%', maxWidth: '600px', textAlign: 'left', margin: 'auto' }}>
                            <h3>Life Assurance</h3>
                            <p>
                                Not everyone needs life insurance but if your children, partner or other relatives depend on your income to cover the mortgage or other living expenses, then the answer is yes – you probably do need life insurance, since it will help provide for your family in the event of your death.
                            </p>
                            <h3>Critical Illness</h3>
                            <p>
                                Critical illness cover is a long-term insurance policy where you’ll get a tax-free lump sum or regular income payment if you’re diagnosed with one of the serious illnesses covered by your insurance policy. It's designed to pay off your mortgage, debts, or pay for alterations to your home such as wheelchair access should you need it.
                            </p>

                            <h3>Income Protection</h3>

                            <p>  If you couldn't work due to a serious illness, how would you manage? Could you survive on your savings, or on your sick pay from work? If not, you’ll need some other way to keep paying the bills – and you might want to consider income protection insurance.</p>

                        </div>
                    </details>
                    <details>
                        <summary><span className="pageHeading" style={{ paddingLeft: "30px", paddingRight: "30px" }}>What's the process?</span></summary>
                        <div style={{ width: '100%', maxWidth: '600px', textAlign: 'left', margin: 'auto' }}>
                            <p>
                                We know you’ve got a lot on your plate right now. The good news is that arranging insurance is typically more straightforward than a mortgage.
                            </p>

                            Most of our customers experience the following journey -
                            <ol>
                                <li>15 min call with an expert - we’ll normally have some indicative quotes ready for you</li>
                                <li>Follow up call - if your advisor needs more information, they may arrange an additional call at your convenience</li>
                                <li>Recommendation - your advisor will help you understand the details of your policy</li>
                                <li>Application - we’ll speak to each applicant and go through a series of medical questions needed for an application</li>
                                <li>Start date - most applications are accepted quickly and you can organise a start date in line with your purchase</li>
                            </ol>
                            <p>
                                Insurers sometimes need more information about health conditions and can request more information from your GP or a medical. Your advisor will let you know if this is required.
                            </p>
                        </div>
                    </details>
                </div>

                {/* <!--<a href="" className="uk-button uk-button-large uk-width-1-1 cta-button">Setup a phone call</a>--> */}

                {/* <!-- Calendly link widget begin --> */}
                <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
                <div style={{ display: display, minWidth: '320px', width: "1000px", height: '1500px', overflow: "hidden", maxHeight: vh, maxWidth: "95%", transform: "translate(-50%, -50%)", position: "absolute", top: "50%", left: "50%" }}>
                    <div onClick={this.displayCalender} style={{ display: display, marginRight: "-90%", fontSize: "40px" }}>
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </div>
                    <div
                        className="calendly-inline-widget"
                        data-url="https://calendly.com/hem-free/speak-to-a-free-advisor"
                        style={{ height: "1000px", maxHeight: "95%", overflow: "hidden" }}
                    />
                </div>
                {/* <a href="" className="uk-button uk-button-large uk-width-1-1 cta-button" onClick={Calendly.initPopupWidget({url: 'https://calendly.com/free40?background_color=f8f8f8&text_color=64696f&primary_color=a400ff'})}>Schedule a call with Ray</a> */}
                {/* <!-- Calendly link widget end --> */}
            </div >

        );
    }
}

export default Thanks;