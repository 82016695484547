import React from 'react';

function NetMonthlyIncome(props) {
    return (
        <div>
            <div className='heading'>
                What is your net monthly income?
            </div>
            <div className='content'>
                This is your take home pay after income tax, national insurance and any other expenditure (e.g. student loans).
            </div>

            <div className='input_contain'>
                <div className='input_wrap'>
                    <div className='pound_sign'>£</div>
                    <input style={{ paddingLeft: "30px" }} value={props.store?.net_monthly_income / 100 || undefined} type="number" placeholder='0' onChange={e => props.add("net_monthly_income", parseInt(e.currentTarget.value) * 100 || "")} />
                </div>
            </div>

            <button disabled={!props.store.net_monthly_income} className='capBtn' style={{ margin: "15px" }} onClick={() => props.setPage(props.page + 1)}>
                Next
            </button>
        </div>
    );
}

export default NetMonthlyIncome;