import React, { Component } from 'react';
import "./accordion.sass";
import * as Icon from "react-bootstrap-icons"

class AccordionButton extends Component {
    openAccordion(e) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        let id = document.getElementById(this.props.id);
        let txt = document.getElementById(this.props.id + "_button_text");
        let chev = document.getElementById(this.props.id + "_button_chevron");
        let extra = 0;
        if (this.props.extra && document.getElementById(this.props.extra)) extra = document.getElementById(this.props.extra).scrollHeight
        if (id && id.style) {
            if (id.style.maxHeight === "0px") {
                if (!this.props.height) id.style.maxHeight = id.scrollHeight + extra + "px";
                else id.style.maxHeight = this.props.height + "px"
                if (this.props.alt) txt.innerHTML = this.props.alt;
                if (this.props.chevron) chev.style.transform = "translate(-50%, calc(-50% - 6px)) rotate(180deg)"
            } else {
                id.style.maxHeight = "0px";
                txt.innerHTML = this.props.phrase;
                if (this.props.chevron) chev.style.transform = "translate(-50%, -50%) rotate(0deg)"
            }
        }
    }

    render() {
        return (
            <div className="accordion_button" onClick={(e) => this.openAccordion(e)} id={this.props.id + "_button"}>
                <div className="text" id={this.props.id + "_button_text"} style={{textDecoration: this.props.underline ? "underline" : "", textUnderlineOffset: "4px"}}>
                    {this.props.phrase}
                </div>
                <div id={this.props.id + "_button_chevron"} style={{ display: this.props.chevron ? "" : "none" }} className="chevron">
                    <Icon.ChevronDown />
                </div>
            </div>
        );
    }
}

export default AccordionButton;