import React from "react";

const links = {
    "Cambridge BS": "https://www.cambridgebs.co.uk/",
    "Buckinghamshire BS": "https://www.bucksbs.co.uk/",
    "Mansfield BS": "https://www.mansfieldbs.co.uk/",
    "Darlington BS": "https://www.darlington.co.uk/",
    "Saffron BS": "https://www.saffronbs.co.uk/",
    "Together": "https://togethermoney.com/",
    "The Mortgage Lender": "https://themortgagelender.com/",
    "Precise": "https://www.precisemortgages.co.uk/",
    "Pepper Money": "https://www.pepper.money/",
    "Foundation": "https://www.foundationhomeloans.co.uk/",
    "Kent Reliance": "https://www.kentreliance.co.uk/",
    "Aldermore": "https://www.aldermore.co.uk/",
    "Kensington": "https://www.kensingtonmortgages.co.uk/",
    "Bluestone": "https://www.bluestone.co.uk/",
    "Vida Homeloan": "https://www.vidahomeloans.co.uk/"
}

export default links;