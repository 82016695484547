import React, { useEffect, useState } from 'react';
import AddressHistory from './questions/address_history';
import CommittedExpenditure from './questions/committed_expenditure';
import Communications from './questions/communications';
import Country from './questions/country';
import Dependents from './questions/dependents';
import DiscretionaryExpenditure from './questions/discretionary_expenditure';
import Gender from './questions/gender';
import MortgageType from './questions/mortgage_type';
import NationalInsurance from './questions/national_insurance';
import NetMonthlyIncome from './questions/net_monthly_income';
import OwnedProperty from './questions/owned_property';
import RetirementAge from './questions/retirement_age';
import Smoker from './questions/smoker';
import Submit from './questions/submit';

function AfterApplication(props) {
    const [page, setPage] = useState(1)
    const [store, setStore] = useState({
        referrer: props.match.params.style ? props.match.params.style : "moresimplemoney"
    })
    const [store2, setStore2] = useState({})

    useEffect(() => {
        try {
            let localStore = JSON.parse(localStorage.getItem("moresimple:further"))
            if(localStore) setStore2(localStore)
        } catch (err) {
            console.log(err)
        }
    }, [])

    function addToStore(key, value) {
        setStore2({
            ...store2,
            [key]: value
        })
    }

    useEffect(() => {
        console.log(store2)
    }, [store2])

    useEffect(() => {
        console.log(props.match.params.style)
        try {
            let stored = JSON.parse(localStorage.getItem("moresimple:store"))
            if (stored) setStore({ ...store, ...stored })
            else window.location.href = "/adverse_credit_full"
        } catch (err) {
            console.log(err)
        }
    }, [])

    function getPage() {
        let p = {
            add: addToStore,
            original: store,
            store: store2,
            page: page,
            setPage: setPage,
            style: props.match.params.style
        }

        let pages = [
            <Gender {...p} />,
            <Communications {...p} />,
            <Smoker {...p} />,
            <Country {...p} />,
            <RetirementAge {...p} />,
            <NationalInsurance {...p} />,
            <AddressHistory {...p} />,
            <Dependents {...p} />,
            <CommittedExpenditure {...p} />,
            <DiscretionaryExpenditure {...p} />,
            // <NetMonthlyIncome {...p} />,
            <MortgageType {...p} />,
            <OwnedProperty {...p} />,
            <Submit {...p} />
        ]

        if (page > 1) return pages[page - 2]
        else return null
    }

    return (
        <div className={props.match.params.style ? props.match.params.style : "encompass"}>
            <div className="adverse_flow" style={{ paddingBottom: props.lower ? "115px" : "20px" }}>
                <div className="navbar" style={{ textAlign: "left" }}>
                    <button style={{ display: page === 1 ? "none" : "", position: "relative" }} className="back" onClick={() => setPage(page - 1)}>
                        <img className="backArrow" src={require("../../img/icon-arrow-back.svg")} alt="back"></img>
                        <div className="backText">Back</div>
                    </button>
                </div>

                {page === 1 ?
                    <>
                        <div className='heading' style={{ fontWeight: "600", fontSize: "40px", marginTop: "30px" }}>
                            Next steps
                        </div>
                        <div className='content' style={{ fontSize: "30px", lineHeight: "36px" }}>
                            Filling out these additional questions will help us to progress your application a lot faster.
                        </div>
                        <button onClick={() => setPage(page + 1)} className='capBtn' style={{ margin: "0px 15px" }}>
                            Let's go
                        </button>
                    </>
                    : null}

                {getPage()}
            </div>
        </div>
    );
}

export default AfterApplication;