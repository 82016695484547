import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Accordion from '../../../Accordion/Accordion';
import AccordionButton from '../../../Accordion/AccordionButton';
import Loader from '../../../loader';
import FetchLenders from '../../FetchLenders';
import reasons from '../../prototype/lenderReasons';
import convert_name from '../../skyscanner/convert_name';
import AuthModal from '../auth/auth_modal';
import format_data from '../format_data';
import logos from './logos';
import pullAdverse from './pull_adverse';

function LandingResults2(props) {
    const commaNumber = require('comma-number')
    const [deposit, setDeposit] = useState("30%")
    const [depositValue, setDepositValue] = useState(20000)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [lenders, setLenders] = useState([])
    const [dis, setDis] = useState([])
    const [money, setMoney] = useState(45000)
    const [income, setIncome] = useState(45000)
    const [aff, setAff] = useState([])

    useEffect(() => {
        // console.log(props.store, format_data(props.store))
        let lenders = pullAdverse({})
        setLenders(lenders[0])
    }, [])

    function toNearest(number, nearest) {
        if (number < 10000) return 10000
        else if (number > 500000) return 500000
        else return Math.round(number / nearest) * nearest
    }

    useEffect(() => {
        getData()
        getAffData()
    }, [])

    async function getData() {
        // console.log(props.store)
        let res = await axios.get(process.env.REACT_APP_API_URL + "/mortgage_brain")

        if (res.data.success) {
            setData(res.data.data)
            setLoading(false)
        }
    }

    async function getAffData() {
        let res2 = await axios.get(process.env.REACT_APP_API_URL + "/mbt")
        if (res2.data.success) {
            setAff(res2.data.data)
            
        }
    }

    useEffect(() => {
        if (document.getElementById("lender_table_body").children.length === 0 && loading === true) {
            document.getElementById("lender_table").style.display = "none"
            document.getElementById("no_results").style.display = "none"
            document.getElementById("loading_results").style.display = "block"
        } else if (document.getElementById("lender_table_body").children.length === 0 && loading === false) {
            // setDis([...dis, deposit])
            // setDeposit(parseInt(deposit) + 5 + "%")
            document.getElementById("lender_table").style.display = "none"
            document.getElementById("no_results").style.display = "block"
            document.getElementById("loading_results").style.display = "none"
        } else {
            document.getElementById("lender_table").style.display = "table"
            document.getElementById("no_results").style.display = "none"
            document.getElementById("loading_results").style.display = "none"
        }
    })

    function checkMath(input, comp) {
        return Math.round(input) === comp || Math.floor(input) === comp
        // || Math.ceil(input) === comp
    }

    function getEntries() {
        let entries = Object.entries(data).map(e => {
            let yr2 = 0
            let yr2Cost = 1000000000
            let yr2Fees = 0
            let yr2Max = 0
            let yr5 = 0
            let yr5Cost = 1000000000
            let yr5Fees = 0
            let yr5Max = 0
            let yr10 = 0
            let yr10Cost = 1000000000
            let yr10Fees = 0
            let yr10Max = 0
            let greyed = false

            for (let i = 0; i < e[1].length; i++) {
                // console.log(e[1][i])
                // let cost = (e[1][i].initialMonthlyPayment * e[1][i].tieInPeriod) + e[1][i].totalFees

                if (aff[toNearest(income, income <= 200000 ? 1000 : 25000)]) {
                    if ((aff[toNearest(income, income <= 200000 ? 1000 : 25000)].quote_lenders.filter(f => convert_name(f.name) === e[0]).length > 0 && aff[toNearest(income, income <= 200000 ? 1000 : 25000)].quote_lenders.filter(f => convert_name(f.name) === e[0])[0].status_message === "Affordable") || aff[toNearest(income, income <= 200000 ? 1000 : 25000)].quote_lenders.filter(f => convert_name(f.name) === e[0]).length === 0) {
                        let cost = e[1][i].initialRate

                        if (e[1][i].maxLTV >= (100 - parseInt(deposit))) {
                            if (e[1][i].maxLoan >= depositValue * 100 / parseInt(deposit) && e[1][i].minLoan <= depositValue * 100 / parseInt(deposit)) {
                                greyed = false
                                if (checkMath(e[1][i].tieInPeriod / 12, 2) && cost < yr2Cost) {
                                    yr2Cost = cost
                                    yr2 = e[1][i].initialRate
                                    yr2Fees = e[1][i].totalFees
                                    yr2Max = e[1][i].maxLoan
                                }
                                if (checkMath(e[1][i].tieInPeriod / 12, 5) && cost < yr5Cost) {
                                    yr5Cost = cost
                                    yr5 = e[1][i].initialRate
                                    yr5Fees = e[1][i].totalFees
                                    yr5Max = e[1][i].maxLoan
                                }
                                if (checkMath(e[1][i].tieInPeriod / 12, 10) && cost < yr10Cost) {
                                    yr10Cost = cost
                                    yr10 = e[1][i].initialRate
                                    yr10Fees = e[1][i].totalFees
                                    yr10Max = e[1][i].maxLoan
                                }
                            } else {
                                if (yr2Cost === 1000000000 && yr5Cost === 1000000000 && yr10Cost === 1000000000) {
                                    greyed = true
                                    if (checkMath(e[1][i].tieInPeriod / 12, 2) && cost < yr2Cost) {
                                        yr2Cost = cost
                                        yr2 = e[1][i].initialRate
                                        yr2Fees = e[1][i].totalFees
                                        yr2Max = e[1][i].maxLoan
                                    }
                                    if (checkMath(e[1][i].tieInPeriod / 12, 5) && cost < yr5Cost) {
                                        yr5Cost = cost
                                        yr5 = e[1][i].initialRate
                                        yr5Fees = e[1][i].totalFees
                                        yr5Max = e[1][i].maxLoan
                                    }
                                    if (checkMath(e[1][i].tieInPeriod / 12, 10) && cost < yr10Cost) {
                                        yr10Cost = cost
                                        yr10 = e[1][i].initialRate
                                        yr10Fees = e[1][i].totalFees
                                        yr10Max = e[1][i].maxLoan
                                    }
                                }
                            }
                        }
                        // else {
                        //     if (yr2Cost === 1000000000 && yr5Cost === 1000000000 && yr10Cost === 1000000000) {
                        //         greyed = true
                        //         if (checkMath(e[1][i].tieInPeriod / 12, 2) && cost < yr2Cost) {
                        //             yr2Cost = cost
                        //             yr2 = e[1][i].initialRate
                        //             yr2Fees = e[1][i].totalFees
                        //             yr2Max = e[1][i].maxLoan
                        //         }
                        //         if (checkMath(e[1][i].tieInPeriod / 12, 5) && cost < yr5Cost) {
                        //             yr5Cost = cost
                        //             yr5 = e[1][i].initialRate
                        //             yr5Fees = e[1][i].totalFees
                        //             yr5Max = e[1][i].maxLoan
                        //         }
                        //         if (checkMath(e[1][i].tieInPeriod / 12, 10) && cost < yr10Cost) {
                        //             yr10Cost = cost
                        //             yr10 = e[1][i].initialRate
                        //             yr10Fees = e[1][i].totalFees
                        //             yr10Max = e[1][i].maxLoan
                        //         }
                        //     }
                        // }
                    }
                }
            }

            if (!lenders.includes(e[0])) return null

            if (yr2 !== 0 || yr5 !== 0 || yr10 !== 0) {
                return <tr key={e[0]} style={{ filter: greyed ? "opacity(0.3)" : "" }}>
                    <td style={{ lineHeight: "30px" }}>{logos[e[0]] ? logos[e[0]] : e[0]}</td>
                    {/* <img className="no_rate" src={require("../../../img/accordion-cross.svg")} /> */}
                    <td style={{ lineHeight: "30px" }} value={yr2Cost}>{yr2 !== 0 ? <>{yr2}%<br />Fees: £{commaNumber(Math.round(yr2Fees))}</> : "-"}</td>
                    <td style={{ lineHeight: "30px" }} value={yr5Cost}>{yr5 !== 0 ? <>{yr5}%<br />Fees: £{commaNumber(Math.round(yr5Fees))}</> : "-"}</td>
                    <td style={{ lineHeight: "30px" }} value={yr10Cost}>{yr10 !== 0 ? <>{yr10}%<br />Fees: £{commaNumber(Math.round(yr10Fees))}</> : "-"}</td>
                </tr>
                // return <tr key={e[0]}>
                //     <td>{logos[e[0]] ? logos[e[0]] : e[0]}</td>
                //     {/* <img className="no_rate" src={require("../../../img/accordion-cross.svg")} /> */}
                //     <td value={yr2Cost}>{yr2 !== 0 ? <>{yr2}%</> : "-"}</td>
                //     <td value={yr5Cost}>{yr5 !== 0 ? <>{yr5}%</> : "-"}</td>
                //     <td value={yr10Cost}>{yr10 !== 0 ? <>{yr10}%</> : "-"}</td>
                // </tr>
            }
        })
            .filter(e => e !== null)
            .sort((a, b) => {
                if (parseFloat(a.props.children[1].props.value) && parseFloat(b.props.children[1].props.value)) {
                    return parseFloat(a.props.children[1].props.value) - parseFloat(b.props.children[1].props.value)
                } else if (parseFloat(a.props.children[2].props.value) && parseFloat(b.props.children[2].props.value)) {
                    return parseFloat(a.props.children[2].props.value) - parseFloat(b.props.children[2].props.value)
                } else if (parseFloat(a.props.children[3].props.value) && parseFloat(b.props.children[3].props.value)) {
                    return parseFloat(a.props.children[3].props.value) - parseFloat(b.props.children[3].props.value)
                }
            })

        let first10 = entries.filter((e, ind) => ind < 10).filter(e => e !== undefined)
        let last = entries.filter((e, ind) => ind >= 10).filter(e => e !== undefined)
        return [first10, last]
    }

    return (
        <div className="encompass" style={{ marginBottom: "120px" }}>
            <div className="adverse_flow">
                <Loader show={loading} text="This may take a moment" />
                {props.auth ? <AuthModal setValue={props.setValue} store={props.store} show={props.auth} save_mortgage prev={props.prev} setPrev={props.setPrev} setPage={props.setPage} /> : null}
                <div className="heading">
                    Your rates
                </div>
                <div className="content">
                    {/* These fixed-rates are what lenders could offer you for a <span className="underline">£{commaNumber(Math.round(depositValue * 100 / parseInt(deposit)))} property</span>, with a deposit of <span className="underline">£{commaNumber(depositValue)}</span> ({100 - parseInt(deposit)}% LTV). */}
                    We would love to help you get a mortgage (we’d appreciate the commission from the lender). Please do book a call or WhatsApp us.
                    <br />
                    <button onClick={() => window.open("https://calendly.com/free40/book", "_blank")} className="primary">Book a call</button>
                    <button onClick={() => window.open("https://wa.me/447590501969", "_blank")} className="whatsapp"><img style={{ width: "26px", verticalAlign: "middle", marginRight: "5px" }} src={require("../../../img/WhatsApp.png")} /><div style={{ display: "inline-block", verticalAlign: "middle", lineHeight: "20px" }}>Chat on <b>WhatsApp</b></div></button>
                </div>

                <div style={{ margin: "0px 15px" }}>
                    Enter your deposit amount to get accurate products from lenders
                </div>
                <div style={{ backgroundColor: "white", padding: "0px 15px 25px", margin: "0px 0px 15px" }}>
                    <div className="input_wrap">
                        <div style={{ display: "inline-block" }} className="pound_sign">£</div>
                        <input className="input" type="number" placeholder="Income" value={depositValue} onChange={e => setDepositValue(e.currentTarget.value)}></input>
                    </div>

                    <input type="range" min="5000" max="100000" value={depositValue} className="slider" id="myRange" onChange={e => {
                        setDepositValue(e.currentTarget.value)
                    }} />
                    <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px" }}>
                        <div style={{ color: "#999", fontSize: "12px" }}>
                            £5,000
                        </div>
                        <div style={{ color: "#999", fontSize: "12px" }}>
                            £100,000
                        </div>
                    </div>
                </div>

                <div style={{ margin: "0px 15px" }}>
                    Enter your annual household income
                </div>
                <div style={{ backgroundColor: "white", padding: "0px 15px 25px", margin: "0px 0px 15px" }}>
                    <div className="input_wrap">
                        <div style={{ display: "inline-block" }} className="pound_sign">£</div>
                        <input className="input" type="number" placeholder="Income" value={income} onChange={e => setIncome(e.currentTarget.value)}></input>
                    </div>

                    <input type="range" min="10000" max="500000" step="1000" value={income} className="slider" id="myRange" onChange={e => {
                        setIncome(e.currentTarget.value)
                    }} />
                    <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px" }}>
                        <div style={{ color: "#999", fontSize: "12px" }}>
                            £10,000
                        </div>
                        <div style={{ color: "#999", fontSize: "12px" }}>
                            £500,000
                        </div>
                    </div>
                </div>

                <div className="content" style={{ marginBottom: "70px" }}>
                    Change your LTV percentage to see what effect this has on the rates available to you:
                </div>

                <div className="deposit_changer" style={{ marginTop: "50px" }}>
                    <div className="deposit_value" style={{ left: `calc(100% / 6 * ${(7 - (parseInt(deposit) / 5)) - 1} + ${parseInt(deposit) <= 15 ? "100% / 6" : "0px"})`, transform: parseInt(deposit) >= 20 ? "" : "translate(-100%, 0%)" }}>
                        £{commaNumber(Math.round(depositValue * 100 / parseInt(deposit)))} mortgage
                    </div>
                    <div className="deposit_arrow" style={{ left: `calc(100% / 6 * ${(7 - (parseInt(deposit) / 5)) - 1} + 100% / 6 / 2 - 10px)` }} />

                    <div disabled={dis.includes("30%")} onClick={dis.includes("30%") ? () => { } : () => setDeposit("30%")} className={dis.includes("30%") ? "deposit disabled" : deposit === "30%" ? "deposit active" : "deposit"} style={{ borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" }}>70%</div>
                    <div disabled={dis.includes("25%")} onClick={dis.includes("25%") ? () => { } : () => setDeposit("25%")} className={dis.includes("25%") ? "deposit disabled" : deposit === "25%" ? "deposit active" : "deposit"}>75%</div>
                    <div disabled={dis.includes("20%")} onClick={dis.includes("20%") ? () => { } : () => setDeposit("20%")} className={dis.includes("20%") ? "deposit disabled" : deposit === "20%" ? "deposit active" : "deposit"}>80%</div>
                    <div disabled={dis.includes("15%")} onClick={dis.includes("15%") ? () => { } : () => setDeposit("15%")} className={dis.includes("15%") ? "deposit disabled" : deposit === "15%" ? "deposit active" : "deposit"}>85%</div>
                    <div disabled={dis.includes("10%")} onClick={dis.includes("10%") ? () => { } : () => setDeposit("10%")} className={dis.includes("10%") ? "deposit disabled" : deposit === "10%" ? "deposit active" : "deposit"}>90%</div>
                    <div disabled={dis.includes("5%")} onClick={dis.includes("5%") ? () => { } : () => setDeposit("5%")} className={dis.includes("5%") ? "deposit disabled" : deposit === "5%" ? "deposit active" : "deposit"} >95%</div>
                </div>

                <div style={{ margin: "0px 15px 30px", display: loading ? "none" : "" }}>
                    Based on the info given, the max amount you can borrow, based on your deposit and income, is <span style={{ textDecoration: "underline", textUnderlineOffset: "4px" }}>£{aff[toNearest(income, income <= 200000 ? 1000 : 25000)] ? commaNumber(aff[toNearest(income, income <= 200000 ? 1000 : 25000)].quote_lenders[0].lend_amount_max) : "X"}</span>.
                </div>

                <table className="lender_table" id="lender_table">
                    <thead>
                        <tr>
                            <td></td>
                            <td>2yr Fix</td>
                            <td>5yr Fix</td>
                            <td>10yr Fix</td>
                        </tr>
                    </thead>
                    <tbody id="lender_table_body">
                        {getEntries()[0]}
                    </tbody>
                </table>

                {getEntries()[1].length > 0 ?
                    <div>
                        <div id="table_acc" style={{ maxHeight: "0px", overflow: "hidden", transition: "0.2s all ease-in-out" }}>
                            <table style={{ marginTop: "-1px" }} className="lender_table" id="lender_table">
                                <tbody id="lender_table_body">
                                    {getEntries()[1]}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ margin: "15px 15px 0px 15px" }} className="table_acc_button">
                            <AccordionButton id="table_acc" phrase="Load more lenders" alt="Show less lenders" />
                        </div>
                    </div>
                    : null}

                <div id="loading_results" className="table_replace" style={{ marginTop: "-30px" }}>
                    Loading results...
                </div>

                <div id="no_results" className="table_replace" style={{ marginTop: "-30px" }}>
                    Sorry, no results at {100 - parseInt(deposit)}% LTV
                </div>

                {/* <div className="subheading" style={{ marginTop: "30px" }}>
                Ready to make that offer? Prove that you can borrow
            </div>
            <div className="content">
                Get a free mortgage-in-principle to give confidence to your offers, detailing how much you can borrow, and who could provide the mortgage.
                <ul>
                    <li>Takes under 10 minutes</li>
                    <li>Does not affect your credit score</li>
                    <li>Absolutely free</li>
                </ul>
            </div>

            <button className="result_button">Get a mortgage in principle</button>

            <div className="subheading">
                Need some questions answered before making your next move?
            </div>
            <div className="content">
                Arrange a quick call with one of our experienced advisors.
            </div>

            <button className="alt_result_button" onClick={() => {
                window.location.href = "https://calendly.com/free40/book"
            }}>Book a call</button> */}
            </div>
        </div>
    );
}

export default LandingResults2;