import React from 'react';

function Checkbox(props) {
    return (
        <div className={props.checked ? "ticker-checked" : "ticker"} onClick={props.onClick}>
            <img style={{ display: props.checked ? "" : "none" }} src={require("../../../img/tick-white.png")} />
        </div>
    );
}

export default Checkbox;