import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function CCJ2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        if (props.page === props.switch + 4 && props.store.ccj_date_2 !== undefined && props.store.ccj_amount_2 !== undefined && props.store.ccj_settled_2 !== undefined) {
            let store = { ...props.store }
            let ccj_date_2 = store.ccj_date_2
            let ccj_amount_2 = store.ccj_amount_2
            let ccj_settled_2 = store.ccj_settled_2
            delete store.ccj_date_2
            delete store.ccj_amount_2
            delete store.ccj_settled_2
            props.setValue({ ...store, ccjs_2: [...props.store.ccjs_2, { ccj_date_2: ccj_date_2, ccj_amount_2: ccj_amount_2, ccj_settled_2: ccj_settled_2 }] })
        }
        setDisplay(displayPage())
    }, [props.page, props.store])

    function remove_ccj(i) {
        props.store.ccjs_2.splice(i, 1)
        props.setValue({ ...props.store, ccjs_2: props.store.ccjs_2 })
    }

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Has " + props.store.first_name_2 + " ever had a CCJ?",
                        info: {
                            id: "ccj_modal_3",
                            text: "What is a CCJ?",
                            content: "A county court judgment (CCJ) can negatively affect your ability to get credit for up to six years. That means loans, credit cards, and even mobile phone contracts may be out of your reach.",
                            content2: "A creditor may apply for a CCJ against you if they think you won't repay money you owe them. If the courts agree with the creditor, they'll issue the judgment and tell you to pay the money back. You'll receive information about your CCJ in the post."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 5
                            }
                        }
                    }} id={"had_a_ccj_2"} setValue={(e) => props.setValue({ ...props.store, had_a_ccj_2: e, ccjs_2: [] })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When was " + props.store.first_name_2 + "'s most recent CCJ issued?",
                        input: {
                            type: "month",
                            id: "ccj_date_2",
                            min: 7,
                            next: props.switch + 2
                        }
                    }} id={"ccj_date_2"} setValue={(e) => props.setValue({ ...props.store, ccj_date_2: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "How much was the CCJ for?",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "ccj_amount_2",
                            next: props.switch + 3
                        }
                    }} id={"ccj_amount_2"} setValue={(e) => props.setValue({ ...props.store, ccj_amount_2: e })} />
                )

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When was the CCJ settled?",
                        input: {
                            type: "month",
                            id: "ccj_settled_2",
                            min: 7,
                            next: props.switch + 4
                        },
                        sub_button: {
                            "Or mark this CCJ as not yet satisfied": {
                                value: "ONGOING",
                                next: props.switch + 4
                            }
                        }
                    }} id={"ccj_settled_2"} setValue={(e) => props.setValue({ ...props.store, ccj_settled_2: e })} />
                )

            case props.switch + 4:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Please list all of " + props.store.first_name_2 + "'s CCJs issued since May 2019",
                        info: {
                            id: "ccj_modal_4",
                            text: "What is a CCJ?",
                            content: "A county court judgment (CCJ) can negatively affect your ability to get credit for up to six years. That means loans, credit cards, and even mobile phone contracts may be out of your reach.",
                            content2: "A creditor may apply for a CCJ against you if they think you won't repay money you owe them. If the courts agree with the creditor, they'll issue the judgment and tell you to pay the money back. You'll receive information about your CCJ in the post."
                        },
                        collection: {
                            type: "ccj",
                            append: "_2",
                            add: props.switch + 1
                        },
                        next: props.switch + 5
                    }} id={"ccj_collection_2"} setValue={(e) => { }} remove={(e) => remove_ccj(e)} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default CCJ2;