import FetchLenders from "../../FetchLenders"

function pullAdverse(data) {
    if (JSON.stringify(data) !== '{}') {
        let adverse = []
        if (data.first_applicant.adverse_credit === "YES" || data.uk_nationals === "NO") {
            if (data.first_applicant.adverse_credit === "YES") {
                let details = data.first_applicant.adverse_credit_details
                if (details.had_a_ccj === "YES") {
                    let ccjs = Object.entries(details.ccjs)
                    let last = "gt_36"
                    for (let i = 0; i < ccjs.length; i++) {
                        if (ccjs[i][1].number > 0) {
                            if (ccjs[i][0] === "last_6") last = "last 6 months"
                            else if (ccjs[i][0] === "last_12" && last !== "last 6 months") last = "last 12 months"
                            else if (ccjs[i][0] === "last_24" && last !== "last 12 months" && last !== "last 6 months") last = "last 24 months"
                            else if (ccjs[i][0] === "last_36" && last !== "last 6 months" && last !== "last 12 months" && last !== "last 24 months") last = "last 36 months"
                        }
                    }
                    adverse.push({ "CCJ": last })
                }
                if (details.had_a_default === "YES") {
                    let defaults = Object.entries(details.defaults)
                    let last = "gt_36"
                    for (let i = 0; i < defaults.length; i++) {
                        if (defaults[i][1].number > 0) {
                            if (defaults[i][0] === "last_6") last = "last 6 months"
                            else if (defaults[i][0] === "last_12" && last !== "last 6 months") last = "last 12 months"
                            else if (defaults[i][0] === "last_24" && last !== "last 12 months" && last !== "last 6 months") last = "last 24 months"
                            else if (defaults[i][0] === "last_36" && last !== "last 6 months" && last !== "last 12 months" && last !== "last 24 months") last = "last 36 months"
                        }
                    }
                    adverse.push({ "Defaults": last })
                }
                if (details.had_a_missed_secured_payment === "YES") {
                    let missed_secured_payments = Object.entries(details.missed_secured_payments)
                    let last = "gt_36"
                    for (let i = 0; i < missed_secured_payments.length; i++) {
                        if (missed_secured_payments[i][1].number > 0) {
                            if (missed_secured_payments[i][0] === "last_6") last = "last 6 months"
                            else if (missed_secured_payments[i][0] === "last_12" && last !== "last 6 months") last = "last 12 months"
                            else if (missed_secured_payments[i][0] === "last_24" && last !== "last 12 months" && last !== "last 6 months") last = "last 24 months"
                            else if (missed_secured_payments[i][0] === "last_36" && last !== "last 6 months" && last !== "last 12 months" && last !== "last 24 months") last = "last 36 months"
                        }
                    }
                    adverse.push({ "Missed Secured Payment": last })
                }
                if (details.had_a_missed_unsecured_payment === "YES") {
                    let missed_unsecured_payments = Object.entries(details.missed_unsecured_payments)
                    let last = "gt_36"
                    for (let i = 0; i < missed_unsecured_payments.length; i++) {
                        if (missed_unsecured_payments[i][1].number > 0) {
                            if (missed_unsecured_payments[i][0] === "last_6") last = "last 6 months"
                            else if (missed_unsecured_payments[i][0] === "last_12" && last !== "last 6 months") last = "last 12 months"
                            else if (missed_unsecured_payments[i][0] === "last_24" && last !== "last 12 months" && last !== "last 6 months") last = "last 24 months"
                            else if (missed_unsecured_payments[i][0] === "last_36" && last !== "last 6 months" && last !== "last 12 months" && last !== "last 24 months") last = "last 36 months"
                        }
                    }
                    adverse.push({ "Missed Unsecured Payment": last })
                }
                if (details.been_bankrupt === "YES") {
                    adverse.push({ "Bankruptcy": details.bankruptcy_since })
                }
                if (details.had_a_iva === "YES") {
                    adverse.push({ "IVA": details.iva_since })
                }
                if (details.had_a_dmp === "YES") {
                    adverse.push({ "DMP": details.dmp_since })
                }
                if (details.had_a_payday_loan === "YES") {
                    adverse.push({ "Payday Loans": details.payday_loan_since })
                }
                if (details.had_a_repossession === "YES") {
                    adverse.push({ "Repossession": details.repossession_since })
                }
            }
            if (data.uk_nationals === "NO") {
                if (data.eu_nationals === "YES") adverse.push({ "Non UK": { eu: "EU", years: data.time_in_uk } })
                else adverse.push({ "Non UK": { eu: "NON_EU", years: data.time_in_uk, visa: data.first_applicant.residency_status } })
            }

            return FetchLenders(adverse)
        } else {
            return FetchLenders([])
        }
    } else {
        return FetchLenders([])
    }
}

export default pullAdverse