import commaNumber from 'comma-number';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import format_data from '../AdverseCredit/full/format_data';

export default function Deposit(props) {
    const [data, setData] = useState(props.data)
    const [dataset, setDataset] = useState([])
    const [bucket, setBucket] = useState(10000)
    const [labels, setLabels] = useState([])

    useEffect(() => {
        setData(props.data)
        setBucket(props.bucket)
    }, [props.data, props.bucket])

    useEffect(() => {
        let arr_d = []
        let arr_l = []
        for(let i = 0; i < 500000; i += parseInt(bucket)) {
            arr_l.push(`£${commaNumber(i)} - £${commaNumber(i + parseInt(bucket) - 1)}`)
            arr_d.push(data.filter(e => format_data(e.data).deposit >= i && format_data(e.data).deposit < i + parseInt(bucket)).length)
        }
        setLabels(arr_l)
        setDataset(arr_d)
    }, [data, bucket])

    return (
        <Bar data={{
            labels: [...labels],
            datasets: [
                {
                    label: 'Number of applicants in deposit bucket',
                    data: [...dataset],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }} />
    );
}