function convert_name(e) {
    let obj = {}
    obj[e] = e

    obj["Nottingham Building Society"] = "Nottingham BS"
    obj["WestBrom Building Society"] = "West Brom"
    obj["Saffron"] = "Saffron BS"
    obj["Coventry Building Society"] = "Coventry"
    obj["Newcastle Building Society"] = "Newcastle BS"
    obj["Leeds Building Society"] = "Leeds BS"
    obj["Virgin"] = "Virgin Money"
    obj["Skipton Building Society"] = "Skipton"
    obj["Monmouthshire Building Society"] = "Monmouthshire"
    obj["Digital Mortgages by Atom Bank"] = "Digital"
    obj["Furness Building Society"] = "Furness BS"
    obj["Teachers Building Society"] = "Teachers BS"
    obj["Hinckley & Rugby Building Society"] = "Hinckley and Rugby"
    obj["Ipswich Building Society"] = "Ipswich BS"
    obj["Tipton & Coseley Building Society"] = "Tipton"
    obj["Newbury Building Society"] = "Newbury"
    obj["Cambridge Building Society"] = "Cambridge BS"
    obj["Hanley Economic Building Society"] = "Hanley Economic"
    obj["PostOffice"] = "Post Office"
    obj["Clydesdale"] = "Clydesdale Bank"
    obj["M&S Bank"] = "M&S Bank"
    obj["Precise Mortgages"] = "Precise"
    obj[""] = ""

    return obj[e]
}

export default convert_name