import React, { useEffect, useState } from 'react';
import auth from '../../full/auth/auth_functions';
import Loader from '../../../loader';
import format_second_data from '../sorting/format';
import axios from 'axios';
import { propfind } from 'superagent';

function Submit(props) {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        command()
    }, [])

    async function command() {
        if (props.original.acre_id) {
            try {
                localStorage.setItem("moresimple:further", JSON.stringify(props.store))
            } catch (err) {
                console.log(err)
            }
            await updateAcre()
            await createCase()
            setLoading(false)
        }
    }

    async function updateAcre() {
        console.log("Updating acre")
        let client = format_second_data(props.store)
        console.log(client)
        try {
            await auth.updateAcre({ raw: true, client: client, id: props.original.acre_id })
            return true
        } catch (err) {
            return false
        }
    }

    async function createCase() {
        let data = {
            clients: [props.original.acre_id],
            preference_mortgage_reason: props.store.preference_mortgage_reason,
            preference_term: props.store.preference_term,
            has_previously_owned_property: props.store.has_previously_owned_property,
            preference_target_deposit: props.original.deposit,
            preference_loan_amount: Math.round(props.original.deposit * 100 / parseInt(props.original.deposit_percent)) - props.original.deposit,
            preference_target_property_value: Math.round(props.original.deposit * 100 / parseInt(props.original.deposit_percent))
        }

        return await axios.post(process.env.REACT_APP_API_URL + "/acre/case", { ...data }).then(async res => {
            return res
        }).catch(err => {
            return err
        })
    }

    return (
        <div>
            <Loader show={loading} />

            <div className='heading' style={{ fontWeight: "600", fontSize: "40px", marginTop: "30px" }}>
                You're all set!
            </div>
            <div className='content'>
                Thank you for taking the time to fill out these additional questions, they'll help to speed up your application.
                <br /><br />
                Soooo... what's next? Book in a call with us to have a chat about your application and really get the ball rolling.
                Even if you're not quite ready to progress your application, we can help to discuss your options.
            </div>

            <button className='capBtn' style={{ margin: "15px" }} onClick={() => {
                window.open(`https://calendly.com/free40/book?utmSource=${props.style ? props.style : "moresimplemoney"}&utmMedium=website&utmCampaign=${props?.match?.params?.campaign || "none"}`, "_blank")
            }}>
                Book a call
            </button>
        </div>
    );
}

export default Submit;