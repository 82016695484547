import React, { Component } from 'react';

class Selections extends Component {
    render() {
        return (
            <button
                className={!this.props.active ? "inactive" : "active"}
                onClick={() => {
                    if (!this.props.active) {
                        this.props.addOption(this.props.value);
                    } else {
                        this.props.removeOption(this.props.value)
                    }
                }}
            >
                {this.props.children}
            </button>
        );
    }
}

export default Selections;