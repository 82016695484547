import commaNumber from 'comma-number';
import React, { useEffect, useState } from 'react';
import superagent from "superagent"
import logos from '../AdverseCredit/full/results/logos';

export default function Application(props) {
    const [cc, setCc] = useState(props.match.params.cc)
    const [number, setNumber] = useState(props.match.params.number)
    const [style, setStyle] = useState("creditladder")
    const [apps, setApps] = useState([])
    const [user, setUser] = useState({})
    const [mflow, setMflow] = useState({})

    useEffect(() => {
        superagent.post(process.env.REACT_APP_API_URL + "/application/get")
            .send({ cc, number })
            .then(res => {
                if (res.body.success) {
                    setApps(res.body.data)
                    setUser(res.body.user)
                    setMflow(res.body.mortgage)
                }
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <div className={style ? style : "free"} style={{ paddingBottom: props.lower ? "115px" : "" }}>
            <div className="adverse_flow">
                <div className="application">
                    <div className="welcome">
                        Hello, {user?.name}
                    </div>
                    <div className="details">
                        <div className="detail_heading">Your details</div>
                        <div className="detail">Mortgage-in-principle: £{apps[0]?.data?.max ? apps[0].data.max : 0}</div>
                        <div className="detail">Number: +{cc}{number}</div>
                        <div className="detail">Deposit: £{commaNumber(mflow?.data?.deposit)}</div>
                        <div className="detail">Adverse credit: {mflow?.data?.adverse_credit}</div>
                    </div>
                    <div className="applications">
                        <div className="content">
                            <div className="content_heading">Get in touch</div>
                            {/* These fixed-rates are what lenders could offer you for a <span className="underline">£{commaNumber(Math.round(props.store.deposit * 100 / parseInt(deposit)))} property</span>, with a deposit of <span className="underline">£{commaNumber(props.store.deposit)}</span> ({100 - parseInt(deposit)}% LTV). */}
                            We would love to help you get a mortgage (we’d appreciate the commission from the lender). Please do book a call or WhatsApp us.
                            <br />
                            <button onClick={() => window.open("https://calendly.com/free40/book", "_blank")} className="primary">Book a call</button>
                            <button onClick={() => window.open("https://wa.me/447590501969", "_blank")} className="whatsapp"><img style={{ width: "26px", verticalAlign: "middle", marginRight: "5px" }} src={require("../img/WhatsApp.png")} /><div style={{ display: "inline-block", verticalAlign: "middle", lineHeight: "20px" }}>Chat on <b>WhatsApp</b></div></button>
                        </div>
                        <div className="app_heading">Your applications</div>
                        <div className="apps">
                            {apps.map((e, n) => {
                                return <div key={n} className="app">
                                    <div className="group">
                                        {logos[e.data.lender]}
                                    </div>
                                    <div className="group">
                                        <div className="app_detail">
                                            <div className="app_detail_heading">LTV</div>
                                            <div className="app_detail_text">{e.data.ltv}%</div>
                                        </div>

                                        <div className="app_detail">
                                            <div className="app_detail_heading">Term</div>
                                            <div className="app_detail_text">{e.data.term} yrs</div>
                                        </div>
                                    </div>
                                    <div className="group">
                                        <div className="app_detail">
                                            <div className="app_detail_heading">Initial rate</div>
                                            <div className="app_detail_text">{e.data.rate}%</div>
                                        </div>
                                        <div className="app_detail">
                                            <div className="app_detail_heading">Fee</div>
                                            <div className="app_detail_text">£{e.data.fee}</div>
                                        </div>
                                    </div>
                                    <div style={{textAlign: "right", marginTop: "10px"}}>
                                        <button className="book" onClick={() => window.open("https://calendly.com/free40/book", "_blank")}>
                                            Book a call to discuss
                                        </button>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}