import React from 'react';

class Terms extends React.Component {
    render() {
        return (
            <div className="listing-flow">
            <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                <div className="navbar">
                    {/* <button className="back" onClick={this.prevPage} >
						<img className="backArrow" src={require("../img/icon-arrow-back.svg")}></img>
						<div className="backText">
							Back
						</div>
					</button> */}
                    <a href="/"><img className="logo" src={require("../img/logo.svg")} alt='free'></img></a>
                </div>

                <div style={{ textAlign: "left" }}>
                    <div className="pageHeadingPolicy">
                        Terms of Use
                        </div>
                    <div className="pageSub">
                        Introduction
                        </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Your use of the Free Website at www.Free.co.uk and the Free Platform is governed by these Terms of Use.
                    <br /><br />
                    This document (referred to as our ‘Terms of Use’) gives you information about the terms upon which we agree to provide, permit and allow you access and use of the Website and Free Platform. We encourage you to print off a copy of this document and refer to it as you use our Systems and Services.
                    <br /><br />
                    If it is necessary to change the Terms of Use or add or remove any part at any time we will post such changes on www.Free.co.uk. It is your responsibility to check the Website for any changes. You agree to be bound by such changes if you should continue to use the Systems and Services.
                    <br /><br />
                    IF ANY FUTURE CHANGES ARE UNACCEPTABLE TO YOU, YOU SHOULD DISCONTINUE USING THE SYSTEMS AND SERVICES. YOUR CONTINUED USE OF THE SYSTEMS AND SERVICES NOW OR FOLLOWING THE POSTING OF ANY SUCH CHANGES WILL INDICATE YOUR ACCEPTANCE OF THESE TERMS AND OF ANY SUCH CHANGES.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        What do words and phrases mean in these terms?
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        <span style={{ fontWeight: "700" }}>‘Account’</span> means your Account that we operate and you control within the Free Platform. For the avoidance of doubt, this includes the area where you use Free and manage the process of a viewing, property listing and/or mortgage application (including all marketing services).
                        <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Complimentary’</span> means free of charge without financial consideration.
                        <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Content’</span> means all of the content, documents, information and matters on or in your Account or contained within the Website and or Free Platform, whether through the Website and/or Free Platform or uploaded manually.
                        <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Free Platform’</span> means the Complimentary software provided to customers in accordance with these Terms of Use.
                        <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Privacy Policy’</span> means the Privacy Policy as amended from time to time and published on the Website and Free Platform.
                        <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Products &amp; Services’</span> means all and each of the products and/or services offered from time to time by Free within the Website and Free Platform.
                        <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Systems’</span> means the Website and Complimentary or subscribed (following a payment) access to the Free Platform.
                        <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Terms’</span> means these Terms of Use and Privacy Policy as amended from time to time.
                        <br /><br />
                        <span style={{ fontWeight: "700" }}>‘User’</span> means you or anyone who uses the Systems
                        <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Website’</span> means www.Free.co.uk, including all systems, Software and processes hosted therein.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Acceptable use terms
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        There are rules and standards that you must abide by in connection with your use of the Systems. This is for the protection of all Users.
                        <br /><br />
                        Free has the right to enforce those guidelines as it deems appropriate in its sole discretion. This means that if you, or anyone using your Account, or identifying information, violate these Terms, Free may take any and all actions from the issuance of a warning to terminating your right to use the Systems (without refund or part credit of any fees paid) to involvement of the appropriate authorities that Free deems necessary or appropriate. Free are not required to provide notice prior to terminate your right to use the Systems for violating these Terms, but it may choose to do so.
                        <br /><br />
                        BY USING THE SYSTEMS, YOU AGREE TO WAIVE ANY CLAIMS YOU MAY HAVE AGAINST FREE, AND TO HOLD FREE HARMLESS AND INDEMNIFY FREE WITH RESPECT TO ANY CLAIMS RELATING TO ANY ACTION TAKEN BY FREE AS PART OF ITS INVESTIGATION OF A SUSPECTED VIOLATION OF THIS AGREEMENT OR AS A RESULT OF ITS FINDING OR DECISION THAT A VIOLATION OF THIS AGREEMENT HAS OCCURRED OR THE WITHDRAWAL OF THE SYSTEMS WHETHER TEMPORARILY OR PERMANENT. THIS MEANS THAT YOU CANNOT SUE FREE FREE FREE LTD OR RECOVER ANY DAMAGES WHATSOEVER FROM FREE AS A RESULT OF ITS DECISION TO REMOVE OR REFUSE TO PROCESS ANY INFORMATION OR CONTENT, TO WARN YOU, TO SUSPEND OR TERMINATE YOUR ACCESS TO THE SYSTEMS, OR TO TAKE ANY OTHER ACTION DURING THE INVESTIGATION OF A SUSPECTED VIOLATION OR AS A RESULT OF FREE CONCLUSION THAT A VIOLATION OF THIS AGREEMENT HAS OCCURRED. THIS WAIVER AND INDEMNITY PROVISION APPLIES TO ALL VIOLATIONS DESCRIBED IN OR CONTEMPLATED BY THIS AGREEMENT.
                        </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Disclaimer of Warranties; Liability Limitations
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        You expressly agree that your use of, or inability to use, the Systems is at your sole risk. The Systems are provided ’as-is‘ and ’as-available‘ for use, without warranties of any kind, either express or implied, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement.
                    <br /><br />
                    In no case shall Free, its Directors, Officers, Employees, Affiliates, Agents or Contractors be liable for any direct, indirect, incidental, special or consequential damages arising from your use of any of the Systems or for any other claim related in any way to your use of the Systems.
                        </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Notices
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Free may send you notices with respect to the Systems by sending an e-mail message to the email address listed in your Free Account or by sending a letter via postal mail to the contact address listed in your Free Account.
                        </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Legal Principles
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        You agree that these Terms are not intended to confer and do not confer any rights or remedies upon any person other than you, as a User of the Systems, and Free.
                    <br /><br />
                    If any part of the Terms is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect. Free's failure to enforce any right or provisions in the Terms will not constitute a waiver of such provision, or any other provision of the Terms.
                    <br /><br />
                    The personal information which you give to Free when registering for access to the Systems will be used in connection with your use of the Systems and will be transferred to and maintained in Free records in the United Kingdom. If you do not consent to this transfer, do not accept these terms and conditions.
                        </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Special Provisions
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Free does not guarantee or warrant that any photos, photobooks, digital images, data or text (collectively, ’Content‘ for the purpose of this provision) you create, upload, send or store for the purpose of your use of the Systems will not be subject to inadvertent damage, corruption or destruction. If any Content is damaged, lost or corrupted in any way, Free will have no obligation or liability to you. You are solely responsible for your use of the Systems, including all Content you generate or upload to Free. Free is not and will not be responsible for what is contained in any such Content.
                    <br /><br />
                    Although Free encourages you to use the Systems to express your creativity, Free reserves the right to refuse to process any order or upload submitted by you containing Content that Free, in its sole discretion, deems abusive, defamatory, obscene, in violation of copyright or trademark laws, or otherwise offensive, illegal, inappropriate or unacceptable, including any material Free believes may cause any liability for it under any applicable law or regulation, or which may cause Free to lose the Systems any suppliers provide as part of the Systems or in connection with fulfilling any order for Systems. In particular, Free reserves the right to refuse to process orders or uploads containing Content which, in Free sole opinion, contains expressions of abuse, offensive language or images, obscenity or pornography (including but not limited to child abuse or child pornography, and/or depictions of minors engaged in sexual conduct or explicitly sexual situations).
                    <br /><br />
                    By submitting Content to Free, you are representing that you are the owner of such material, or have proper authorisation from the owner of the Content to distribute it. You hereby grant Free a worldwide, royalty-free, non-exclusive license to use the Content for the limited purpose of providing and processing the Systems.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        No Ownership
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Nothing in this Agreement shall be construed to convey to you any interest, title, or license in a Free user account, email address, domain name, or similar resource used by you in connection with the Systems or the Software.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Our Privacy Policy
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        You understand that by using the Systems, you consent and agree to the collection and use of certain information about you and your use of the Website in accordance with our Privacy Policy. You further consent and agree that we may collect, use, transmit, process and maintain information related to your Account, and any devices or computers registered thereunder, for purposes of providing the Systems, and any features therein, to you. Information collected by us when you use the Systems may also include technical or diagnostic information related to your use that may be used by us to support, improve and enhance our Systems. For more information please read our full <a href="/privacy-policy" target="_blank" style={{ textDecoration: "underline" }}>Privacy Policy</a>.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Registration
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        In order to take advantage of the Free Platform you will need to register on www.Free.co.uk.
                    <br /><br />
                    You must provide accurate information about yourself and agree to our authentication process. We will as part of that process take details of your IP address, name and phone number. By registering with Free you give us express permission to do so. Once registration is complete we will open you an Account free of charge, which will give you access to the Free Platform.
                    <br /><br />
                    You should keep all your account information (including phone authentication codes) secret and do not disclose the details to anyone. Please refer to the Terms of Use for further information, terms and conditions.
                    <br /><br />
                    As part of our professional obligation it is important that we know who is booking a viewing and whether they are in a position to proceed with a purchase. When booking a viewing you may have to confirm your funding arrangements. We will advise the seller of your position should an offer be made together with the details provided by you on our platform at the time you submit your offer. You may be contacted again by us at the time an offer is made to seek further information and clarification.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Anti Money Laundering
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        All estate agents have a legal obligation to conduct identification checks on their customers in accordance with Anti-Money Laundering Regulations.
                    <br /><br />
                    This will include proof of ownership which will be established by a Land Registry search or copying Title Deeds where a property is not registered.
                    <br /><br />
                    Identification checks for sellers must take place at the point you list your property on Free. For buyers this should take place at the point your offer is accepted by a seller and must be done prior to exchange of contracts on the property.
                    <br /><br />
                    We shall carry out an electronic identification check which has no impact on your credit rating and any information that we receive in connection with that search will solely be used for the purposes of the Anti-Money Laundering Regulations. By agreeing to our Terms of Use you understand that we may undertake a search with a credit reference agency (CRA) to verify your identity.  To do so a CRA may check the details you supply against any particulars on any database (public or otherwise) to which they have access. They may also use your details in the future to assist other companies for verification purposes. A record of the search will be retained.
                    <br /><br />
                    The processing of personal data in accordance with these Regulations is lawful and necessary for the prevention of money laundering or terrorist financing and is for the performance of a task carried out in the public interest.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Access to the Free Platform
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Once you have registered with Free you will be given access to the Free Platform. You will then be able to use the Free Platform subject to these Terms of Use, the Privacy Policy and any other terms and conditions you have agreed to. Please ensure you have full knowledge of these terms and conditions.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Your Agreement
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        You agree that you will NOT use the Systems to:
                    <ul>
                            <li>upload, download, post, email, transmit, store or otherwise make available any Content that is unlawful, harassing, threatening, harmful, tortious, defamatory, libellous, abusive, violent, obscene, vulgar, invasive of another’s privacy, hateful, racially or ethnically offensive, or otherwise objectionable</li>
                            <li>stalk, harass, threaten or harm another</li>
                            <li>pretend to be anyone, or any entity, you are not — you may not impersonate or misrepresent yourself as another person, entity, another User, a Free employee, or , or otherwise misrepresent your affiliation with a person or entity (Free reserves the right to reject or block any Free ID or e-mail address which could be deemed to be an impersonation or misrepresentation of your identity, or a misappropriation of another person’s name or identity)</li>
                            <li>engage in any copyright infringement or other intellectual property infringement (including uploading any content to which you do not have the right to upload), or disclose any trade secret or confidential information in violation of a confidentiality, employment, or nondisclosure agreement</li>
                            <li>post, send, transmit or otherwise make available any unsolicited or unauthorized email messages, advertising, promotional materials, junk mail, spam, or chain letters, including, without limitation, bulk commercial advertising and informational announcements</li>
                            <li>upload, post, email, transmit, store or otherwise make available any material that contains viruses or any other computer code, files or programs designed to harm, interfere or limit the normal operation of the Systems (or any part thereof), or any other computer software or hardware</li>
                            <li>interfere with or disrupt the Systems (including accessing the Systems through any automated means, like scripts or web crawlers), or any servers or networks connected to the Systems, or any policies, requirements or regulations of networks connected to the Systems (including any unauthorised access to, use or monitoring of data or traffic thereon)</li>
                            <li>plan or engage in any illegal activity; and/or</li>
                            <li>gather and store personal information on any other Users of the Systems to be used in connection with any of the foregoing prohibited activities.</li>
                        </ul>
                    You further agree:
                    <ul>
                            <li>not to reveal your Account information to anyone</li>
                            <li>that you are solely responsible for maintaining the confidentiality and security of your Account and for all activities that occur on or through your Account, and you agree to immediately notify Free of any security breach</li>
                            <li>that the Products and Services are designed and intended for personal use on an individual basis and you should not share your Account information with other individuals and/or corporate bodies</li>
                            <li>that provided we have exercised reasonable skill and due care, Free shall not be responsible for any losses arising out of the unauthorised use of your Account resulting from you not following these rules</li>
                            <li>to provide accurate and complete information when you register with, and as you use the Systems and you agree to update your account information to keep it accurate and complete.</li>
                            <li>that failure to provide accurate, current and complete Account information and data may result in the suspension and/or termination of your Account and/or Products and Services (without refund or part credit of fees paid).</li>
                        </ul>
                        </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Removal of Content
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        You acknowledge that Free is not responsible or liable in any way for any Content provided by others and has no duty to pre-screen such Content. However, Free reserves the right at all times to determine whether Content is appropriate and in compliance with this Agreement, and may pre-screen, move, refuse, modify and/or remove Content at any time, without prior notice and in its sole discretion, if such Content is found to be in violation of this Agreement or is otherwise objectionable.
                    <br /><br />
                    Further, Free reserves the right to access your account (which may involve taking complete control) to ensure the orderly and reasonable conduct in accordance with these terms, the Privacy Policy and (if applicable) Service Agreement. This may include withdrawal of properties that have been sold by another agent or the withdrawal of offers to sell a property. You will be provided with notice to take the necessary steps to remedy the issue. Should you fail to respond to this request within 24 hours, the appropriate action will be taken by Free.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Access to your Account and Content
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Free reserves the right to take steps Free believes are reasonably necessary or appropriate to enforce and/or verify compliance with any part of this Agreement. You acknowledge and agree that Free may, without liability to you, access, use, preserve and/or disclose your Account information and Content to law enforcement authorities, government officials, and/or a third party, as Free believes is reasonably necessary or appropriate, if legally required to do so or if we have a good faith belief that such access, use, disclosure, or preservation is reasonably necessary to: (a) comply with legal process or request; (b) enforce this Agreement, including investigation of any potential violation thereof; (c) detect, prevent or otherwise address security, fraud or technical issues; or (d) protect the rights, property or safety of Free, its Users, a third party, or the public as required or permitted by law.
                    <br /><br />
                    As part of the process of using the Systems certain personal information will need to be disclosed to third parties such as a Seller, prospective Purchaser, legal advisor, third party web portals and such others as Free deems necessary to enable them to provide the Systems. By agreeing to these Terms you agree and accept that such information can be disclosed.
                    <br /><br />
                    For the avoidance of doubt, Free reserves the right to take control of your Account (including blocking account use), in order to enforce all or any element of these Terms or Service Agreement.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Using your Information
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        A prospective Purchaser is someone wishing to view a property, to provide feedback, to make an offer to purchase or who agrees a sale. Free may need to disclose the name, address, contact details, and relevant financial information of the prospective Purchaser to the Seller (and vice versa) in advance of a viewing or any subsequent viewing taking place at the Seller’s property. By participating in any of the above processes or services, you agree for your information to be disclosed.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Getting your Cake
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Thanks for reading this - most people don’t. If you would like to be rewarded for your super-duper reading work, have a cake on us, for Free. Email “OMGFreeCake” to <a href="mailto:born@free.co.uk" style={{ textDecoration: "underline" }}>born@free.co.uk</a>, and we’ll be in touch. This offer does not constitute a binding contract - cake is offered at our discretion. Offer is limited to one cake per company or household.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Legal Advisor
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Free will need to disclose the name, address, contact details, proposed purchase address, the amount the property is being sold/purchased for and any conditions of sale in relation to both the Seller and the Purchaser to the Legal Advisors acting on either or both sides of the transaction. By agreeing to these Terms you agree to allow us to do so.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Estate Agent and Mortgage Advisors
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Free will need to disclose the name, address, contact details, proposed purchase address, the amount the property is being sold/purchased for and any conditions of sale in relation to both the Seller and the Purchaser to any Estate Agent and/or Mortgage Advisor involved in the transaction. By agreeing to these Terms you agree to allow us to do so.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Information and Data
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Any information and or data provided by you to Free and used by Free directly or indirectly in the performance of the Terms shall remain at all times the property of you. It shall be identified, clearly marked and recorded as such by Free on all media and in all documentation.
                    <br /><br />
                    Free shall take all reasonable precautions to preserve the integrity and prevent any corruption or loss, damage or destruction of this data and information.
                    <br /><br />
                    Free agrees to comply and have adequate measures in place to ensure that its staff comply at all times with the provisions and obligations contained in (as amended from time to time):
                    <ul>
                            <li>The Data Protection Act 1998.</li>
                            <li>The Telecommunications (Data Protection and Privacy) (Direct Marketing).</li>
                            <li>Regulations 1998 4.4.3 The Consumer Protection Distance Selling Regulations 2000.</li>
                        </ul>
                    All personal data acquired by Free from you shall only be used for the purposes of this Agreement and shall not be further processed or disclosed without the consent of you, other than in circumstances detailed in this Agreement.
                    <br /><br />
                    Nothing in this Agreement shall oblige you to disclose any information to Free if it is of the view that to do so would be a breach of the Data Protection Act 1998.
                    <br /><br />
                    Free shall indemnify you for any breach of the legislation above, which renders you liable for any costs, fines, claims or expenses howsoever arising. In fulfillment of its obligations above, Free will have in place and will maintain at all times the Information Standards which will deal comprehensively with:
                    <ul>
                            <li>The protection of the confidentiality, integrity and security of all and any information supplied to Free by you</li>
                            <li>The audit and accounting procedures in place to deal with the requirements of this clause</li>
                            <li>The reliability and training of staff to ensure awareness of (and compliance with) their obligations</li>
                            <li>Any other measures and procedures to ensure that Free obligations under the above clauses are met.</li>
                        </ul>
                        </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Copyright Notice - DMCA
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        If you believe that any Content in which you claim copyright has been infringed by anyone using the Systems, please contact Free at born@free.co.uk Free may, in its sole discretion, suspend and/or terminate Accounts of Users that are found to be repeat infringers.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Violations of this Agreement
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        If while using the Systems, you encounter Content you find inappropriate, or otherwise believe to be a violation of this Agreement, you may report it by sending an email to <a href="born@free.co.uk" style={{ textDecoration: "underline" }}>born@free.co.uk</a>.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        License from you
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Free does not claim ownership of the materials and/or Content you submit or make available on the Systems. However, by submitting or posting such Content on areas of the Systems that are accessible by the public or other Users with whom you consent to share such Content, you grant Free a worldwide, royalty-free, non-exclusive license to use, distribute, reproduce, modify, adapt, publish, translate, publicly perform and publicly display such Content on the Systems solely for the purpose for which such Content was submitted or made available, without any compensation or obligation to you. You agree that any Content submitted or posted by you shall be your sole responsibility, shall not infringe or violate the rights of any other party or violate any laws, contribute to or encourage infringing or otherwise unlawful conduct, or otherwise be obscene, objectionable, or in poor taste. By submitting or posting such Content on areas of the Systems that are accessible by the public or other Users, you are representing that you are the owner of such material and/or have all necessary rights, licenses, and authorisation to distribute it.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Changes to Content
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        You understand that in order to provide the Systems and make your Content available thereon, Free may transmit your Content across various public networks, in various media, and modify or change your Content to comply with technical requirements of connecting networks or devices or computers. You agree that the license permits Free to take any such actions.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Trademark information
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Free, the Free logo and other Free trademarks, Systems marks, graphics, and logos used in connection with the Systems and Software are trademarks or registered trademarks of Free, including the intellectual property rights of the Free brand. Other trademarks, Systems marks, graphics, and logos used in connection with the Systems or Software may be the trademarks of their respective owners. You are granted no right or license in any of the aforesaid trademarks, and further agree that you shall not remove, obscure, or alter any proprietary notices (including trademark and copyright notices) that may be affixed to or contained within the Product(s) or Software.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Free proprietary right
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        You acknowledge and agree that Free and/or its licensors own all legal right, title and interest in and to the Systems, including but not limited to graphics, User interface, the scripts, logos, sounds, music, artwork, videos, computer code and software used to implement the Systems, and any software provided to you as a part of and/or in connection with the Systems, including any and all intellectual property rights that exist therein, whether registered or not, and wherever in the world they may exist. You further agree that the Systems (including the Software, or any other part thereof) contains proprietary and confidential information that is protected by applicable intellectual property and other laws, including but not limited to copyright. You agree that you will not use such proprietary information or materials in any way whatsoever except for use of the Systems in compliance with this Agreement. No portion of the Systems may be reproduced in any form or by any means, except as expressly permitted in these Terms.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        License from Free
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Free grants you a personal, non-exclusive, non-transferable, limited license to use the Software as provided to you by Free as a part of the Systems and in accordance with this Agreement; provided that you do not (and do not permit anyone else to) copy, modify, rent, loan, distribute, create a derivative work of, reverse engineer, decompile, or otherwise attempt to discover the source code (unless expressly permitted or required by law), sell, lease, sublicense, assign, grant a security interest in or otherwise transfer any right in the Software, and you shall not exploit the Systems in any unauthorized way whatsoever, including, but not limited to, by trespass or burdening network capacity.
                        <br/><br/>
                        THE USE OF THE SYSTEMS OR ANY PART OF THE SYSTEMS, EXCEPT FOR USE OF THE SYSTEMS AS PERMITTED IN THE TERMS, IS STRICTLY PROHIBITED AND INFRINGES ON THE INTELLECTUAL PROPERTY RIGHTS OF OTHERS AND MAY SUBJECT YOU TO CIVIL AND CRIMINAL PENALTIES, INCLUDING POSSIBLE MONETARY DAMAGES, FOR COPYRIGHT INFRINGEMENT.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Links and other third party materials
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                    Certain content, components or features of the Systems may include materials and/or services from third parties and/or hyperlinks to other web sites, resources or Content. Because Free may have no control over such third party sites, services and/or materials, you acknowledge and agree that Free is not responsible for the availability of such sites, services or resources, and does not endorse or warrant the accuracy of any such sites or resources, and shall in no way be liable or responsible for any Content, advertising, Systems or materials on or available from such sites or resources. You further acknowledge and agree that Free shall not be responsible or liable in any way for any damages you incur or allege to have incurred, either directly or indirectly, as a result of your use and/or reliance upon any such content, advertising, systems or materials on or available from such sites or resources.
                    
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageContent">
                        Free free free Ltd
                        <br/>
                        38B Enmore Road
                        <br/>
                        London
                        <br/>
                        SE25 5NG
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default Terms;