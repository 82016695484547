import React from 'react';

function RetirementAgeOG(props) {
    return (
        <div>
            <div className='heading'>
                What is your expected retirement age?
            </div>

            <div className='input_contain'>
                <div className='input_wrap'>
                    <input value={props.store?.retirement_age || undefined} type="number" placeholder='67' onChange={e => props.add("retirement_age", parseInt(e.currentTarget.value) || undefined)} />
                    <div className='following_label'>
                        years old
                    </div>
                    {/* <div className='required'>*</div> */}
                </div>
            </div>

            <button disabled={!props.store.retirement_age || props.store.retirement_age < 18} className='capBtn' style={{ margin: "15px" }} onClick={() => props.next()}>
                Next
            </button>
        </div>
    );
}

export default RetirementAgeOG;