import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function IVA(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Have you ever had an Individual Voluntary Arrangement (IVA)?",
                        info: {
                            id: "iva_modal",
                            text: "What is an individual voluntary agreement?",
                            content: "An IVA (or Individual Voluntary Arrangement) is a legally binding agreement between you and your creditors (people who you owe money to). It’s so you can repay your debts over an agreed time period, usually between 5 and 6 years.",
                            content2: "When lenders see an IVA on your credit file, they perceive you to be ‘high-risk’ because you’ve faced difficulties in repaying debts in the past. But it doesn’t mean you won’t be able to borrow any money."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 2
                            }
                        }
                    }} id={"had_a_iva"} setValue={(e) => props.setValue({ ...props.store, had_a_iva: e })} />
                )
            
            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When were you discharged?",
                        input: {
                            type: "month",
                            id: "iva_discharged",
                            min: 7,
                            next: props.switch + 2
                        },
                        sub_button: {
                            "Or mark this IVA as not yet satisfied": {
                                value: "ONGOING",
                                next: props.switch + 2
                            }
                        }
                    }} id={"iva_discharged"} setValue={(e) => props.setValue({ ...props.store, iva_discharged: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default IVA;