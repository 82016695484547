import React from "react";

class Next_Steps extends React.Component {
    render() {
        return (
            <div className="listing-flow">
                <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                    <div className="navbar">
                        {/* <button className="back" onClick={this.prevPage} >
                            <img className="backArrow" src={require("../img/icon-arrow-back.svg")}></img>
                            <div className="backText">
                                Back
                            </div>
                        </button> */}
                        <a className="iddLink" href="/"><img className="logo" src={require("../img/logo.svg")} alt='free'></img></a>
                    </div>

                    <div style={{ textAlign: "left" }}>
                        <div className="pageHeadingPolicy">
                            Mortgage Insurance Next Steps
                        </div>
                        <div className="pageContent"  style={{ textAlign: "left" }}>
                            Once you’re happy with your insurance recommendation from Free.co.uk, we need to arrange a phone call to go through a medical questionnaire. 
                            <br/><br/>
                            We use LifeQuote to help us complete and manage the application process - they are experts in making the process quick and easy for you. 
                            <br/><br/>
                            To ensure the call goes smoothly, you’ll need to have the following information to hand -
                            <br/>
                            <ul>
                                <li>your medical history</li> 
                                <li>your lifestyle</li>
                                <li>any serious illnesses suffered by your immediate family</li>
                                <li>any existing policies</li>
                                <li>GP details</li>
                                <li>your height and weight</li> 
                                <li>details of any medication you are on</li> 
                                <li>bank account details</li>
                            </ul>
                            The call takes about 15 minutes per applicant.
                            <br/><br/>
                            Please also bear in mind that you’re taking our insurance to ensure that you, and your loved ones, are looked after, should the worst happen. For this very reason, it’s important that all the information provided by you is accurate and true to the best of your knowledge and belief. If any of this information is inaccurate or untrue your policy may be cancelled or treated as if it never existed, or your claim rejected or not fully paid
                            <br/><br/>
                            Finally, in some cases, the insurer may ask you to undergo a medical (at their expense), or write to your GP for further information. This is quite normal and it is possible that in some cases, this medical information might lead to the insurer increasing their premium. If this is the case we will discuss any changes with you before commencing cover, keeping you in control.
                            <br/><br/>
                            Should you have any questions or concerns about this process, please do not hesitate to contact your adviser at Free.co.uk. We’re here to make sure that you can relax, knowing that you are properly covered.

                        </div>
                        <hr className="policyDivider" />

                        <div className="pageContent">
                            More Simple Money Limited<br/>
                            Warwick House<br/>
                            65-66 Queen Street<br/>
                            London<br/>
                            EC4R 1EB
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Next_Steps;