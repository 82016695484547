import React, { Fragment, useEffect, useRef, useState } from 'react';
import commaNumber from "comma-number";
import superagent from "superagent";
import AuthModal from '../auth/auth_modal';
import Loader from '../../../loader';
import "./acre_results.sass"
import * as Icon from "react-bootstrap-icons"
import LoadingBar from './loading_bar/loading_bar';
import Applied from './applied';
import ReactResizer from "react-resize-detector"

function AcreResults2(props) {
    const [store, setStore] = useState(props.store)
    const [products, setProducts] = useState([])
    const [grouped, setGrouped] = useState({})
    const [loading, setLoading] = useState(true)
    const [run, setRun] = useState(false)

    const [affordability, setAffordability] = useState([])
    const [rejects, setRejects] = useState([])

    const [index, setIndex] = useState(0)

    const [loadingMax, setLoadingMax] = useState(0)
    const [loadingCurrent, setLoadingCurrent] = useState(0)
    const [loadingText, setLoadingText] = useState("This may take a moment")

    const [applied, setApplied] = useState(false)

    const [protectionDec, setProtectionDec] = useState(null)
    const [protectionLev, setProtectionLev] = useState(null)
    const [protectionLoader, setProtectionLoader] = useState(true)

    const [generalBoth, setGeneralBoth] = useState(null)
    const [generalBuild, setGeneralBuild] = useState(null)
    const [generalLoader, setGeneralLoader] = useState(true)

    const [deposit, setDeposit] = useState(store.deposit)
    const [LTV, setLTV] = useState(70)
    const [propertyValue, setPropertyValue] = useState(parseInt(deposit / (100 - LTV) * 100))
    const [loanAmount, setLoanAmount] = useState(propertyValue - deposit)
    const [loanTerm, setLoanTerm] = useState(store.preference_term)
    const [initialState, setInitialState] = useState({
        ...{
            deposit,
            LTV,
            propertyValue,
            loanAmount,
            loanTerm
        }
    })
    const [initial, setInitial] = useState(true)

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    useEffect(() => {
        setStore(props.store)
    }, [props.store])

    useEffect(() => {
        getGeneral()
        if (store.acre_id) getProtection()
        if (store.case_id) runAffordability()
    }, [store])

    useEffect(() => {
        let current = true;

        if (initialState?.["deposit"] !== parseInt(deposit)) current = false
        if (initialState?.["propertyValue"] !== parseInt(propertyValue)) current = false
        if (initialState?.["LTV"] !== parseInt(LTV)) current = false
        if (initialState?.["loanAmount"] !== parseInt(loanAmount)) current = false
        if (initialState?.["loanTerm"] !== parseInt(loanTerm)) current = false

        setInitial(current)
    }, [deposit, LTV, propertyValue, loanAmount, loanTerm])

    useEffect(() => {
        setLoading(false)

        let max = 0
        for (let i = 0; i < affordability.length; i++) {
            if (affordability[i]?.result > max) max = affordability[i]?.result
        }
    }, [store])

    function runAffordability() {
        superagent.post(process.env.REACT_APP_API_URL + "/acre/affordability", {
            id: props.store.affordability_id,
            case: props.store.case_id
        }).then(res => {
            let rejects = res.body.data.filter(e => !e.status)

            let arr = res.body.data.map(e => {
                return {
                    lender: e.lender.name,
                    result: parseInt(e?.result) || undefined
                }
            })

            let maxTotal = Math.max(...arr)

            if (maxTotal === 0) {
                runAffordability()
            } else {
                setAffordability(arr)
                setRejects(rejects)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (Object.entries(affordability).length !== 0) {
            if (products?.length === 0 && !run) {
                runSourcing([], 0)
            } else {
                setGrouped(groupLenders(products))
            }
        }
    }, [LTV, affordability])

    function getGeneral() {
        setGeneralLoader(true)
        superagent.post(process.env.REACT_APP_API_URL + "/acre/insurance/general", {
            dob: store.date_of_birth,
            first_name: store.first_name,
            last_name: store.last_name,
            title: store.title,
            postcode: store?.addresses?.[0]?.address?.postcode || "SE25 5NG"
        }).then(res => {
            setGeneralBoth(res.body.data.both)
            setGeneralBuild(res.body.data.building)
            setGeneralLoader(false)
        }).catch(err => {
            console.log(err)
        })
    }

    function getProtection() {
        // setProtection(null)
        setProtectionLoader(true)
        superagent.post(process.env.REACT_APP_API_URL + "/acre/insurance/protection", {
            client_id: store.acre_id,
            cover_amount: loanAmount,
            income: store.annual_income,
            dob: store.date_of_birth,
            first_name: store.first_name,
            last_name: store.last_name,
            gender: store.gender,
            title: store.title,
            term: loanTerm,
            smoker: store.smoker_status
        }).then(res => {
            setProtectionDec(res.body.data.decreasing)
            setProtectionLev(res.body.data.level)
            setProtectionLoader(false)
        }).catch(err => {
            console.log(err)
        })
    }

    function wait(time) {
        return new Promise(r => setTimeout(r, time))
    }

    function runSourcing(arr, num) {
        setRun(false)
        setProducts([])
        setGrouped({})
        setLoading(true)
        setLoadingMax(0)
        setLoadingCurrent(0)
        superagent.post(process.env.REACT_APP_API_URL + "/acre/sourcing", {
            case: store,
            deposit: deposit,
            ltv: LTV,
            term: loanTerm * 12,
            start: 0,
            rows: 0
        }).then(async res => {
            if (res.body.data.response.numFound === 0) {
                setRun(true)
                setProducts([])
                setGrouped([])
                setLoading(false)
            } else {
                let lenders = Object.entries(res.body.data.facet_counts.facet_fields.lender)
                for (let i = 0; i < lenders.length; i++) {
                    for (let j = 0; j < 3; j++) {
                        let fixed = 2
                        if (j === 1) fixed = 5
                        if (j === 2) fixed = 10
                        let result = await runLender(lenders[i][0], fixed)
                        result.map(e => e.fees = JSON.parse(e.fees))
                        arr.push(...result)
                        await wait(300)
                    }
                    runAffordability()
                    arr = arr.sort((a, b) => a?.monthlypayment - b?.monthlypayment).map((e, n) => { return { ...e, n } })
                    setProducts([...arr])
                    setGrouped(groupLenders([...arr]))
                    setLoading(false)
                    setLoadingMax(lenders.length)
                    setLoadingCurrent(i + 1)
                    setRun(true)
                    await wait(300)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    async function runLender(lender, fixed) {
        return await superagent.post(process.env.REACT_APP_API_URL + "/acre/sourcing", {
            case: store,
            lenders: [lender],
            deposit: deposit,
            ltv: LTV,
            term: loanTerm * 12,
            start: 0,
            rows: 1,
            fixed: fixed
        }).then(res => {
            return res.body.data.response.docs.map(e => {
                let name = e.name.split("-")
                let term = !isNaN(parseInt(e.name)) ? parseInt(e.name) : parseInt(e.name.slice(e.name.indexOf(" ")))
                if (name[0].includes("Month")) term = getTerm(term)
                let rate = e.name.slice(0, e.name.indexOf("%"))
                rate = rate.slice(rate.lastIndexOf(" "))
                let initialMonthly = e.truecostforperiod / (fixed * 12)
                return { ...e, "x-sourcing-id": res.body.response.header?.["x-sourcing-id"], rate, term, initialMonthly }
            })
        }).catch(err => {
            console.log(err)
        })
    }

    function groupLenders(products) {
        return products.reduce(function (r, a) {
            r[a.lender] = r[a.lender] || [];
            r[a.lender].push(a);
            return r;
        }, Object.create(null))
    }

    function to2dp(num) {
        // return num.toFixed(2).replace(/[.,]00$/, "")
        let v = Math.round(num * 100) / 100
        return isNaN(v) ? 0 : v
    }

    function to0dp(num) {
        let v = Math.round(num)
        return isNaN(v) ? 0 : v
    }

    // Run after initial deposit etc state has been changed
    useEffect(() => {
        if (Object.values(initialState).filter(e => e === 0).length === 0 && !initial) {
            console.log("Run sourcing")
            setLoadingText("Updating products")
            setInitial(true)
            getProtection()
            runSourcing([], 0)
        } else {
            console.log("Can't run")
        }
    }, [initialState])

    function getTerm(term) {
        return Math.round(term / 12)
    }

    function apply(n, sourcing) {
        setLoadingText("Saving product...")
        setLoading(true)
        superagent.post(process.env.REACT_APP_API_URL + "/acre/product/save", {
            details: {
                case_id: store.case_id,
                client_ids: [store.acre_id],
                product_order: products.map(e => {
                    return {
                        product_id: e.id,
                        raw_results_reference: e["x-sourcing-id"],
                        type: "SINGLE"
                    }
                }),
                reason: [],
                index: n,
                results_ids: [...new Set(products.map(e => {
                    return e["x-sourcing-id"]
                }))]
            },
            "x-sourcing-id": sourcing
        }).then(res => {
            superagent.post(process.env.REACT_APP_API_URL + "/acre/portal", {
                "case_id": store.case_id,
                "client_id": store.acre_id,
                "email": store.contact_details_email
            }).then(res => {
                setLoadingText("This may take a moment")
                setLoading(false)
                setApplied(products.filter(e => e.n === n)[0])
            }).catch(err => {
                console.log(err)
                setLoadingText("This may take a moment")
                setLoading(false)
                setApplied(products.filter(e => e.n === n)[0])
            })
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <ReactResizer onResize={(w, h) => {
            setWidth(w)
            setHeight(h)
        }}>
            <div className="adverse_flow acre_results">
                <Loader show={loading} text={loadingText} />
                <LoadingBar style={props.style} current={loadingCurrent} max={loadingMax} />
                {applied ? <Applied client={store.acre_id} product={applied} /> : null}

                {props.auth ? <AuthModal zIndex={999} save_acre setValue={props.setValue} store={props.store} show={props.auth} save_mortgage prev={props.prev} setPrev={props.setPrev} setPage={props.setPage} /> : null}

                <div className="heading">
                    Your rates
                </div>
                <div className="content">
                    {/* These fixed-rates are what lenders could offer you for a <span className="underline">£{commaNumber(Math.round(props.store.deposit * 100 / parseInt(deposit)))} property</span>, with a deposit of <span className="underline">£{commaNumber(props.store.deposit)}</span> ({100 - parseInt(deposit)}% LTV). */}
                    We would love to help you get a mortgage. {props.style === "sprung" ? null : "Please do book a call."}
                    {props.style === "sprung" ? null : <>
                        <br />
                        <button onClick={() => window.open("https://calendly.com/free40/book", "_blank")} className="primary">Book a call</button>
                        {/* <button onClick={() => window.open("https://wa.me/447590501969", "_blank")} className="whatsapp"><img style={{ width: "26px", verticalAlign: "middle", marginRight: "5px" }} src={require("../../../img/WhatsApp.png")} /><div style={{ display: "inline-block", verticalAlign: "middle", lineHeight: "20px" }}>Chat on <b>WhatsApp</b></div></button> */}
                    </>
                    }
                </div>

                <table className='insurance_table'>
                    <Loader show={generalLoader || protectionLoader} />
                    <thead></thead>
                    {width >= 680 ?
                        <tbody>
                            <tr>
                                <td></td>
                                <td>Building and Contents</td>
                                <td>Building Only</td>
                            </tr>
                            <tr>
                                <td>General Insurance</td>
                                <td>£{parseFloat(generalBoth) || 0} / mo</td>
                                <td>£{parseFloat(generalBuild) || 0} / mo</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Decreasing Term Assurance</td>
                                <td>Level Term Assurance</td>
                            </tr>
                            <tr>
                                <td>Protection Insurance</td>
                                <td>£{protectionDec / 100} / mo</td>
                                <td>£{protectionLev / 100} / mo</td>
                            </tr>
                        </tbody> : null}
                    {width < 680 ? <tbody>
                        <tr>
                            <td colSpan={2}>General Insurance</td>
                        </tr>
                        <tr>
                            <td>Building and Contents</td>
                            <td>Building Only</td>
                        </tr>
                        <tr>
                            <td>£{parseFloat(generalBoth) || 0} / mo</td>
                            <td>£{parseFloat(generalBuild) || 0} / mo</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>Protection Insurance</td>
                        </tr>
                        <tr>
                            <td>Decreasing Term Assurance</td>
                            <td>Level Term Assurance</td>
                        </tr>
                        <tr>
                            <td>£{protectionDec / 100} / mo</td>
                            <td>£{protectionLev / 100} / mo</td>
                        </tr>
                    </tbody> : null}
                </table>

                <table className='stats_table' id="stats_table">
                    <thead>
                        <tr>
                            <td>Property Value</td>
                            <td>Deposit</td>
                            {width >= 680 ?
                                <td>Loan Required <b>({LTV > 0 ? to2dp(LTV) : 0}%)</b></td>
                                : null}
                            {width >= 680 ?
                                <td>Loan Term</td>
                                : null}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {getInput(propertyValue, "£", false, e => {
                                    let v = e.currentTarget.value
                                    setPropertyValue(parseInt(v))
                                    setLoanAmount(v - deposit <= 0 ? 0 : v - deposit)
                                    setLTV(100 / v * (v - deposit))
                                })}
                            </td>
                            <td>
                                {getInput(deposit, "£", false, e => {
                                    let v = e.currentTarget.value
                                    setLoanAmount(propertyValue - v)
                                    setDeposit(parseInt(v))
                                    setLTV(100 / propertyValue * (propertyValue - v))
                                })}
                            </td>
                            {width >= 680 ?
                                <td>
                                    {getInput(loanAmount, "£", false, e => {
                                        let v = e.currentTarget.value
                                        setLoanAmount(parseInt(v))
                                        setPropertyValue(isNaN(parseInt(v) + parseInt(deposit)) || parseInt(v) === 0 ? 0 : parseInt(v) + parseInt(deposit))
                                        setLTV(100 / (parseInt(v) + parseInt(deposit)) * v)
                                    })}
                                </td>
                                : null}
                            {width >= 680 ?
                                <td>
                                    {getInput(loanTerm, false, "years", e => {
                                        let v = e.currentTarget.value
                                        setLoanTerm(parseInt(v))
                                    })}
                                </td>
                                : null}
                        </tr>
                    </tbody>
                    {width < 680 ? <thead>
                        <tr>
                            <td>Loan Required <b>({LTV > 0 ? to2dp(LTV) : 0}%)</b></td>
                            <td>Loan Term</td>
                        </tr>
                    </thead> : null}
                    {width < 680 ? <tbody>
                        <tr>
                            <td>
                                {getInput(loanAmount, "£", false, e => {
                                    let v = e.currentTarget.value
                                    setLoanAmount(parseInt(v))
                                    setPropertyValue(isNaN(parseInt(v) + parseInt(deposit)) || parseInt(v) === 0 ? 0 : parseInt(v) + parseInt(deposit))
                                    setLTV(100 / (parseInt(v) + parseInt(deposit)) * v)
                                })}
                            </td>
                            <td>
                                {getInput(loanTerm, false, "years", e => {
                                    let v = e.currentTarget.value
                                    setLoanTerm(parseInt(v))
                                })}
                            </td>
                        </tr>
                    </tbody> : null}
                </table>

                <div style={{
                    margin: "15px"
                }}><b>Scroll down to see the full list of available products.</b></div>

                {run && products?.length === 0 ? <div className='no_results'>Sorry, no results found.</div> : null}

                {Object.entries(grouped).length > 0 ?
                    <table className='result_table'>
                        <thead>
                            <tr>
                                <td style={{ textAlign: "left", paddingLeft: "10px" }}>Lender</td>
                                {/* <td></td> */}
                                <td colSpan={3}>Monthly cost (Rate)</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "left" }}>Max Loan</td>
                                {/* <td>Max Loan</td> */}
                                <td>2 year</td>
                                <td>5 year</td>
                                <td>10 year</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(grouped).map((e, k) => {
                                let aff = true
                                let aff_result = 0
                                if (
                                    affordability?.filter(f => e[1][0].lender === f.lender).length === 0
                                    || affordability?.filter(f => e[1][0].lender === f.lender)?.[0]?.result < (loanAmount)
                                ) {
                                    aff = false
                                    aff_result = affordability?.filter(f => e[1][0].lender === f.lender)?.[0]?.result || 0
                                }
                                else aff_result = affordability?.filter(f => e[1][0].lender === f.lender)?.[0]?.result || 0
                                if (aff_result === 0) aff = false
                                let loan = loanAmount
                                let style = { opacity: aff ? 1 : 0.3, backgroundColor: k % 2 === 1 ? "white" : "#d4d4d4" }

                                let yr2 = e[1].filter(e => e.term === 2)
                                let yr5 = e[1].filter(e => e.term === 5)
                                let yr10 = e[1].filter(e => e.term === 10)

                                return <Fragment key={k}>
                                    <tr className='new_results_row' style={style}>
                                        <td>
                                            {getLogo(e[0]) || e[0]}
                                            <div style={{ color: aff_result >= loan ? "green" : "red", marginTop: "5px" }}>
                                                {/* {aff_result >= loan ?
                                                    <div className='aff_icon'><Icon.Check2 /></div>
                                                    :
                                                    <div className='aff_icon'><Icon.X /></div>
                                                } */}
                                                £{commaNumber(to2dp(aff_result))}
                                            </div>
                                        </td>
                                        {/* <td style={{ color: aff_result >= loan ? "green" : "red", display: "inline-block", verticalAlign: "middle", paddingLeft: "10px" }}></td> */}
                                        <td>{getLenderRow(yr2, aff)}</td>
                                        <td>{getLenderRow(yr5, aff)}</td>
                                        <td>{getLenderRow(yr10, aff)}</td>
                                    </tr>
                                </Fragment>
                            })}
                        </tbody>
                    </table>
                    : null}
            </div>
        </ReactResizer >
    );

    function getInput(value, start, end, onChange) {
        return <div className='stat_div'>
            {start ? <div className='stat_label_start'>{start}</div> : null}
            <div className='stat_input_div'
                style={{
                    backgroundColor: !value ? "#fda4a4" : "transparent",
                    borderTopLeftRadius: start ? "" : "5px",
                    borderBottomLeftRadius: start ? "" : "5px",
                    borderTopRightRadius: end ? "" : "5px",
                    borderBottomRightRadius: end ? "" : "5px",
                    width: end === "years" ? "70px" : ""
                }}
            ><input type="number" className='stat_input' value={value} onChange={onChange} onBlur={() => {
                if (!initial) {
                    console.log("Update")
                    setInitialState({ deposit, LTV, propertyValue, loanAmount, loanTerm })
                }
            }} /></div>
            {end ? <div className='stat_label_end'>{end}</div> : null}
        </div>
    }

    function getLenderRow(rate, aff) {
        let style = {
            padding: "0px",
            textAlign: "right"
        }

        let cheapest = to0dp(rate[0]?.monthlypayment) - to0dp(products?.[0]?.monthlypayment) === 0

        return rate.length === 0 ? <b>-</b> :
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <tbody>
                    <tr><td style={{ ...style, color: cheapest ? "green" : "black" }}>£{commaNumber(to0dp(rate[0]?.monthlypayment))} <span style={{ color: "black" }}>({rate[0].rate?.trim()}%)</span></td></tr>
                    <tr>{cheapest ? <td></td> : <td style={{ ...style, paddingTop: "-5px", color: "red" }}>+£{commaNumber(to0dp(rate[0]?.monthlypayment - products?.[0]?.monthlypayment))}</td>}</tr>
                    {aff ? <tr style={{ textAlign: "right" }}><td><u onClick={() => {
                        apply(rate[0].n, rate[0]?.["x-sourcing-id"])
                    }} style={{ textUnderlineOffset: "4px" }}>Apply</u></td></tr> : null}
                </tbody>
            </table>
    }

    function getLogo(lender) {
        let without = ["Digital Mortgages", "Norton Home Loans", "Even"]
        if(without.includes(lender)) return lender
        try {
            // return logos[lender]
            return <img className='lender_logo_result' alt={lender} src={`https://api1.acreplatforms.net/v1/acre/local-sourcing/lenderImage?lenderName=${lender.replace(/ /g, "%20")}`} />
            // return lender
        } catch (err) {
            return false
        }
    }
}

export default AcreResults2;