import React, { useEffect, useState } from 'react';
import * as Icon from "react-bootstrap-icons"

function Dependents(props) {
    const [num, setNum] = useState(props.store?.number_of_dependants || 1)
    const baseDep = {
        relationship: "",
        name: "",
        date_of_birth: "",
        dependency_end_date: ""
    }
    const [dependents, setDependents] = useState(props.store?.dependants || [{ ...baseDep }])

    useEffect(() => {
        if (dependents !== props.store?.dependants) props.add("dependants", dependents)
    }, [dependents])

    useEffect(() => {
        // if (props.original?.dependants && props.original?.dependants > 0) setNum(props.original?.dependants)
        // else if (props.original?.dependants === 0) props.setPage(props.page + 1)
    }, [props.original])

    return (
        <div>
            <div className='heading'>
                Let us know a bit more about your{props.store?.number_of_dependants && props.store?.number_of_dependants === 1 ? " dependant" : ` ${props.store?.number_of_dependants} dependants`}
            </div>
            <div className='content'>
                We'd like to know their relationship to you, their name, their birthday and when the dependency ends. If you have no dependants, you can ignore this page.
            </div>

            {Array(num).fill(num).map((e, n) => {
                return <div style={{ marginBottom: "30px" }} key={n}>
                    <div style={{ margin: "0px", fontSize: "20px", fontWeight: "600", flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "space-between" }} className='content further-sub'>
                        <div>Dependent {n + 1}</div>
                        {n > 0 ? <div style={{ fontSize: "36px", cursor: "pointer" }} onClick={() => removeDependent(n)}><Icon.X /></div> : null}
                    </div>

                    <div className='input_contain'>
                        <div className='input_wrap'>
                            <select value={dependents[n]?.relationship} onChange={e => addToDependents("relationship", e.currentTarget.value, n)} style={{ width: "calc(100% + 2px)", fontSize: "14px", color: dependents[n]?.relationship ? "black" : "grey" }}>
                                <option value="" disabled selected>Relationship</option>
                                <option value="CHILD" style={{ color: "black" }}>Child</option>
                                <option value="PARENT" style={{ color: "black" }}>Parent</option>
                                <option value="SIBLING" style={{ color: "black" }}>Sibling</option>
                                <option value="SPOUSE" style={{ color: "black" }}>Spouse</option>
                                <option value="OTHER" style={{ color: "black" }}>Other</option>
                            </select>
                        </div>
                    </div>

                    {getInput("text", "Name", "name", n)}

                    <div className='further-sub-heading' style={{ margin: "15px 15px -10px", fontWeight: "600" }}>Date of birth</div>
                    {getInput("date", "", "date_of_birth", n)}

                    <div className='further-sub-heading' style={{ margin: "15px 15px -10px", fontWeight: "600" }}>Date dependency ends (if end date exists)</div>
                    {getInput("date", "", "dependency_end_date", n)}
                </div>
            })}

            <div className='further-add' style={{ margin: "15px", cursor: "pointer" }} onClick={() => {
                let arr = [...dependents]
                arr.push({ ...baseDep })
                setDependents(arr)
                setNum(num + 1)
            }}>
                <Icon.Plus /> Add dependent
            </div>

            <button className='capBtn' style={{ margin: "15px" }} onClick={() => {
                props.next()
            }}>
                Next
            </button>
        </div>
    );

    function addToDependents(key, value, item) {
        let arr = [...dependents]
        arr[item] = { ...arr[item], [key]: value }
        setDependents(arr)
    }

    function removeDependent(n) {
        let arr = [...dependents]
        arr.splice(n, 1)
        setDependents(arr)
        setNum(num - 1)
    }

    function getInput(type, placeholder, key, n) {
        let input_style = {
            fontSize: "14px",
            lineHeight: "16px",
            height: "20px"
        }

        return <div className='input_contain'>
            <div className='input_wrap'>
                <input value={dependents[n]?.[key]} style={input_style} type={type} placeholder={placeholder} onChange={e => addToDependents(key, e.currentTarget.value, n)} />
            </div>
        </div>
    }
}

export default Dependents;