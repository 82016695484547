import React, { useEffect, useState } from 'react';
import superagent from "superagent"
import logos from "../logos"
import "./skyscanner.sass"
import convert_name from "./convert_name"
import Loader from '../../loader';

function Skyscanner(props) {
    const commaNumber = require('comma-number')
    const [income, setIncome] = useState(10000)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        superagent
            .get(process.env.REACT_APP_API_URL + "/mbt")
            .then(res => {
                if (res.body.success) {
                    setData(res.body.data)
                    setLoading(false)
                }
            })
            .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if (data) {
            if (income < 200000) console.log(data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message === "Affordable"))
            else console.log(data[toNearest(income, 25000)]?.quote_lenders.filter(e => e.status_message === "Affordable"))
            if(props.setIncome) props.setIncome(income)
        }
    }, [income, data])

    function toNearest(number, nearest) {
        return Math.round(number / nearest) * nearest
    }

    return (
        <div className="skyscanner" style={{ paddingBottom: props.lower ? "115px" : "20px" }}>
            <Loader show={loading} />
            <div className="wrap">
                <span style={{ display: props.remove_head ? "none" : "" }}>
                    <div className="heading">
                        Find out which lenders are affordable.
                    </div>

                    <div className="sub_heading">
                        Enter your annual income and we'll show you the lenders that are affordable.
                    </div>
                </span>

                <div style={{ zIndex: 2, position: "sticky", top: "-1px", backgroundColor: "white", padding: "0px 15px 25px", margin: "0px -15px" }}>
                    <div className="input_wrap">
                        <div style={{ display: "inline-block" }} className="pound_sign">£</div>
                        <input className="input" type="number" placeholder="Income" value={income} onChange={e => setIncome(e.currentTarget.value)}></input>
                    </div>

                    <input type="range" min="10000" max="500000" value={income} className="slider" id="myRange" onChange={e => {
                        let value = e.currentTarget.value
                        if (value >= 10000 && value <= 200000) setIncome(toNearest(value, 1000))
                        else setIncome(toNearest(value, 25000))
                    }} />
                    <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", marginTop: "0px", marginBottom: "-20px" }}>
                        <div style={{ color: "#999", fontSize: "12px" }}>
                            £10,000
                        </div>
                        <div style={{ color: "#999", fontSize: "12px" }}>
                            £500,000
                        </div>
                    </div>
                </div>

                <br />

                {income >= 10000 && income <= 500000 ?
                    income < 200000
                        ?
                        <>
                            {/* <table style={{ display: "inline-block" }}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td>Lender</td>
                                        <td>Affordability</td>
                                        <td>Max loan amount</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message === "Affordable").map(e => {
                                        return <tr>
                                            <td>{logos[convert_name(e.name)]}</td>
                                            <td>{convert_name(e.name)}</td>
                                            <td>{e.status_message}</td>
                                            <td>£{commaNumber(e.lend_amount_max || e.lend_amount)}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table> */}
                            <div className="lender_group">
                                <div style={{ margin: "20px 0px" }}>{data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message === "Affordable").length} lenders are affordable</div>
                                {data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message === "Affordable").map(e => {
                                    return <div className="lender_box">
                                        <div className="lender_logo">{logos[convert_name(e.name)]}</div>
                                        <div className="lender_info">
                                            <div className="lender_name">
                                                {convert_name(e.name)}
                                            </div>
                                            <div className="lender_amount">
                                                £{commaNumber(e.lend_amount_max || e.lend_amount)}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </>
                        :
                        <>
                            {/* <table style={{ display: "inline-block" }}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td>Lender</td>
                                        <td>Affordability</td>
                                        <td>Max loan amount</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data[toNearest(income, 25000)]?.quote_lenders.filter(e => e.status_message === "Affordable").map(e => {
                                        return <tr>
                                            <td>{logos[convert_name(e.name)]}</td>
                                            <td>{convert_name(e.name)}</td>
                                            <td>{e.status_message}</td>
                                            <td>£{commaNumber(e.lend_amount_max || e.lend_amount)}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table> */}
                            <div className="lender_group">
                                <div style={{ margin: "20px 0px" }}>{data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message === "Affordable").length} lenders are affordable</div>
                                {data[toNearest(income, 25000)]?.quote_lenders.filter(e => e.status_message === "Affordable").map(e => {
                                    return <div className="lender_box">
                                        <div className="lender_logo">{logos[convert_name(e.name)]}</div>
                                        <div className="lender_info">
                                            <div className="lender_name">
                                                {convert_name(e.name)}
                                            </div>
                                            <div className="lender_amount">
                                                £{commaNumber(e.lend_amount_max || e.lend_amount)}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </>
                    :
                    income < 10000 ? <div style={{ fontSize: "20px", margin: "20px 0px" }}>Income must be greater than £10,000</div>
                        : income > 500000 ? <div style={{ fontSize: "20px", margin: "20px 0px" }}>Income must be less than £500,000</div> : null}


                {income >= 10000 && income <= 500000 && data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message !== "Affordable").length > 0 ?
                    income < 200000
                        ?
                        <>
                            {/* <table style={{ display: "inline-block" }}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td>Lender</td>
                                        <td>Affordability</td>
                                        <td>Max loan amount</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message === "Affordable").map(e => {
                                        return <tr>
                                            <td>{logos[convert_name(e.name)]}</td>
                                            <td>{convert_name(e.name)}</td>
                                            <td>{e.status_message}</td>
                                            <td>£{commaNumber(e.lend_amount_max || e.lend_amount)}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table> */}
                            <div className="lender_group_bad">
                                <div style={{ margin: "20px 0px" }}>{data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message !== "Affordable").length} lenders are not affordable</div>
                                {data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message !== "Affordable").map(e => {
                                    return <div className="lender_box">
                                        <div className="lender_logo">{logos[convert_name(e.name)]}</div>
                                        <div className="lender_info">
                                            <div className="lender_name">
                                                {convert_name(e.name)}
                                            </div>
                                            <div className="lender_amount">
                                                {e.status_message}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </>
                        :
                        <>
                            {/* <table style={{ display: "inline-block" }}>
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td>Lender</td>
                                        <td>Affordability</td>
                                        <td>Max loan amount</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data[toNearest(income, 25000)]?.quote_lenders.filter(e => e.status_message === "Affordable").map(e => {
                                        return <tr>
                                            <td>{logos[convert_name(e.name)]}</td>
                                            <td>{convert_name(e.name)}</td>
                                            <td>{e.status_message}</td>
                                            <td>£{commaNumber(e.lend_amount_max || e.lend_amount)}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table> */}
                            <div className="lender_group_bad">
                                <div style={{ margin: "20px 0px" }}>{data[toNearest(income, 1000)]?.quote_lenders.filter(e => e.status_message !== "Affordable").length} lenders are not affordable</div>
                                {data[toNearest(income, 25000)]?.quote_lenders.filter(e => e.status_message !== "Affordable").map(e => {
                                    return <div className="lender_box">
                                        <div className="lender_logo">{logos[convert_name(e.name)]}</div>
                                        <div className="lender_info">
                                            <div className="lender_name">
                                                {convert_name(e.name)}
                                            </div>
                                            <div className="lender_amount">
                                                {e.status_message}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </>
                    :
                    null}
            </div>
        </div>
    );
}

export default Skyscanner;