import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function Repossession2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Has " + props.store.first_name_2 + " ever had a repossession?",
                        info: {
                            id: "repossession_modal_2",
                            text: "What is a repossession?",
                            content: "Repossession is the term used to describe the taking back of property after a borrower has defaulted on payments. The lender either repossesses the collateral or pays a third-party service to do so.",
                            content2: "You probably won’t be able to get a mortgage in the first 12 months after a repossession. The further away in time the repossession is, the more lenders will be willing to consider you and the more likely you will be accepted for a mortgage."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 2
                            }
                        }
                    }} id={"had_a_repossession_2"} setValue={(e) => props.setValue({ ...props.store, had_a_repossession_2: e })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When did " + props.store.first_name_2 + "'s repossession occur?",
                        input: {
                            type: "month",
                            id: "repossession_settled_2",
                            min: 7,
                            next: props.switch + 2
                        }
                    }} id={"repossession_settled_2"} setValue={(e) => props.setValue({ ...props.store, repossession_settled_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Repossession2;