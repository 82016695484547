import React from "react"

const logos = {
    "Accord": getImg("accord"),
    "Aldermore": getImg("aldermore"),
    "Barclays": getImg("barclays"),
    "Bath BS": getImg("bath"),
    "Beverley": getImg("beverley"),
    "Bluestone": getImg("bluestone"),
    "Bank of Ireland": getImg("boi"),
    "Buckinghamshire BS": getImg("buckinghamshire"),
    "Chorley": getImg("chorley"),
    "Clydesdale Bank": getImg("clydesdale"),
    "Coventry": getImg("coventry"),
    "Darlington BS": getImg("darlington"),
    "Digital": getImg("digital"),
    "Dudley BS": getImg("dudley"),
    "Foundation": getImg("foundation"),
    "Furness BS": getImg("furness"),
    "Halifax": getImg("halifax"),
    "Hanley Economic": getImg("hanley-economic"),
    "Harpenden BS": getImg("harpenden"),
    "Hinckley and Rugby": getImg("hr"),
    "HSBC": getImg("hsbc"),
    "Ipswich BS": getImg("ipswich"),
    "Kensington": getImg("kensington"),
    "Kent Reliance": getImg("kent-reliance"),
    "Leeds BS": getImg("leeds"),
    "Leek Utd": getImg("leek"),
    "Loughborough": getImg("loughborough"),
    "Mansfield BS": getImg("mansfield"),
    "Market Harborough": getImg("market-harborough"),
    "Marsden BS": getImg("marsden"),
    "Masthaven": getImg("masthaven"),
    "Metro Bank": getImg("metro-bank"),
    "Monmouthshire": getImg("monmouthshire"),
    "The Mortgage Lender": getImg("mortgage-lender"),
    "MS Bank": getImg("ms-bank"),
    "Nationwide": getImg("nationwide"),
    "Nationwide Building Society": getImg("nationwide"),
    "NatWest": getImg("natwest"),
    "Newbury": getImg("newbury"),
    "Newcastle BS": getImg("newcastle"),
    "Newcastle Building Society": getImg("newcastle"),
    "Pepper Money": getImg("pepper-money"),
    "Platform": getImg("platform"),
    "Post Office": getImg("post-office"),
    "Precise": getImg("precise"),
    "Principality": getImg("principaliity"),
    "Saffron BS": getImg("saffron"),
    "Santander": getImg("santander"),
    "Scottish Widows": getImg("scottish-widow"),
    "Scottish Widows Bank": getImg("scottish-widow"),
    "Scottish BS": getImg("scottish"),
    "Skipton": getImg("skipton"),
    "Stafford Railway": getImg("stafford-railway"),
    "Teachers BS": getImg("teachers"),
    "Cambridge BS": getImg("the-cambridge"),
    "Family Building Society": getImg("the-family"),
    "Melton Mowbry": getImg("the-melton"),
    "Nottingham BS": getImg("the-nottingham"),
    "Tipton": getImg("the-tipton"),
    "Together": getImg("together"),
    "TSB": getImg("tsb"),
    "Vernon BS": getImg("vernon"),
    "Vida Homeloan": getImg("vida"),
    "Virgin Money": getImg("virgin-money"),
    "West Brom": getImg("west-brom"),
    "Sprung": getImg("sprung"),
    "Swansea": getImg("swansea"),
    "Swansea Building Society": getImg("swansea"),
}

function getImg(src) {
    return <img className="lender_logo_result" src={require("./logos/SVG/lender-logo-" + src + ".svg")} alt={src} />
}

export default logos