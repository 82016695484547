import React from "react";

class Service extends React.Component {
    render() {
        return (
            <div className="listing-flow">
            <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                <div className="navbar">
                    {/* <button className="back" onClick={this.prevPage} >
						<img className="backArrow" src={require("../img/icon-arrow-back.svg")}></img>
						<div className="backText">
							Back
						</div>
					</button> */}
                    <a href="/"><img className="logo" src={require("../img/logo.svg")} alt='free'></img></a>
                </div>

                <div style={{ textAlign: "left" }}>
                    <div className="pageHeadingPolicy">
                        Service Agreement
                    </div>

                    <div className="pageSub">
                        Introduction
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        This document (referred to as our ‘Service Agreement’) gives you information about the terms upon which we agree to offer and provide you with our Products and Services.
                    <br /><br />
                    To agree to the following Service Agreement, you must be (i) a beneficial owner of the property being sold or have the permission of the beneficial owner to sell the property and (ii) you are not an estate agent operating on behalf of the beneficial owner.  By confirming your acceptance to these terms and conditions, as set out below, you are agreeing to both (i) and (ii) of this paragraph being true.
                    <br /><br />
                    We encourage you to store a copy of this document and refer to it as you use our Products and Services.
                    <br /><br />
                    If it is necessary to change the Services Agreement or add or remove any part at any time, we will post such changes on the Website. It is your responsibility to check the Website for any changes. You agree to be bound by such changes if you should continue to use the Products and Services.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        What do words and phrases mean in these terms?
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        <span style={{ fontWeight: "700" }}>‘Account’</span> means your Account that we operate and you control within the Listing Dashboard. For the avoidance of doubt, this includes the area where you Instruct Free and manage the process of a sale and purchase (including all marketing services).
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Listing’</span> means how your property is to be displayed in advertising and marketing which may include (subject to the nature of the Products and Services) photographs, descriptions, key features and floor plans.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Completion’</span> means the formal legal completion of the sale of your property.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Content’</span> means all of the content, documents, information and matters on or in your Account or contained within the Website and or Listing Dashboard, whether through the Website and/or Listing Dashboard or uploaded manually.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Conveyancing Services’</span> means Juno Property Lawyers Limited or such other firm or firms who are part of a panel of Solicitors or Licensed Conveyancers introduced by us to you.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Instruct’</span> means taking advantage of the Products and Services
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Interruption’</span> means any form of Interruption to the ability of Free to provide or operate the Listings Dashboard, the Systems or Products and Services.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘On the Market’</span> means advertised on www.rightmove.co.uk, www.zoopla.co.uk, other similar property portals and www.free.co.uk.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Listing Dashboard’</span> means the Systems provided to you in accordance with this agreement to manage the Products and Services.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Privacy Policy’</span> means the Privacy Policy as amended from time to time and published on the Website and Listings Dashboard.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Products and Services’</span> means all and each of the products and/or services offered from time to time by Free within the Website and Listings Dashboard.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Systems’</span> means the Website and access to the Listing Dashboard.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Terms of Use’</span> means the terms and conditions for using the Website and Listings Dashboard and published from time to time on the Website.
                    <br /><br />
                        <span style={{ fontWeight: "700" }}>‘Website’</span> means www.free.co.uk including all Systems, software and processes hosted therein.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Creating and Approving your Listing
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        You are responsible whatever the nature of the Products and Services you use to ensure that all descriptions, photographs, floor plans and information uploaded and/or used in any advertising and marketing are accurate, current and are in no way misleading. If any of the information you provide or approve is or is found to be in breach of these terms, it will be removed immediately and you will be subject to the terms and indemnities contained in the Terms of Use and this Agreement.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Photographs
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Free will provide the services of a third-party professional photographer to ensure high quality images of your property. {/*You own the photos that are taken of your property.*/}
                    <br /><br />
                    While we own the photos, we grant you a full license to use them.
                    <br /><br />
                    Should you be unable to use these services, you are able to upload your own photographs.
                    <br /><br />
                    If you wish to add your own images to your listing the photograph must be;
                    <ol>
                            <li>owned by you or you have permission to use it</li>
                            <li>of the Property</li>
                            <li>representative of the Property's current condition.</li>
                        </ol>
                    In order to ensure the best possible service for all of our customers we will examine any photographs that are uploaded by you in advance of them becoming live on the property portals and the Website. This process will be carried out 24 hours a day to ensure that there is little or no delay in them becoming live.
                    <br /><br />
                    We reserve the right to refuse to allow any image to be uploaded, made live or presented on any marketing or property portal that we consider in our absolute discretion to be inappropriate, inaccurate, misleading or likely to cause distress or embarrassment to anyone viewing the image.
                    <br /><br />
                    You may not use your listing with us to gain interest in other properties not listed with us, and you may not upload any corporate or other logos, image or brand identifier to the property description or include the same in any image you submit to us.  We can cancel your agreement with us if we reasonably believe you are doing this.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Energy Performance Certificate (‘EPC’)
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        You must have ordered an EPC prior to our marketing the Property, and you agree to provide us with a copy of the EPC as soon as it is received.  We reserve the right to terminate our Agreement with you if you do not provide us with a valid EPC within 21 days of us beginning to market the Property.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Advertising and Marketing
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        We will advertise and market your property for up to 3 months on such property portals, Websites or publications as we consider to be the most effective at securing interest on your property from potential purchasers in our absolute discretion. We may withdraw or no longer take advantage of the services of such property portals, Websites or publications at our absolute discretion.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Fees
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Except as otherwise stated, we will supply the services to you free of charge for 3 months, subject to the terms of this agreement.
                    <br /><br />
                    Additional Products and Services may incur a fee from third-party providers e.g. conveyancing and EPCs.
                    <br /><br />
                    Subject to the terms set out, if you choose to cancel our agreement at any time, we will remove your property from our Website, portals and other advertising mediums.
                    <br /><br />
                    At the end of your 3-month marketing period your property will be delisted and removed from our Website, portals and other advertising mediums unless we decide, in our sole discretion, to continue to market your property for you free of charge for such a period as we decide.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Your Viewing Availability
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        As you will be conducting your own viewings, you will need to confirm your availability whilst creating your listing. This will enable people who want to view, to choose a date and time to suit them. You will be notified when a viewing is booked and will be required to confirm your acceptance promptly.
                    <br /><br />
                    If you do not respond in a timely fashion, we reserve the right to cancel this viewing.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Viewings
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Subject to Interruption, we will arrange viewing through SMS and your Listings Dashboard. You will be notified immediately a viewing is booked. We will provide you with the name of the person(s) who wish to view together with the date and time.
                    <br /><br />
                    It is your responsibility to ensure that the property is ready and prepared for the viewing and is in a safe condition.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Empty Properties
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        If your property is empty, you shall ensure that you conduct regular security and condition checks from time to time.
                    <br /><br />
                    It is your responsibility to ensure that the property is ready and prepared for viewings and is in a safe condition.
                    <br /><br />
                    Free shall not be responsible for doing so.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Feedback
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        We will request feedback from every person who has viewed or was due to view your property.
                    <br /><br />
                    We will also request feedback from you after every viewing.
                    <br /><br />
                    Buyer feedback for the seller will be immediately available in your Listings Dashboard as soon as it has been provided and will be exactly as provided by the person concerned.
                    <br /><br />
                    We will request additional feedback from buyers and sellers for Free in order to improve our Products and Services. This feedback may not be available in your Listings Dashboard.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Offers
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Subject to Interruption we will report offers to you immediately via the Listings Dashboard. The nature, extent, position of the potential purchaser or tenant and desire to proceed, will be requested and provided to you automatically as the offer is made.
                    <br /><br />
                    We will make such reasonable checks as are possible to ensure the offer made meets our criteria for a sale to proceed.
                    <br /><br />
                    The information will always be made available exactly how it is provided by the potential purchaser through the Listings Dashboard.
                    <br /><br />
                    You will get a choice whether to accept the offer, reject the offer, reject and negotiate. You will get to choose one of these options each time you receive an offer.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Cancellations
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        Should you wish to cancel or withdraw any Products and Services you may do so at any time. You can withdraw your Listing at any time by clicking on the ‘unpublish’ icon on your property page in the Listings Dashboard. In certain circumstances you may be able to re-list the property within a designated period after withdrawal. Details will be made available at the time of seeking to withdraw/relist your Advert.
                    <br /><br />
                    You are entitled to a 14 day cooling off period from the moment you instruct Free.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Provision of personal information
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        For the effective use of the Products and Services we will be providing certain people with personal information such as your name, address, contact details, viewing availability, agreed sale price and position to proceed. Please read the Terms of Use and our Privacy Policy for more information, terms, and conditions. It is your responsibility to provide accurate up to date personal information through the Listings Dashboard.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                        Third party companies
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                        We will introduce to you, the buyer or any prospective buyer (where we deem appropriate and / or necessary) third party services such as mortgages, insurance, and conveyancing but only where we reasonably believe that the services being provided are in the best interests of you, the buyer or any prospective buyer and further that the third party companies are capable and required to, provide an exceptional service.
                    <br /><br />
                    We may receive commission for introducing you but only when you agree to take advantage of such products or services. You may decide of your own free will and we will never pressurise or require you to use such products or services. You hereby expressly agree and accept that in the event that we introduce a product or service from a third party company that we may accept a payment or commission as a result of that introduction.
                    <br /><br />
                    Where requested, we will Instruct on your behalf third parties to conduct some of the services advertised by Free. Whilst we will make all reasonable efforts to ensure that they continue to provide an exceptional service we cannot accept liability for the conduct and service of those third parties acting on your behalf.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                    Availability of the Products
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                    Whilst we will make all reasonable efforts to ensure that the Products and Services are available at all times, Free does not guarantee, represent or warrant that your access to any of the products will be uninterrupted or error-free.
                    <br /><br />
                    We will always make all reasonable efforts to ensure that all Content submitted by you to us will be secure. We cannot guarantee that the Listings Dashboard, Products and Services, the Systems or the Website will be free from loss, corruption, attack, viruses, ‘worms’, ‘trojan horses’ or other harmful components, or from interference, hacking or other security intrusions. You should be sure to back up your own system periodically to ensure that all Content available to you and located within your Account, is available to you in the event of any loss or damage as Free reserves the right to remove or suspend all or any part of the Content, at any time in accordance with these terms.
                    <br /><br />
                    Please read the <a href="/terms-of-use" style={{textDecoration: "underline"}}>Terms of Use</a> for further information.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                    Complaints
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                    We aim for your sale with us to be trouble free, but if you have any problems with our service, you can contact us at <a href="born@free.co.uk" style={{textDecoration: "underline"}}>born@free.co.uk</a>.
                    <br /><br />
                    If you make a complaint, you’ll get a written response from us within 3 working days. This will either be a full response, or we’ll let you know that we’re looking into it and will come back to you within 15 working days.
                    <br /><br />
                    If you are dissatisfied with how we deal with a complaint, you are entitled to refer the matter to The Property Ombudsman within six months of receipt of our final view. 
                    <br /><br />
                    We are members of The Property Ombudsman and abide by The Property Ombudsman Code of Practice.  Please note that The Property Ombudsman will only review complaints made by consumers. You agree that we may disclose information relating to the sale of the Property to The Property Ombudsman if The Property Ombudsman asks us for it.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                    Compliance
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                    We undertake to comply with the terms of the Estate Agents Act 1979 and the Consumer Protection from Unfair Trading Regulations 2008 (“CPR”) where those acts relate to our appointment as your selling agent.
                    <br /><br />
                    Under the CPR it is a criminal offence for a selling agent to make inaccurate or misleading statements about your property (be they written or verbal), including through the sales particulars, listings and other marketing, photographs and floor plans.  This includes anything that might give the wrong impression about a property and includes omitting facts.  This means that:
                    <ul>
                        <li>you are responsible for providing us with accurate information about the property and must tell us immediately if there is any inaccuracy or misleading information in our sales particulars, adverts or any other information that we provide to prospective purchasers and/or their representatives about the property.  We will ask you to verify certain information and require you to assist us to the best of your knowledge, having made reasonable enquiries where necessary</li>
                        <li>you will be responsible for any loss, damage or costs we have to pay in the event that you provide incomplete, incorrect or misleading information</li>
                        <li>you shall inform us immediately of any changes in the information that you provide in respect of the property</li>
                        <li>we reserve the right not to publish any information that you provide.</li>
                    </ul>
                    All information on our Website and provided to potential purchasers and sellers directly cannot be guaranteed by us and does not form part of any contract.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                    Money Laundering
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                    We are subject to the Money Laundering Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017, the Proceeds of Crime Act 2002, Terrorism Act 2000 and Criminal Finances Act 2017 (the “Legislation”).  
                    <ul>
                        <li>we reserve the right to ask you for such information as we require to comply with the Legislation to verify your identity (or the identity of the person/entity that you represent), which must be received before we can proceed with any work on your behalf</li>
                        <li>we reserve the right to terminate our relationship with you if, when requested to provide such information, you fail to do so or we consider that the evidence provided is insufficient to discharge our obligations under the Legislation (or such similar legislation as is in force from time to time)</li>
                        <li>you acknowledge that we may also be required to provide information to the relevant authorities without prior notification or any liability to you if we know or have a reasonable reason to suspect that you (or the person/entity that you represent) are involved in money laundering or terrorist financing.</li>
                    </ul>
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                    Exclusion and Limitation of Liability
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                    Nothing in these terms and conditions shall limit or exclude our liability for death or personal injury caused by our negligence (or the negligence of our employees or agents) or fraud or fraudulent misrepresentation or for any other liability that cannot be excluded or limited by English Law.  Notwithstanding this, we shall not be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, or any indirect or consequential loss arising under or in connection with any agreement between us; 
                    <br/><br/>
                    Use of our Website shall be at your sole risk and we will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
                    <ul>
                        <li>use of or reliance on any Content displayed on our Website; or</li>
                        <li>use of, or inability to use, our Website; or</li>
                        <li>any inaccuracies contained on our Website relating to your Property or otherwise.</li>
                    </ul>
                    We are also not responsible for any computer viruses or any other harmful technology issues you experience that result in loss or damage while using our Website.  Use of our Website is subject to our Privacy Policy, and Terms of Use Policy which are all available to view on our Website. 
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                    Entire Agreement
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                    This entire agreement & any documents referred within it are the entire agreement between us and you.
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageSub">
                    Law and Jurisdiction
                    </div>
                    <div className="pageContent" style={{ textAlign: "left" }}>
                    Subject to English law and jurisdiction
                    </div>
                    <hr className="policyDivider" />

                    <div className="pageContent">
                        Free free free Ltd
                        <br/>
                        38B Enmore Road
                        <br/>
                        London
                        <br/>
                        SE25 5NG
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default Service;