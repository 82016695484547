import additionalRules from "./additionalRules";
import lenders from "./lenders.json";
import foreign_rules from "./foreign_national.json"

function FetchLenders(input) {
    let arr = [];
    let list = Object.keys(lenders);
    let res = false
    let info = {}
    let lenderInfo = {}
    for (let i = 0; i < list.length; i++) {
        [res, lenderInfo] = compareLender(list[i], input)
        info[list[i]] = lenderInfo
        if (res) {
            arr.push(list[i]);
        };
    }
    return [arr, info];
}

function compareLender(lender, input) {
    input = [...input.filter(e => e["Non UK"]), ...input.filter(e => !e["Non UK"])]
    let leading = ["Accord", "Bank of Ireland", "Clydesdale Bank", "Coventry", "Digital", "HSBC", "Leeds BS", "Metro Bank", "Nationwide", "NatWest", "Nottingham BS", "Platform", "Santander", "Skipton", "TSB", "Virgin Money", "Dudley BS"]

    let ltv90 = ["Scottish Widows", "Kent Reliance", "Tipton", "Teachers BS", "Scottish BS", "Platform", "Aldermore", "Bank of Ireland", "Barclays", "Beverley", "Chorley", "Darlington", "Furness BS", "Halifax", "HSBC", "Ipswich BS", "Monmouthshire", "Natwest", "Newbury"]
    let ltv85 = ["Vida Homeloan", "The Mortgage Lender", "Bluestone", "Clydesdale Bank", "Marsden BS", "Pepper Money", "Precise", "Santander"]
    let ltv80 = ["Buckinghamshire BS", "Foundation", "Harpended BS", "Leek Utd", "Masthaven", "Principality", "Saffron BS"]
    let ltv75 = ["Stafford Railway", "Family Building Society"]
    let ltv70 = ["Bath BS", "Melton Mowbry", "Together"]

    let ltv60 = []

    let lenderInfo = { ...require("./lenders.json")[lender] };
    let suggested = true;
    if ((input.length === 1 && input[0].LTV) || input.length === 0) {
        if (ltv90.includes(lender)) lenderInfo.LTV = returnLTV("90%")
        else if (ltv85.includes(lender)) lenderInfo.LTV = returnLTV("85%")
        else if (ltv80.includes(lender)) lenderInfo.LTV = returnLTV("80%")
        else if (ltv75.includes(lender)) lenderInfo.LTV = returnLTV("75%")
        else if (ltv70.includes(lender)) lenderInfo.LTV = returnLTV("70%")
        else if (ltv60.includes(lender)) lenderInfo.LTV = returnLTV("60%")
        else lenderInfo.LTV = returnLTV("95%")
    }

    if (lender === "Darlington BS") lenderInfo.LTV = returnLTV("80%")
    if (lender === "Ipswich BS") lenderInfo.LTV = returnLTV("90%")
    if (lender === "Hinckley and Rugby") lenderInfo.LTV = returnLTV("70%")
    if (lender === "Leek Utd") lenderInfo.LTV = returnLTV("85%")

    for (let i = 0; i < input.length; i++) {
        let comp = Object.entries(input[i])[0];
        if (comp[0] === "Non UK" && !(comp[1].years === "5_OR_MORE" && comp[1].eu === "EU")) {
            let rules = foreign_rules[comp[1].eu][lender]
            if (rules) {
                if (rules.length > 0) {
                    let match = false
                    for (let i = 0, size = rules.length; i < size; i++) {
                        if (!match) {
                            if (comp[1].years >= rules[i].Min_UK) {
                                if (comp[1].visa) {
                                    if (comp[1].visa >= rules[i].Min_Visa) {
                                        match = true
                                        lenderInfo.LTV = returnLTV(rules[i].LTV)
                                    } else {
                                        if (i === rules.length - 1) {
                                            suggested = false
                                        }
                                    }
                                } else {
                                    match = true
                                    lenderInfo.LTV = returnLTV(rules[i].LTV)
                                }
                            } else {
                                if (i === rules.length - 1) {
                                    suggested = false
                                }
                            }
                        }
                    }
                } else {
                    if (comp[1].years >= rules.Min_UK) {
                        if (comp[1].visa) {
                            if (comp[1].visa >= rules.Min_Visa) {
                                lenderInfo.LTV = returnLTV(rules.LTV)
                            } else {
                                suggested = false
                            }
                        } else {
                            lenderInfo.LTV = returnLTV(rules.LTV)
                        }
                    } else {
                        suggested = false
                    }
                }
            } else {
                suggested = false
            }
        } else {
            if (comp[0] !== "Non UK") {
                if (comp[0] !== "LTV" && leading.includes(lender)) {
                    lenderInfo.LTV["85%"] = false
                    lenderInfo.LTV["90%"] = false
                    lenderInfo.LTV["95%"] = false
                }
                lenderInfo = additionalRules(lender, lenderInfo, comp[0], comp[1])
                if (!lenderInfo[comp[0]][comp[1]]) {
                    suggested = false;
                };
            }
        }
    }

    return [suggested, lenderInfo];
}

function returnLTV(newLTV) {
    if (newLTV === "60%") {
        return {
            "70%": false,
            "75%": false,
            "80%": false,
            "85%": false,
            "90%": false,
            "95%": false
        }
    } else if (newLTV === "65%") {
        return {
            "70%": false,
            "75%": false,
            "80%": false,
            "85%": false,
            "90%": false,
            "95%": false
        }
    } else if (newLTV === "70%") {
        return {
            "70%": true,
            "75%": false,
            "80%": false,
            "85%": false,
            "90%": false,
            "95%": false
        }
    } else if (newLTV === "75%") {
        return {
            "70%": true,
            "75%": true,
            "80%": false,
            "85%": false,
            "90%": false,
            "95%": false
        }
    } else if (newLTV === "80%") {
        return {
            "70%": true,
            "75%": true,
            "80%": true,
            "85%": false,
            "90%": false,
            "95%": false
        }
    } else if (newLTV === "85%") {
        return {
            "70%": true,
            "75%": true,
            "80%": true,
            "85%": true,
            "90%": false,
            "95%": false
        }
    } else if (newLTV === "90%") {
        return {
            "70%": true,
            "75%": true,
            "80%": true,
            "85%": true,
            "90%": true,
            "95%": false
        }
    } else if (newLTV === "95%") {
        return {
            "70%": true,
            "75%": true,
            "80%": true,
            "85%": true,
            "90%": true,
            "95%": true
        }
    }
}

export default FetchLenders;