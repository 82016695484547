import React from 'react';

function Gender(props) {
    return (
        <div>
            <div className='heading'>
                How do you identify?
            </div>
            <div className='content'>
                Please let us know the title and gender you identify with, and your marital status.
            </div>

            <div className='input_contain'>
                <div className='input_wrap'>
                    <select onChange={e => props.add("title", e.currentTarget.value)} style={{ width: "100%", color: props.store.title ? "black" : "grey" }}>
                        <option value="" disabled selected>Title</option>
                        {getOption("Mr")}
                        {getOption("Mrs")}
                        {getOption("Miss")}
                        {getOption("Ms")}
                        {getOption("Mx")}
                        {getOption("Dr")}
                        {getOption("Sir")}
                        {getOption("Prof")}
                        {getOption("Lord")}
                        {getOption("Lady")}
                        {getOption("Dame")}
                        {getOption("Prefer not to say")}
                    </select>
                    <div className='required'>*</div>
                </div>
            </div>

            <div className='input_contain'>
                <div className='input_wrap'>
                    <select onChange={e => props.add("gender", e.currentTarget.value)} style={{ width: "100%", color: props.store.gender ? "black" : "grey" }}>
                        <option value="" disabled selected>Gender</option>
                        {getOption("Male")}
                        {getOption("Female")}
                        {getOption("None")}
                        {getOption("Other")}
                        {getOption("Prefer not to say")}
                    </select>
                    <div className='required'>*</div>
                </div>
            </div>

            <div className='input_contain'>
                <div className='input_wrap'>
                    <select onChange={e => props.add("marital_status", e.currentTarget.value)} style={{ width: "100%", color: props.store.marital_status ? "black" : "grey" }}>
                        <option value="" disabled selected>Marital status</option>
                        {getOption("Married")}
                        {getOption("Single")}
                        {getOption("Divorced")}
                        {getOption("Separated")}
                        {getOption("Widowed")}
                        {getOption("Cohabit")}
                        {getOption("Civil partnership")}
                    </select>
                    <div className='required'>*</div>
                </div>
            </div>

            <button disabled={!checkReq()} className='capBtn' style={{ margin: "15px" }} onClick={() => props.setPage(props.page + 1)}>
                Next
            </button>
        </div>
    );

    function checkReq() {
        if(props.store.title && props.store.gender && props.store.marital_status) return true
        else return false
    }

    function getOption(title) {
        return <option style={{ color: "black" }} value={title.toUpperCase().replace(/ /g, "_")}>{title}</option>
    }
}

export default Gender;