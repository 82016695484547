import React, { useEffect, useState } from 'react';
import * as Icon from "react-bootstrap-icons"
import PhoneInput from './phone_input';
import axios from "axios"
import auth from './auth_functions';
import Loader from '../../../loader';
import { includes } from 'lodash';
import Checkbox from './checkbox';
import format_data from '../format_data';

function AuthModal(props) {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [countryCode, setCountryCode] = useState("+44")
    const [id, setId] = useState("")
    const [page, setPage] = useState(props.ask_name ? 0 : 1)
    const [code, setCode] = useState("")
    const [token, setToken] = useState("")
    const [firstName, setFirstName] = useState(props.store.first_name || "")
    const [lastName, setLastName] = useState(props.store.last_name || "")
    const [state, setState] = useState(false)
    const [loading, setLoading] = useState(true)
    const [SSO, setSSO] = useState(false)
    const [callPermission, setCallPermission] = useState(props.store.permission === false ? false : true)

    useEffect(() => {
        if (props.id ? document.getElementById(props.id) : document.getElementById("auth_modal")) {
            if (document.getElementById(props.id ? props.id : "auth_modal").style.display !== "none" && !SSO) {
                setSSO(true)
                SingleSignOn()
            }
        }
    }, [props.show])

    async function SingleSignOn() {
        try {
            let token = localStorage.getItem("token")
            if (!token) throw "No token"
            let res = await auth.get_current(token)
            if (res.data.success) {
                // let resp = await auth.get_experian(token, res.data.user.id)
                // console.log(resp)
                setFirstName(res.data.user.name.split(" ")[0])
                setLastName(res.data.user.name.split(" ")[1])
                setId(res.data.user.id)
                setCountryCode(res.data.user.phone_number.split("(0)")[0])
                setPhoneNumber(res.data.user.phone_number.split("(0)")[1])
                setToken(res.data.user.token)
            }
            else throw "Current user not found"
        } catch (err) {
            console.log(err)
            try {
                localStorage.removeItem("token")
            } catch (err) { }
            setLoading(false)
        }
    }

    useEffect(() => {
        try {
            if (token) {
                localStorage.setItem("token", token)
            }
        } catch (err) {
            console.log(err)
        }

        if (token && props.save_mortgage && props.show) {
            saveData()
        } else if (token && props.return_data && props.show) {
            getData()
        } else if (token && props.thankyou && props.show) {
            setLoading(false)
            setPage(3)
        }
    }, [token])

    async function request_code() {
        setLoading(true)
        let res = await auth.request_code(countryCode, phoneNumber, props.originator)
        console.log(res)
        if (res.data.success) {
            setId(res.data.id)
            setPage(2)
            setLoading(false)
        }
    }

    async function verify_code() {
        setLoading(true)
        let res = await auth.verify_code(id, code)
        if (res.data.success) {
            signup()
        } else {
            console.log("Wrong code")
        }
    }

    async function signup() {
        let res = await auth.signup(firstName, lastName, countryCode, phoneNumber, callPermission)
        if (res.data.success) {
            setState(res.new)
            setToken(res.data.user.token)
            setCallPermission(res.data.user.permission)
            if (!props.save_mortgage && !props.return_data) {
                setPage(3)
                setLoading(false)
            }
        }
    }

    async function saveData() {
        if (props.save_acre && !props.store?.saved_to_acre) {
            let [success, id, id2] = await saveAcre()
            if (success) {
                let [case_success, case_id] = await createCase(id, id2)
                if (case_success) {
                    let [affordability_success, affordability_id] = await setupAffordability(case_id)
                    await auth.saveData(countryCode, phoneNumber, { ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber, saved_to_acre: true, acre_id: id, case_created: true, case_id: case_id, affordability_id }, token)
                    setPage(3)
                    setLoading(false)
                    props.setValue({ ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber, saved_to_acre: true, acre_id: id, acre_id_2: id2, case_created: true, case_id, affordability_id })
                } else console.log("Case not successful")
            }
        } else if (props.save_acre && props.store?.saved_to_acre) {
            let update_acre = await updateAcre(props.store?.acre_id)
            let save = { ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber }
            let success, case_id, aff_success, aff_id
            if (!props.store?.case_created) [success, case_id] = await createCase(props.store?.acre_id)
            if (!props.store?.affordability_id) [aff_success, aff_id] = await setupAffordability(props.store?.case_id || case_id)
            if (success) save = { ...save, case_created: true, case_id: case_id }
            if (aff_success) save = { ...save, affordability_id: aff_id }
            if (update_acre) {
                if (case_id) await auth.saveData(countryCode, phoneNumber, { ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber, case_created: true, case_id: case_id, affordability_id: aff_id }, token)
                setPage(3)
                setLoading(false)
                props.setValue({ ...save })
            }
        } else if (props.save_mortgage) {
            let res = await auth.saveData(countryCode, phoneNumber, { ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber }, token)
            if (res.data.success) {
                setPage(3)
                setLoading(false)
                props.setValue({ ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber })
            }
        }

        // let res = await auth.saveData(countryCode, phoneNumber, { ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber }, token)
        // if (res.data.success && props.save_mortgage) {
        //     if (props.save_acre && !res.data.data?.saved_to_acre) {
        //         let [success, id] = await saveAcre()
        //         if (success) {
        //             let [case_success, case_id] = await createCase(id)
        //             if (case_success) {
        //                 await auth.saveData(countryCode, phoneNumber, { ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber, saved_to_acre: true, acre_id: id, case_created: true, case_id: case_id }, token)
        //                 setPage(3)
        //                 setLoading(false)
        //                 props.setValue({ ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber, saved_to_acre: true, acre_id: id })
        //             } else console.log("Case not successful")
        //         }
        //     } else if (props.save_acre && res.data.data.saved_to_acre) {
        //         let update_acre = await updateAcre(res.data.data.acre_id)
        //         let save = { ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber }
        //         let success, case_id
        //         if (!res.data.data.case_created) [success, case_id] = await createCase(res.data.data.acre_id)
        //         if (success) save = { ...props.store, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber, case_created: true, case_id: case_id }
        //         if (update_acre) {
        //             setPage(3)
        //             setLoading(false)
        //             props.setValue({...save})
        //         }
        //     }
        // }
    }

    async function saveAcre() {
        if (props.store.number_of_applicants === 1) {
            try {
                console.log("Saving to acre")
                let res = await auth.saveAcre({ ...props.store, phone_number: countryCode + phoneNumber })
                return [true, res.data.data.client_id, undefined]
            } catch (err) {
                return [false, null, undefined]
            }
        } else if (props.store.number_of_applicants === 2) {
            try {
                console.log("Saving first applicant to acre")
                let res = await auth.saveAcre({ ...props.store, phone_number: countryCode + phoneNumber })
                console.log("Saving second applicant to acre")
                let res2 = await auth.saveAcre2({ ...props.store, phone_number: countryCode + phoneNumber })
                return [true, res.data.data.client_id, res2.data.data.client_id]
            } catch (err) {
                return [false, null, null]
            }
        }
    }

    async function updateAcre(id) {
        console.log("Updating acre")
        try {
            await auth.updateAcre({ ...props.store, phone_number: countryCode + phoneNumber, id })
            return true
        } catch (err) {
            return false
        }
    }

    async function createCase(acre_id, acre_id_2) {
        let data = {
            clients: acre_id_2 ? [acre_id, acre_id_2] : [acre_id],
            preference_mortgage_reason: props.store.preference_mortgage_reason,
            preference_term: props.store.preference_term,
            has_previously_owned_property: props.store.has_previously_owned_property,
            preference_target_deposit: props.store.deposit,
            preference_loan_amount: parseInt(Math.round(props.store.deposit * 100 / parseInt(props.store.deposit_percent)) - props.store.deposit),
            preference_target_property_value: parseInt(Math.round(props.store.deposit * 100 / parseInt(props.store.deposit_percent)))
        }

        return axios.post(process.env.REACT_APP_API_URL + "/acre/case", { ...data }).then(async res => {
            console.log(res)
            return [true, res.data.data.case.case_id]
        }).catch(err => {
            return [false, err]
        })
    }

    async function setupAffordability(case_id) {
        console.log("Acre affordability setup")
        try {
            let res = await auth.setupAffordability(case_id)
            console.log(res)
            return [true, res.data.data.id]
        } catch (err) {
            return [false, null]
        }
    }

    async function getAffordability(affordability_id) {

    }

    async function getData() {
        let res = await auth.getData(countryCode, phoneNumber, token)
        console.log(res)
        if (res.data.success) {
            props.setValue({ ...res.data.data, permission: callPermission, phone_number: countryCode + "(0)" + phoneNumber })
            try {
                sessionStorage.setItem("store", JSON.stringify(res.data.data.page === 114 || res.data.data.page === 116 ? { ...res.data.data, permission: callPermission, page: 116, prev: res.data.data.prev.includes(115) || res.data.data.prev.includes(116) ? res.data.data.prev : [...res.data.data.prev, 115, 116], phone_number: countryCode + "(0)" + phoneNumber } : { ...res.data.data, phone_number: countryCode + "(0)" + phoneNumber }))
            } catch (err) {

            }
            props.setPage(res.data.data.page === 114 ? 116 : res.data.data.page)
            props.setPrev(res.data.data.page === 114 || res.data.data.page === 116 ? [...res.data.data.prev, 115, 116] : res.data.data.prev)
            setLoading(false)
        } else {
            props.setPage(1)
            setLoading(false)
        }
    }

    return (
        <div className="modal" id={props.id || "auth_modal"} style={{ display: props.show ? "block" : "none", zIndex: props.zIndex || "" }}>
            <Loader show={loading} />
            {page === 0 ?
                <div className="modalContent">
                    <div className="modalX" onClick={() => {
                        // if (props.same_page) document.getElementById("auth_modal").style.display = "none"
                        if (props.same_page) props.setShow(false)
                        else {
                            props.setPage(props.prev[props.prev.length - 2])
                            props.setPrev(props.prev.slice(0, props.prev.length - 1))
                        }
                    }}><Icon.X /></div>
                    <div className="heading">
                        Save your details
                    </div>
                    <div className="content">
                        Create a free account to save your progress so far.
                    </div>
                    <input className="name_input" type="text" placeholder="First name" onChange={e => setFirstName(e.currentTarget.value)} />
                    <br />
                    <input className="name_input" type="text" placeholder="Last name" onChange={e => setLastName(e.currentTarget.value)} />
                    <div style={{ textAlign: "left", marginLeft: "15px" }}>
                        <button onClick={() => setPage(1)} style={{ padding: "10px 40px" }} className="capBtn">
                            Next
                        </button>
                    </div>
                    <div className="content">
                        Already have an account?
                    </div>
                    <div onClick={() => setPage(1)} className="content" style={{ textDecoration: "underline", marginTop: "-15px", cursor: "pointer" }}>
                        Login
                    </div>
                </div>
                :
                page === 1 ?
                    <div className="modalContent">
                        <div className="modalX" onClick={() => {
                            if (props.same_page) props.setShow(false)
                            else {
                                props.setPage(props.prev[props.prev.length - 2])
                                props.setPrev(props.prev.slice(0, props.prev.length - 1))
                            }
                        }}><Icon.X /></div>
                        <div className="heading">
                            Your mobile number
                        </div>
                        <div className="content">
                            We'll send you a 6-digit security code to verify your account.
                        </div>
                        <PhoneInput cc={e => setCountryCode(e)} number={e => setPhoneNumber(e)} countryCode={countryCode} phoneNumber={phoneNumber} />

                        <div style={{ textAlign: "left", margin: "15px 0px -5px 15px" }}>
                            <Checkbox checked={callPermission} onClick={() => setCallPermission(!callPermission)} />
                            <label className="label">I would like you to call me to discuss my mortgage options.</label>
                        </div>

                        <div style={{ textAlign: "left", marginLeft: "15px" }}>
                            <button onClick={() => request_code()} style={{ padding: "10px 40px" }} className="capBtn">
                                Next
                            </button>
                        </div>
                    </div>
                    :
                    page === 2 ?
                        <div className="modalContent">
                            <div className="modalX" onClick={() => {
                                if (props.same_page) props.setShow(false)
                                else {
                                    props.setPage(props.prev[props.prev.length - 2])
                                    props.setPrev(props.prev.slice(0, props.prev.length - 1))
                                }
                            }}><Icon.X /></div>
                            <div className="heading">
                                Please enter your 6-digit security code
                            </div>
                            <div className="content">
                                Sent via SMS to {countryCode} (0){phoneNumber}.
                            </div>
                            <div onClick={() => request_code()} className="content" style={{ textDecoration: "underline", marginTop: "-15px", cursor: "pointer" }}>
                                Resend
                            </div>
                            <input value={code} onChange={e => setCode(e.currentTarget.value)} type="number" placeholder="Enter your code" style={{ width: "calc(100% - 51px)" }} />
                            <div style={{ textAlign: "left", marginLeft: "15px" }}>
                                <button disabled={code.length === 6 ? "" : "disabled"} onClick={() => verify_code()} style={{ padding: "10px 40px" }} className="capBtn">
                                    Next
                                </button>
                            </div>
                            <div className="content">
                                Wrong number?
                            </div>
                            <div onClick={() => setPage(1)} className="content" style={{ textDecoration: "underline", marginTop: "-15px", cursor: "pointer" }}>
                                Go back
                            </div>
                        </div>
                        :
                        page === 3 ?
                            <div className="modalContent">
                                <div className="modalX" onClick={() => props.setShow(false)}><Icon.X /></div>
                                <div className="heading">
                                    Successfully {state ? "created an account" : "logged in"}
                                </div>
                                <div className="content">
                                    Next time you come here you can sign in and see your results.
                                </div>
                                <div style={{ textAlign: "left", marginLeft: "15px" }}>
                                    <button className="white" onClick={() => document.getElementById("auth_modal").style.display = "none"}>
                                        Got it
                                    </button>
                                </div>
                            </div>
                            : null}
        </div>
    );
}

export default AuthModal;