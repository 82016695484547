import React from 'react';

function CommittedExpenditure(props) {
    return (
        <div>
            <div className='heading'>
                Your committed expenditure
            </div>
            <div className='content'>
                These figures don't need to be 100% accurate, a close estimate will suffice.
            </div>

            {getInput("number", "Other mortgage / rent", "mortgage_rent")}
            {getInput("number", "Spousal / child maintenance", "maintenance_payments")}
            {getInput("number", "Fuel", "fuel")}
            {getInput("number", "Water", "water")}
            {getInput("number", "Communications", "communications")}
            {getInput("number", "Television", "television_license")}
            {getInput("number", "Council tax", "council_tax")}
            {getInput("number", "Car costs", "car_costs")}
            {getInput("number", "Other transport costs", "other_transport_costs")}
            {getInput("number", "Service charge / ground rent", "ground_rent_service_charge_shared_equity_rent")}

            <button className='capBtn' style={{ margin: "15px" }} onClick={() => props.next()}>
                Next
            </button>
        </div>
    );

    function getInput(type, placeholder, key) {
        let input_style = {
            fontSize: "14px",
            lineHeight: "16px",
            height: "20px",
            paddingLeft: "25px"
        }

        return <>
            <div className='further-sub-heading' style={{ margin: "15px 15px -10px", fontWeight: "600" }}>{placeholder}</div>
            <div className='input_contain'>
                <div className='input_wrap'>
                    <div style={{fontSize: "18px"}} className='pound_sign'>£</div>
                    <input value={props.store?.["outgoings_" + key] / 100 || ""} style={input_style} type={type} placeholder={0} onChange={e => props.add("outgoings_" + key, parseInt(e.currentTarget.value) * 100 || "")} />
                </div>
            </div>
        </>
    }
}

export default CommittedExpenditure;