import React from 'react';

function MiloBenefits(props) {
    return (
        <div className='milo_benefits milo_section'>
            <div className='header'>
                Mortgage Benefits
            </div>

            <div className='box'>
                <div className='column'>
                    <div className='block'>
                        <div className='heading'>
                            Simple, 100% online process
                        </div>
                        <div className='body'>
                            Access your account and submit items anytime to keep things moving forward.
                        </div>
                    </div>

                    <div className='block'>
                        <div className='heading'>
                            Great rates and terms
                        </div>
                        <div className='body'>
                            Get a 30 year fixed rate mortgage up to 75% of the property value for a non-US consumer.
                        </div>
                    </div>

                    <div className='block'>
                        <div className='heading'>
                            A global specialized team
                        </div>
                        <div className='body'>
                            Our team understands your diverse needs and is ready to help to make your dreams a reality.
                        </div>
                    </div>
                </div>
                <div className='column'>
                    <div className='block'>
                        <div className='heading'>
                            Online digital closing
                        </div>
                        <div className='body'>
                            Close your loan online and remotely with no need to travel to the US or to an embassy.
                        </div>
                    </div>

                    <div className='block'>
                        <div className='heading'>
                            Direct lender
                        </div>
                        <div className='body'>
                            We are not a broker and lend our own capital to stand behind our commitment to close your loan on time.
                        </div>
                    </div>

                    <div className='block'>
                        <div className='heading'>
                            Transparent fees
                        </div>
                        <div className='body'>
                            Loan fees are always provided to you early in the process and to give you confidence to move forward.
                        </div>
                    </div>
                </div>

                <button className='apply_btn'>
                    Apply
                </button>
            </div>
        </div>
    );
}

export default MiloBenefits;