import axios from "axios"
import * as FullStory from '@fullstory/browser';

async function get_current(token) {
    return await axios.post(process.env.REACT_APP_API_URL + "/auth/current", {
        token: token
    }, {
        headers: { "x-access-token": token }
    }).then(response => {
        return response
    }).catch(err => { return err })
}

async function get_experian(token, id) {
    return await axios.post(process.env.REACT_APP_API_URL + "/get_credit", {
        user: id
    }, {
        headers: { "x-access-token": token }
    }).then(response => {
        return response
    }).catch(err => { return err })
}

async function request_code(countryCode, phoneNumber, originator) {
    return await axios.post(process.env.REACT_APP_API_URL + "/verify/send_code", {
        phone_number: countryCode.slice(1) + phoneNumber,
        originator
    }).then(response => {
        return response
    }).catch(err => { return err })
}

async function verify_code(id, code) {
    return await axios.post(process.env.REACT_APP_API_URL + "/verify/verify_code", {
        id: id,
        token: code
    }).then(res => {
        console.log(res)
        return res
    }).catch(err => console.log(err))
}

async function signup(first_name, last_name, countryCode, phoneNumber, callPermission) {
    return await axios.post(process.env.REACT_APP_API_URL + "/auth/signup", {
        name: first_name + " " + last_name,
        phone_number: countryCode + "(0)" + phoneNumber,
        permission: callPermission
    }).then(async res => {
        let response = await signin(countryCode, phoneNumber, callPermission)
        if (res.data.success && response.data.success) {
            response.new = true
            await sendNotification("New Customer", {
                name: first_name + " " + last_name,
                number: countryCode + "(0)" + phoneNumber,
                permission: callPermission
            })
            await sendSMS("new", countryCode + phoneNumber, {})
        }
        return response
    }).catch(err => {
        return err
    })
}

async function signin(countryCode, phoneNumber, callPermission) {
    return await axios.post(process.env.REACT_APP_API_URL + "/auth/signin", {
        phone_number: countryCode + "(0)" + phoneNumber,
        permission: callPermission
    }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}

async function saveData(countryCode, phoneNumber, data, token) {
    return await axios.post(process.env.REACT_APP_API_URL + "/mortgage/save", {
        phone_number: countryCode + "(0)" + phoneNumber,
        data: data
    }, { headers: { "x-access-token": token } }).then(async res => {
        if (res.data.requires_update) {
            return await updateData(res.data.id, data, token, countryCode, phoneNumber)
        }
        else if (res.data.success) {
            sendNotification("Mortgage Form", {
                name: data.first_name + " " + data.last_name,
                number: countryCode + "(0)" + phoneNumber,
                id: countryCode.slice(1) + "/" + phoneNumber,
                permission: data.permission
            })
            return res
        }
    }).catch(err => {
        return err
    })
}

async function saveAcre(data, token) {
    return await axios.post(process.env.REACT_APP_API_URL + "/acre/client", { ...data }).then(async res => {
        console.log(res)
        return res
    }).catch(err => {
        return err
    })
}

async function saveAcre2(data, token) {
    return await axios.post(process.env.REACT_APP_API_URL + "/acre/client/second", { ...data }).then(async res => {
        console.log(res)
        return res
    }).catch(err => {
        return err
    })
}

async function updateAcre(data) {
    return await axios.patch(process.env.REACT_APP_API_URL + "/acre/client", { ...data }).then(async res => {
        console.log(res)
        return res
    }).catch(err => {
        return err
    })
}

async function setupAffordability(case_id) {
    return await axios.post(process.env.REACT_APP_API_URL + "/acre/affordability", { case: case_id }).then(async res => {
        return res
    }).catch(err => {
        return err
    })
}

async function returnAffordability(affordability_id) {
    return await axios.post(process.env.REACT_APP_API_URL + "/acre/affordability", { affordability_id }).then(async res => {
        return res
    }).catch(err => {
        return err
    })
}

async function updateData(id, data, token, countryCode, phoneNumber) {
    return await axios.post(process.env.REACT_APP_API_URL + "/mortgage/update", {
        id: id,
        data: data
    }, { headers: { "x-access-token": token } }).then(res => {
        sendNotification("Mortgage Form", {
            name: data.first_name + " " + data.last_name,
            number: countryCode + "(0)" + phoneNumber,
            id: countryCode.slice(1) + "/" + phoneNumber,
            permission: data.permission
        })
        return res
    }).catch(err => {
        return err
    })
}

async function getData(countryCode, phoneNumber, token) {
    return await axios.post(process.env.REACT_APP_API_URL + "/mortgage/get", {
        phone_number: countryCode + "(0)" + phoneNumber
    }, { headers: { "x-access-token": token } }).then(res => {
        return res
    }).catch(err => {
        return err
    })
}

async function sendNotification(type, details) {
    // window['_fs_ready'] = function () {
    axios.post(process.env.REACT_APP_API_URL + "/notify", {
        type: type,
        details: details,
        url: FullStory.getCurrentSessionURL()
    }).then(res => {
        return true
    }).catch(err => console.log(err))
    // }
}

async function sendSMS(type, number, details) {
    axios.post(process.env.REACT_APP_API_URL + "/send_sms", {
        type,
        number,
        details
    }).then(res => {
        return true
    }).catch(err => console.log(err))
}

let auth = {
    get_current,
    get_experian,
    request_code,
    verify_code,
    signup,
    signin,
    saveData,
    saveAcre,
    saveAcre2,
    updateAcre,
    setupAffordability,
    returnAffordability,
    updateData,
    getData,
    sendNotification
}

export default auth