import React, { useEffect, useState } from 'react';
import * as Icon from "react-bootstrap-icons"
let validator = require("email-validator");

function Communications2(props) {
    const [coms, setComs] = useState({
        phone: true,
        email: true,
        sms: true,
        mail: true
    })

    // useEffect(() => {
    //     props.add("communications", coms)
    // }, [coms])

    return (
        <div>
            <div className="applicantReference">
                {props.name}'s details
            </div>
            <div className='heading'>
                What is {props.name}'s email address?
            </div>

            {getInput("text", "Email address", "contact_details_email_2")}

            {/* <div className='heading' style={{ marginTop: "35px" }}>
                Are you ok to be contacted via the following channels?
            </div>

            {getCheckbox("phone", "Phone")}
            {getCheckbox("email", "Email")}
            {getCheckbox("sms", "SMS")}
            {getCheckbox("mail", "Mail")} */}

            <button disabled={!props.store.contact_details_email_2 || !validator.validate(props.store.contact_details_email_2)} className='capBtn' style={{ margin: "15px" }} onClick={() => props.next()}>
                Next
            </button>
        </div>
    );

    function getInput(type, placeholder, key) {
        return <div className='input_contain'>
            <div className='input_wrap'>
                <input type={type} value={props.store?.[key]} placeholder={placeholder} onChange={e => props.add(key, e.currentTarget.value)} />
                {/* <div className='required'>*</div> */}
            </div>
        </div>
    }

    function getCheckbox(key, placeholder) {
        return <div style={{ marginTop: "15px" }} onClick={() => setComs({ ...coms, [key]: coms[key] ? false : true })}>
            <div style={{ border: "1px solid black", width: "20px", height: "20px", margin: "0px 15px", display: "inline-block", verticalAlign: "middle", backgroundColor: coms[key] ? "black" : "white" }}>
                <div style={{ fontSize: "19px", marginTop: "0px", marginLeft: "0px", color: "white" }}><Icon.Check2 /></div>
            </div>
            <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                {placeholder}
            </div>
        </div>
    }
}

export default Communications2;