import React, { Component } from 'react';
import "./accordion.sass";

class Accordion extends Component {
    render() {
        return (
            <div className="accordion" style={{ maxHeight: this.props.active ? "" : "0px", padding: this.props.noPadding ? "0px" : "", margin: this.props.extendMargin ? "0px -15px" : "" }} id={this.props.id}>
                {this.props.children}
            </div>
        );
    }
}

export default Accordion;