import cc from "../country_codes.json"

export default function format_second_data(data) {
    let {
        title,
        gender,
        country_of_birth,
        nationalities,
        addresses,
        dependants,
        preference_term,
        preference_mortgage_reason,
        has_previously_owned_property,
        ...d
    } = data

    let dep = []
    for (let i = 0; i < dependants.length; i++) {
        if (
            dependants[i].relationship &&
            dependants[i].name &&
            dependants[i].date_of_birth &&
            dependants[i].dependency_end_date
        ) dep.push(dependants[i])
    }

    return {
        ...d,
        title: title === "PREFER_NOT_TO_SAY" ? "INVALID_TITLE" : title,
        gender: gender === "PREFER_NOT_TO_SAY" || gender === "OTHER" || gender === "NONE" ? "NOT_SPECIFIED" : gender,
        country_of_birth: cc.filter(e => e.Name === country_of_birth)[0].Code,
        nationalities: nationalities.map(e => {
            return e.split(", ")[1]
        }),
        addresses: addresses.map(e => {
            e.address.country = e.address.country ? cc.filter(f => f.Name === e.address.country)[0].Code : ""
            e.address.postcode = e.address.postcode.trim().slice(0, -3) + " " + e.address.postcode.trim().slice(-3)
            console.log(e)
            return e
        }),
        dependants: dep
    }
}