import React, { useEffect, useState } from 'react';
import AddressHistory from '../../apply/questions/address_history';
import Communications from '../../apply/questions/communications';
import Country from '../../apply/questions/country';
import Dependents from '../../apply/questions/dependents';
import NationalInsurance from '../../apply/questions/national_insurance';
import Smoker from '../../apply/questions/smoker';
import Selection from '../selection';

function ApplicationDetails(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Who will be on the application?",
                        content: "Buying a home with a combined income can get you access to better offers",
                        buttons: {
                            "Just me": {
                                value: 1,
                                next: props.switch + 1
                            },
                            "Me & someone else": {
                                value: 2,
                                next: props.switch + 1
                            },
                            "More than two of us": {
                                value: 3,
                                modal: {
                                    id: "more_than_2_applicants",
                                    type: "callback",
                                    text: "Mortgages for more than two people require further information - it's not a problem, we'll just need a few more details."
                                }
                            },
                        }
                    }} id={"number_of_applicants"} setValue={(e) => props.setValue({ ...props.store, number_of_applicants: e })} />
                )

            case props.switch + 1:
                return (
                    <span>
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            heading: "What's your name?",
                            content: "We need to understand a few details about you.",
                            select: {
                                id: "title",
                                placeholder: "Title",
                                options: {
                                    "Mr": "MR",
                                    "Mrs": "MRS",
                                    "Miss": "MISS",
                                    "Ms": "MS",
                                    "Mx": "MX",
                                    "Dr": "DR",
                                    "Sir": "SIR",
                                    "Prof": "PROF",
                                    "Lord": "LORD",
                                    "Lady": "LADY",
                                    "Dame": "DAME",
                                    "Prefer not to say": "PREFER_NOT_TO_SAY"
                                }
                            }
                        }} id={"title"} setValue={(e) => props.setValue({ ...props.store, title: e })} />
                        <div style={{ marginTop: "-15px" }} />
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            input: {
                                type: "text",
                                id: "first_name",
                                placeholder: "First name",
                                next: props.switch + 2,
                                min: 1,
                                button: false
                            }
                        }} id={"first_name"} setValue={(e) => props.setValue({ ...props.store, first_name: e })} />
                        <div style={{ marginTop: "-15px" }} />
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            input: {
                                type: "text",
                                id: "last_name",
                                placeholder: "Last name",
                                next: props.switch + 2,
                                min: 1,
                                focus: false
                            }
                        }} id={"last_name"} setValue={(e) => props.setValue({ ...props.store, last_name: e })} />
                    </span>
                )

            case props.switch + 2:
                return (
                    <span>
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            heading: "How do you identify?",
                            content: "Please let us know the gender you identify with, and your marital status.",
                            select: {
                                id: "gender",
                                placeholder: "Gender",
                                options: {
                                    "Male": "MALE",
                                    "Female": "FEMALE",
                                    "None": "NONE",
                                    "Other": "OTHER",
                                    "Prefer not to say": "PREFER_NOT_TO_SAY"
                                }
                            }
                        }} id={"gender"} setValue={(e) => props.setValue({ ...props.store, gender: e })} />
                        <div style={{ marginTop: "-15px" }} />
                        <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                            select: {
                                id: "marital_status",
                                placeholder: "Marital status",
                                options: {
                                    "Married": "MARRIED",
                                    "Single": "SINGLE",
                                    "Divorced": "DIVORCED",
                                    "Separated": "SEPARATED",
                                    "Widowed": "WIDOWED",
                                    "Cohabit": "COHABIT",
                                    "Civil partnership": "CIVIL_PARTNERSHIP"
                                },
                                next: props.switch + 3,
                                button: true
                            }
                        }} id={"marital_status"} setValue={(e) => props.setValue({ ...props.store, marital_status: e })} />
                    </span>
                )

            // case props.switch + 4:
            //     return <NationalInsurance next={() => {
            //         props.setPage(props.switch + 5)
            //         props.setPrev([...props.prev, props.switch + 5])
            //     }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />

            // case props.switch + 5:
            //     return <Smoker next={() => {
            //         props.setPage(props.switch + 6)
            //         props.setPrev([...props.prev, props.switch + 6])
            //     }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When's your date of birth?",
                        content: "We won't send you a birthday card but this can impact how long your mortgage could be for. e.g. 25 years",
                        content2: "(Okay, we might send you a card).",
                        input: {
                            type: "date",
                            id: "dob",
                            next: props.switch + 4,
                            min: 10
                        }
                    }} id={"date_of_birth"} setValue={(e) => props.setValue({ ...props.store, date_of_birth: e })} />
                )

            case props.switch + 4:
                return <span>
                    <Country next={() => {
                        props.setPage(props.switch + 5)
                        props.setPrev([...props.prev, props.switch + 5])
                    }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />
                </span>

            case props.switch + 5:
                return <AddressHistory next={() => {
                    props.setPage(props.switch + 6)
                    props.setPrev([...props.prev, props.switch + 6])
                }} store={props.store} add={(a, b) => props.setValue({ ...props.store, [a]: b })} />

            // case props.switch + 7:
            //     return (
            //         <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
            //             heading: "Are all of the applicants UK nationals?",
            //             content: "There's a slightly different process invloved for non-UK citizens when applying for a mortgage.",
            //             buttons: {
            //                 "Yes": {
            //                     value: "YES",
            //                     next: props.switch + 9
            //                 },
            //                 "No": {
            //                     value: "NO",
            //                     next: props.switch + 7.5
            //                 }
            //             }
            //         }} id={"uk_nationals"} setValue={(e) => props.setValue({ ...props.store, uk_nationals: e })} />
            //     )

            // case props.switch + 7.5:
            //     return (
            //         <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
            //             heading: "Are all of the applicants EU nationals?",
            //             content: "There's a slightly different process invloved for non-EU citizens when applying for a mortgage.",
            //             buttons: {
            //                 "Yes": {
            //                     value: "YES",
            //                     next: props.switch + 8.5
            //                 },
            //                 "No": {
            //                     value: "NO",
            //                     next: props.switch + 8
            //                 }
            //             }
            //         }} id={"eu_nationals"} setValue={(e) => props.setValue({ ...props.store, eu_nationals: e })} />
            //     )

            // case props.switch + 8:
            //     return (
            //         <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
            //             heading: "What's your current residency status?",
            //             content: "Mortgage providers may require some further information, depending on your residency.",
            //             buttons: {
            //                 "Indefinite leave to remain": {
            //                     value: "INDEFINITE",
            //                     next: props.switch + 8.5
            //                 },
            //                 "More than two years on visa *": {
            //                     value: "MORE_THAN_TWO_YEARS",
            //                     next: props.switch + 8.5
            //                     // modal: {
            //                     //     id: "more_than_2_applicants",
            //                     //     type: "callback",
            //                     //     text: "Your visa status can affect what you can borrow – lenders will often use this as an indicator to refuse certain mortgage products."
            //                     // }
            //                 },
            //                 "Less than two years on visa *": {
            //                     value: "LESS_THAN_TWO_YEARS",
            //                     next: props.switch + 8.5
            //                     // modal: {
            //                     //     id: "more_than_2_applicants",
            //                     //     type: "callback",
            //                     //     text: "Your visa status can affect what you can borrow – lenders will often use this as an indicator to refuse certain mortgage products."
            //                     // }
            //                 }
            //             },
            //             content3: "* The current remaining time on your visa"
            //         }} id={"residency_status"} setValue={(e) => props.setValue({ ...props.store, residency_status: e })} />
            //     )

            // case props.switch + 8.5:
            //     return (
            //         <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
            //             heading: "How many years have you lived in the UK?",
            //             content: "Please give your answer to the closest year.",
            //             buttons: {
            //                 "0": {
            //                     value: "0",
            //                     next: props.switch + 9
            //                 },
            //                 "1": {
            //                     value: "1",
            //                     next: props.switch + 9
            //                 },
            //                 "2": {
            //                     value: "2",
            //                     next: props.switch + 9
            //                 },
            //                 "3": {
            //                     value: "3",
            //                     next: props.switch + 9
            //                 },
            //                 "4": {
            //                     value: "4",
            //                     next: props.switch + 9
            //                 },
            //                 "5 or more": {
            //                     value: "5_OR_MORE",
            //                     next: props.switch + 9
            //                 }
            //             }
            //         }} id={"time_in_uk"} setValue={(e) => props.setValue({ ...props.store, time_in_uk: e })} />
            //     )

            // case props.switch + 9:
            //     return (
            //         <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
            //             heading: "How many dependents do you have?",
            //             content: "This includes any children or financially dependent adults over the age of 17 (please don't include any other applicants!)",
            //             buttons: {
            //                 "0": {
            //                     value: "0",
            //                     next: props.switch + 11
            //                 },
            //                 "1": {
            //                     value: 1,
            //                     next: props.switch + 10
            //                 },
            //                 "2": {
            //                     value: 2,
            //                     next: props.switch + 10
            //                 },
            //                 "3": {
            //                     value: 3,
            //                     next: props.switch + 10
            //                 },
            //                 accordion: {
            //                     id: "dependants_accordion",
            //                     phrase: "More options",
            //                     alt: "Less options",
            //                     buttons: {
            //                         "4": {
            //                             value: 4,
            //                             next: props.switch + 10
            //                         },
            //                         "5": {
            //                             value: 5,
            //                             next: props.switch + 10
            //                         },
            //                         "6": {
            //                             value: 6,
            //                             next: props.switch + 10
            //                         },
            //                         "7": {
            //                             value: 7,
            //                             next: props.switch + 10
            //                         },
            //                         "8": {
            //                             value: 8,
            //                             next: props.switch + 10
            //                         },
            //                         "9": {
            //                             value: 9,
            //                             next: props.switch + 10
            //                         }
            //                     }
            //                 }
            //             }
            //         }} id={"number_of_dependants"} setValue={(e) => props.setValue({ ...props.store, number_of_dependants: e })} />
            //     )

            // case props.switch + 10:
            //     return <span>
            //         <Dependents next={() => {
            //             props.setPage(props.switch + 11)
            //             props.setPrev([...props.prev, props.switch + 11])
            //         }} add={(a, b) => props.setValue({ ...props.store, [a]: b })} store={props.store} />
            //     </span>

            default:
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default ApplicationDetails;