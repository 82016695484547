import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function CompanyDirector(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What's your shareholding of the company?",
                        content: "This helps lenders understand hwo and what they can offer to lend you.",
                        buttons: {
                            "Less than 20%": {
                                value: "LESS_THAN_20",
                                next: props.switch + 1
                            },
                            "More than 20%": {
                                value: "MORE_THAN_20",
                                next: props.switch + 1
                            }
                        }
                    }} id={"shareholding_of_company"} setValue={(e) => props.setValue({ ...props.store, shareholding_of_company: e })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "How many years have you been in business?",
                        input: {
                            type: "years",
                            placeholder: "0",
                            id: "company_director_years_in_business",
                            min: 1,
                            next: props.switch + 2
                        }
                    }} id={"company_director_years_in_business"} setValue={(e) => props.setValue({ ...props.store, company_director_years_in_business: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What was your salary for 2020–2021?",
                        content: "This is before tax, and excluding any  bonuses, dividends, commission, or overtime, for the 2020-2021 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "company_director_net_profit_20_21",
                            min: 10000,
                            next: props.switch + 3
                        }
                    }} id={"company_director_net_profit_20_21"} setValue={(e) => props.setValue({ ...props.store, company_director_net_profit_20_21: e })} />
                )

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What was your salary for 2019–2020?",
                        content: "This is before tax, and excluding any  bonuses, dividends, commission, or overtime, for the 2019-2020 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "company_director_net_profit_19_20",
                            min: 10000,
                            next: props.store.shareholding_of_company === "LESS_THAN_20" ?  props.switch + 7 : props.switch + 4
                        }
                    }} id={"company_director_net_profit_19_20"} setValue={(e) => props.setValue({ ...props.store, company_director_net_profit_19_20: e })} />
                )

            case props.switch + 4:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What was your operating profit for 2020–2021?",
                        content: "This is any after all business expenses before tax, for the 2020-2021 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "company_director_operating_profit_20_21",
                            min: 10000,
                            next: props.switch + 5
                        }
                    }} id={"company_director_operating_profit_20_21"} setValue={(e) => props.setValue({ ...props.store, company_director_operating_profit_20_21: e })} />
                )

            case props.switch + 5:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "What was your operating profit for 2019–2020?",
                        content: "This is any after all business expenses before tax, for the 2019-2020 tax year.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "company_director_operating_profit_19_20",
                            min: 10000,
                            next: props.getSwitch("retirementAge")
                        }
                    }} id={"company_director_operating_profit_19_20"} setValue={(e) => props.setValue({ ...props.store, company_director_operating_profit_19_20: e })} />
                )

        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default CompanyDirector;