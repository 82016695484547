import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, useParams } from "react-router-dom";

import Thanks from "./components/thanks";
import Protection from "./components/protection.jsx";

// import { MortgageApplication } from "./components/MortgageApplication";
// import LandingPage from "./components/landing_page";
// import Landing_Page_2 from "./components/landing_page_2.0";
// import Landing_Page_3 from "./components/landing_page_3.0";

import About from "./components/about";

// import Listing from "./components/listing-flow/listing";
// import Availability from "./components/listing-flow/availability";
// import Dashboard from "./components/listing-flow/dashboard";
// import PhotoUpload from "./components/listing-flow/photo_upload";
// import Photoshoot from "./components/listing-flow/photoshoot";
// import SelectListing from "./components/listing-flow/select_listing";
// import Login from "./components/listing-flow/login";
// import Viewings from "./components/listing-flow/viewings";
// import Bookings from "./components/listing-flow/bookings";
// import Publish from "./components/listing-flow/publish";
// import mini_MIP from "./components/mini_MIP";
// import MIP_Dashboard from "./components/mortgage-wizard/dashboard";

// import Buyer_Offer from "./components/Offers/buyer_offer";

// import Entry from "./components/mortgage-wizard/entry";

// import Calculator from "./components/tools/calculator";
// import Optimiser2 from "./components/tools/optimiser2";
// import Valuator2 from "./components/tools/valuation2";
// import Optimiser3 from "./components/tools/optimiser3";

// import MagicLink from "./components/magic-link/ml";

import Terms from "./components/policy/terms";
import Privacy from "./components/policy/privacy";
import Service from "./components/policy/service";
import IDD from "./components/policy/idd";
import Next_Steps from "./components/policy/next_steps";

// import Menu from "./components/menu";
// import Seller_Offer from "./components/Offers/seller_offer";
// import Buyer_Offers from "./components/Offers/buyer_offers";
// import Buyer_Seller_Dashboard from "./components/listing-flow/buyer_seller_dashboard";
// import { Homepage } from "./components/Homepage";
// import agent from "./agent";

// import Review_Feedback from "./components/admin/reviewFeedback";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
// import SplentoRouter from "./components/Splento/SplentoRouter";
import Landing_Sparse from "./components/landing_sparse";
import AdverseCredit from "./components/AdverseCredit/prototype/AdverseCredit";
import Minimal from "./components/AdverseCredit/minimal/minimal";
import Full from "./components/AdverseCredit/full/full";
import IFrame from "./components/AdverseCredit/iframe/iframe";
import CreditladderPrototype from "./components/AdverseCredit/prototype/creditladder";
import Widget from "./components/ChatWidget/widget";
import LandingResults from "./components/AdverseCredit/full/results/landing";
import LandingResults2 from "./components/AdverseCredit/full/results/landing_2";
import Steps from "./components/AdverseCredit/steps/steps";
import Skyscanner from "./components/AdverseCredit/skyscanner";
import Step12 from "./components/AdverseCredit/combine/step_1_2";
import Application from "./components/Application";
import Charts from "./components/Charts";
import Calculator from "./components/tools/calculator";
import Optimiser from "./components/tools/optimiser";
import Optimiser2 from "./components/tools/optimiser2";
import Optimiser3 from "./components/tools/optimiser3";
import Valuator from "./components/tools/valuation";
import Valuator2 from "./components/tools/valuation2";
import Milo from "./components/Milo";
import AfterApplication from "./components/AdverseCredit/apply/after_application";
import AcreResults from "./components/AdverseCredit/full/acre_results/acre_results";
import AcreResultsCached from "./components/AdverseCredit/full/acre_results/acre_results_cached";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#f00",
		},
	},
});

const urls = [
	"https://free.co.uk",
	"https://www.free.co.uk",
	"https://www.creditladder.co.uk",
	"http://localhost:3000",
	"http://192.168.1.189:3000",
	"https://moresimple.money",
	"https://www.moresimple.money"
]
const paths = ["/jwks", "/thanks"]
const check = urls.includes(window.location.origin) && !paths.includes(window.location.pathname) && (window.location.pathname === "/adverse_credit_prototype" || window.location.pathname === "/adverse_credit_full" ? window.self === window.top : true)

function App() {
	// const [user, setUser] = useState();

	// useEffect(() => {
	//   (async () => {
	//     try {
	//       let userData = await agent.Auth.current();
	//       setUser(userData);
	//       window.intercomSettings = {
	//         app_id: "td245xhb",
	//         user_id: `${userData.phone}`,
	//         name: `${userData.name} ${userData.surname}`, // Full name
	//         phone: `${userData.phone}`, // Email address
	//         created_at: (new Date(userData.created_at).getTime() / 1000).toFixed(
	//           0
	//         ), // Signup date as a Unix timestamp
	//       };
	//     } catch {
	//       setUser(null);
	//       window.intercomSettings = {
	//         app_id: "td245xhb",
	//       };
	//     }
	//   })();
	// }, [setUser]);

	useEffect(() => {
		// 
		// console.log(urls.includes(window.location.origin))
		if (window.MessageBirdChatWidget) {
			window.MessageBirdChatWidget.hide()
		}
	}, [window.MessageBirdChatWidget])

	return (
		<div>
			{/* <ThemeProvider theme={theme}> */}
			<div id="outer-container">
				{/* <Landing_Menu /> */}
				<main id="page-wrap">
					<Switch>
						{/* <Route exact path="/"><Landing_Sparse lower={check} /></Route> */}
						<Route exact path="/" render={props => (<Step12 {...props} lower={check} />)}></Route>
						<Route exact path="/optimiser" render={props => (<Optimiser2 {...props} lower={check} />)}></Route>
						<Route exact path="/valuation" render={props => (<Valuator2 {...props} lower={check} />)}></Route>
						{/* <Route exact path="/sell">
                <Homepage user={user} />
              </Route>
              <Route exact path="/buy">
                <Homepage user={user} />
              </Route>
              <Route exact path="/buy-legacy">
                <Homepage user={user} />
              </Route>
              <Route exact path="/buy/mip">
                <Homepage user={user} />
              </Route>
              <Route path="/buy/mip/*">
                <Homepage user={user} />
              </Route>
              <Route path="/buy/application">
                <Homepage user={user} />
              </Route> */}

						<Route path="/stamp_duty/:style" render={props => (<Calculator {...props} />)}></Route>
						<Route path="/stamp_duty"><Calculator lower={check} /></Route>
						<Route path="/adverse_credit_prototype"><AdverseCredit lower={check} /></Route>
						{/* <Route path="/adverse_credit_creditladder"><AdverseCredit lower={check} style="creditladder" /></Route> */}
						<Route path="/charts"><Charts /></Route>
						<Route path="/milo/:style" render={props => (<Milo {...props} />)}></Route>
						<Route path="/milo"><Milo /></Route>
						<Route path="/adverse_credit_creditladder"><Step12 style="creditladder" lower={check} /></Route>
						<Route path="/adverse_credit_minimal" component={Minimal}></Route>
						<Route path="/adverse_credit_full/:style" render={props => (<Full {...props} lower={check} />)}></Route>
						<Route path="/adverse_credit_full" render={props => (<Full {...props} lower={check} />)}></Route>


						<Route path="/example_1" render={props => (
							<div class="encompass" width="886.390625" height="835">
								<div class="adverse_flow" id="free_iframe" style={{ paddingBottom: "115px", paddingTop: "30px" }}>
									<div className="adverse_flow acre_results">
										<AcreResultsCached affordability={require("./affordability.json")[0]} products={require("./sourcing.json")[0]} {...props} store={require("./clients.json")[0]} lower={check} />
									</div>
								</div>
							</div>)}></Route>

						<Route path="/example_2" render={props => (
							<div class="encompass" width="886.390625" height="835">
								<div class="adverse_flow" id="free_iframe" style={{ paddingBottom: "115px", paddingTop: "30px" }}>
									<div className="adverse_flow acre_results">
										<AcreResultsCached affordability={require("./affordability.json")[1]} products={require("./sourcing.json")[1]} {...props} store={require("./clients.json")[1]} lower={check} />
									</div>
								</div>
							</div>)}></Route>

						<Route path="/example_3" render={props => (
							<div class="encompass" width="886.390625" height="835">
								<div class="adverse_flow" id="free_iframe" style={{ paddingBottom: "115px", paddingTop: "30px" }}>
									<div className="adverse_flow acre_results">
										<AcreResultsCached affordability={require("./affordability.json")[2]} products={require("./sourcing.json")[2]} {...props} store={require("./clients.json")[2]} lower={check} />
									</div>
								</div>
							</div>)}></Route>


						<Route path="/affordability" render={props => (<Skyscanner {...props} lower={check} />)}></Route>
						<Route path="/steps" render={props => (<Steps {...props} lower={check} />)}></Route>
						<Route path="/steps_combine/:style" render={props => (<Step12 {...props} lower={check} />)}></Route>
						<Route path="/steps_combine" render={props => (<Step12 {...props} lower={check} />)}></Route>
						<Route path="/iframe" component={IFrame} />

						<Route path="/result_landing" component={LandingResults2} />
						<Route path="/further_details/:style" render={props => (<AfterApplication {...props} lower={check} />)} />
						<Route path="/further_details" render={props => (<AfterApplication {...props} lower={check} />)} />

						<Route path="/:cc/:number" render={props => (<Application {...props} lower={check} />)}></Route>
						<Route path="/thanks" component={Thanks}></Route>
						{/* <Route path="/protection" component={Protection}></Route> */}

						<Route path="/about" component={About}></Route>

						{/* <Route path="/landing_redo" component={Landing_Page_3}></Route> */}

						{/* <Route
                path="/approveViewing/:slug/:id"
                component={Review_Feedback}
              ></Route> */}

						<Route path="/terms-of-use" component={Terms}></Route>
						<Route path="/service-agreement" component={Service}></Route>
						<Route path="/privacy-policy" component={Privacy}></Route>
						<Route path="/IDD" component={IDD}></Route>
						<Route path="/next_steps" component={Next_Steps}></Route>

						{/* <Route path="/admin" component={Test}></Route> */}

						{/* <Route path="/listing" component={Listing}></Route>
              <Route path="/bookings" component={Bookings}></Route>
              <Route path="/photo-upload/:slug" component={PhotoUpload}></Route>
              <Route path="/photoshoot/:slug" component={Photoshoot}></Route>
              <Route path="/select-listing" component={SelectListing}></Route>
              <Route path="/login" component={Login}></Route>
              <Route path="/viewings/:slug" component={Viewings}></Route>
              <Route path="/offers/:slug" component={Seller_Offer}></Route> */}
						{/* <Route path="/book-viewing" component={BookViewing}></Route> */}
						{/* <Route
                path="/availability/:slug"
                component={Availability}
              ></Route>
              <Route
                exact
                path="/dashboard"
                component={Buyer_Seller_Dashboard}
              ></Route>
              <Route path="/dashboard/:slug" component={Dashboard}></Route>
              <Route path="/publish/:slug" component={Publish}></Route>
              <Route exact path="/splento/:slug">
                <SplentoRouter />
              </Route> */}
						{/* <Route path="/EPC" component={EPC}></Route> */}

						{/* <Route exact path="/mini_MIP" component={mini_MIP}></Route>
						<Route path="/mini_MIP/dashboard" component={Mini_MIP_Dashboard}></Route> */}

						{/* <Route exact path="/mortgage/wizard" component={Entry}></Route>
              <Route path="/mortgage/wizard/dashboard/:id">
                <Redirect to="/buy" />
              </Route>

              <Route path="/calculator" component={Calculator}></Route>
              <Route path="/optimiser" component={Optimiser3}></Route>
              <Route path="/optimiser2" component={Optimiser2}></Route>
              <Route path="/valuation" component={Valuator2}></Route> */}
						{/* <Route path="/chart" component={Chart}></Route> */}

						{/* <Route path="/loader" component={Loader}></Route> */}
						{/* <Route path="/ml/:code/:redirect+" component={MagicLink}></Route>

              <Route path="/view-offers" component={Buyer_Offers}></Route>
              <Route path="/offer/:slug" component={Buyer_Offer}></Route>
              <Route
                exact
                path="/:reschedule/:slug"
                component={mini_MIP}
              ></Route>
              <Route exact path="/:slug" component={mini_MIP}></Route> */}
						{/* <Route path="/"><Landing_Sparse lower={check} /></Route> */}
						<Route path="/" render={props => (<Step12 {...props} lower={check} />)}></Route>
					</Switch>
					<Widget show={check} />
				</main>
			</div>
			{/* </ThemeProvider> */}
		</div>
	);
}

export default App;
