import React, { useEffect, useState } from 'react'
import "./toggle.sass"

function Toggle(props) {

    const [degree, setDegree] = useState("0px")
    const [style, setStyle] = useState(props.style === "creditladder" ? "#5e00fa" : "black")

    useEffect(() => {
        let values = window.location.pathname === "/adverse_credit_creditladder" || window.location.pathname === "/steps_combine/creditladder" ? {
            95: "109px",
            90: "151px",
            85: "190px",
            80: "232px",
            75: "272px",
            70: "100%"
        } : window.location.pathname === "/steps_combine/closefield" ?
            {
                95: "109px",
                90: "144px",
                85: "180px",
                80: "220px",
                75: "260px",
                70: "100%"
            }
            : {
                95: "109px",
                90: "147px",
                85: "185px",
                80: "227px",
                75: "267px",
                70: "100%"
            }
        setDegree(values[parseInt(props.value)] ? values[parseInt(props.value)] : "0px")
    }, [props.value])

    function get_color() {
        let path = window.location.pathname
        if (path === "/adverse_credit_creditladder" || path === "/steps_combine/creditladder") {
            return "#5e00fa"
        } else if (path === "/steps_combine/nude") {
            return "#efa07c"
        } else if (path === "/steps_combine/vesta") {
            return "#fd682b"
        } else if (path === "/steps_combine/closefield") {
            return "#e5b4aa"
        } else {
            return "black"
        }
    }

    return (
        <div className="toggle" style={{ background: `linear-gradient(90deg, ${get_color()} ${degree}, white ${degree})` }}>
            <div className="toggle_key" style={{ color: props.value ? "white" : "" }}>
                {Object.keys(props.options)[0]}
            </div>
            {Object.entries(props.options["Expected deposit"])[0][1].map((e, n) => {
                return (
                    <div key={`toggle_${n}`} className="toggle_value" onClick={() => props.addOption({ "LTV": e + "%" })} style={{ color: e >= parseInt(props.value) ? "white" : "" }}>
                        {100 - e + "%"}
                    </div>
                )
            })}
        </div>
    );
}

export default Toggle;