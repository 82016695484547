import React from 'react';
import json from './outcodes.json';
import "scroll-behavior-polyfill";

class Optimiser2 extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            location: 1,
            numRooms: this.props.beds || 2,
            propArray: [],
            greaterPropArray: [],
            lesserPropArray: [],
            withUserPrice: [],
            featured: [],
            pages: ["Loading..."],
            userPrice: this.props.price || 530000,
            index: 24,
            outcodes: json,
            postcode: this.props.postcode || "Loading...",
            postcodeNotFound: false,
            locationError: null,
            predictionArray: null,
            pageNumber: null,
            pagePosition: null,
            links: [],
            scroll: false,
            loadingUpdate: null,
            started: false,
            loading: <div className="middle-container" id="loading">
                        <div className="top loading"><div className="section-content">
                            <div className="section-info">
                            <img className="logo" src={require("../img/splashHome.svg")} alt='free'></img>
                                <div id="loader" className="loader">
                                    <div id="loader2" className="loader2">
                                        <div id="loader3" className="loader3">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        };
    }

    async componentWillMount() {
        let begin = 0;
        let end = this.state.outcodes.length;
        let prediction = [];
        if(this.state.postcode !== undefined){
            for(var k = begin; k <= end; k++){
                if(this.state.outcodes[k] !== undefined){
                        let expr = (
                            <option value={this.state.outcodes[k].outcode}></option>
                        );
                        prediction.push(expr);
                }
            }
        }

        this.setState({
            predictionArray: prediction
        });
    }

    async componentDidMount() {
        // Using a proxy to work around the CORS issue:
        const $style = document.createElement("style");
        document.head.appendChild($style);
        $style.innerHTML = `
        
        .optimiser2, .optimiser2 #slide1, .optimiser2 #slide2, .optimiser2 #slide3 {
            height: 100vh;
            width: 100vw;
            position: relative;
        }
        
        .optimiser2 .main {
            height: calc(100% - 42px);
        }
        
        .optimiser2 ul {
            list-style-type: none;
            padding: 0%;
            margin-top: 5px;
            margin-bottom: 0;
        }
        
        .optimiser2 li {
            font-size: 13px;
        }
        
        .optimiser2 .main {
            font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;;
            text-align: center;
            height: 100%;
        }
        
        .optimiser2 .optimiser-td {
            padding: 15px 30px 15px 30px;
            background-color: #02bc83;
            border-radius: 10px;
            text-align: left;
            margin: 4px;
            color: white;
            display: inline-block;
            vertical-align: top;
        }
        
        .optimiser2 .optimiser-page {
            font-size: 24px;
            font-weight: 600;
        }
        
        @media (max-height: 600px){
            .optimiser2 .optimiser-td {
                padding: 5px 30px 5px 30px;
            }
        
            .optimiser2 .optimiser-page {
                font-size: 20px;
            }
        }
        
        .optimiser2 .optimiser-user-price {
            background-color: transparent;
            color: black;
            font-weight: 700;
        }
        
        .optimiser2 table {
            width: 100%;
            height: 100%;
            table-layout: fixed;
            overflow-y: hidden;
            border: none;
            border-collapse: collapse;
            
        }
        
        .optimiser2 td {
            width: 50%;
            overflow: hidden;
            overflow-y: hidden;
            position: relative;
            -webkit-scroll-snap-align: start;
            scroll-snap-align: start;
        }
        
        .optimiser2 .scrollTable {
            position: relative;
            width: 100%;
            overflow-x: auto;
            scroll-behavior: smooth;
            z-index: -2;
        }
        
        .optimiser2 .optimiser-table {
            overflow: auto;
            white-space: nowrap;
            scroll-behavior: smooth;
            margin-left: 90px;
            margin-right: 90px;
        }
        
        .optimiser2 .fadeLeft {
            background-image : linear-gradient(to left, 
                            #f0f0f000, 
                            #f0f0f099 90%);
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100px;
            height: 100%;
            z-index: -1;
        }
        
        .optimiser2 .fadeRight {
            background-image : linear-gradient(to right, 
                                #f0f0f000, 
                                #f0f0f099 90%);
            position: absolute;
            right: 0;
            top: 0;
            width: 100px;
            height: 100%;
            z-index: -1;
        }
        
        .optimiser2 .alterPrice {
            font-size: 50px;
            vertical-align: middle;
            z-index: 1;
        }
        
        .optimiser2 .alterPriceText {
            font-size: 18px;
            font-weight: 600;
            vertical-align: middle;
            z-index: 1;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
        
        @keyframes antispin {
            0% { transform: rotate(720deg); }
            100% { transform: rotate(0deg); }
        }
        
        .optimiser2 .logo {
            width: 200px;
            position: absolute;
            top: 12%;
            left: 11%;
        }
        
        .optimiser2 .loader {
            border: 10px solid transparent;
            border-top: 10px solid #02bc83;
            border-radius: 50%;
            width: 240px;
            height: 240px;
            animation: spin 1s linear infinite;
            z-index: 5;
        }
        
        .optimiser2 .loader2 {
            border: 10px solid transparent;
            border-top: 10px solid #02bc83;
            border-radius: 50%;
            width: 220px;
            height: 220px;
            animation: antispin 1s linear infinite;
            z-index: 5;
        }
        
        .optimiser2 .loader3 {
            border: 10px solid transparent;
            border-top: 10px solid #02bc83;
            border-radius: 50%;
            width: 200px;
            height: 200px;
            animation: spin 0.4s linear infinite;
            z-index: 5;
        }
        
        .optimiser2 .loading {
            width: auto;
        }
        
        .optimiser2 .top{
            width: 80%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0px solid;
            border-radius: 10px;
        }
        
        .optimiser2 .middle-container {
            display: block;
            /* min-height: 65vh; */
        }
        
        .optimiser2 .section-content {
            z-index: 1;
            padding-top: 1px;
            padding-bottom: 5px;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        
        .optimiser2 .uk-navbar {
            overflow: hidden;
            display: flex;
            position: relative;
            background: #f6f6f6!important;
            padding: 0 17px;
        }
        
        .optimiser2 .App {
            width: 100%;
        }
        
        .optimiser2 .uk-logo {
            font-size: 1.1875rem;
            font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
            color: #666;
            text-decoration: none;
            font-family: Oswald;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 18px;
            letter-spacing: 0px;
            color: #ccc;
        }
        
        .optimiser2 .uk-navbar-item, .optimiser2 .uk-navbar-nav>li>a, .optimiser2 .uk-navbar-toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            min-height: 80px;
            padding: 0 15px;
            font-size: 16px;
            font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
            text-decoration: none;
        }
        
        .optimiser2 .uk-navbar-item {
            color: #666;
            min-height: 42px;
            padding: 0;
        }
        
        .optimiser2 label {
            font-size: 22px;
            font-weight: 600;
        }
        
        .optimiser2 input {
            padding: 1px 2px;
            padding-right: 13px;
            border: 2px solid #02bc83;
            font-size: 20px;
            margin-top: 5px;
            margin-bottom: 10px;
            margin-right: -64px;
            height: 25px;
            width: calc(100% - 46px);
            border-radius: 0;
        }
        
        .optimiser2 #priceInput {
            padding-left: 15px;
            padding-right: 0px;
        }
        
        .optimiser2 #locationInput {
            margin-right: 0px;
        }
        
        .optimiser2 .score {
            color: #02bc83;
            font-weight: 700;
        }
        
        .optimiser2 a {
            text-decoration: none;
            color: white;
        }
        
        .optimiser2 a:visited {
            color: #00694a;
        }
        
        .optimiser2 a:hover {
            text-shadow: 0px 0px 1px;
        }
        
        .optimiser2 .updateBtn {
            width: 40%;
            padding: 5px;
            margin-top: 10px;
            background-color: #02bc83;
            border: none;
            font-size: 24px;
            color: white;
            vertical-align: middle;
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
        }
        
        .optimiser2 .positionBtn {
            width: 40%;
            padding: 5px;
            margin-top: 10px;
            background-color: #02bc83;
            border: none;
            font-size: 14px;
            color: white;
            vertical-align: middle;
            cursor: pointer;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
        }
        
        .optimiser2 .incrBtn {
            margin-left: 5px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        
        .optimiser2 .decrBtn {
            padding-top: 10px;
            padding-bottom: 10px;
            margin-right: 5px;
            background-color: #bc0202;
        }
        
        .optimiser2 .incrBtn:hover, .optimiser2 .decrBtn:hover {
            transform: scale(1.02);
        }
        
        .optimiser2 .disableBtn {
            background-color: rgb(143, 143, 143);
            color: rgb(63, 63, 63);
        }
        
        .optimiser2 .disableBtn:hover {
            transform: none;
        }
        
        .optimiser2 .startBtn {
            padding: 15px;
            width: 50%;
        }
        
        .optimiser2 .scrollBtn {
            width: 100%;
            padding: 5px;
            background-color: transparent;
            border: none;
            border-radius: 5px;
            font-size: 20px;
            color: black;
            vertical-align: middle;
            cursor: pointer;
        }
        
        .optimiser2 .backBtn {
            color: black;
        }
        
        .optimiser2 .updateBtn:hover {
            transition: all 0.1s ease-in-out;
            transform: scale(1.05);
        }
        
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button {  
        
           opacity: 0;
           display: none;
        
        }
        
        input::-webkit-calendar-picker-indicator {
            opacity: 0;
            display: none;
        }
        
        .optimiser2 .note {
            margin-top: -10px;
            font-size: 20px;
            vertical-align: middle;
        }
        
        .optimiser2 .notes {
            padding-left: 15px;
            padding-right: 15px;
        }
        
        .optimiser2 #clickNote {
            position: absolute;
            top: 50%;
            left: -100px;
            font-weight: 700;
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
        
        .optimiser2 .note2 {
            margin-top: -10px;
            font-size: 18px;
        }
        
        .optimiser2 .scrollToPages {
            font-size: 30px;
            vertical-align: middle;
            z-index: 2;
        }
        
        @media (max-width:847px){
            .optimiser2 table {
                width: 100%;
            }
        
            .optimiser2 .scrollBtn {
                display: block;
            }
        }
        
        @media (max-height: 620px){
            .optimiser2 .disappearOnSmallerScreen {
                display: none;
            }
        
            .optimiser2 label {
                font-size: 20px;
            }
        
            .optimiser2 .note {
                font-size: 18px;
            }
        
            .optimiser2 input {
                font-size: 20px;
            }
        }
        
        .optimiser2 .formContent {
            padding-left: 15px;
            padding-right: 15px;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            width: 100%;
            max-height: 100%;
        }
        
        .optimiser2 #formHouse {
            padding-top: 42px;
            padding-bottom: 26px;
        }
        
        .optimiser2 #slide1, .optimiser2 #slide2, .optimiser2 #slide3 {
            -webkit-scroll-snap-align: start;
            -webkit-scroll-snap-stop: always;
            scroll-snap-stop: always;
            scroll-snap-align: start;
        }
        
        .optimiser2 small {
            z-index: 2;
        }
        
        .optimiser2 .firstBreak {
            display: none;
        }
        
        @media (min-height: 650px){
            .optimiser2 .firstBreak {
                display: block;
            }
        
            .optimiser2 .alterPriceText {
                font-size: 22px;
            }
        }
        
        .optimiser2 .secondBreak {
            display: none;
        }
        
        @media (min-height: 700px) {
            .optimiser2 .secondBreak {
                display: block;
            }
        
            .optimiser2 li {
                font-size: 16px;
            }
        }
        
        @media (min-height: 800px) {
            .optimiser2 li {
                font-size: 18px;
            }
        
            .optimiser2 .optimiser-page {
                font-size: 26px;
            }
        }
        
        .optimiser2 #comment {
            font-size: 28px;
            font-weight: 600;
            padding-left: 10px;
            padding-right: 10px;
        }
        
        .optimiser2 #fillComment {
            font-size: 20px;
        }
        
        .optimiser2 .mainLogo {
            width: 200px;
        }
        
        .optimiser2 .mainMenu {
            width: calc(100% - 30px);
        }
        
        .optimiser2 .poundSign {
            position: absolute;
            margin-left: 4px;
            margin-top: 3px;
            color: #02bc83;
            font-size: 22px;
        }
        
        @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) { 
            @media {
                .optimiser2 .poundSign {
                    margin-top: 7px;
                }
            }
        }
        
        @media not all and (min-resolution:.001dpcm) { 
            @media {
                .optimiser2 .poundSign {
                    margin-top: 7px;
                }
            }
        }
        
        .optimiser2 .tooltip {
            position: relative;
            display: inline-block;
            font-size: 22px;
        }
        
        .optimiser2 .tooltiptext {
            font-size: 14px;
            font-weight: 400;
        }
          
        .optimiser2 .tooltip .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: rgb(34, 34, 34);
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 10px 10px;
            position: absolute;
            z-index: 1;
            top: calc(-50% - 5px);
            right: 125%;
        }
          
        .optimiser2 .tooltip .tooltiptext::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent rgb(34, 34, 34);
        }
        
        .optimiser2 .tooltip:hover .tooltiptext {
            visibility: visible;
        }
        
        .optimiser2 .negative, .optimiser2 .plus {
            background-color: transparent;
            border: 2px solid #02bc83;
            font-size: 20px;
            cursor: pointer;
            width: 32px;
            height: 31px;
            text-align: center;
            padding: 0;
            margin: 0;
        }
        
        .optimiser2 .negative:hover {
            background-color: #02bc83;
        }
        
        .optimiser2 .plus:hover {
            background-color: #02bc83;
        }
        
        .optimiser2 .negative {
        }
        
        .optimiser2 .plus {
            border-left: none;
        }
        
        :focus {
            outline: none;
        }
        
        .optimiser2 .propertyForm {
            text-align: left;
            margin-left: 5%;
            margin-right: 5%;
        }
        
        .optimiser2 .listItem {
            padding-left: 10px;
            padding-right: 10px;
        }
        `;
        
        // Without a proxy, used a chrome extension to circumvent the issue, which I think arose due to the fact that it was being run locally:
        const url = "https://optimiser-proxy.herokuapp.com/optimiser-proxy?location=" + this.state.location + "&numRooms=" + this.state.numRooms + "&index=" + this.state.index;

        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            data: data
        });
        this.sortData();
    }

    sortData() {
        let numPropResponses = this.state.data.numReturnedResults;
        let totalResults = this.state.data.totalAvailableResults;
        let featuredPropertyNum = this.state.data.featuredProperties.length;
        for(let i = 0; i < featuredPropertyNum; i++){
            let link = this.state.data.featuredProperties[0].identifier;
            this.setState({
                featured: this.state.featured.concat(<a href={"https://www.rightmove.co.uk/property-for-sale/property-"+link+".html"} target="_blank" rel="noopener noreferrer">Featured</a>)
            });
        }

        for(let i = 0; i < numPropResponses; i++){
            let price = this.state.data.properties[i].price;
            let link = this.state.data.properties[i].identifier;
            this.setState({
                propArray: this.state.propArray.concat(price),
                links: this.state.links.concat(link)
            });
        }

        if(this.state.index >= totalResults){
            this.sortPropArray();
        }

        if(this.state.index < totalResults){
            this.state.index += 24;
            this.componentDidMount();
        }
    }

    sortPropArray() {
        let begin = 0;
        let end = this.state.outcodes.length;
        let prediction = [];
        if(this.state.postcode !== undefined){
            for(var k = begin; k <= end; k++){
                if(this.state.outcodes[k] !== undefined){
                        let expr = (
                            <option value={this.state.outcodes[k].outcode}></option>
                        );
                        prediction.push(expr);
                }
            }
        }

        this.setState({
            propArray: this.state.propArray.sort(function(a, b){return b-a})
        }, () => {
            this.filterPropArray();
        });
    }

    filterPropArray() {
        let greaterArray = [];
        let lesserArray = [];

        for(let i = 0; i < this.state.propArray.length; i++){
            if(this.state.propArray[i] > this.state.userPrice){
                greaterArray.push(this.state.propArray[i]);
            } else {
                lesserArray.push(this.state.propArray[i]);
            }
        }

        this.setState({
            greaterPropArray: greaterArray,
            lesserPropArray: lesserArray
        }, () => {
            this.findPostcode();
        });
    }

    findPostcode() {
        this.setState({
            postcode: this.state.outcodes[this.state.location - 1].outcode,
            postcodeNotFound: false
        }, () => {
            this.formatProps();
        });
    }

    formatProps(){
        let currentArray = [];

        for(let i = 0; i < this.state.greaterPropArray.length; i++){
            let link = this.state.links[i];
            currentArray.push(<a href={"https://www.rightmove.co.uk/property-for-sale/property-"+link+".html"} target="_blank" rel="noopener noreferrer">£{this.state.greaterPropArray[i]}</a>);
        }

        let pageNumber = Math.floor(this.state.greaterPropArray.length / 24 + 1);
        let pagePosition = this.state.greaterPropArray.length % 24 + 1;
        let linkLocation = 1;
        if(this.state.featured.length >= pageNumber){
            pagePosition = this.state.greaterPropArray.length % 24 + 2;
            linkLocation = 2;
        }
        currentArray.push(<span id="userPrice" className="optimiser-user-price">£{this.state.userPrice}&nbsp;<span><span className="fa fa-angle-left"></span>&nbsp;You are here</span></span>);

        for(let i = 0; i < this.state.lesserPropArray.length; i++){
            let link = this.state.links[i + ((pageNumber-1)*24 + pagePosition - linkLocation)];
            currentArray.push(<a href={"https://www.rightmove.co.uk/property-for-sale/property-"+link+".html"} target="_blank" rel="noopener noreferrer">£{this.state.lesserPropArray[i]}</a>);
        }

        this.setState({
            withUserPrice: currentArray,
            pageNumber: pageNumber,
            pagePosition: pagePosition
        }, () => {
            this.splitProps();
        });
    }

    splitProps() {
        const chunkSize = 24;
        const arr = this.state.withUserPrice;
        const groups = arr.map((e, i) => { 
            return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null; 
        }).filter(e => { return e; });

        let defaultPages = [];
        let count = 1;

        for(let i = 0; i < groups.length; i++){
            let tableStructure = (
                <div className="optimiser-td" id={count}>
                    <span className="optimiser-page">Page {count}</span>
                    <ul>
                        <li>{this.state.featured[i]}</li>
                        {groups[i].map(set => (
                            <li>{set}</li>
                        ))}
                    </ul>
                </div>
            );

            defaultPages.push(tableStructure);
            count++;
        }

        this.setState({
            pages: defaultPages,
            loading: null,
            loadingUpdate: null
        }, () => {
            if(this.state.scroll === true){
                this.scrollToObject();
                this.setState({
                    scroll: false
                });
            }
        });
    }

    updatePrice = (event) => {
        this.setState({
            userPrice: event.target.value
        }, () => {
            this.sortPropArray();
        });
    }

    updateRooms = (event) => {
        this.setState({
            numRooms: event.target.value
        });
    }

    updatePostcode = (event) => {
        this.setState({
            postcode: event.target.value.trim().toUpperCase()
        }, () => {
            let postcode = this.state.postcode;
            let result = this.state.outcodes.filter(function(e){return e.outcode === postcode});
            if(result[0] !== undefined){
                this.setState({
                    postcodeNotFound: false,
                    location: result[0].locationIdent,
                });
            } else {
                this.setState({
                    postcodeNotFound: true
                });
            }
        });
    }

    runUpdate = () => {
        this.setState({
            propArray: [],
            featured: [],
            index: 24,
            locationError: null,
            links: [],
            scroll: true,
            loadingUpdate: <div className="middle-container" id="loading">
                        <div className="top loading"><div className="section-content">
                            <div className="section-info">
                            <img className="logo" src={require("../img/splashHome.svg")} alt='free'></img>
                                <div className="loader">
                                    <div className="loader2">
                                        <div className="loader3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }, () => {
            this.componentDidMount();
        });
    }

    scrollToObject = () => {
        document.getElementById(this.state.pageNumber).scrollIntoView({inline: "center"});
    }

    scrollToHome = () => {
        document.getElementById("slide1").scrollIntoView({inline: "center"});
    }

    increasePrice = () => {
        this.setState({
            userPrice: this.state.greaterPropArray[this.state.greaterPropArray.length - 1],
            scroll: true
        }, () => {
            this.sortPropArray();
        });
    }

    decreasePrice = () => {
        this.setState({
            userPrice: this.state.lesserPropArray[0] - 50,
            scroll: true
        }, () => {
            this.sortPropArray();
        });
    }

    startSession = () => {
        this.setState({
            started: true
        });
    }

    plusPrice = (e) => {
        e.preventDefault();
        let newPrice = parseInt(this.state.userPrice) + 1000;
        this.setState({
            userPrice: newPrice,
            scroll: false
        }, () => {
            this.sortPropArray();
        });
    }

    minusPrice = (e) => {
        e.preventDefault();
        let newPrice = this.state.userPrice - 1000;
        if(newPrice < 0){
            newPrice = this.state.userPrice;
        }
        this.setState({
            userPrice: newPrice,
            scroll: false
        }, () => {
            this.sortPropArray();
        });
    }

    plusRooms = (e) => {
        e.preventDefault();
        this.setState({
            numRooms: parseInt(this.state.numRooms) + 1,
            scroll: false
        });
    }

    minusRooms = (e) => {
        e.preventDefault();
        if(this.state.numRooms !== 0){
            this.setState({
                numRooms: this.state.numRooms - 1,
                scroll: false
            });
        }
    }

    render() {
        let display = "none";
        if(this.state.loading === null){
            display = "block";
        }

        let opacity = "0.2";
        let scroll = "none";
        if(this.state.loadingUpdate === null){
            opacity = "1";
            scroll = "block"
        }

        let greaterDisplay = <button style={{cursor: "pointer"}} className="positionBtn incrBtn" onClick={this.increasePrice}>Increase Position</button>;
        if(this.state.greaterPropArray.length === 0){
            greaterDisplay = <button style={{cursor: "auto"}} className="positionBtn incrBtn disableBtn">Increase Position</button>;
        }

        let lesserDisplay = <button style={{cursor: "pointer"}} className="positionBtn decrBtn" onClick={this.decreasePrice}>Decrease Position</button>;
        if(this.state.lesserPropArray.length === 0){
            lesserDisplay = <button style={{cursor: "auto"}} className="positionBtn decrBtn disableBtn">Decrease Position</button>;
        }

        let colour = "#02bc8320";
        if(this.state.postcodeNotFound === true){
            colour = "#bc020240";
        }

        let priceColour = "#bc020240";
        if(this.state.userPrice > 0){
            priceColour = "#02bc8320";
        }

        let roomColour = "#bc020240";
        if(this.state.numRooms >= 0){
            roomColour = "#02bc8320";
        }

        return (
            <div className="optimiser2">
            
            
            {this.state.loading}
            
                <div className="main" style={{display: display, opacity: opacity}}>
                <div valign="center" id="slide1">

                    <div className="formContent" id="formHouse">
                        {/* <img className="logo" src={require("../img/splashHome.svg")}></img> */}

                        {/* <br/> */}

                        <span id="comment">How visible is your property?</span>

                        <br/>

                        <span id="fillComment">Fill out your details to find out!</span>
                        
                        <br/><br/>

                        <div className="propertyForm">

                            <label>Price of Property<br/><span className="poundSign">£</span>
                                <span className="inputBar">
                                    <input 
                                        style={{backgroundColor: priceColour}}
                                        id="priceInput"
                                        type="number" 
                                        min="0" 
                                        value={this.state.userPrice} 
                                        placeholder={this.state.userPrice} 
                                        onChange={this.updatePrice}
                                        step="50">
                                    </input>
                                    <button onClick={this.minusPrice} className="negative"><i className="fa fa-minus" aria-hidden="true"></i></button>
                                    <button onClick={this.plusPrice} className="plus"><i className="fa fa-plus" aria-hidden="true"></i></button>
                                </span>
                            </label>
                            &nbsp;
                            <span className="tooltip">
                                <i className="fas fa-info-circle"></i>
                                <span className="tooltiptext">The price that you would like to sell your house at</span>
                            </span>

                            <br/>

                            <label>Location of Property<br/>
                                <input
                                    style={{backgroundColor: colour}}
                                    id="locationInput"
                                    type="text"
                                    list="postcodes" 
                                    name="myBrowser" 
                                    value={this.state.postcode}
                                    onChange={this.updatePostcode}
                                />
                            </label>
                            <datalist id="postcodes">
                                {this.state.predictionArray}
                            </datalist>
                            &nbsp;
                            <span className="tooltip">
                                <i className="fas fa-info-circle"></i>
                                <span className="tooltiptext">The postcode district of your location, e.g. SW2</span>
                            </span>

                            <br/>

                            <label>Number of Bedrooms <br/>
                                
                                <input 
                                    style={{backgroundColor: roomColour}}
                                    type="number" 
                                    min="0" 
                                    value={this.state.numRooms} 
                                    placeholder={this.state.numRooms} 
                                    onChange={this.updateRooms}>
                                </input>
                                <button onClick={this.minusRooms} className="negative"><i className="fa fa-minus" aria-hidden="true"></i></button>
                                <button onClick={this.plusRooms} className="plus"><i className="fa fa-plus" aria-hidden="true"></i></button>
                            </label>
                            &nbsp;
                            <span className="tooltip">
                                <i className="fas fa-info-circle"></i>
                                <span className="tooltiptext">The number of bedrooms in your house</span>
                            </span>

                        </div>

                        <br className="firstBreak"/>

                        <div className="notes">
                            <span className="note">You are number <span className="score">{this.state.pagePosition}</span> on page <span className="score">{this.state.pageNumber}</span></span><br/>
                            {/* {moveUp} */}
                        </div>

                        <br className="secondBreak"/>
                        
                        <button className="updateBtn" onClick={this.runUpdate}>UPDATE</button>

                        <button className="scrollBtn" onClick={this.scrollToObject}>Scroll to see listings <span className="fa fa-angle-down scrollToPages"></span></button>

                    </div>
                </div>

                <div valign="center" id="slide2" style={{display: scroll}}>

                    <div className="formContent">

                        <span className="alterPriceText">Page <span className="score">{this.state.pageNumber}</span>, number <span className="score">{this.state.pagePosition}</span></span>
                        
                        <br className="firstBreak"/>
                        <br className="firstBreak"/>

                        <span id="clickNote">
                            <small>Click on a listing to view the property!</small>
                        </span>

                        <span className="fadeLeft"></span>
                        <div className="scrollTable">
                            <span className="optimiser-table">
                                {this.state.pages}
                            </span>
                        </div>
                        <span className="fadeRight"></span>

                        <br className="secondBreak"/>

                        {lesserDisplay}
                        {greaterDisplay}

                    </div>
                </div>
            </div>
            {this.state.loadingUpdate}
        </div>
        );
    }
}

export default Optimiser2;
