import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import "./components/landing_menu.sass";
import "./messagebird.sass"

import store from './store'
import { Provider } from 'react-redux'
import * as FullStory from '@fullstory/browser';

FullStory.init({ orgId: 'WNJTS', recordCrossDomainIFrames: true });

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter><App /></BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
