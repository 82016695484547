import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function Bankruptcy2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Has " + props.store.first_name_2 + " ever filed for bankruptcy?",
                        info: {
                            id: "bankruptcy_modal_2",
                            text: "What is bankruptcy?",
                            content: "Bankruptcy is a legal status for people who're unable to repay the money they owe.",
                            content2: "Bankruptcy is seen as a serious credit issue because it tells mortgage lenders you’ve had issues with repaying debts in the past. Even if this is no longer the case. Bankruptcy will remain on your credit file for a period of six years."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 2
                            }
                        }
                    }} id={"been_bankrupt_2"} setValue={(e) => props.setValue({ ...props.store, been_bankrupt_2: e })} />
                )
            
            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "When was " + props.store.first_name_2 + " discharged?",
                        input: {
                            type: "month",
                            id: "bankruptcy_discharged_2",
                            min: 7,
                            next: props.switch + 2
                        },
                        sub_button: {
                            "Or mark this bankruptcy as not yet satisfied": {
                                value: "ONGOING",
                                next: props.switch + 2
                            }
                        }
                    }} id={"bankruptcy_discharged_2"} setValue={(e) => props.setValue({ ...props.store, bankruptcy_discharged_2: e })} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Bankruptcy2;