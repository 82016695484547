import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function Default(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        if (props.page === props.switch + 5 && props.store.default_date !== undefined && props.store.default_amount !== undefined && props.store.default_settled !== undefined && props.store.default_for_mobile !== undefined) {
            let store = {...props.store}
            let default_date = store.default_date
            let default_amount = store.default_amount
            let default_settled = store.default_settled
            let default_for_mobile = store.default_for_mobile
            delete store.default_date
            delete store.default_amount
            delete store.default_settled
            delete store.default_for_mobile
            props.setValue({ ...store, defaults: [...props.store.defaults, {default_date: default_date, default_amount: default_amount, default_settled: default_settled, default_for_mobile: default_for_mobile }]})
        }
        setDisplay(displayPage())
    }, [props.page, props.store])

    function remove_default(i) {
        props.store.defaults.splice(i, 1)
        props.setValue({...props.store, defaults: props.store.defaults})
    }

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Have you ever defaulted?",
                        info: {
                            id: "default_modal",
                            text: "What is a default?",
                            content: "A default means that you failed to repay a debt, so it negatively affects your credit score – causing it to go down.",
                            content2: "Lenders don’t like defaults because it’s a signal to them that you’ve struggled to repay debts in the past. And this means you’re considered a ‘high-risk’ borrower."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 6
                            }
                        }
                    }} id={"had_a_default"} setValue={(e) => props.setValue({ ...props.store, had_a_default: e, defaults: [] })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When was your most recent default?",
                        input: {
                            type: "month",
                            placeholder: "MM - YYYY",
                            id: "default_date",
                            min: 7,
                            next: props.switch + 2
                        }
                    }} id={"default_date"} setValue={(e) => props.setValue({ ...props.store, default_date: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "How much was the default for?",
                        input: {
                            type: "number",
                            placeholder: "10000",
                            id: "default_amount",
                            next: props.switch + 3
                        }
                    }} id={"default_amount"} setValue={(e) => props.setValue({ ...props.store, default_amount: e })} />
                )

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When was the default settled?",
                        input: {
                            type: "month",
                            placeholder: "MM - YYYY",
                            id: "default_settled",
                            min: 7,
                            next: props.switch + 4
                        },
                        sub_button: {
                            "Or mark this Default as not yet satisfied": {
                                value: "ONGOING",
                                next: props.switch + 4
                            }
                        }
                    }} id={"default_settled"} setValue={(e) => props.setValue({ ...props.store, default_settled: e })} />
                )

            case props.switch + 4:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Was the default for a mobile phone?",
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 5
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 5
                            }
                        }
                    }} id={"default_for_mobile"} setValue={(e) => props.setValue({ ...props.store, default_for_mobile: e })} />
                )

            case props.switch + 5:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Please list all defaults since May 2019",
                        info: {
                            id: "default_modal_2",
                            text: "What is a default?",
                            content: "A default means that you failed to repay a debt, so it negatively affects your credit score – causing it to go down.",
                            content2: "Lenders don’t like defaults because it’s a signal to them that you’ve struggled to repay debts in the past. And this means you’re considered a ‘high-risk’ borrower."
                        },
                        collection: {
                            type: "default",
                            add: props.switch + 1
                        },
                        next: props.switch + 6
                    }} id={"default_collection"} setValue={(e) => {}} remove={(e) => remove_default(e)} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Default;