import React from 'react';
import json from './outcodes.json';
import Loader from '../loader';
import "./_valuator.sass"

class Valuator extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			postcode: "EH10",
			postcodeNotFound: false,
			outcode: 794,
			count: 1,
			numRooms: 2,
			priceArray: [],
			pastPriceArray: [0],
			totalPrice: 0,
			numMatches: 0,
			avgPrice: 0,
			estPrice: 0,
			decisions: [],
			calculationArray: [],
			lessThanPrice: [],
			moreThanPrice: [],
			images: [],
			pages: [],
			pagesInUse: [],
			pagesInUseHistory: [],
			pagesHistory: [],
			data: [],
			modal: null,
			properties: [],
			pulledProperties: [],
			outcodes: json,
			predictionArray: null,
			pageCount: 0,
			originalPageCount: 0,
			error: null,
			imageScroll: 1,
			maxScroll: null,
			loaded: false,
			loading: <Loader transparent absolute show text="This may take a few minutes" />
		}
	}

	//Pull all properties sold in the past year matching the location and number of bedrooms
	async componentDidMount() {
		let postcode = this.props.postcode.trim();
		this.setState({
			postcode: postcode
		});

		let numRooms = this.props.numRooms;
		this.setState({
			numRooms: parseInt(numRooms)
		});

		let outcodeSearch = this.state.outcodes.find(record => record.outcode === postcode);
		let outcode = outcodeSearch.locationIdent;

		this.setState({
			outcode: outcode
		});

		let begin = 0;
		let end = this.state.outcodes.length;
		let prediction = [];
		if (this.state.postcode !== undefined) {
			for (var k = begin; k <= end; k++) {
				if (this.state.outcodes[k] !== undefined) {
					let expr = (
						<option key={"option_" + k} value={this.state.outcodes[k].outcode}></option>
					);
					prediction.push(expr);
				}
			}
		}

		this.setState({
			predictionArray: prediction
		});

		// Without a proxy, used a chrome extension to circumvent the issue, which I think arose due to the fact that it was being run locally:
		const url = "https://api.allorigins.win/raw?url=" + encodeURIComponent("https://www.rightmove.co.uk/house-prices/result?locationType=OUTCODE&locationId=" + outcode + "&soldIn=1&page=" + this.state.count);

		const response = await fetch(url);
		const data = await response.json();

		// let firstSplit = data.search("window.__PRELOADED_STATE__ = ") + 29;
		// let splitOne = data.slice(firstSplit, data.length);
		// let secondSplit = splitOne.search("</script>");
		// let splitTwo = splitOne.slice(0, secondSplit);
		let result = data;

		if (result.results.resultCount === "0") {
			this.setState({
				error: `Sorry, no results for area code ${this.props.postcode}`,
				loading: null
			});
		} else if (result.results.resultCount !== "0") {

			let properties = result.results.properties;

			let totalPrice = 0;
			let numMatches = 0;
			let property = this.state.properties;

			for (let i = 0; i < properties.length; i++) {

				let numRoomsResult = properties[i].bedrooms;

				if (numRoomsResult === this.state.numRooms) {
					let price = properties[i].transactions[0].displayPrice;
					price = parseInt(price.slice(1, price.length).replace(/,/g, ""));
					if (properties[i].images.count > 1) {
						property.push(properties[i]);
						totalPrice = totalPrice + price;
						numMatches++;
					}
				}
			}

			this.setState({
				count: this.state.count + 1,
				properties: property,
				data: this.state.data.concat(result),
				totalPrice: parseInt(this.state.totalPrice) + totalPrice,
				numMatches: this.state.numMatches + numMatches,
				error: null
			}, () => {
				this.setState({
					avgPrice: Math.round(this.state.totalPrice / this.state.numMatches)
				}, () => {
					if (result.pagination.last >= this.state.count) {
						this.componentDidMount();
					} else {
						if (property.length === 0) {
							this.setState({
								error: `Sorry, no results for area code ${this.props.postcode}`,
								loading: null
							});
						} else {
							this.sortProperties();
						}
					}
				});
			});
		} else {
			this.setState({
				error: "Unknown Error",
				loading: null
			});
		}
	}

	//Sort properties into ascending order of price sold
	sortProperties() {
		let pastPriceArray = [];
		pastPriceArray.push(this.state.avgPrice);

		this.setState({
			properties: this.state.properties.sort(function (a, b) {
				a = parseInt(a.transactions[0].displayPrice.slice(1, a.length).replace(/,/g, ""))

				b = parseInt(b.transactions[0].displayPrice.slice(1, b.length).replace(/,/g, ""));

				return a - b
			}),
			pastPriceArray: pastPriceArray
		}, () => {
			this.sortData();
		});
	}

	async sortData() {

		let size = this.state.properties.length;
		let increment = Math.round(size / 15);
		if (size < 15) {
			increment = Math.ceil(size / 15);
		}

		let property = [];
		let counter = 0;
		let x = Math.round(size / 2) - increment;
		let y = Math.round(size / 2) - increment - increment;

		for (let i = 0; i < size; i += increment) {
			let variable = x;
			if ((i / increment) % 2 !== 0) {
				variable = y;
				y = y - increment;
				if (y < 0) {
					variable = x;
					x = x + increment;
				} else {
					counter++;
				}
			} else {
				variable = x;
				x = x + increment;
			}

			let priceArray2 = this.state.priceArray;
			let imageArray2 = this.state.images;
			let page = this.state.pages;
			let properties = this.state.properties;
			if (properties[variable] !== undefined) {
				let price = properties[variable].transactions[0].displayPrice;
				price = parseInt(price.slice(1, price.length).replace(/,/g, ""));

				let imgUrl = properties[variable].images.imageUrl;
				imgUrl = imgUrl.replace("135x100", "656x437");
				let image = <img className="cardImg" alt="PropertyImage" src={imgUrl}></img>;
				imageArray2.push(image);

				let address = properties[variable].address;
				let dateSold = properties[variable].transactions[0].dateSold;

				let url2 = properties[variable].detailUrl;
				url2 = url2.replace(/&amp;/g, "&");

				let response2 = await fetch("https://api.allorigins.win/raw?url=" + encodeURIComponent(url2));
				let data2 = await response2.text();

				let firstSplit2 = data2.search("RIGHTMOVE.PROPERTYDETAILS.imageGallery.init") + 56;
				let splitOne2 = data2.slice(firstSplit2, data2.length);
				let secondSplit2 = splitOne2.search("mainImageContainer") - 4;
				let splitTwo2 = splitOne2.slice(0, secondSplit2);
				let result2;
				try {
					result2 = JSON.parse(splitTwo2);
				} catch {
					result2 = [];
				}

				properties[variable].extraImages = result2;

				property.push(properties[variable]);

				let extraImages = [];

				for (let j = 0; j < properties[variable].extraImages.length; j++) {
					let extraImage = properties[variable].extraImages[j].thumbnailUrl;
					extraImage = extraImage.replace("135x100", "656x437");
					extraImages.push(<img style={{ width: `calc(100% / ${parseInt(properties[variable].extraImages.length * 1.5)}` }} key={`image_${i}_${j}`} className="cardImg" id={"image" + (parseInt(j) + 1)} alt="PropertyImage" src={extraImage} />);
				}

				let pageObject = ({
					"page":
						<div>
							<div className="card">

								<div style={{ width: "100%", overflow: "hidden", scrollBehavior: "smooth" }}>
									<div style={{ width: parseInt(extraImages.length * 1.5) + "00%", textAlign: "left" }}>
										{extraImages}
									</div>
								</div>

								<div className="cardInfo">
									<div className="cardAddress">
										{address}
									</div>
									<div className="cardExtraInfo">
										{this.state.numRooms} Bedroom House
									</div>
									<div className="cardPrice">
										£{price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
									</div>
									<div className="cardSold">
										Sold {dateSold}
									</div>
								</div>

								<button className="siteButton">
									<a href={properties[variable].detailUrl} target="_blank" rel="noopener noreferrer"><i className="fa fa-globe"></i> Visit Site</a>
								</button>


								{/* <button className="numPhotos" onClick={() => this.openModal(properties[variable].detailUrl)}>
							{properties[variable].images.count} Photos <i className='far fa-images'></i>
						</button> */}

								<div className="valueForm" id="radioSet">
									<button id="lessThanMine" onClick={this.radioChangeDown} value={price} className="radioBtn radioDown">Less than mine</button><br />
									<button id="sameAsMine" onClick={this.radioChangeSame} value={price} className="radioBtn radioSame">About the same</button><br />
									<button id="moreThanMine" onClick={this.radioChangeUp} value={price} className="radioBtn radioUp">More than mine</button>
									<div className="middle-container" id="loaderButton"></div>
								</div>
							</div>
						</div>,
					count: properties[variable].extraImages.length
				}
				);

				if (variable >= size / 2) {
					page.push(pageObject);
				} else {
					page.splice(0, 0, pageObject);
				}

				let math = Math.round(page.length / 2);
				if (math === 1) {
					math = 0;
				}

				if (i === 0) {
					let history = [page[counter]];
					this.setState({
						pagesHistory: history,
						loading: null
					});
				}

				this.setState({
					pageCount: counter,
					originalPageCount: counter,
					priceArray: priceArray2,
					pages: page,
					pagesInUse: page,
					pagesInUseHistory: [page],
					pulledProperties: property,
					maxScroll: properties[0].images.count
				}, () => {
					// this.setState({
					// 	pageCount: Math.round(this.state.pulledProperties.length / 2)
					// });
					// if(i > (size / 2)){
					// 	this.setState({
					// 		loading: null
					// 	})
					// }
					if (i > (size - 1 - increment)) {
						this.loadPages();
					}
				});
			};
		}

		this.setState({
			loading: null,
			loaded: true
		});
	}

	loadPages() {
		let inUse = this.state.pagesInUse.slice();

		let index = Math.round(this.state.pagesInUse.length / 2);
		inUse.splice(index, 1);

		let inUseHistory = [inUse];

		this.setState({
			pagesInUse: inUse,
			pageCount: index,
			pagesInUseHistory: inUseHistory
		}, () => {
			// this.loadImages();
		});
	}

	updatePostcode = (e) => {
		this.setState({
			postcode: e.target.value.trim().toUpperCase()
		}, () => {
			let postcode = this.state.postcode;
			let result = this.state.outcodes.filter(function (e) { return e.outcode === postcode });
			if (result[0] !== undefined) {
				this.setState({
					postcodeNotFound: false,
					outcode: result[0].locationIdent,
				});
			} else {
				this.setState({
					postcodeNotFound: true
				});
			}
		});
	}

	updateRoomNumber = (e) => {
		this.setState({
			numRooms: parseInt(e.target.value)
		});
	}

	runUpdate = () => {
		this.setState({
			count: 1,
			priceArray: [],
			pastPriceArray: [0],
			properties: [],
			pulledProperties: [],
			images: [],
			pages: [],
			pagesInUse: [],
			pagesHistory: [],
			pagesInUseHistory: [],
			data: [],
			calculationArray: [],
			lessThanPrice: [],
			moreThanPrice: [],
			totalPrice: 0,
			numMatches: 0,
			avgPrice: 0,
			estPrice: 0,
			pageCount: 0,
			imageScroll: 1,
			loaded: false,
			loading: <Loader transparent absolute show text="This may take a few minutes" />
		}, () => {
			this.componentDidMount();
		});
	}

	radioChangeDown = (e) => {
		let calculationArray = this.state.calculationArray;
		let lessThanPrice = this.state.lessThanPrice;
		let count = 0;

		let decisions = this.state.decisions.slice();
		decisions.push("less");

		for (let i = 0; i < calculationArray.length; i++) {
			if (calculationArray[i] > 0) {
				count++;
			}
		}

		lessThanPrice.push(e.target.value * 1.1);
		if (count > 0 && this.state.avgPrice < e.target.value) {
			calculationArray.push(e.target.value * 1.1);
		} else {
			calculationArray.push(0);
		}

		let inUse = this.state.pagesInUse.slice();

		inUse.splice(this.state.pageCount, 1);

		let history = this.state.pagesHistory.slice();
		history.push(inUse[this.state.pageCount]);

		let inUseHistory = this.state.pagesInUseHistory.slice();
		inUseHistory.push(inUse);

		this.setState({
			calculationArray: calculationArray,
			lessThanPrice: lessThanPrice,
			pagesHistory: history,
			pagesInUse: inUse,
			decisions: decisions,
			pagesInUseHistory: inUseHistory,
			imageScroll: 1
		}, () => {
			this.calculateEstPrice();
			this.scrollToLeft();
		});
	}

	radioChangeSame = (e) => {
		let decisions = this.state.decisions.slice();
		decisions.push("same");

		let calculationArray = this.state.calculationArray;
		calculationArray.push(e.target.value);

		let inUse = this.state.pagesInUse.slice();
		inUse.splice(this.state.pageCount, 1);

		let history = this.state.pagesHistory.slice();

		let pageCount = this.state.pageCount;
		if (pageCount > inUse.length - 1) {
			pageCount = inUse.length - 1;
		} else if (history.length % 2 === 0) {
			pageCount -= 1;
		}

		history.push(inUse[pageCount]);

		let inUseHistory = this.state.pagesInUseHistory.slice();
		inUseHistory.push(inUse);

		this.setState({
			pagesHistory: history,
			pagesInUse: inUse,
			pageCount: pageCount,
			calculationArray: calculationArray,
			decisions: decisions,
			pagesInUseHistory: inUseHistory,
			imageScroll: 1,
		}, () => {
			this.calculateEstPrice();
			this.scrollToLeft();
		});
	}

	radioChangeUp = (e) => {
		let decisions = this.state.decisions.slice();
		decisions.push("more");

		let calculationArray = this.state.calculationArray;
		let moreThanPrice = this.state.moreThanPrice;
		let count = 0;

		for (let i = 0; i < calculationArray.length; i++) {
			if (calculationArray[i] > 0) {
				count++;
			}
		}

		moreThanPrice.push(e.target.value * 0.9);
		if (count > 0 && this.state.avgPrice > e.target.value) {
			calculationArray.push(e.target.value * 0.9);
		} else {
			calculationArray.push(0);
		}

		let inUse = this.state.pagesInUse.slice();

		let history = this.state.pagesHistory.slice();
		if (this.state.pageCount < 0) {
			history.push(null);
		} else {
			history.push(inUse[this.state.pageCount - 1]);
			inUse.splice(this.state.pageCount - 1, 1);
		}

		let pageCount = this.state.pageCount;

		let inUseHistory = this.state.pagesInUseHistory.slice();
		inUseHistory.push(inUse);

		this.setState({
			calculationArray: calculationArray,
			moreThanPrice: moreThanPrice,
			pageCount: pageCount - 1,
			decisions: decisions,
			pagesHistory: history,
			pagesInUse: inUse,
			pagesInUseHistory: inUseHistory,
			imageScroll: 1,
		}, () => {
			this.calculateEstPrice();
			this.scrollToLeft();
		});
	}

	goBack = () => {
		let calculationArray = this.state.calculationArray;
		calculationArray.pop();

		let history = this.state.pagesHistory.slice();
		let decisions = this.state.decisions.slice();
		let last = decisions[decisions.length - 1];
		let index = this.state.pageCount;

		if (last === "less") {
			index -= 1;
		}

		if (history.length % 2 === 0 && last === "same") {
		} else {
			index += 1;
		}

		decisions.pop();

		let inUseHistory = this.state.pagesInUseHistory.slice();
		inUseHistory.pop();
		history.pop();

		let pastPriceArray = this.state.pastPriceArray;
		pastPriceArray.pop();

		this.setState({
			pageCount: index,
			calculationArray: calculationArray,
			lessThanPrice: [],
			moreThanPrice: [],
			pastPriceArray: pastPriceArray,
			avgPrice: pastPriceArray[pastPriceArray.length - 1],
			pagesHistory: history,
			pagesInUse: inUseHistory[inUseHistory.length - 1],
			pagesInUseHistory: inUseHistory,
			decisions: decisions,
			imageScroll: 1
		}, () => {
			this.scrollToLeft();
		});
	}

	restart = () => {
		let avgPrice = this.state.pastPriceArray[0];
		let history = this.state.pagesHistory;

		while (history.length > 1) {
			history.pop();
		}

		let pages = this.state.pages.slice();
		let index = this.state.originalPageCount;
		// pages.splice(index, 1);

		this.setState({
			avgPrice: avgPrice,
			pastPriceArray: [this.state.pastPriceArray[0]],
			lessThanPrice: [],
			moreThanPrice: [],
			calculationArray: [],
			pagesHistory: history,
			decisions: [],
			pagesInUse: pages,
			pagesInUseHistory: [pages],
			pageCount: index,
			imageScroll: 1
		}, () => {
			this.scrollToLeft();
		});
	}

	calculateEstPrice() {
		let calculationArray = this.state.calculationArray;

		let total = 0;
		let count = 0;
		let est = this.state.avgPrice;

		for (let i = 0; i < calculationArray.length; i++) {
			if (calculationArray[i] !== 0) {
				total = parseInt(total) + parseInt(calculationArray[i]);
				count++;
				est = total / count;
			}
		}

		if (calculationArray.length !== 0) {
			est = est * 1.025;
		}

		if (count === 0 && this.state.moreThanPrice.length > 0) {
			if (this.state.moreThanPrice[this.state.moreThanPrice.length - 1] < this.state.avgPrice) {
				est = this.state.moreThanPrice[this.state.moreThanPrice.length - 1] * 1.08;
			}
		} else if (count === 0 && this.state.lessThanPrice.length > 0) {
			if (this.state.lessThanPrice[this.state.lessThanPrice.length - 1] > this.state.avgPrice) {
				est = this.state.lessThanPrice[this.state.lessThanPrice.length - 1];
			}
		}

		if (est === null || est === undefined) {
			est = this.state.average;
		}

		let pastPriceArray = this.state.pastPriceArray;
		pastPriceArray.push(Math.round(est));

		this.setState({
			pastPriceArray: pastPriceArray,
			avgPrice: Math.round(est)
		}, () => {
		});
	}

	scrollToRight = () => {
		let scroll = this.state.imageScroll + 1;
		if (!document.getElementById("image" + scroll)) {
			scroll -= 1;
		}
		this.setState({
			imageScroll: scroll
		}, () => {
			if (document.getElementById("image" + this.state.imageScroll)) {
				document.getElementById("image" + this.state.imageScroll).scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
			}
		});
	}

	scrollToLeft = () => {
		let scroll = this.state.imageScroll;
		if (scroll > 1) {
			scroll -= 1;
		}
		this.setState({
			imageScroll: scroll
		}, () => {
			if (document.getElementById("image" + this.state.imageScroll)) {
				document.getElementById("image" + this.state.imageScroll).scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
			}
		});
	}

	render() {

		// if(document.getElementById("image"+this.state.imageScroll)){
		// 	this.scrollToLeft();
		// }

		let mainDisplay = "none";
		if (this.state.loading === null) {
			mainDisplay = "block";
		}

		let endDisplay = "none";
		let removeDisplay = "inline-block";
		if (this.state.pagesInUse.length === 0 || this.state.pagesHistory[this.state.pagesHistory.length - 1] === undefined) {
			if (this.state.error === null || this.state.error === undefined) {
				endDisplay = "inline-block";
				removeDisplay = "none";
			}
		}

		let back = <button className="backBtn backBtnActive" style={{ display: removeDisplay }} onClick={this.goBack}>
			<i className="fa fa-angle-left"></i> Back
		</button>;

		let restart = <button className="backBtn backBtn2 backBtnActive" style={{ display: removeDisplay }} onClick={this.restart}>
			<i className="fas fa-redo"></i> Restart
		</button>;
		if (this.state.pagesHistory.length === 1 || this.state.error !== null) {
			back = <button className="backBtn" style={{ color: "#9e9e9e", cursor: "auto", display: removeDisplay }}>
				<i className="fa fa-angle-left"></i> Back
			</button>;

			restart = <button className="backBtn backBtn2" style={{ color: "#9e9e9e", cursor: "auto", display: removeDisplay }}>
				<i className="fas fa-redo"></i> Restart
			</button>;
		}

		let page = null;
		let count = 0;
		if (this.state.pagesHistory[this.state.pagesHistory.length - 1] !== null && this.state.pagesHistory[this.state.pagesHistory.length - 1] !== undefined) {
			page = this.state.pagesHistory[this.state.pagesHistory.length - 1].page;
			count = this.state.pagesHistory[this.state.pagesHistory.length - 1].count;
		}

		if (!this.state.loaded) {
			if (document.getElementById("lessThanMine")) {
				document.getElementById("lessThanMine").disabled = true;
				document.getElementById("sameAsMine").disabled = true;
				document.getElementById("moreThanMine").disabled = true;
				document.getElementById("lessThanMine").style.color = "#9e9e9e";
				document.getElementById("sameAsMine").style.color = "#9e9e9e";
				document.getElementById("moreThanMine").style.color = "#9e9e9e";
				document.getElementById("sameAsMine").style.borderTop = "1px solid #9e9e9e";
				document.getElementById("moreThanMine").style.borderTop = "1px solid #9e9e9e";
			}

			// if (document.getElementById("radioSet")) {
			// 	let set = document.getElementById("radioSet");
			// 	set.style.opacity = "0.6";
			// }
		} else {
			if (document.getElementById("lessThanMine")) {
				document.getElementById("lessThanMine").disabled = false;
				document.getElementById("sameAsMine").disabled = false;
				document.getElementById("moreThanMine").disabled = false;
				document.getElementById("lessThanMine").style.color = "black";
				document.getElementById("sameAsMine").style.color = "black";
				document.getElementById("moreThanMine").style.color = "black";
				document.getElementById("sameAsMine").style.borderTop = "1px solid black";
				document.getElementById("moreThanMine").style.borderTop = "1px solid black";
				document.getElementById("lessThanMine").classList.add("readyButton");
				document.getElementById("sameAsMine").classList.add("readyButton");
				document.getElementById("moreThanMine").classList.add("readyButton");
			}

			if (document.getElementById("loaderButton")) {
				let set = document.getElementById("loaderButton");
				set.style.display = "none";
			}
		}

		return <div id="valuator">

			<div style={{ position: "relative", height: "200px", display: this.state.loading === null ? "none" : "" }}>
				{this.state.loading}
			</div>

			<div style={{ display: mainDisplay }}>
				{/* <div className="context">
					<div className="contextHeading">
						<strong>Are you getting a good price on your new home?</strong>
					</div>
					<div className="contextContent">
						<div className="contextText">
							We'll give you an estimated valuation of your new home based on your opinion of similar properties in the area.
						</div>

						<i>
							Compare the location, quality of the interior, size of rooms, and any features to decide
							if the property is worth more or less than yours.
						</i>
					</div>
				</div> */}

				{/* <div className="searchBar">
					<label>
						<strong>Postcode:</strong>
						<input
							className="postcodeInput"
							onChange={this.updatePostcode}
							list="postcodes"
							type="text"
							value={this.state.postcode}
							style={{ backgroundColor: colour }}
						></input>
						<datalist id="postcodes">
							{this.state.predictionArray}
						</datalist>
					</label>

					<label>
						&nbsp;&nbsp;
						<strong>No. of Bedrooms:</strong>
						<input 
							className="roomNumInput" 
							onChange={this.updateRoomNumber} 
							type="number" 
							value={this.state.numRooms}
						>
						</input>
					</label>
					
					<button className="searchBtn" onClick={this.runUpdate}>Search</button>
				</div> */}

				{/* Number of results: {this.state.properties.length}
					<br/>
					Number shown: {this.state.pulledProperties.length}
					<br/>
					Min price: {minPrice}
					<br/>
					Max price: {maxPrice}
					<br/> */}

				<div className="priceLine">

				</div>
				<div className="priceDot">

				</div>
				<table style={{ display: this.state.error !== null ? "none" : "" }}>
					<tbody>
						<tr valign="middle">
							<td className="altPrice altPriceMin">
								<span className="amount">£{(Math.round(this.state.pastPriceArray[this.state.pastPriceArray.length - 1] / 100 * 90)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
								<br />
								Min. Value
							</td>
							<td className="estPrice">
								<span className="amount valuation">£{this.state.pastPriceArray[this.state.pastPriceArray.length - 1].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
								<br />
								Estimated Value
							</td>
							<td className="altPrice altPriceMax">
								<span className="amount">£{(Math.round(this.state.pastPriceArray[this.state.pastPriceArray.length - 1] / 100 * 110)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
								<br />
								Max. Value
							</td>
						</tr>
					</tbody>
				</table>

				{this.state.modal}
				<div className="error">
					{this.state.error}
				</div>

				<div className="game" style={{ display: this.state.error !== null ? "none" : "" }}>
					<div className="cardHeading" style={{ display: removeDisplay, width: "400px", maxWidth: "100%" }}>
						Do you think this property is worth more or less than yours?
					</div>
					<div style={{ display: endDisplay, padding: "15px", width: "400px", maxWidth: "100%" }}>
						{/* <img src={require("../img/rosette-green.svg")} className="rosette" alt='rosette'></img> */}
						<div className="finishPrice">£{this.state.pastPriceArray[this.state.pastPriceArray.length - 1].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
						<span className="finishText">Congratulations!<br /><br /></span>
						<span className="finishExText">You have a more accurate valuation based on similar properties in your area.<br /><br /></span>
						<span className="finishExText">
							{this.state.pastPriceArray[this.state.pastPriceArray.length - 1] > this.props.price
								?
								"Your custom valuation is more than the property is up for. This is great news, it means you are getting a good deal!"
								:
								"Your custom valuation is less than the property is up for. Perhaps you aren't getting the best deal on your property."
							}
							<br /></span>
					</div>
					<br />
					{back}
					{restart}
					<br />
					<div className="scrollImages" style={{ display: removeDisplay }}>
						<button className="leftScroll" onClick={this.scrollToLeft}>
							<i className="fa fa-angle-left"></i>
						</button>
						<button className="imageNumber">
							{this.state.imageScroll} of {count} Photos
						</button>
						<button className="rightScroll" onClick={this.scrollToRight}>
							<i className="fa fa-angle-right"></i>
						</button>
					</div>

					{page}
				</div>
			</div>
		</div>;
	}
}

export default Valuator;