import React from 'react';

function MortgageType(props) {
    return (
        <div>
            <div className='heading'>
                What type of mortgage are you looking for?
            </div>
            <div className='content'>
                Let us know your prferred mortgage details.
            </div>

            <div className='input_contain'>
                <div className='input_wrap'>
                    <select value={props.store?.preference_mortgage_reason || undefined} onChange={e => props.add("preference_mortgage_reason", e.currentTarget.value)} style={{ width: "calc(100% + 2px)", color: props.store?.preference_mortgage_reason ? "black" : "grey" }}>
                        <option value="" disabled selected>Mortgage reason</option>
                        {getOption("First time buyer or purchase only", "FTB")}
                        {getOption("House move", "HOUSE_MOVE")}
                        {getOption("Remortgage", "REMORTGAGE")}
                        {getOption("Buy-to-let remortgage", "BTL_REMORTGAGE")}
                        {getOption("Buy-to-let", "BTL")}
                        {getOption("Protection", "PROTECTION")}
                        {getOption("Business protection", "BUSINESS_PROTECTION")}
                        {getOption("Equity release", "EQUITY_RELEASE")}
                        {getOption("Bridging finance", "BRIDGING")}
                        {getOption("Commercial mortgage", "COMMERCIAL")}
                    </select>
                    {/* <div className='required'>*</div> */}
                </div>
            </div>

            <div className='further-sub-heading' style={{ margin: "15px 15px -10px", fontWeight: "600" }}>Mortgage term</div>
            <div className='input_contain'>
                <div className='input_wrap'>
                    <input value={props.store?.preference_term || undefined} type="number" placeholder='25' onChange={e => props.add("preference_term", parseInt(e.currentTarget.value) || undefined)} />
                    <div className='following_label'>
                        years
                    </div>
                    {/* <div className='required'>*</div> */}
                </div>
            </div>

            <button disabled={!props.store.preference_mortgage_reason || !props.store.preference_term} className='capBtn' style={{ margin: "15px" }} onClick={() => props.next()}>
                Next
            </button>
        </div>
    );

    function getOption(text, value) {
        return <option value={"REASON_" + value} style={{ color: "black" }}>{text}</option>
    }
}

export default MortgageType;