import React, { useEffect, useState } from 'react';
import Selection from '../../../selection';

function AdditionalIncome2(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        reference: props.store.first_name_2 + "'s details",
                        heading: "Does " + props.store.first_name_2 + " have any additional income?",
                        content: "This includes things like a car allowance or a second job.",
                        input: {
                            type: "number",
                            placeholder: "0",
                            id: "additional_income_2",
                            next: props.switch + 1
                        }
                    }} id={"additional_income_2"} setValue={(e) => props.setValue({ ...props.store, additional_income_2: e })} />
                )

            // case props.switch + 1:
            //     return (
            //         <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
            //             reference: props.store.first_name_2 + "'s details",
            //             heading: `What is ${props.store.first_name_2}'s net monthly income?`,
            //             content: "This is the monthly income after all deductions have been taken, such as income tax and national insurance.",
            //             input: {
            //                 type: "number",
            //                 placeholder: "0",
            //                 id: "net_monthly_income_2",
            //                 next: props.switch + 2
            //             }
            //         }} id={"net_monthly_income_2"} setValue={(e) => props.setValue({ ...props.store, net_monthly_income_2: e })} />
            //     )

            default:
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default AdditionalIncome2;