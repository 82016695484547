import React, { Component } from 'react';
import "./landing_sparse.sass";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { FaWindows } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import settings from './settings';

const url = "https://free.us19.list-manage.com/subscribe/post?u=d07117df69703cf35c69b27be&amp;id=7cc3bafab4";

class Landing_Sparse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email_valid: false
        }
    }

    componentDidMount() {
        let c = document.querySelector("#m div").children;
        c[1].setAttribute("id", "mailchimp_submit");
        c[0].setAttribute("id", "email_field");

        let validator = require("email-validator");

        document.getElementById("mailchimp_submit").addEventListener("click", function () {
            if (this.state.email_valid) {
                setTimeout(function () {
                    window.location.href = "/thanks";
                }, 500);
            }
        }.bind(this));

        document.getElementById("email_field").addEventListener("input", function () {
            if (validator.validate(this.value)) {
                this.setState({
                    email_valid: true
                });
            }
        }.bind(this));
    }

    render() {
        return (
            <div className="landing" style={{ paddingBottom: this.props.lower ? "115px" : "" }}>
                <div className="display">
                    {/* <div className="logo_wrap">
                        <img className="logo" src={require("./img/logo.svg")} alt="Free logo" />
                    </div> */}

                    <div className="heading" style={{marginTop: "40px"}}>
                        MORE <div className="free">SIMPLE</div> MONEY
                    </div>
                    <div className="mission">
                        We are making mortgage applications simple.
                    </div>
                    <div className="heading" style={{ paddingTop: "0px" }}>
                        <div className="free">SIMPLE</div> IS BETTER.
                    </div>

                    {/* <div className="content_section">
                        <div className="content_heading">
                            Here to apply for a mortgage?
                        </div>
                        <div className="content_content">
                            Are you interested in getting a mortgage, but aren't sure what steps to take? Let us help!
                            <br />
                            We will help guide you through all the necessary steps to obtaining your mortgage.
                        </div>
                        <ul>
                            <li>
                                <div className="tool" onClick={() => window.location.href = "/optimiser"}>
                                    Step 1: 
                                </div>
                            </li>
                            <li>
                                <div className="tool" onClick={() => window.location.href = "/calculator"}>
                                    Stamp Duty Calculator
                                </div>
                            </li>
                            <li>
                                <div className="tool" onClick={() => window.location.href = "/valuator"}>
                                    House Valuator
                                </div>
                            </li>
                        </ul>
                    </div> */}

                    <div className="content_section">
                        <div className="content_heading">
                            Would you like to keep up to date with what we're working on?
                        </div>

                        <br />

                        <div className="meantime_section">
                            <div className="heading">
                                Join the mailing list
                            </div>
                            <div className="mail_chimp" id="m">
                                <MailchimpSubscribe url={url} />
                            </div>
                        </div>

                        <div className="meantime_section">
                            <div className="heading">
                                Chat to us on Whatsapp
                            </div>
                            <button style={{ backgroundColor: "#25D366", color: "white", border: "1px solid white" }} className="btn" onClick={() => window.open("https://wa.me/447590501969", "_blank")}>
                                <img src={require("./img/WhatsApp.png")} className="whatsapp_logo" alt="Whatsapp" /> Chat
                            </button>
                        </div>

                        <div className="meantime_section">
                            <div className="heading">
                                Book a call
                            </div>
                            <button className="btn" onClick={() => window.open("https://calendly.com/free40/book", "_blank")}>
                                Book
                            </button>
                        </div>

                        {/* <div className="or_hr">
                            <div className="or_divide">
                                <div className="or">
                                    OR
                            </div>
                            </div>
                        </div> */}

                        {/* <div style={{ cursor: "pointer" }} className="meantime_section" onClick={() => document.getElementById("tools").scrollIntoView({ behavior: "smooth" })}>
                            <div className="heading">
                                View our tools and videos
                            </div>
                            <div className="arrow">
                                <i class="fas fa-arrow-down"></i>
                            </div>
                        </div> */}
                    </div>

                    <div className="content_section">
                        <div className="content_heading">
                            So... what are we working on?
                        </div>
                        <div className="content_content">
                            Currently, we are creating a system in which users can view all the rates that lenders will offer based on your credit history.
                            With this system, you will be able to plug in all of your details and receive up-to-date, accurate product offers.
                            We hope to expand this further to include mortgage sourcing, affordability and open banking.
                        </div>
                        <div style={{ textAlign: "center" }}>
                            {/* <Slider className="slideshow" {...settings}>
                                <div className="img_wrap"><img src={require("./img/ss_1.png")} className="project_image" alt="Project" ></img></div>
                                <div className="img_wrap"><img src={require("./img/ss_3.png")} className="project_image" alt="Project" ></img></div>
                                <div className="img_wrap"><img src={require("./img/ss_2.png")} className="project_image" alt="Project" ></img></div>
                            </Slider> */}
                            <iframe className="adverse_widget" src="https://free.co.uk/adverse_credit_prototype"></iframe>
                        </div>
                    </div>
                </div>

                {/* <div className="other_section">
                    <div id="tools" className="tools">
                        <div className="heading">
                            Our other tools...
                    </div>
                        <ul>
                            <li>
                                <div className="tool" onClick={() => window.location.href = "/optimiser"}>
                                    Rightmove Optimiser
                            </div>
                            </li>
                            <li>
                                <div className="tool" onClick={() => window.location.href = "/calculator"}>
                                    Stamp Duty Calculator
                            </div>
                            </li>
                            <li>
                                <div className="tool" onClick={() => window.location.href = "/valuator"}>
                                    House Valuator
                            </div>
                            </li>
                        </ul>
                    </div> */}

                {/* <div className="videos">
                        <div className="heading">
                            Our videos...
                    </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/DE9h42bT-_c" title='Runners'></iframe>
                        </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/t_PZtH-COgY" title='Postman'></iframe>
                        </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/SMedxSX2iWc" title='Beautiful game'></iframe>
                        </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/V2-tsKguZwY" title='Life partner'></iframe>
                        </div>
                        <div className="video">
                            <iframe className="video" src="https://www.youtube.com/embed/AUiM_9cv-kg" title='Love song'></iframe>
                        </div>
                    </div> */}
                {/* </div> */}
            </div>
        );
    }
}

export default Landing_Sparse;