import React, { useEffect, useState } from 'react';
import Selection from '../../selection';

function PaydayLoan(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        if (props.page === props.switch + 3 && props.store.payday_loan_date !== undefined && props.store.payday_loan_settled !== undefined) {
            let store = { ...props.store }
            let payday_loan_date = store.payday_loan_date
            let payday_loan_settled = store.payday_loan_settled
            delete store.payday_loan_date
            delete store.payday_loan_settled
            props.setValue({ ...store, payday_loans: [...props.store.payday_loans, { payday_loan_date: payday_loan_date, payday_loan_settled: payday_loan_settled }] })
        }
        setDisplay(displayPage())
    }, [props.page, props.store])

    function remove_payday_loan(i) {
        props.store.payday_loans.splice(i, 1)
        props.setValue({ ...props.store, payday_loans: props.store.payday_loans })
    }

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Have you ever had a payday loan?",
                        info: {
                            id: "payday_loan_modal",
                            text: "What is a payday loan?",
                            content: "A payday loan is a short-term loan that can help you cover immediate cash needs until you get your next paycheck.",
                            content2: "Generally, lenders don’t like payday loans because they take it as a sign you might find yourself in financial difficulties in the future."
                        },
                        buttons: {
                            "Yes": {
                                value: "YES",
                                next: props.switch + 1
                            },
                            "No": {
                                value: "NO",
                                next: props.switch + 4
                            }
                        }
                    }} id={"had_a_payday_loan"} setValue={(e) => props.setValue({ ...props.store, had_a_payday_loan: e, payday_loans: [] })} />
                )

            case props.switch + 1:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When did you last take a payday loan?",
                        input: {
                            type: "month",
                            id: "payday_loan_date",
                            min: 7,
                            next: props.switch + 2
                        }
                    }} id={"payday_loan_date"} setValue={(e) => props.setValue({ ...props.store, payday_loan_date: e })} />
                )

            case props.switch + 2:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "When was the payday loan settled?",
                        input: {
                            type: "month",
                            id: "payday_loan_settled",
                            min: 7,
                            next: props.switch + 3
                        }
                    }} id={"payday_loan_settled"} setValue={(e) => props.setValue({ ...props.store, payday_loan_settled: e })} />
                )

            case props.switch + 3:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "Please list all payday loans taken since May 2019",
                        info: {
                            id: "payday_loan_modal_2",
                            text: "What is a payday loan?",
                            content: "A payday loan is a short-term loan that can help you cover immediate cash needs until you get your next paycheck.",
                            content2: "Generally, lenders don’t like payday loans because they take it as a sign you might find yourself in financial difficulties in the future."
                        },
                        collection: {
                            type: "payday_loan",
                            add: props.switch + 1
                        },
                        next: props.switch + 4
                    }} id={"payday_loan_collection"} setValue={(e) => { }} remove={(e) => remove_payday_loan(e)} />
                )
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default PaydayLoan;