import React, { useEffect, useState } from 'react';
import Selection from '../selection';

function Intro(props) {
    const [display, setDisplay] = useState(displayPage())

    useEffect(() => {
        setDisplay(displayPage())
    }, [props.page, props.store])

    function displayPage() {
        switch (props.page) {
            case props.switch:
                return (
                    <Selection prev={props.prev} setPrev={props.setPrev} store={props.store} setPage={props.setPage} page={{
                        heading: "We can help you find a mortgage",
                        content: "What would you like to do next?",
                        buttons: {
                            "Arrange a call": {
                                value: 1,
                                link: "https://calendly.com/free40/book"
                            },
                            "Complete your application": {
                                value: 2,
                                next: props.switch + 1
                            },
                            "Save your details": {
                                value: 2,
                                next: props.switch + 117
                            },
                        },
                        content3: "Been here before?",
                        sub_button: {
                            "Login": {
                                auth: true,
                                success: props.switch + 116,
                                fail: props.switch + 1
                            }
                        }
                    }} id={"what_next"} setValue={(e) => props.setValue(e)} />
                )

            default:
        }
    }

    return (
        <div>
            {display}
        </div>
    );
}

export default Intro;