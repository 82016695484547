function additionalRules(lender, info, issue, time) {
    let maxLTV = 95
    if (!info.LTV["95%"]) maxLTV = 90
    if (!info.LTV["90%"]) maxLTV = 85
    if (!info.LTV["85%"]) maxLTV = 80
    if (!info.LTV["80%"]) maxLTV = 75
    if (!info.LTV["75%"]) maxLTV = 70
    if (!info.LTV["70%"]) maxLTV = 65

    if (lender === "Darlington BS") {
        if (issue === "CCJ") {
            if (time === "last 36 months" || time === "last 24 months" || time === "last 12 months" || time === "last 6 months") maxLTV = getLTV(maxLTV, 65)
            else maxLTV = getLTV(maxLTV, 80)
        } else if (issue === "Defaults") {
            if (time === "last 24 months") maxLTV = getLTV(maxLTV, 80)
            else if (time === "last 12 months" || time === "last 6 months") maxLTV = getLTV(maxLTV, 70)
            else maxLTV = getLTV(maxLTV, 80)
        } else if (issue === "Missed Secured Payment" || issue === "Missed Unsecured Payment") {
            if (time === "last 24 months" || time === "last 12 months" || time === "last 6 months") maxLTV = getLTV(maxLTV, 65)
            else maxLTV = getLTV(maxLTV, 80)
        } else if (issue === "Bankruptcy" || issue === "IVA") {
            if (time === "last 3 years") maxLTV = getLTV(maxLTV, 65)
            else if (time === "last 6 years") maxLTV = getLTV(maxLTV, 70)
            else maxLTV = getLTV(maxLTV, 80)
        } else if (issue === "DMP") {
            if (time === "last 3 years") maxLTV = getLTV(maxLTV, 80)
            else maxLTV = getLTV(maxLTV, 80)
        } else if (issue === "Repossession") {
            if (time === "last 3 years") maxLTV = getLTV(maxLTV, 70)
            else maxLTV = getLTV(maxLTV, 80)
        }
    }

    if (lender === "Ipswich BS") {
        if (issue === "CCJ") {
            if (time === "last 12 months" || "last 6 months") maxLTV = getLTV(maxLTV, 75)
        } else if (issue === "Defaults") {
            if (time === "last 12 months" || "last 6 months") maxLTV = getLTV(maxLTV, 75)
        }
    }

    if (lender === "Hinckley and Rugby") {
        if (issue === "CCJ") {
            if (time === "> 36 months") maxLTV = getLTV(maxLTV, 75)
        } else if (issue === "Missed Secured Payment") {
            if (time === "last 6 months" || time === "last 12 months" || time === "last 24 months") maxLTV = getLTV(maxLTV, 75)
        } else if (issue === "Bankruptcy") {
            if (time === "> 6 months") maxLTV = getLTV(maxLTV, 75)
        } else if (issue === "IVA" || issue === "DMP") {
            if (time === "last 6 years" || time === "> 6 years") maxLTV = getLTV(maxLTV, 75)
        }
    }

    if (lender === "Leek Utd") {
        if(issue === "CCJ") {
            if (time !== "> 36 months") maxLTV = getLTV(maxLTV, 70)
        }
    }

    if (maxLTV > 90) {
        info.LTV["95%"] = true
        info.LTV["90%"] = true
        info.LTV["85%"] = true
        info.LTV["80%"] = true
        info.LTV["75%"] = true
        info.LTV["70%"] = true
    } else if (maxLTV > 85) {
        info.LTV["95%"] = false
        info.LTV["90%"] = true
        info.LTV["85%"] = true
        info.LTV["80%"] = true
        info.LTV["75%"] = true
        info.LTV["70%"] = true
    } else if (maxLTV > 80) {
        info.LTV["95%"] = false
        info.LTV["90%"] = false
        info.LTV["85%"] = true
        info.LTV["80%"] = true
        info.LTV["75%"] = true
        info.LTV["70%"] = true
    } else if (maxLTV > 75) {
        info.LTV["95%"] = false
        info.LTV["90%"] = false
        info.LTV["85%"] = false
        info.LTV["80%"] = true
        info.LTV["75%"] = true
        info.LTV["70%"] = true
    } else if (maxLTV > 70) {
        info.LTV["95%"] = false
        info.LTV["90%"] = false
        info.LTV["85%"] = false
        info.LTV["80%"] = false
        info.LTV["75%"] = true
        info.LTV["70%"] = true
    } else if (maxLTV > 65) {
        info.LTV["95%"] = false
        info.LTV["90%"] = false
        info.LTV["85%"] = false
        info.LTV["80%"] = false
        info.LTV["75%"] = false
        info.LTV["70%"] = true
    } else if (maxLTV < 70) {
        info.LTV["95%"] = false
        info.LTV["90%"] = false
        info.LTV["85%"] = false
        info.LTV["80%"] = false
        info.LTV["75%"] = false
        info.LTV["70%"] = false
    }

    return info
}

function getLTV(ltv, value) {
    if (ltv > value) {
        return value
    } else {
        return ltv
    }
}

export default additionalRules