import React from 'react';

function Rates(props) {
    return (
        <div className='milo_rates milo_section'>
            <div className='header'>
                Today's Current Rates
            </div>
        </div>
    );
}

export default Rates;