import React, { useEffect } from 'react';
import MiloBenefits from './benefits';
import "./index.sass"
import Rates from './rates';

function Milo(props) {
    useEffect(() => {

    }, [])

    return (
        <div className={props.match?.params?.style ? `milo_wrap milo_${props.match.params.style}` : "milo_wrap"}>
            <div className="milo">
                <div className='cta'>
                    <div className='header'>
                        <div className='top'>
                            A mortgage has never been easier
                        </div>
                        <div className='bottom'>
                            We reshaped the mortgage process so you can apply and close completely online.
                        </div>
                        <button className='apply_btn'>
                            Apply
                        </button>
                    </div>
                    <div className='images'>
                        Images
                    </div>
                </div>

                <MiloBenefits />
                <Rates />
            </div>
        </div>
    );
}

export default Milo;